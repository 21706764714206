import React from 'react';
import { useState } from 'react';
import { Button } from 'antd';
import { createUser, signup } from '../../helpers/api';
import { createNotification } from '../../helpers/utils';
import { USER_LOGIN } from '../../actions/user.actions';
import { connect } from 'react-redux';

function CreateNewUser({ loadAllUsers, createAdmin }) {
	const [data, setData] = useState({ name: '', email: '', password: '' });
	async function newUser() {
		if (data.name?.length && data.email?.length && data.password?.length && data.password2?.length) {
			if (data.password !== data.password2) {
				createNotification({ type: 'danger', title: 'Passwords do not match' });
				return false;
			}
			try {
				if (createAdmin) {
					await createAdmin({ ...data });
				} else {
					// await createUser({ ...data });
					await signup({ ...data, isCreateUser: true });
				}
				createNotification({ type: 'success', title: 'A new user created' });
				loadAllUsers();
				const timeOut = setTimeout(() => {
					setData({ name: '', email: '', password: '', password2: '' });
					clearTimeout(timeOut)
				}, 300);
			} catch (e) {
				createNotification({
					type: 'danger',
					title: e.message,
					message: e.response?.data?.error || e.response?.data?.message
				});
			}
		} else {
			createNotification({ type: 'danger', title: 'All the fields are required to fill' });
		}
	}
	return (
		<div>
			<div className="m-b:1">
				<div className="t-a:l f-w:100 sub-title2 f:.8">Name</div>
				<div className="p-t:.2 circular">
					<span
						class="ant-input-affix-wrapper search-box-left-panel"
						style={{ borderRadius: 6, padding: '2px 12px ' }}
					>
						<input
							value={data.name}
							onChange={(e) => setData({ ...data, name: e.target.value })}
							style={{ padding: 5 }}
							type="text"
							className="ant-input circular f-w:100 p-l:.1 search-left-panel"
						/>
					</span>
				</div>
			</div>

			<div className="m-b:1">
				<div className="t-a:l f-w:100 sub-title2 f:.8">Email</div>
				<div className="p-t:.2 circular">
					<span
						class="ant-input-affix-wrapper search-box-left-panel"
						style={{ borderRadius: 6, padding: '2px 12px ' }}
					>
						<input
							value={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
							style={{ padding: 5 }}
							type="email"
							className="ant-input circular f-w:100 p-l:.1 search-left-panel"
						/>
					</span>
				</div>
			</div>

			<div className="m-b:1">
				<div className="t-a:l f-w:100 sub-title2 f:.8">Password</div>
				<div className="p-t:.2 circular">
					<span
						class="ant-input-affix-wrapper search-box-left-panel"
						style={{ borderRadius: 6, padding: '2px 12px ' }}
					>
						<input
							value={data.password}
							onChange={(e) => setData({ ...data, password: e.target.value })}
							style={{ padding: 5 }}
							type="password"
							className="ant-input circular f-w:100 p-l:.1 search-left-panel"
						/>
					</span>
				</div>
			</div>

			<div className="m-b:1">
				<div className="t-a:l f-w:100 sub-title2 f:.8">Re-type password</div>
				<div className="p-t:.2 circular">
					<span
						class="ant-input-affix-wrapper search-box-left-panel"
						style={{ borderRadius: 6, padding: '2px 12px ' }}
					>
						<input
							value={data.password2}
							onChange={(e) => setData({ ...data, password2: e.target.value })}
							style={{ padding: 5 }}
							type="password"
							className="ant-input circular f-w:100 p-l:.1 search-left-panel"
						/>
					</span>
				</div>
			</div>

			<div className="t-a:r m-t:2">
				<Button
					type="primary"
					className="circular create-btn f-w:100 sub-title"
					size="middle"
					onClick={() => newUser()}
				>
					Create
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadAllUsers: () => dispatch({ type: USER_LOGIN })
	};
};
CreateNewUser = connect(mapStateToProps, mapDispatchToProps)(CreateNewUser);
export default CreateNewUser;
