import React from 'react';
import PlusIcon from './SvgComponents/PlusIcon';
import CreateNewGroup from '../ModalComponents/CreateNewGroup';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

function Channels({
	allRooms,
	selectRoom,
	selectedRoom,
	search,
	loadRoomMessage,
	unreadCounts,
	refereshUnreadMessageCount
}) {
	const [parentDivWidth, setParentDivWidth] = useState();
	window.addEventListener('resize', () => {
		updateWidth();
	});

	function updateWidth() {
		let parentElem = document.getElementsByClassName('left-panel')[0];
		setParentDivWidth && parentElem && parentElem.offsetWidth && setParentDivWidth(parentElem.offsetWidth);
	}
	useEffect(() => {
		updateWidth();
	}, []);

	return (
		<div className="p:1 circular p-t:.5">
			<div>
				<div className="f-w:100 sub-title2">Groups</div>
				<div
					className="fl:r channel-plus-icon-box"
					data-tip
					data-for="setting3"
					data-tip
					data-for="addTeam"
					onClick={() => window.setmodal({ title: 'Create a new group', body: <CreateNewGroup /> })}
				>
					<PlusIcon width="9px" height="9px" />
				</div>
			</div>
			<div className="groups-list p-t:.6 p-l:.2">
				{Object.keys(allRooms)
					.filter((roomId) => {
						if (allRooms[roomId].isDmRoom) {
							return false;
						}
						if (search === '' || !search) {
							return true;
						}
						if (search) {
							var searchValue = new RegExp(search.toLowerCase());
							if (searchValue.test(allRooms[roomId].name.toLowerCase())) {
								return true;
							}
						}
					})

					.map((roomId) => {
						return [
							<div
								key={roomId}
								className={`m-b:.4 p-b:.0 p-t:.0 p-r:.4 p-l:.4 cursor v-a:m truncate ${
									selectedRoom && roomId == selectedRoom.roomId ? 'active-group' : 'group'
								} `}
								onClick={() => {
									selectRoom(roomId);
									loadRoomMessage(roomId);
									localStorage.setItem(roomId, 0);
									refereshUnreadMessageCount();
								}}
							>
								<UnreadCountComponent roomId={roomId} />
								<span className="m-r:.5">#</span>{' '}
								<span className="crcular f-w:100">{allRooms[roomId].name}</span>
							</div>
							// ,
							// selectedRoom && roomId == selectedRoom.roomId && parentDivWidth ? (
							// 	<div
							// 		className="selected-room-bg selected-channel"
							// 		style={{ width: parentDivWidth }}
							// 	></div>
							// ) : null
						];
					})}
			</div>
		</div>
	);
}

function UnreadCountComponent({ roomId, unreadCounts }) {
	const [unreadCount, setUnreadCount] = useState(unreadCounts[roomId]);

	useEffect(() => {
		setUnreadCount(unreadCounts[roomId]);
	}, [unreadCounts[roomId]]);

	return unreadCount && parseInt(unreadCount) >= 1 ? (
		<div className="unread-count">
			{unreadCount >= 9 ? (
				<span className="v-a:m">
					9<sup>+</sup>
				</span>
			) : (
				<span className="v-a:m">{unreadCount}</span>
			)}
		</div>
	) : null;
}
const mapStateToProps = (state) => {
	return {
		unreadCounts: JSON.parse(JSON.stringify(state.rooms.unreadCounts))
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};
UnreadCountComponent = connect(mapStateToProps, mapDispatchToProps)(UnreadCountComponent);

export default Channels;
