import classnames from 'classnames';
import { createBrowserHistory } from 'history';
import isElectron from 'is-electron';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
	DISCONNECT_LAST_USER,
	PEER_CALL_LEFT,
	SET_DISABLE_AUDIO_ONLY_STATE,
	SET_ENABLE_AUDIO_ONLY_STATE,
	SET_MUTE_AUDIO_ONLY_STATE,
	SET_UNMUTE_AUDIO_ONLY_STATE,
	START_NEW_CALL
} from '../../actions/call.actions';
import * as requestActions from '../../actions/request.actions';
import Notifications from './Notifications';
import Peers from './Peers';
import { Appear } from './transitions';

let electron = null;

if (isElectron() && typeof window?.require === 'function') {
	electron = window.require('electron');
}

const history = createBrowserHistory();

function Room({ room, me, disableAudioOnly, enableAudioOnly, muteAudio, unmuteAudio, closeCall, pointerShareEvent }) {
	useEffect(() => {
		// startNewCall({
		// 	// oneToOneRoomId: roomId,
		// 	user: me,
		// 	invitedUser: me
		// });
		return () => {
			console.log('Rerender');
			closeCall();
		};
	}, []);

	return (
		<Appear duration={300}>
			<div data-component="Room">
				<Notifications />

				<div className="state">
					<div className={classnames('icon', room.state)} />
					<p className={classnames('text', room.state)} style={{ height: 5 }}>
						{room.state}
					</p>
				</div>

				<Peers />

				{/* <div
					style={{ width: 180, borderRadius: 200, borderWidth: 5 }}
					className={classnames('me-container', {
						'active-speaker': amActiveSpeaker
					})}
				>
					<Me />
				</div> */}

				{/* <div className='chat-input-container'>
						<ChatInput />
					</div> */}

				<div className="sidebar">
					<div
						data-place="right"
						className={classnames('button', 'hide-videos', {
							on: me.audioOnly,
							disabled: me.audioOnlyInProgress
						})}
						data-tip={"Show/hide participants' video"}
						onClick={() => {
							me.audioOnly ? disableAudioOnly() : enableAudioOnly();
						}}
					/>

					<div
						data-place="right"
						className={classnames('button', 'mute-audio', {
							on: me.audioMuted
						})}
						data-tip={"Mute/unmute participants' audio"}
						onClick={() => {
							me.audioMuted ? unmuteAudio() : muteAudio();
						}}
					/>

					{isElectron() &&
						Object.keys(window.store.getState().producers).findIndex(
							(p) => window.store.getState().producers[p].type === 'share'
						) > -1 &&
						me.allowPonterShare && (
							<div
								data-place="right"
								className={classnames('button', 'pointer-sharing', {
									on: me.pointerShareEvent
								})}
								data-tip={'On/off mouse pointer sharing'}
								onClick={() => {
									pointerShareEvent(!me.pointerShareEvent);
								}}
							/>
						)}

					{/* 
						<div
							data-place="right"
							className={classnames('button', 'restart-ice', {
								disabled: me.restartIceInProgress
							})}
							data-tip="Restart ICE"
							onClick={() => roomClient.restartIce()}
						/> */}
					<div
						className="button"
						data-place="right"
						data-tip="End call"
						style={{ marginTop: 7, background: '#ed4245', borderRadius: 100 }}
						// onClick={async () => {
						// 	await roomClient.close();
						// 	// let streamLength = window.stream.getTracks().length;
						// 	// window.stream.getTracks().forEach((track, index) => {
						// 	// track.stop();
						// 	// if (index == streamLength - 1) {
						// 	this.props.endCall();
						// 	// window.location.reload();
						// 	// }
						// 	// });
						// }}
						onClick={closeCall}
					>
						<svg
							className="controlIcon-35oS15 centerIcon-2G6o-T"
							aria-hidden="false"
							width
							height
							viewBox="0 0 24 24"
							style={{
								/* background: '#ed4245', */
								/* borderRadius: '100px', */
								height: '24px',
								width: '24px',
								verticalAlign: 'middle',
								textAlign: 'center',
								marginLeft: '8px',
								marginTop: '8px'
							}}
						>
							<path
								fill="currentColor"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M21.1169 1.11603L22.8839 2.88403L19.7679 6.00003L22.8839 9.11603L21.1169 10.884L17.9999 7.76803L14.8839 10.884L13.1169 9.11603L16.2329 6.00003L13.1169 2.88403L14.8839 1.11603L17.9999 4.23203L21.1169 1.11603ZM18 22H13C6.925 22 2 17.075 2 11V6C2 5.447 2.448 5 3 5H7C7.553 5 8 5.447 8 6V10C8 10.553 7.553 11 7 11H6C6.063 14.938 9 18 13 18V17C13 16.447 13.447 16 14 16H18C18.553 16 19 16.447 19 17V21C19 21.553 18.553 22 18 22Z"
								style={{ width: '24px', height: '24px' }}
							/>
						</svg>
					</div>
				</div>

				<ReactTooltip type="light" effect="solid" delayShow={100} delayHide={100} delayUpdate={50} />
			</div>
		</Appear>
	);
}

// Room.propTypes = {
// 	room: appPropTypes.Room.isRequired,
// 	me: appPropTypes.Me.isRequired,
// 	amActiveSpeaker: PropTypes.bool.isRequired,
// 	onRoomLinkCopy: PropTypes.func.isRequired
// };

const mapStateToProps = (state) => {
	return {
		room: state.room,
		me: state.me,
		amActiveSpeaker: state.me.id === state.room.activeSpeakerId
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRoomLinkCopy: () => {
			requestActions.notify({
				text: 'Room link copied to the clipboard'
			});
		},
		disableAudioOnly: () => dispatch({ type: SET_DISABLE_AUDIO_ONLY_STATE }),
		enableAudioOnly: () => dispatch({ type: SET_ENABLE_AUDIO_ONLY_STATE }),
		muteAudio: () => dispatch({ type: SET_MUTE_AUDIO_ONLY_STATE }),
		unmuteAudio: () => dispatch({ type: SET_UNMUTE_AUDIO_ONLY_STATE }),
		startNewCall: (payload) => dispatch({ type: START_NEW_CALL, payload }),
		closeCall: () => {
			dispatch({ type: DISCONNECT_LAST_USER });
			dispatch({ type: PEER_CALL_LEFT, payload: { peerId: window.store.getState().me._id } });
		},
		pointerShareEvent: (enable) => dispatch({ type: 'SET_MOUSE_POINTER_ENABLED', payload: enable })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
