import { useState, useRef, useEffect } from 'react';

function useDimentionObserver(elRef) {
    const [dimentions, setDimentions] = useState(null);
    const observer = useRef(
        new ResizeObserver((entries) => {
            if (entries?.length) {
                const { width, height } = entries[0].contentRect;
                setDimentions({
                    width,
                    height
                });
            }
        })
    );
    useEffect(() => {
        if (elRef?.current) {
            observer?.current?.observe(elRef.current);
        }
        return () => {
            if (elRef?.current) {
                observer?.current?.unobserve(elRef.current);
            }
        };
    }, [elRef, observer]);
    return dimentions;
}

useDimentionObserver.propTypes = {};

export default useDimentionObserver;
