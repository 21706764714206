import CONSTS from '../helpers/commonConsts';
import {
	LOGGED_IN,
	LOGGED_OUT,
	MODAL_UPDATED,
	SOCKET_STATUS,
	UPDATE_PIC,
	UPDATE_CURRENT_USER_PROFILE,
	STATUS_CHANGED,
	KNOCK_SOUND_ON,
	KNOCK_SOUND_OFF,
	FILE_REMOVED,
	FILE_ADDED,
	CHANGE_NAME,
	SWITCH_TEAM
} from '../actions/user.actions';

import {
	SET_CALL_ROOM_STATE,
	SET_MEDIA_CAPABILITIES,
	SET_SHARE_IN_PROGRESS,
	SET_AUDIO_ONLY_STATE,
	SET_AUDIO_ONLY_IN_PROGRESS
} from '../actions/call.actions';

import { RESET_ALL_STATES } from '../actions/init.actions'

const initialState = {
	id: null,
	displayName: null,
	displayNameSet: false,
	device: null,
	canSendMic: false,
	canSendWebcam: false,
	canChangeWebcam: false,
	webcamInProgress: false,
	shareInProgress: false,
	audioOnly: false,
	audioOnlyInProgress: false,
	audioMuted: false,
	restartIceInProgress: false,
	isWindowScreenShareOpen: false,
	isLoggedIn: false,
	createdAt: null,
	isAdmin: false,
	name: '',
	token: '',
	updatedAt: null,
	username: '',
	imgData: '',
	socketStatus: CONSTS.SOCKET_STATUS.DISCONNECTED,
	status: CONSTS.STATUS.ONLINE,
	knockSound: false,
	knockFrom: {},
	profilePic: null,
	modal: {
		show: false,
		ModalBody: null,
		title: null
	},
	file: '',
	peers: {},
	pointerShareEvent: false,
	sendMouseEvenet: false,
	allowPonterShare: true,
};

const me = (state = initialState, action) => {
	switch (action.type) {
		case SET_CALL_ROOM_STATE: {
			const roomState = action.payload.state;

			if (roomState === 'closed') {
				return {
					...state,
					webcamInProgress: false,
					shareInProgress: false,
					audioOnly: false,
					audioOnlyInProgress: false,
					audioMuted: false,
					restartIceInProgress: false,
					isWindowScreenShareOpen: false,
					peers: {}
				};
			} else {
				return state;
			}
		}

		case 'SET_ME': {
			const { peerId, displayName, displayNameSet, device } = action.payload;

			return { ...state, id: peerId, displayName, displayNameSet, device };
		}

		case SET_MEDIA_CAPABILITIES: {
			const { canSendMic, canSendWebcam } = action.payload;
			return { ...state, canSendMic, canSendWebcam };
		}

		case 'SET_CAN_CHANGE_WEBCAM': {
			const canChangeWebcam = action.payload;

			return { ...state, canChangeWebcam };
		}

		case 'SET_WEBCAM_IN_PROGRESS': {
			const { flag } = action.payload;

			return { ...state, webcamInProgress: flag };
		}

		case SET_SHARE_IN_PROGRESS: {
			const { flag } = action.payload;

			return { ...state, shareInProgress: flag };
		}

		case 'SET_DISPLAY_NAME': {
			let { displayName } = action.payload;

			// Be ready for undefined displayName (so keep previous one).
			if (!displayName) displayName = state.displayName;

			return { ...state, displayName, displayNameSet: true };
		}

		case SET_AUDIO_ONLY_STATE: {
			const { enabled } = action.payload;
			return { ...state, audioOnly: enabled };
		}

		case SET_AUDIO_ONLY_IN_PROGRESS: {
			const { flag } = action.payload;

			return { ...state, audioOnlyInProgress: flag };
		}

		case 'SET_AUDIO_MUTED_STATE': {
			const { enabled } = action.payload;

			return { ...state, audioMuted: enabled };
		}

		case 'SET_RESTART_ICE_IN_PROGRESS': {
			const { flag } = action.payload;

			return { ...state, restartIceInProgress: flag };
		}
		case 'TOGGLE_WINDOW_SCREEN_SHARE': {
			const { isWindowScreenShareOpen } = action.payload;

			return { ...state, isWindowScreenShareOpen };
		}

		case 'SET_MOUSE_POINTER_ENABLED': {
			return { ...state, pointerShareEvent: action.payload };
		}
		case 'SET_ALLOW_MOUSE_POINTER': {
			return { ...state, allowPonterShare: action.payload };
		}

		case 'SET_SEND_MOUSE_EVENT': {
			return { ...state, sendMouseEvenet: action.payload };
		}

		case 'LEAVE_CALL':
			return { ...state, sendMouseEvenet: false, pointerShareEvent: false, activeCallId: null, serverUrl: null };

		case 'END_CALL':
			return { ...state, sendMouseEvenet: false, pointerShareEvent: false };

		case LOGGED_IN:
			return {
				...state,
				isLoggedIn: true,
				...action.payload.user
			};

		case LOGGED_OUT:
			return {
				...state,
				isLoggedIn: false
			};

		case KNOCK_SOUND_ON:
			return {
				...state,
				...action.payload,
				knockSound: true,
			};

		case KNOCK_SOUND_OFF:
			return {
				...state,
				...action.payload,
				knockSound: false
			};

		case UPDATE_PIC:
			return {
				...state,
				lastSnap: action.payload.imgData
			};
		case SWITCH_TEAM:
			return {
				...state,
				teamId: action.payload.teamId
			};
		case UPDATE_CURRENT_USER_PROFILE:
			return {
				...state,
				profilePic: action.payload.profilePic
			};
		case MODAL_UPDATED:
			return {
				...state,
				modal: {
					...action.payload
				}
			};
		case SOCKET_STATUS:
			return {
				...state,
				socketStatus: action.payload.socketStatus
			};

		case STATUS_CHANGED:
			return {
				...state,
				status: action.status
			};
		case CHANGE_NAME:
			let obj = {};
			const localUser = localStorage.getItem('user');
			if (localUser) {
				obj = JSON.parse(localUser);
				obj.name = action.name;
			}
			localStorage.setItem('user', JSON.stringify(obj));
			return {
				...state,
				displayName: action.name,
				name: action.name
			};
		case FILE_ADDED:
			return {
				...state,
				file: action.payload.file
			};
		case FILE_REMOVED:
			return {
				...state,
				file: ''
			};

		default:
			return state;
	}
};

export default me;
