import { useWindowWidth } from '@react-hook/window-size';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { TRY_LOGOUT, UPDATE_MODAL } from './actions/user.actions';
import UserStatusIcon from './components/LeftPanel/Rooms/UserStatusIcon';
import PanelSettings from './components/ModalComponents/PanelSettings';
import { STATUS } from './helpers/commonConsts';

import './ToolTips.scss'

function ToolTips({ me, changeStatus, logout, updateModal }) {
	const windowWidth = useWindowWidth();
	return (
		<div>
			<ReactTooltip id="addTeam" type="info" place="right" effect="solid">
				<span className="circular f:1">Create a new group</span>
			</ReactTooltip>
			{/* <ReactTooltip id="setting3" type="info" place="right" effect="solid">
				<span className="circular f:1">Panel settings</span>
			</ReactTooltip> */}
			<ReactTooltip id="suser2" type="info" place="right" effect="solid">
				<span className="circular f:1">Create a new user</span>
			</ReactTooltip>
			<ReactTooltip id="chatCopy" type="info" place="top" effect="solid">
				<span className="circular f:1">Message copied</span>
			</ReactTooltip>
			<ReactTooltip
				id="status-change"
				effect="solid"
				globalEventOff="click"
				arrowColor="#00000000"
				clickable={true}
				delayHide={10}
			>
				<div className="m-t:.4 m-b:.4">
					<div
						data-tip
						data-for="onlineInfo"
						onClick={() => {
							changeStatus(STATUS.ONLINE);
						}}
						className="p-l:.5 p-r:.1 sub-title status-option p-t:.4 p-b:.4"
					>
						<div className="d:i-b v-a:m">
							<UserStatusIcon status={STATUS.ONLINE} socketStatus={me.socketStatus} />
						</div>
						<div className="m-l:.7 d:i-b v-a:m"> Online</div>
						<ReactTooltip id="onlineInfo" type="info" place="right" effect="solid">
							<div>Chat heads captured on regular intervals.</div>
							<div>User will be accessible for other users to connect directly.</div>
							<div>Call would connect right away.</div>
						</ReactTooltip>
					</div>

					<div className="p-l:.5 p-r:.5 p-t:.4 p-b:.4">
						<div style={{ height: '0.5px', width: '100%', background: 'rgba(97, 97, 97, 0.8)' }}></div>
					</div>
					<div
						data-tip
						data-for="dndInfo"
						onClick={() => {
							changeStatus(STATUS.BUSY);
						}}
						className="p-l:.5 p-r:.1 sub-title status-option p-t:.4 p-b:.5"
					>
						<div className="d:i-b v-a:t">
							<UserStatusIcon status={STATUS.BUSY} socketStatus={me.socketStatus} />
						</div>
						<div className="m-l:.7 d:i-b v-a:t" style={{ width: 'calc(100% - 20px)' }}>
							Do Not Disturb
						</div>
						<ReactTooltip id="dndInfo" type="info" place="right" effect="solid">
							<div>Chat heads captured on regular intervals.</div>
							<div>User will not receive any other desktop notifications.</div>
							<div>User will receive a knock and a notification when another user tries to connect.</div>
						</ReactTooltip>
					</div>
					<div className="p-l:.5 p-r:.5 p-t:.4 p-b:.4">
						<div style={{ height: '0.5px', width: '100%', background: 'rgba(97, 97, 97, 0.8)' }}></div>
					</div>
					<div
						data-tip
						data-for="awayInfo"
						onClick={() => {
							changeStatus(STATUS.AWAY);
						}}
						className="p-l:.5 p-r:.1 sub-title status-option p-t:.4 p-b:.4"
					>
						<div className="d:i-b v-a:t">
							<UserStatusIcon status={STATUS.AWAY} socketStatus={me.socketStatus} />
						</div>
						<div className="m-l:.7 d:i-b v-a:t" style={{ width: 'calc(100% - 20px)' }}>
							Away
						</div>
						<ReactTooltip id="awayInfo" type="info" place="right" effect="solid">
							<div>Chat heads would not be captured.</div>
							<div>User would not be accessible for other users to call.</div>
							<div>User would have access to all of the application.</div>
						</ReactTooltip>
					</div>

					<div className="p-l:.5 p-r:.5 p-t:.4 p-b:.4">
						<div style={{ height: '0.5px', width: '100%', background: 'rgba(97, 97, 97, 0.8)' }}></div>
					</div>
					<div
						className="p-l:.5 p-r:.1 sub-title p-t:.4 p-b:.4"
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							width: 'calc(100%)',
							justifyContent: 'center'
						}}
					>
						<Button
							type="danger"
							className="circular f-w:100 danger-color m-r:1 profile-drop-btn"
							danger
							size="middle"
							onClick={() => {
								logout();
							}}
						>
							Log Out
						</Button>

						<Button
							type="default"
							className="circular f-w:100  m-r:1 profile-drop-btn settings-btn"
							size="middle"
							data-for="setting3"
							title="Panel Settings"
							onClick={() => {
								updateModal({ show: true, ModalBody: PanelSettings, title: 'Panel settings' });
							}}
						>
							Settings
						</Button>
					</div>

					<div className="m-b:.9"></div>
				</div>
			</ReactTooltip>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch({ type: TRY_LOGOUT }),
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload })
	};
};
const mapStateToProps = (state) => ({});

ToolTips = connect(mapStateToProps, mapDispatchToProps)(ToolTips);

export default ToolTips;
