import React, { useState } from 'react'
import { connect } from 'react-redux';
import { SERVER_URL } from '../../config'
import { Typography } from 'antd';
import './Invite.scss'
const { Text } = Typography;

function Invite({ me, inviteUrl }) {
    const [isCopied, setIsCopied] = useState(false)
    return (
        <>
            <div className="circular p-t:.5 invite_url_container">
                <div>
                    <div className="f-w:100 sub-title2 truncate p-l:1 p-b:.5">Invite User</div>

                </div>

                <div className='invite_url'>
                    <Text
                        onClick={() => {
                            setIsCopied(true)
                            navigator.clipboard.writeText(inviteUrl)
                            setTimeout(() => {
                                setIsCopied(false)
                            }, 1000);
                        }}
                        className='sub-title2'
                        style={
                            {
                                width: '100%',
                            }
                        }
                        ellipsis={
                            {
                                tooltip: inviteUrl,
                            }
                        }
                    >
                        {isCopied ? 'Copied!' : inviteUrl}
                    </Text>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        me: state.me,
        inviteUrl: `${!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://' : 'https://'}${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}?invitedId=${state.me.teams[state.me.teamId].token}`
    };
};

export default connect(mapStateToProps, null)(Invite);

