import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import Input from './Input'

import SingleOption from './SingleOption'

import './SelectInput.scss'

/**
 * @param {Array} allList, array of object with object containing @name key , @name value , @name image .
 * @param {Object} Object.image, object containing @name src , @name type 
 * @returns 
 */
function SelectInput({ allList, onSelect, onChange, onRemove, selected = [], selectedStatic = [], imageStyleProps, optionsStyleProps, placeholder = "Select Option", onRemoveAll, noSelect = false }) {
    const [selectedList, setSelectedList] = useState(selected);
    const [searchedList, setSearchedList] = useState(null);
    const [isSearched, setIsSearched] = useState(false)

    const debounceSearch = useCallback(debounce(handleDebounceFn, 100), []);

    // Search Input
    function handleDebounceFn(inputValue, allList, onChange) {
        if (!inputValue?.length) {
            setIsSearched(false)
        } else {
            if (!isSearched) {
                setIsSearched(true)
            }
        }
        // onChange && onChange(inputValue)

        if (allList && Array.isArray(allList) && inputValue) {
            const filteredArr = allList.filter(function (item) {
                return item.value.toLowerCase().search(
                    inputValue.toLowerCase()) !== -1;
            }).filter(item => searchedList ? [...searchedList].includes(item.key) : item);
            setSearchedList(filteredArr)
        } else {
            setSearchedList(null)
        }
    }

    const handleOnInputChange = (e) => {
        debounceSearch(e?.target?.value || null, allList, onChange)
    }

    // Select Option
    const handleOptionSelect = (key, value) => {
        const selected = new Set([...selectedList, key])
        setSelectedList(selected)
        onSelect && onSelect([...selected])
    }

    // Remove Option
    const handleRemoveOption = (key, value) => {
        const newList = [...selectedList].filter(selected => selected !== key);
        const selected = newList || []
        setSelectedList(selected)
        onRemove && onRemove(selected)
    }


    // Render method
    const renderOptions = () => {
        let options = [];
        const jsx = (data, i) => <SingleOption key={i} data={data} imageStyleProps={imageStyleProps} onSelect={handleOptionSelect} selectedList={selectedList} />
        if (searchedList && Array.isArray(searchedList)) {
            options = searchedList.map((data, i) =>
                jsx(data, i)
            )
        } else if (allList && Array.isArray(allList)) {
            options = allList.map((data, i) =>
                jsx(data, i)
            )
        } else {
            options = []
        }
        return options;
    }

    useEffect(() => {
        setSelectedList([...selected, ...selectedStatic])
    }, [])


    const handleRemoveAll = () => {
        onRemoveAll && onRemoveAll()
        setSelectedList([...selectedStatic])
    }

    return (
        <div className='select_input_container'>
            <Input placeholder={placeholder} onChange={handleOnInputChange} selectedList={[...selectedList]} selectedStatic={selectedStatic} allList={allList} removeOption={handleRemoveOption} onRemoveAll={handleRemoveAll} />
            {noSelect ? null : <div className='options_container' style={{ ...optionsStyleProps }}>
                {!searchedList?.length && isSearched ? 'No Data Found!' : selectedList?.length === allList?.length ? 'All Items Selected!' : renderOptions()}
            </div>}
        </div >
    )
}

SelectInput.defaultProps = {
    imageStyleProps: {
        width: '1.5rem',
        height: '1.5rem',
        display: 'inline-flex',
        borderRadius: '50%',
        backgroundColor: 'rgba(255,255,255,0.9)',
    }
}

SelectInput.propTypes = {
    allList: PropTypes.arrayOf(PropTypes.object),
    imageStyleProps: PropTypes.object,
    optionsStyleProps: PropTypes.object
}

export default React.memo(SelectInput)
