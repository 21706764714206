import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	ACCEPT_INVITE_REQUEST,
	ADD_PEER,
	ATTEMPTING_CALL,
	CALL_INVITE,
	DISCONNECT_LAST_USER,
	END_CALL,
	INCOMING_CALL_INVITE,
	INITIATE_CALL_REQUEST_ROOM,
	INVITE_REQUEST,
	JOIN_CALL,
	NEW_CALL_STARTED,
	PEER_ATTEMPTING_CALL,
	REMOVE_ALL_NOTIFICATIONS,
	SEND_ATTEMPTING_CALL,
	SEND_CALL_END,
	SEND_MOUSE_EVENTS,
	SEND_NOTIFICATION,
	SEND_PEER_INVITE_REQUEST,
	SET_CALL_ROOM_STATE,
	SET_CONSUMER_LAYER,
	SET_DISABLE_AUDIO_ONLY_STATE,
	SET_DISABLE_CALL_SCREEN_SHARE,
	SET_DISABLE_CALL_WEBCAM,
	SET_DISABLE_MIC,
	SET_ENABLE_AUDIO_ONLY_STATE,
	SET_ENABLE_CALL_SCREEN_SHARE,
	SET_ENABLE_CALL_WEBCAM,
	SET_ENABLE_MIC,
	SET_MEDIA_CAPABILITIES,
	SET_MOUSE_POINTER_ENABLED,
	SET_MUTE_AUDIO_ONLY_STATE,
	SET_MUTE_MIC,
	SET_UNMUTE_AUDIO_ONLY_STATE,
	SET_UNMUTE_MIC,
	START_NEW_CALL
} from '../../actions/call.actions';
import { NEW_ROOM_CREATED } from '../../actions/room.actions';
import { REMOVE_CALL_FROM_TIMER } from '../../actions/timer.actions';
import {
	CALL_CONNECTING_STATUS,
	EMIT_CALL_CONNECTING_STATUS,
	ROOM_JOINED_SOUND_OFF,
	ROOM_JOINED_SOUND_ON,
	USER_CALL_STATUS_UPDATE
} from '../../actions/users.actions';
import { createRoom } from '../../helpers/api';
import deviceInfo from '../../helpers/deviceInfo';
import { createNotification, createPushNotification } from '../../helpers/utils';
import { initProto } from '../../helpers/videoRoom/protoHelpers';
import RoomClient from '../../helpers/videoRoom/RoomClient';
import protoNotificationHandlers from './helpers/signallingEvenetHandlers/protoNotificationHandlers';
import protoRequestHandlers from './helpers/signallingEvenetHandlers/protoRequestHandlers';
import {
	disableAudioOnly,
	disableMic,
	enableAudioOnly,
	enableMic,
	muteAudio,
	muteMic,
	unmuteAudio,
	unmuteMic
} from './helpers/signallingEventManager/audio.saga.handler';
import { disableWebcam, enableWebcam } from './helpers/signallingEventManager/cam.saga.handler';
import { setConsumerPreferredLayers } from './helpers/signallingEventManager/consumer.saga.handler';
import { sendMouseCoords, sendMousePointerEnabledDisabled } from './helpers/signallingEventManager/events.saga.handler';
import { disableShare, enableShare } from './helpers/signallingEventManager/share.saga.handler';

let _protoo = null;
let roomClient = null;

function* connectionToMedia({ roomId, user, invitedUserId, action, serverUrl }) {
	_protoo = yield call(initProto, { url: `${serverUrl}&peerId=${user._id}` });

	const device = deviceInfo();
	roomClient = new RoomClient({
		roomId,
		peerId: user._id,
		displayName: user.name,
		device,
		produce: true,
		consume: true,
		datachannel: true,
		_protoo
	});

	_protoo.on('request', protoRequestHandlers(roomClient, window.store));
	_protoo.on('notification', protoNotificationHandlers(roomClient, window.store));

	// Join room states and stuff --start
	const { peers, canSendMic, canSendWebcam } = yield call(invokeRoomFunc, roomClient, 'join');
	if (peers) {
		yield put({
			type: SET_CALL_ROOM_STATE,
			payload: { state: 'calling...' }
		});
		for (const peer of peers) {
			// Add callpeer
			yield put({
				type: ADD_PEER,
				payload: { peer: { ...peer, consumers: [], dataConsumers: [] } }
			});
		}
		yield put({
			type: REMOVE_ALL_NOTIFICATIONS
		});

		createPushNotification({
			type: 'info',
			message: `You have joined a meeting ${peers?.[0]?.displayName ? 'with ' + peers?.[0]?.displayName : ''}`
		});

		yield put({
			type: ROOM_JOINED_SOUND_ON
		});

		yield put({
			type: SET_MEDIA_CAPABILITIES,
			payload: { canSendMic, canSendWebcam }
		});

		yield put({
			type: SET_ENABLE_MIC
		});

		setTimeout(() => {
			function* setSoundOff() {
				yield put({
					type: ROOM_JOINED_SOUND_OFF
				});
			}

			const off = setSoundOff();
			off.next();
		}, 5000);
	}
}

// initiating Call
export function* initiatingTyCall(action) {
	let { user, invitedUser, oneToOneRoomId } = action.payload;
	const state = yield select();
	const activeRoomId = state?.call?.roomId;
	const color = state?.call?.color;
	const serverUrl = state?.call?.serverUrl;
	try {

		if (!oneToOneRoomId) {
			let data = yield call(createRoom, { members: [user._id, invitedUser._id], roomType: 1, teamId: user.teamId });
			oneToOneRoomId = data.data._id;
			yield put({ type: NEW_ROOM_CREATED, payload: data.data });
		}
		if (oneToOneRoomId) {
			yield put({ type: ATTEMPTING_CALL, payload: { callRoomId: activeRoomId, color } });
			yield put({
				type: NEW_CALL_STARTED,
				payload: { roomId: activeRoomId, invitedUserId: invitedUser._id }
			});
			yield put({
				type: SET_CALL_ROOM_STATE,
				payload: { state: 'calling...' }
			});
			yield call(connectionToMedia, {
				roomId: activeRoomId,
				user,
				invitedUserId: invitedUser._id,
				action,
				serverUrl
			});
			yield put({
				type: SEND_PEER_INVITE_REQUEST,
				payload: { invitedPeerId: invitedUser._id, callRoomId: activeRoomId }
			});
			yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: invitedUser._id, callRoomId: activeRoomId, color, blink: true } })
		} else {
			createNotification({
				type: 'danger',
				title: 'Couldnt start the call.',
				message: 'Please try after initiating chat with ' + invitedUser.name
			});
			yield call(createRoom, { members: [user._id, invitedUser._id], roomType: 1, teamId: user.teamId });
		}
	} catch (error) {
		yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: invitedUser._id, callRoomId: null, color: null, blink: false } })
		yield put({
			type: SEND_CALL_END,
			payload: { callRoomId: activeRoomId }
		});
		yield put({ type: END_CALL });
	}
}

// join a incoming call
export function* joinIncomingCall(action) {
	const { callRoomId, invitingPeerId, serverUri } = action.payload;
	const state = yield select();
	const color = state?.users?.allUsers[invitingPeerId]?.color
	const serverUrl = state?.users?.allUsers[invitingPeerId]?.serverUrl
	try {

		if (action.payload?.call?.roomId) {
			createNotification({
				type: 'danger',
				title: action.payload.allUsers[invitingPeerId]?.name.toUpperCase() + ' tried calling you',
				message: 'If this persists please reload the page and check for microphone and camera permissions.'
			});
			yield put({ type: SEND_NOTIFICATION, payload: { to: invitingPeerId } });
		} else {
			yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: state?.me._id, callRoomId, color, blink: true } })
			yield put({ type: PEER_ATTEMPTING_CALL, payload: { callRoomId, color } });
			yield put({
				type: JOIN_CALL, payload: {
					roomId: callRoomId, lastCallUserId: invitingPeerId, serverUrl,
					color
				}
			});
			yield put({
				type: SET_CALL_ROOM_STATE,
				payload: { state: 'calling...' }
			});
			yield call(connectionToMedia, {
				roomId: callRoomId,
				user: state?.me,
				invitedUserId: invitingPeerId,
				action,
				serverUrl: serverUri
			});
			yield put({ type: ACCEPT_INVITE_REQUEST, payload: { callRoomId, invitingPeerId } });
		}
	} catch (error) {
		yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: state?.me._id, callRoomId: null, color: null, blink: false } })
		yield put({
			type: SEND_CALL_END,
			payload: { callRoomId }
		});
		yield put({ type: END_CALL });

	}
}

// add user in a active call
export function* addUserInActiveCall(action) {
	const { videoRoomId, invitedUserId } = action.payload;
	const state = yield select();
	const color = state?.call?.color
	try {

		yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: invitedUserId, callRoomId: videoRoomId, color, blink: true } })
		yield put({ type: PEER_ATTEMPTING_CALL, payload: { callRoomId: videoRoomId, color } });
		yield put({ type: JOIN_CALL, payload: { roomId: videoRoomId, lastCallUserId: invitedUserId } });
		yield put({
			type: SEND_PEER_INVITE_REQUEST,
			payload: { invitedPeerId: invitedUserId, callRoomId: videoRoomId }
		});
	} catch (error) {
		yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: invitedUserId, callRoomId: null, color: null, blink: false } });
		yield put({
			type: SEND_CALL_END,
			payload: { callRoomId: videoRoomId }
		});
		yield put({ type: END_CALL });
	}
}

// join into a  call
export function* JoinInActiveCall(action) {
	const { from } = action.payload;
	const state = yield select();
	const serverUrl = state?.users?.allUsers[from]?.serverUrl;
	const activeCallId = state?.users?.allUsers[from]?.activeCallId;
	const color = state?.users?.allUsers[from]?.color;

	try {

		yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: state?.me._id, callRoomId: activeCallId, color, blink: true } })
		yield put({ type: PEER_ATTEMPTING_CALL, payload: { callRoomId: activeCallId, color } });
		yield put({ type: JOIN_CALL, payload: { roomId: activeCallId, lastCallUserId: from } });
		yield put({
			type: SET_CALL_ROOM_STATE,
			payload: { state: 'calling...' }
		});
		yield call(connectionToMedia, {
			roomId: activeCallId,
			user: state?.me,
			invitedUserId: from,
			action,
			serverUrl
		});
	} catch (error) {
		yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: state?.me._id, callRoomId: null, color: null, blink: false } })
		yield put({
			type: SEND_CALL_END,
			payload: { callRoomId: activeCallId }
		});
		yield put({ type: END_CALL });
	}
}

export function* initiatingCall(action) {
	yield put({ type: INITIATE_CALL_REQUEST_ROOM, payload: action.payload });
}

export function* startCall() {
	try {
		yield takeEvery(START_NEW_CALL, initiatingCall);
	} catch (error) { }
}

export function* sendAttempCall() {
	try {
		yield takeEvery(SEND_ATTEMPTING_CALL, initiatingTyCall);
	} catch (error) { }
}

export function* incominCall() {
	try {
		yield takeEvery(INVITE_REQUEST, joinIncomingCall);
	} catch (error) { }
}

export function* tryToAddUserInActiveCall() {
	try {
		yield takeEvery(CALL_INVITE, addUserInActiveCall);
	} catch (error) { }
}

export function* tryToJoinInActiveCall() {
	try {
		yield takeEvery(INCOMING_CALL_INVITE, JoinInActiveCall);
	} catch (error) { }
}

export function* openCallWindow(action) {
	const { user, invitedUser, roomId } = action.payload;
	const activeRoomId = Math.random().toString(36).slice(2);
	yield put({ type: NEW_CALL_STARTED, payload: { roomId: activeRoomId, invitedUserId: invitedUser.id } });
	yield put({ type: CALL_INVITE, payload: { to: roomId, videoRoomId: activeRoomId } });
	// window.ipcRenderer.invoke('show-call-window', { user, activeRoomId, invitedUser });
	// window.location.href = window.location.origin + '?roomId=' + activeRoomId;
	window.inCall = true;
}
export function* endCallAndCloseRoomClient() {
	yield call(invokeRoomFunc, roomClient, 'close');
	const state = yield select();
	yield put({ type: USER_CALL_STATUS_UPDATE, payload: { userId: state?.me._id, callRoomId: state.call?.roomId, color: state.call?.color, blink: false } })
	yield put({ type: END_CALL });
	yield put({
		type: SEND_CALL_END,
		payload: { callRoomId: state.call?.roomId }
	});
	yield put({ type: REMOVE_CALL_FROM_TIMER });
	let callStarted = state?.timer?.callTimers?.callsStarted;
	if (callStarted.invitedUserId) {
		yield put({
			type: CALL_CONNECTING_STATUS,
			payload: { userId: callStarted.invitedUserId, callConnecting: null }
		});
		yield put({
			type: EMIT_CALL_CONNECTING_STATUS,
			payload: { invitedUserId: callStarted.invitedUserId, callConnecting: null }
		});
	}
}

export function* diconnectLastUser() {
	try {
		yield takeEvery(DISCONNECT_LAST_USER, endCallAndCloseRoomClient);
	} catch (e) {
		console.log(e);
	}
}

export function* disableAudioOnlySaga() {
	yield takeLatest(SET_DISABLE_AUDIO_ONLY_STATE, function* (action) {
		yield call(disableAudioOnly, { roomClient, _protoo, store: window.store });
	});
}

export function* enableAudioOnlySaga() {
	yield takeLatest(SET_ENABLE_AUDIO_ONLY_STATE, function* (action) {
		yield call(enableAudioOnly, { roomClient, _protoo, store: window.store });
	});
}

export function* enableMicSaga() {
	yield takeLatest(SET_ENABLE_MIC, function* (action) {
		yield call(enableMic, { roomClient, _protoo, store: window.store });
	});
}
export function* disableMicSaga() {
	yield takeLatest(SET_DISABLE_MIC, function* (action) {
		yield call(disableMic, { roomClient, _protoo, store: window.store });
	});
}

export function* muteMicSaga() {
	yield takeLatest(SET_MUTE_MIC, function* (action) {
		yield call(muteMic, { roomClient, _protoo, store: window.store });
	});
}
export function* unmuteMicSaga() {
	yield takeLatest(SET_UNMUTE_MIC, function* (action) {
		yield call(unmuteMic, { roomClient, _protoo, store: window.store });
	});
}

export function* muteAudioSaga() {
	yield takeLatest(SET_MUTE_AUDIO_ONLY_STATE, function* (action) {
		yield call(muteAudio, { roomClient, _protoo, store: window.store });
	});
}

export function* unmuteAudioSaga() {
	yield takeLatest(SET_UNMUTE_AUDIO_ONLY_STATE, function* (action) {
		yield call(unmuteAudio, { roomClient, _protoo, store: window.store });
	});
}

export function* enableWebcamSaga() {
	yield takeLatest(SET_ENABLE_CALL_WEBCAM, function* (action) {
		yield call(enableWebcam, { roomClient, _protoo, store: window.store });
	});
}
export function* disableWebcamSaga() {
	yield takeLatest(SET_DISABLE_CALL_WEBCAM, function* (action) {
		yield call(disableWebcam, { roomClient, _protoo, store: window.store });
	});
}
export function* enableShareSaga() {
	yield takeLatest(SET_ENABLE_CALL_SCREEN_SHARE, function* (action) {
		yield call(enableShare, { roomClient, _protoo, store: window.store, action });
	});
}
export function* disableShareSaga() {
	yield takeLatest(SET_DISABLE_CALL_SCREEN_SHARE, function* (action) {
		yield call(disableShare, { roomClient, _protoo, store: window.store });
		yield put({ type: 'SET_MOUSE_POINTER_ENABLED', payload: false });
	});
}

export function* setConsumerPreferredLayersSaga() {
	yield takeLatest(SET_CONSUMER_LAYER, function* (action) {
		yield call(setConsumerPreferredLayers, { roomClient, _protoo, store: window.store, action });
	});
}
export function* sendMouseCoordsSaga() {
	yield takeLatest(SEND_MOUSE_EVENTS, function* (action) {
		yield call(sendMouseCoords, { roomClient, _protoo, store: window.store, action });
	});
}

export function* setMousePointerEnabledDisabled() {
	yield takeLatest(SET_MOUSE_POINTER_ENABLED, function* (action) {
		yield call(sendMousePointerEnabledDisabled, { roomClient, _protoo, store: window.store, action });
	});
}

export default function* rootSaga() {
	yield all([
		startCall(),
		sendAttempCall(),
		incominCall(),
		tryToAddUserInActiveCall(),
		tryToJoinInActiveCall(),
		diconnectLastUser(),
		disableAudioOnlySaga(),
		enableAudioOnlySaga(),
		muteAudioSaga(),
		unmuteAudioSaga(),
		enableMicSaga(),
		disableMicSaga(),
		muteMicSaga(),
		unmuteMicSaga(),
		enableWebcamSaga(),
		disableWebcamSaga(),
		enableShareSaga(),
		disableShareSaga(),
		setConsumerPreferredLayersSaga(),
		sendMouseCoordsSaga(),
		setMousePointerEnabledDisabled()
	]);
}
async function invokeRoomFunc(roomClient, name, ops = {}) {
	async function func(name, ops) {
		return await this?.[name](ops);
	}
	return await func.call(roomClient, name, ops);
}
