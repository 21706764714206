import React, { Suspense } from 'react';
import isElectron from 'is-electron';

function ElectronCompLoader({ name, options = {} }) {
	function loadComponent(name) {
		if (isElectron()) {
			const Component = React.lazy(() => import(`./components/${name}`));
			return Component;
		} else {
			console.log('Error');
			return null;
		}
	}

	const Component = loadComponent(name);

	return isElectron() ? (
		<div>
			<Suspense fallback={<div>Loading...</div>}>{Component && <Component {...options} />}</Suspense>
		</div>
	) : null;
}

export default ElectronCompLoader;
