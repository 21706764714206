import { fork, all } from 'redux-saga/effects';
import initSaga from './init.saga';
import authSaga from './auth.saga';
import usersSaga from './users.saga';
import socketSaga from './socket.saga';
import callSaga from './call.saga/index';
import roomSaga from './room.saga';
import timerSaga from './timer.saga';
export default function* rootSaga() {
	yield all([
		fork(initSaga),
		fork(usersSaga),
		fork(authSaga),
		fork(socketSaga),
		fork(roomSaga),
		fork(callSaga),
		fork(timerSaga)
	]);
}
