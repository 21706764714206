import React from 'react';

function EmojiSvg(props) {
	return (
		<svg x="0px" y="0px" viewBox="0 0 512 512" style={{ ...props }} id={props.id || 'emojiTrigger'}>
			<g>
				<g>
					<path d="M137.091,297.651c7.507,58.932,57.971,104.635,118.909,104.635c60.938,0,111.402-45.703,118.909-104.635H137.091z" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M437.019,74.981C388.668,26.629,324.38,0,256,0S123.332,26.629,74.981,74.981C26.628,123.332,0,187.62,0,256
			s26.628,132.668,74.981,181.019C123.332,485.371,187.62,512,256,512s132.668-26.629,181.019-74.981
			C485.372,388.668,512,324.38,512,256S485.372,123.332,437.019,74.981z M311.376,188.396c24.953-24.955,65.556-24.953,90.509,0
			c5.951,5.95,5.951,15.599,0,21.55c-2.976,2.976-6.875,4.464-10.774,4.464c-3.899,0-7.8-1.488-10.774-4.464
			c-13.072-13.072-34.34-13.071-47.41,0c-5.95,5.95-15.599,5.95-21.55,0C305.425,203.995,305.425,194.347,311.376,188.396z
			 M110.115,188.396c24.953-24.953,65.556-24.955,90.509,0c5.951,5.95,5.951,15.599,0,21.55c-2.976,2.976-6.875,4.464-10.774,4.464
			s-7.8-1.488-10.774-4.464c-13.071-13.07-34.339-13.071-47.41,0c-5.95,5.95-15.599,5.95-21.55,0
			C104.164,203.995,104.164,194.347,110.115,188.396z M256,432.762c-82.902,0-150.349-67.447-150.349-150.349
			c0-8.415,6.823-15.238,15.238-15.238h270.222c8.415,0,15.238,6.823,15.238,15.238C406.349,365.315,338.902,432.762,256,432.762z"
					/>
				</g>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
}

export default EmojiSvg;
