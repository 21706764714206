import * as cookiesManager from '../../cookiesManager';
import { SET_CONSUMER_RESUMED } from '../../../actions/call.actions';

import { notify } from '../../../actions/request.actions'

async function muteMic() {
	this.logger.debug('muteMic()');
	if (!this._micProducer) {
		await this.enableMic();
	}
	try {
		this._micProducer?.pause?.();
		await this._protoo.request('pauseProducer', { producerId: this._micProducer.id });
	} catch (error) {
		this.logger.error('muteMic() | failed: %o', error);
		throw new Error(`Error pausing server-side mic Producer: ${error}`);
	}
}

async function unmuteMic() {
	this.logger.debug('unmuteMic()');

	this._micProducer.resume();

	try {
		await this._protoo.request('resumeProducer', { producerId: this._micProducer.id });
	} catch (error) {
		this.logger.error('unmuteMic() | failed: %o', error);
		throw new Error(`unmuteMic() | failed: %o ${error}`);
	}
}

async function enableMic() {
	this.logger.debug('enableMic()');

	if (this._micProducer) return;
	if (!this._mediasoupDevice.canProduce('audio')) {
		this.logger.error('enableMic() | cannot produce audio');

		return;
	}

	let track;

	try {
		if (!this._externalVideo) {
			this.logger.debug('enableMic() | calling getUserMedia()');
			try {

				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				window.stream = stream;
				track = stream.getAudioTracks()[0];
			} catch (error) {
				console.log('Error Enabling Mic')
			}
		} else {
			const stream = await this._getExternalVideoStream();

			track = stream.getAudioTracks()[0].clone();
		}

		this._micProducer = await this._sendTransport.produce({
			track,
			codecOptions: {
				opusStereo: 1,
				opusDtx: 1
			}
			// NOTE: for testing codec selection.
			// codec : this._mediasoupDevice.rtpCapabilities.codecs
			// 	.find((codec) => codec.mimeType.toLowerCase() === 'audio/pcma')
		});

		this._micProducer.on('transportclose', () => {
			this._micProducer = null;
		});

		this._micProducer.on('trackended', () => {
			notify({
				type: 'error',
				text: 'Microphone disconnected!'
			})

			this.disableMic().catch(() => { });
		});
	} catch (error) {
		this.logger.error('enableMic() | failed:%o', error);
		if (track) track.stop();
		throw new Error(`Error enabling microphone: ${error}`);
	}
}

async function disableMic() {
	this.logger.debug('disableMic()');

	if (!this._micProducer) return;

	this._micProducer.close();

	try {
		await this._protoo.request('closeProducer', { producerId: this._micProducer.id });
	} catch (error) {
		throw new Error(`Error closing server-side mic Producer: ${error}`)
	}
}

async function enableAudioOnly() {
	this.logger.debug('enableAudioOnly()');

	this.disableWebcam();
	for (const consumer of this._consumers.values()) {
		if (consumer.kind !== 'video') continue;
		this.pauseConsumer(consumer);
	}
}

async function disableAudioOnly({ store }) {
	this.logger.debug('disableAudioOnly()');

	if (!this._webcamProducer && this._produce && (cookiesManager.getDevices() || {}).webcamEnabled) {
		this.enableWebcam();
	}

	for (const consumer of this._consumers.values()) {
		if (consumer.kind !== 'video') continue;
		try {
			await this.resumeConsumer(consumer);
			store.dispatch({
				type: SET_CONSUMER_RESUMED,
				payload: {
					consumerId: consumer.id,
					originator: 'local'
				}
			});
		} catch (error) { }
	}
}

export { muteMic, enableMic, disableMic, unmuteMic, enableAudioOnly, disableAudioOnly };
