import React from 'react';
import CrossSvg from '../../images/cross.svg';
import { useState } from 'react';
import { Button } from 'antd';
import Draggable from 'react-draggable';
import { createUser } from '../../helpers/api';
import { createNotification, imageCompressOptions } from '../../helpers/utils';
import imageCompression from 'browser-image-compression';

function PasteImage({ clearFiles, image, selectedRoom, sendMessage, user }) {
	const [data, setData] = useState({ message: '' });
	async function sendMessageWithImage() {
		var reader = new FileReader();
		let blob = await fetch(image).then((r) => r.blob());
		console.log(blob.type, 'type');
		reader.readAsDataURL(blob);
		reader.onload = async function (event) {
			const timestamp = Date.now();
			let compressedFile = await imageCompression(blob, imageCompressOptions);
			compressedFile = await imageCompression.getDataUrlFromFile(compressedFile);
			sendMessage({
				to: selectedRoom._id,
				from: user._id,
				message: { text: data.message },
				image: compressedFile,
				type: 1,
				timestamp,
				isDm: selectedRoom.isDmRoom,
				chatMessageId: `${timestamp}${Math.ceil(Math.random() * 100)}`
			});
		};
		clearFiles();
	}
	return image ? (
		<div className="modal-main circular">
			<Draggable>
				<div className="modal-body-cont">
					<div className="modal-header p:.7 p-l:.9 dm-sans">
						Share image
						<div
							className="fl:r m-t:0 cursor"
							style={{ marginTop: -2 }}
							onClick={() => {
								clearFiles();
							}}
						>
							<div className="cross-icon d:i-b" style={{ backgroundImage: `url(${CrossSvg})` }} />
						</div>
					</div>
					<div className="modal-body p:.5 p-l:.9 p-r:.8 p-b:1 m-t:.9">
						<div className="m-b:3">
							<img
								src={image}
								key={image}
								alt={`Pasted: ${image}`}
								style={{
									width: '373px',
									height: 'auto',
									borderRadius: 1,
									boxShadow: 'rgb(243 243 243 / 43%) 0px 0px 0px 1px'
								}}
							/>
						</div>

						<div className="m-b:5">
							<div className="p-t:.2 circular">
								<span
									class="ant-input-affix-wrapper search-box-left-panel"
									style={{ borderRadius: 3, padding: '2px 12px ' }}
								>
									<input
										placeholder="Message"
										onChange={(e) => setData({ message: e.target.value })}
										style={{ padding: 5, color: 'white' }}
										type="text"
										className="ant-input circular f-w:100 p-l:.1 search-left-panel"
									/>
								</span>
							</div>
						</div>

						<div className="t-a:r m-t:2">
							<Button
								type="primary"
								className="circular create-btn f-w:100 sub-title"
								size="middle"
								onClick={() => sendMessageWithImage()}
							>
								Upload
							</Button>
						</div>
					</div>
				</div>
			</Draggable>
		</div>
	) : null;
}

export default PasteImage;
