import Logger from '../../../../helpers/Logger';
import { ROOM_JOINED_SOUND_OFF, ROOM_JOINED_SOUND_ON } from '../../../../actions/user.actions';
import { ADD_PEER } from '../../../../actions/call.actions';
import { notify } from '../../../../actions/request.actions';

import isElectron from 'is-electron';

let electron = null;
let ipcRen = null;

if (isElectron() && typeof window?.require === 'function') {
	electron = window.require('electron');
	ipcRen = window.ipcRenderer;
}

const logger = new Logger('RoomClient_Protoo_Notifications');

export default (roomClient, store) => {
	return async (notification, reject) => {
		const dispatch = window.store.dispatch;
		logger.debug('proto "notification" event [method:%s, data:%o]', notification.method, notification.data);
		switch (notification?.method) {
			case 'producerScore': {
				const { producerId, score } = notification.data;

				dispatch({
					type: 'SET_PRODUCER_SCORE',
					payload: { producerId, score }
				});

				break;
			}

			case 'newPeer': {
				const peer = notification.data;

				dispatch({
					type: ADD_PEER,
					payload: { peer: { ...peer, consumers: [], dataConsumers: [] } }
				});

				notify({
					text: `${peer.displayName} has joined the meeting`
				});

				dispatch({ type: ROOM_JOINED_SOUND_ON });
				const timeOut = setTimeout(() => {
					dispatch({ type: ROOM_JOINED_SOUND_OFF });
					clearTimeout(timeOut)
				}, 5000);

				break;
				// return handleNewPeer(notification.data)
			}

			case 'peerClosed': {
				const { peerId } = notification.data;

				dispatch({
					type: 'REMOVE_PEER',
					payload: { peerId }
				});

				let users = store?.getState().users.allUsers;
				if (users && users[peerId]) {
					notify({
						text: `${users[peerId].name} has left the meeting.`
					});
				}
				break;
			}

			case 'peerDisplayNameChanged': {
				const { peerId, displayName, oldDisplayName } = notification.data;

				dispatch({
					type: 'SET_PEER_DISPLAY_NAME',
					payload: { displayName, peerId }
				});
				notify({
					text: `${oldDisplayName} is now ${displayName}`
				});
			}

			case 'getMouseEvents': {
				if (window.store.getState().me?.pointerShareEvent) {
					const { producerId, coords, id, resolution } = notification.data;
					const producerName = window.store.getState().users.allUsers[producerId].name;

					const producerPic = window.store.getState().users.allUsers[producerId].lastSnap;

					const shareProducer = Object.values(window.store.getState().producers).find(producer => producer.type === 'share')

					const { width, height, left, top } = shareProducer.appData;

					// console.log(producerId, coords, id, resolution, 'producerId, coords, id, resolution');
					if (isElectron()) {

						const dataToSend = {
							producerId,
							coords,
							resolution,
							myResolution: {
								height: height,
								width: width,
								left,
								top
							},
							producerName,
							pic: producerPic
						};

						ipcRen.send('mEvents', JSON.stringify(dataToSend));
					}
				}

				// notify({
				//     text: `Error while getting mouse event`
				// })

				break;
			}

			case 'sharePointerEvent': {
				const { data, id } = notification.data;
				console.log(data, id, 'from new event------------------');
				dispatch({
					type: 'UPDATE_POINTER_SHARING',
					payload: { pointerSharing: data, id }
				});

				break;
			}

			case 'downlinkBwe': {
				// logger.debug("'downlinkBwe' event:%o", notification.data);

				break;
			}

			case 'consumerClosed': {
				const { consumerId } = notification.data;
				const consumer = roomClient._consumers.get(consumerId);

				if (!consumer) break;

				consumer.close();
				roomClient._consumers.delete(consumerId);

				const { peerId } = consumer.appData;

				dispatch({
					type: 'REMOVE_CONSUMER',
					payload: { consumerId, peerId }
				});
				dispatch({
					type: 'REMOVE_CONSUMER_ID',
					payload: { consumerId, peerId }
				});

				break;
			}

			case 'consumerPaused': {
				const { consumerId } = notification.data;
				const consumer = roomClient._consumers.get(consumerId);

				if (!consumer) break;

				consumer.pause();

				dispatch({
					type: 'SET_CONSUMER_PAUSED',
					payload: { consumerId, originator: 'remote' }
				});

				break;
			}

			case 'consumerResumed': {
				console.log(notification.data, 'notification.data');
				console.log(notification, 'notification');
				const { consumerId } = notification.data;
				console.log(consumerId, 'consumerId');
				const consumer = roomClient._consumers.get(consumerId);

				if (!consumer) break;
				console.log('resuming consumer');
				consumer.resume();

				dispatch({
					type: 'SET_CONSUMER_RESUMED',
					payload: { consumerId, originator: 'remote' }
				});

				break;
			}

			case 'consumerLayersChanged': {
				const { consumerId, spatialLayer, temporalLayer } = notification.data;
				const consumer = roomClient._consumers.get(consumerId);

				if (!consumer) break;

				dispatch({
					type: 'SET_CONSUMER_CURRENT_LAYERS',
					payload: { consumerId, spatialLayer, temporalLayer }
				});

				break;
			}

			case 'consumerScore': {
				const { consumerId, score } = notification.data;

				dispatch({
					type: 'SET_CONSUMER_SCORE',
					payload: { consumerId, score }
				});

				break;
			}

			case 'dataConsumerClosed': {
				const { dataConsumerId } = notification.data;
				const dataConsumer = roomClient._dataConsumers.get(dataConsumerId);

				if (!dataConsumer) break;

				dataConsumer.close();
				roomClient._dataConsumers.delete(dataConsumerId);

				const { peerId } = dataConsumer.appData;

				dispatch({
					type: 'REMOVE_DATA_CONSUMER',
					payload: { dataConsumerId, peerId }
				});

				break;
			}

			case 'activeSpeaker': {
				const { peerId } = notification.data;

				dispatch({
					type: 'SET_ROOM_ACTIVE_SPEAKER',
					payload: { peerId }
				});

				break;
			}

			default: {
				logger.error('unknown protoo notification.method "%s"', notification.method);
			}
		}
	};
};
