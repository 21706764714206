import React from 'react'
import { SERVER_URL } from '../../../../../config'

import './Image.scss'

function Image({ data, imageStyleProps }) {
  const stylesProps = {
    width: '1.5rem',
    height: '1.5rem',
    display: 'inline-flex',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,0.9)',
    ...imageStyleProps
  }
  return (
    <div className='single_option--image'>
      {data.image.type === 'blob' ? <div style={{
        backgroundImage: `url(${SERVER_URL + '/' + data.image.src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center',
        ...stylesProps
      }}></div> :
        <img src={data.image.src} style={{ ...stylesProps }} />}
    </div>
  )
}

Image.propTypes = {}

export default Image
