import React from 'react';

function DotSvg(props) {
	return (
		<svg
			class={props.class}
			style={{ ...props }}
			version="1.1"
			id={props.offline ? 'offlineDot' : 'onlineDot'}
			x="0px"
			y="0px"
			viewBox="0 0 29.107 29.107"
		>
			<g>
				<g id="c147_full_moon">
					<g>
						<path
							d="M14.554,0C6.561,0,0,6.562,0,14.552c0,7.996,6.561,14.555,14.554,14.555c7.996,0,14.553-6.559,14.553-14.555
				C29.106,6.562,22.55,0,14.554,0z"
						/>
					</g>
				</g>
				<g id="Capa_1_14_"></g>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
}

export default DotSvg;
