import React from 'react';
import CrossSvg from '../../images/cross.svg';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { UPDATE_MODAL } from '../../actions/user.actions';
import Avatar from 'antd/lib/avatar/avatar';
function ModalContainer({
	updateModal,
	modal,
	ModalBody,
	showModal,
	updateDragAndResize,
	updateCenterPanelLocation,
	isDraggable = true
}) {
	const renderJsx = () => {
		return (
			<div className="modal-body-cont">
				<div className="modal-header p:.7 p-l:.9 dm-sans" style={{ background: modal.noHeader && 'none' }}>
					{modal.title}
					<div
						className="fl:r m-t:0 cursor"
						style={{ marginTop: -2 }}
						onClick={() => updateModal({ show: false, ModalBody: null })}
					>
						<div
							className="cross-icon d:i-b"
							style={{
								backgroundImage: `url(${CrossSvg})`,
								backgroundColor: modal.crossBg && '#1c1c207a'
							}}
						/>
					</div>
				</div>
				<div className="modal-body p:.5 p-l:2 p-r:2 p-b:1 m-t:.5" style={{ padding: modal.noPadding && 0 }}>
					<ModalBody
						updateDragAndResize={updateDragAndResize}
						{...modal}
						updateModal={updateModal}
						updateCenterPanelLocation={updateCenterPanelLocation}
					/>
				</div>
			</div>
		);
	};

	const renderBg = () => {
		return (
			<div
				onClick={() => updateModal({ show: false, ModalBody: null })}
				style={{
					position: 'fixed',
					top: '50%',
					left: '50%',
					height: '100vh',
					width: '100vw',
					background: 'rgba(0,0,0,0.2)',
					transform: 'translate(-50%, -50%)',
					zIndex: -1
				}}
			></div>
		);
	};

	return showModal ? (
		modal.v2 ? (
			<div>
				<div id="file-input-overlay">
					<div className="modal-main circular" style={{ width: 750, padding: '0', zIndex: 10 }}>
						{renderBg()}
						<div
							className="modal-body-cont p-b:0"
							style={{ boxShadow: 'none', background: '#212529', borderRadius: 7 }}
						>
							<ModalBody {...modal} updateModal={updateModal} />
						</div>
					</div>
				</div>
			</div>
		) : (
			<div
				className="modal-main circular"
				style={{ width: modal.modalWidth, height: modal.modalHeight, padding: '0', zIndex: 10 }}
			>
				{renderBg()}
				{isDraggable ? <Draggable>{renderJsx()}</Draggable> : <div>{renderJsx()}</div>}
			</div>
		)
	) : null;
}

const mapStateToProps = (state) => {
	return {
		showModal: state.me.modal.show,
		modal: state.me.modal,
		ModalBody: state.me.modal.ModalBody
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload })
	};
};

ModalContainer = connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
export default ModalContainer;
