import React from 'react';

function PlusIcon(props) {
	return (
		<svg id="chanel-plus-icon" style={{ ...props }} viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg">
			<path d="m272 184c-4.417969 0-8-3.582031-8-8v-176h-80v176c0 4.417969-3.582031 8-8 8h-176v80h176c4.417969 0 8 3.582031 8 8v176h80v-176c0-4.417969 3.582031-8 8-8h176v-80zm0 0" />
		</svg>
	);
}

export default PlusIcon;
