import React, { useState, useEffect } from 'react';
import { Drawer, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { SWITCH_TEAM } from '../../../actions/user.actions';

const TeamsToggle = ({ me, switchTeam }) => {
	const [visible, setVisible] = useState(false);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	useEffect(() => {}, []);

	const handleSwitchTeam = (e) => {
		switchTeam(e.target.getAttribute('data-value'));
	};

	return (
		<>
			<Space>
				<Button type="primary" onClick={showDrawer}>
					{'>'}
				</Button>
			</Space>
			<Drawer
				title="Switch Teams"
				closable={false}
				placement={'left'}
				width={300}
				onClose={onClose}
				visible={visible}
			>
				{Object.values(me?.teams)?.length
					? Object.values(me.teams).map((team, i) => (
							<div key={i} onClick={handleSwitchTeam} data-value={team._id}>
								{team.name}
							</div>
					  ))
					: 'No Team found!'}
			</Drawer>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		me: state.me
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		switchTeam: (teamId) => dispatch({ type: SWITCH_TEAM, payload: { teamId } })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsToggle);
