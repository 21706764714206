import React from 'react';
import ThreeDotIcon from './SvgComponents/ThreeDotIcon';
import InfoIcon from './SvgComponents/InfoIcon';
import ReactTooltip from 'react-tooltip';
import Avatar from 'react-avatar';
import DotSvg from './../LeftPanel/SvgComponents/DotSvg';
import CallSvg from '../LeftPanel/SvgComponents/CallSvg';
import { UserStatus } from '../LeftPanel/TeamHeader';
import UserProfile from '../HelperComponents/UserProfile';
import MemberProfile from '../ModalComponents/MemberProfile';

function ProfileHeader({ selectedRoom, updateModal, allUsers }) {
	return (
		<div className="active-header-center p:.8 p-l:2 p-r:2 circular truncate">
			<div
				className="m-r:.9 d:i-b v-a:t m-t:.4"
				onClick={() => {
					updateModal({
						show: true,
						ModalBody: MemberProfile,
						userProfile: allUsers?.[selectedRoom?.targetUser._id],
						noHeader: true,
						noPadding: true,
						crossBg: true
						// modalWidth: 800
					});
				}}
			>
				{selectedRoom && selectedRoom?.targetUser.profilePic ? (
					<UserProfile
						styleProps={{ width: 40, height: 40 }}
						profilePic={selectedRoom.targetUser.profilePic}
					/>
				) : (
					<Avatar
						size="40"
						textSizeRatio={2.3}
						round={true}
						name={(selectedRoom && selectedRoom?.targetUser?.name || selectedRoom?.targetUser.username) || 'Not available'}
						className="circular"
					/>
				)}
			</div>
			<div className="m-b:.0 f-w:500 f:1 section-header d:i-b m-t:.2">
				<span>{(selectedRoom && selectedRoom?.targetUser?.name || selectedRoom?.targetUser.username) || 'Not available'}</span>
				{selectedRoom && (
					<UserStatus
						centerPanel
						status={selectedRoom?.targetUser?.status}
						socketStatus={selectedRoom?.targetUser?.socketStatus}
						onCall={selectedRoom?.targetUser?.activeCallId ? true : false}
						callColor={
							`#${window.store.getState().users.allUsers[selectedRoom?.targetUser?._id].color}`
						}
					/>
				)}
			</div>
		</div>
	);
}

export default ProfileHeader;
