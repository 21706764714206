import React from 'react';

function SettingsIcon(props) {
	return (
		<svg
			style={{ ...props }}
			id={props.id ? props.id : 'fillSvg'}
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
		>
			<g>
				<g>
					<path
						d="M394.667,0H117.333C52.629,0,0,52.629,0,117.333v277.333C0,459.371,52.629,512,117.333,512h277.333
			   C459.371,512,512,459.371,512,394.667V117.333C512,52.629,459.371,0,394.667,0z M380.139,239.488
			   c0.704,5.504,1.195,11.008,1.195,16.512c0,5.483-0.491,10.944-1.195,16.192c-0.491,3.755,1.003,7.488,3.989,9.813l37.12,29.099
			   l-34.923,60.352l-43.712-17.579c-3.52-1.451-7.531-0.875-10.539,1.472c-8.341,6.507-17.835,12.032-28.203,16.491
			   c-3.435,1.472-5.845,4.629-6.357,8.32l-5.248,46.507h-71.275l-6.507-46.251c-0.512-3.712-2.965-6.891-6.443-8.341
			   c-10.176-4.267-19.392-9.664-28.139-16.448c-2.987-2.347-6.997-2.923-10.517-1.493l-43.712,17.579L90.752,311.36l37.12-29.099
			   c2.965-2.325,4.48-6.037,4.011-9.771c-0.725-5.483-1.216-10.987-1.216-16.491c0-5.483,0.491-10.944,1.195-16.192
			   c0.491-3.755-1.003-7.488-3.989-9.813l-37.12-29.099l34.923-60.352l43.712,17.579c3.499,1.408,7.509,0.853,10.539-1.472
			   c8.341-6.485,17.835-12.053,28.203-16.491c3.435-1.472,5.845-4.629,6.357-8.32l5.248-46.507h71.275l6.507,46.251
			   c0.512,3.712,2.965,6.891,6.443,8.341c10.219,4.288,19.413,9.664,28.139,16.448c3.008,2.347,7.04,2.901,10.539,1.472
			   l43.712-17.579l34.923,60.352l-37.12,29.099C381.184,232.043,379.669,235.755,380.139,239.488z"
					/>
				</g>
			</g>
			<g>
				<g>
					<circle cx="256" cy="256" r="64" />
				</g>
			</g>
			<g>
				<g>{/* <circle cx="256" cy="256" r="42.667" /> */}</g>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
}

export default SettingsIcon;
