import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './Toast.scss';
class Toast extends Component {
	constructor() {
		super();
		const timeOut = setTimeout(() => {
			this.props.onDismissClick(this.props.id);
			clearTimeout(timeOut);
		}, 5000);
	}

	render() {
		return (
			<li onClick={this.props.onDismissClick} className="toast">
				<span className="toast__content circular" style={{ color: 'white' }}>
					{this.props.text}
				</span>
			</li>
		);
	}

	shouldComponentUpdate() {
		return false;
	}
}

Toast.propTypes = {
	color: PropTypes.string.isRequired,
	onDismissClick: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired
};

export default Toast;
