import React from 'react';
import { connect } from 'react-redux';
import Member from './Member';
import './AllMembers.scss';
import { STATUS, SOCKET_STATUS } from '../../helpers/commonConsts';
import {
	CREATE_ROOM,
	LOAD_ACTIVE_ROOMS,
	LOAD_MESSAGE,
	REFERESH_UNREAD_NOTIFICATION_COUNT,
	SELECT_ROOM
} from '../../actions/room.actions';
import { ContextMenuTrigger } from 'react-contextmenu';
import { UPDATE_MODAL } from '../../actions/user.actions';
import MemberProfile from '../ModalComponents/MemberProfile';
import { connectCall, createNotification, getCallColor, getRoomId } from '../../helpers/utils';
import { addAdmins, removeMembers, removeUserFromTeam } from '../../helpers/api';
import { ADD_CALL_TO_TIMER } from '../../actions/timer.actions';
import { CALL_INVITE, START_NEW_CALL } from '../../actions/call.actions';
function AllMembers({
	updateModal,
	allMembers,
	membersCount,
	me,
	allRooms,
	call,
	allUsers,
	selectRoom,
	refereshUnreadMessageCount,
	loadRoomMessage,
	makeRoom,
	chatBoxHeight,
	loadActiveRooms,
	selectedRoom,
	inviteToCall,
	startNewCall,
	addCallToTimer,
	admins
}) {
	async function createNewRoom(member) {
		let payload = {
			members: [member.userId, me._id],
			teamId: me.teamId,
			roomType: 1
		};

		await makeRoom(payload);
	}

	async function removeMemberFromGroup(me) {
		try {
			let result = await removeMembers({ members: [me._id], roomId: selectedRoom.roomId });
			if (!result.success) {
				throw new Error(result.message);
			}
			loadActiveRooms();
			createNotification({ type: 'success', message: me?.name.toUpperCase() + ' removed from the group' });
		} catch (e) {
			createNotification({
				type: 'danger',
				message: e.message,
				title: `Could not remove ${me.name} from the group`
			});
		}
	}

	async function makeAdmin(me) {
		try {
			let result = await addAdmins({ userId: me._id, roomId: selectedRoom.roomId });
			if (!result.success) {
				throw new Error(result.message);
			}
			loadActiveRooms();
			createNotification({ type: 'success', message: me?.name.toUpperCase() + ' added to admins' });
		} catch (e) {
			createNotification({
				type: 'danger',
				message: e.message,
				title: `Could not add ${me.name} to admins`
			});
		}
	}

	function callUser(invitedUser) {
		connectCall({
			call,
			loggedInUser: me,
			inviteToCall,
			invitedUser: invitedUser,
			startNewCall,
			roomId: selectRoom.roomId,
			addCallToTimer
		});
	}

	async function disableUser(user) {
		try {
			let result = await removeUserFromTeam({ userId: user._id });
			if (!result.success) {
				throw new Error(result.message);
			}
			createNotification({ type: 'success', message: user?.name.toUpperCase() + ' removed from the team' });
		} catch (e) {
			createNotification({
				type: 'danger',
				message: e.message,
				title: `Could not remove ${user?.name.toUpperCase()}`
			});
		}
	}

	return (
		<div className="p:1 all-members" style={{ height: `calc(${chatBoxHeight}px - 1.87rem)`, overflow: 'auto' }}>
			<div className="m-b:.7 sub-title circular f-w:100 f:1">Members - {membersCount}</div>
			<div>
				{allMembers
					.sort((member1, member2) => member1?.name?.localeCompare(member2?.name))
					.map((member) => (
						<ContextMenuTrigger
							id="same_unique_identifier"
							user={allUsers?.[member.userId]}
							admins={admins}
							isAdmin={admins.includes(me._id)}
							isCurrentUser={me._id == member.userId}
							loggedInUser={me}
							collect={(p) => p}
							profile={() => {
								updateModal({
									show: true,
									ModalBody: MemberProfile,
									userProfile: allUsers?.[member.userId],
									noHeader: true,
									noPadding: true,
									crossBg: true,
									isLoggedInUser: me._id == member.userId && true
									// modalWidth: 800
								});
							}}
							message={() => {
								if (me._id == member.userId) {
								} else if (selectRoom(getRoomId(allUsers?.[member.userId], me, allRooms))) {
									selectRoom(getRoomId(allUsers?.[member.userId], me, allRooms));
									loadRoomMessage(getRoomId(allUsers?.[member.userId], me, allRooms));
									localStorage.setItem(getRoomId(allUsers?.[member.userId], me, allRooms), 0);
									refereshUnreadMessageCount();
								} else {
									createNewRoom();
								}
							}}
							removeFromGroup={(me) => {
								removeMemberFromGroup(me);
							}}
							call={(invitedUser) => callUser(invitedUser)}
							makeAdmin={(me) => makeAdmin(me)}
							disableUser={(me) => disableUser(me)}
						>
							<div
								onClick={async () => {
									updateModal({
										show: true,
										crossBg: true,
										ModalBody: MemberProfile,
										userProfile: allUsers?.[member.userId],
										noHeader: true,
										noPadding: true,
										isLoggedInUser: me._id == member.userId && true

										// modalWidth: 800
									});
								}}
								className="p-t:.6 p:.4 p-t:.1 p-l:.7 p-b:.1 member-bar cursor sub-title"
								style={{
									marginLeft: '-0.587rem',
									opacity:
										allUsers?.[member.userId]?.socketStatus == SOCKET_STATUS.DISCONNECTED && 0.4
								}}
							>
								<Member
									disabled={allUsers?.[member.userId]?.disabled}
									status={allUsers?.[member.userId]?.status}
									socketStatus={allUsers?.[member.userId]?.socketStatus}
									onCall={allUsers?.[member.userId]?.activeCallId}
									callColor={getCallColor(allUsers?.[member.userId], call)}
									key={member.userId}
									name={allUsers?.[member.userId].name}
									lastSnap={allUsers?.[member.userId].lastSnap}
									profilePic={allUsers?.[member.userId].profilePic}
									isAdmin={admins.includes(member.userId)}
								/>
							</div>
						</ContextMenuTrigger>
					))}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		allUsers: state.users.allUsers,
		allRooms: state.rooms.allRooms,
		me: state.me,
		call: state.call
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		inviteToCall: (payload) => dispatch({ type: CALL_INVITE, payload }),
		startNewCall: (payload) => dispatch({ type: START_NEW_CALL, payload }),
		addCallToTimer: (payload) => dispatch({ type: ADD_CALL_TO_TIMER, payload }),
		loadActiveRooms: () => dispatch({ type: LOAD_ACTIVE_ROOMS }),
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload }),
		makeRoom: (payload) => dispatch({ type: CREATE_ROOM, payload }),
		selectRoom: (payload) => dispatch({ type: SELECT_ROOM, payload }),
		refereshUnreadMessageCount: () => dispatch({ type: REFERESH_UNREAD_NOTIFICATION_COUNT }),
		loadRoomMessage: (payload) =>
			dispatch({
				type: LOAD_MESSAGE,
				payload: {
					roomId: payload
				}
			})
	};
};

AllMembers = connect(mapStateToProps, mapDispatchToProps)(AllMembers);
export default AllMembers;
