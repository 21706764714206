import { ADD_TOAST, REMOVE_TOAST } from '../actions/toast.actions';
import { RESET_ALL_STATES } from '../actions/init.actions'

const initialState = [];

const notifications = (state = initialState, action) => {
	const { payload, type } = action;
	switch (action.type) {
		case 'ADD_NOTIFICATION': {
			const { notification } = action.payload;

			return [...state, notification];
		}

		case 'REMOVE_NOTIFICATION': {
			const { notificationId } = action.payload;

			return state.filter((notification) => notification.id !== notificationId);
		}

		case 'REMOVE_ALL_NOTIFICATIONS': {
			return [];
		}
		case ADD_TOAST:
			return [payload, ...state];

		case REMOVE_TOAST:
			return state.filter((toast) => toast.id !== payload);
		case RESET_ALL_STATES: {
			return initialState
		}
		default:
			return state;
	}
};

export default notifications;
