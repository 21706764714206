import { ADD_DATA_PRODUCER } from '../../../actions/call.actions';
async function enableMEventProducer(store) {
	this.logger.debug('enableMEventProducer()');
	try {
		this.mEventProducer = await this._sendTransport.produceData({
			ordered: false,
			maxPacketLifeTime: 2000,
			label: 'mEvent',
			priority: 'medium',
			appData: { info: 'mEvent-DataProducer' }
		});

		const dataProducer = {
			id: this.mEventProducer.id,
			sctpStreamParameters: this.mEventProducer.sctpStreamParameters,
			label: this.mEventProducer.label,
			protocol: this.mEventProducer.protocol
		};

		window.store.dispatch({
			type: ADD_DATA_PRODUCER,
			payload: { dataProducer }
		});

		this.mEventProducer.on('transportclose', () => {
			this.mEventProducer = null;
		});

		this.mEventProducer.on('open', () => {
			this.logger.debug('mEvent DataProducer "open" event');
		});

		this.mEventProducer.on('close', () => {
			this.logger.error('mEvent DataProducer "close" event');

			this.mEventProducer = null;
			throw 'mEvent DataProducer closed';
		});

		this.mEventProducer.on('error', (error) => {
			this.logger.error('mEvent DataProducer "error" event:%o', error);

			throw `mEvent DataProducer error: ${error}`;
		});

		this.mEventProducer.on('bufferedamountlow', () => {
			this.logger.debug('mEvent DataProducer "bufferedamountlow" event');
		});
	} catch (error) {
		this.logger.error('enableMEventProducer() | failed:%o', error);
		throw `Error enabling mEvent DataProducer: ${error}`;
	}
}

/**
 *
 * @param {Object} obj
 * @param {String} obj.id
 * @param {Object} obj.coords
 */
async function sendMouseCoords(obj) {
	this.logger.debug('_sendMouseCoords()');
	try {
		this._protoo.notify('mouseEvent', obj);
	} catch (error) {
		this.logger.error('_sendMouseCoords() | failed:%o', error);
		throw new Error(error);
	}
}

async function sendMousePointerEnabledDisabled(obj) {
	this.logger.debug('sendMousePointerEnabledDisabled()');
	try {
		this._protoo.notify('pointerShareEvent', obj);
	} catch (error) {
		this.logger.error('sendMousePointerEnabledDisabled() | failed:%o', error);
		throw new Error(error);
	}
}

export { enableMEventProducer, sendMouseCoords, sendMousePointerEnabledDisabled };
