import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import downloadImageFromUrl from '../../helpers/imgDwnUrl';

import './PanelSettings.scss';

function ImageViewer({ image }) {
	const [dimensions, setDimensions] = useState({});
	function getMeta(url) {
		let img = new Image();
		img.onload = function () {
			if (img.width >= img.height) {
				if (0.6 * img.width <= window.innerWidth) {
					setDimensions({ width: window.innerWidth * 0.3, height: 'auto' });
				} else if (img.width <= 0.5 * window.innerWidth) {
					setDimensions({ width: img.width, height: img.height });
				} else {
					setDimensions({ width: window.innerWidth * 0.55, height: 'auto' });
				}
			} else {
				if (0.6 * img.height <= window.innerHeight) {
					setDimensions({ height: window.innerWidth * 0.3, width: img.width });
				} else if (img.height <= 0.5 * window.innerHeight) {
					setDimensions({ width: img.width, height: img.height });
				} else {
					setDimensions({ height: window.innerHeight * 0.55, width: 'auto' });
				}
			}
		};
		img.src = url;
	}

	useEffect(() => {
		getMeta(image);
	}, []);

	return dimensions.width ? (
		<div className="panel-settings m-t:.6">
			<img src={image} width={dimensions.width} height={dimensions.height} style={{ borderRadius: 6 }} />
			<div className="t-a:r m-t:5">
				<div
					type="text"
					class="circular m-t:.3 f-w:400 sub-title cursor m-r:4 cancel-btn fl:l"
					onClick={() => {
						downloadImageFromUrl(image);
					}}
				>
					Download
				</div>
				<Button
					type="primary"
					className="circular create-btn f-w:100 sub-title"
					size="middle"
					onClick={() => {
						let data = image;
						let w = window.open('about:blank');
						let imageFile = new Image();
						imageFile.src = data;
						const timeOut = setTimeout(function () {
							w.document.write(imageFile.outerHTML);
							clearTimeout(timeOut)
						}, 100);
					}}
				>
					Open in a new tab
				</Button>
			</div>
		</div>
	) : null;
}

export default ImageViewer;
