import React from 'react';
import { connect } from 'react-redux';
import './LargeNotification.scss';
import { SOCKET_STATUS } from '../../helpers/commonConsts';
import Draggable from 'react-draggable';

function DisconnectedNotification({ socketStatus }) {

	return socketStatus === SOCKET_STATUS.DISCONNECTED || (navigator && !navigator.onLine) ? (
		<div className="large-notif">
			<Draggable>
				<div className="large-notif-cont">
					<div className="user-info product">
						<div className="fl:m v-a:m m-t:.8">
							You are right now offline, we are working on connecting you back. Please refresh if if
							something goes wrong.
						</div>
					</div>
				</div>
			</Draggable>
		</div>
	) : null;
}

const mapStateToProps = (state) => {
	return {
		socketStatus: state?.me?.socketStatus
	};
};

DisconnectedNotification = connect(mapStateToProps)(DisconnectedNotification);
export default DisconnectedNotification;
