export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MESSAGE = 'MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';

export const NEW_ROOM_CREATED = 'NEW_ROOM_CREATED';
export const SELECT_ROOM = 'SELECT_ROOM';
export const LOAD_ROOM_INFO = 'LOAD_ROOM_INFO';

export const LOAD_ACTIVE_ROOMS = 'LOAD_ACTIVE_ROOMS';
export const LOAD_ACTIVE_ROOMS_SUCCESS = 'LOAD_ACTIVE_ROOMS_SUCCESS';
export const LOAD_ACTIVE_ROOMS_FAILED = 'LOAD_ACTIVE_ROOMS_FAILED';

export const LOAD_MESSAGE = 'LOAD_MESSAGE';
export const LOAD_MESSAGE_SUCCESS = 'LOAD_MESSAGE_SUCCESS';
export const LOAD_MESSAGE_FAILED = 'LOAD_MESSAGE_FAILED';

export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

export const NEW_ROOM = 'NEW_ROOM'; // socket event
export const CREATE_ROOM = 'CREATE_ROOM';
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_FAILED = 'CREATE_ROOM_FAILED';
export const REFERESH_UNREAD_NOTIFICATION_COUNT = 'REFERESH_UNREAD_NOTIFICATION_COUNT';
export const LOAD_MORE_MESSAGE = 'LOAD_MORE_MESSAGE';
export const FETCH_ROOMS_NETWORK = 'FETCH_ROOMS_NETWORK';
export const SEARCH = 'SEARCH';

// sreach room
export function search(value) {
	return { type: SEARCH, payload: value };
}

export function loadRoomInfo({ roomId }) {
	return {
		type: LOAD_ROOM_INFO,
		payload: { roomId }
	};
}

export function selectRoom(roomId) {
	return {
		type: SELECT_ROOM,
		payload: roomId
	};
}

export function loadActiveRooms() {
	return {
		type: LOAD_ACTIVE_ROOMS
	};
}

export function sendMessage({ to, message }) {
	return {
		type: SEND_MESSAGE,
		payload: {
			to,
			message,
			type: 1
		}
	};
}

//loading message history
export function loadRoomMessage(roomId) {
	return {
		type: LOAD_MESSAGE,
		payload: {
			roomId
		}
	};
}

//create room action
export function createRoom({ name, members, roomType, teamId }) {
	return {
		type: CREATE_ROOM,
		payload: {
			name,
			members,
			roomType,
			teamId
		}
	};
}

// load more messaage
export function loadMoreMessage({ limit, skip }) {
	return {
		type: LOAD_MORE_MESSAGE,
		payload: {
			limit,
			skip
		}
	};
}
