export const TICK_SNAP_TIMER = 'TICK_SNAP_TIMER';
export const STOP_SNAP_TIMER = 'STOP_SNAP_TIMER';
export const START_SNAP_TIMER = 'START_SNAP_TIMER';
export const RESET_SNAP_TIMER = 'RESET_SNAP_TIMER';

export const TICK_CALL_TIMER = 'TICK_CALL_TIMER';
export const STOP_CALL_TIMER = 'STOP_CALL_TIMER';
export const START_CALL_TIMER = 'START_CALL_TIMER';
export const RESET_CALL_TIMER = 'RESET_CALL_TIMER';
export const ADD_CALL_TO_TIMER = 'ADD_CALL_TO_TIMER';
export const REMOVE_CALL_FROM_TIMER = 'REMOVE_CALL_FROM_TIMER';
