import { createSelector } from 'reselect';
import { SOCKET_STATUS } from '../helpers/commonConsts';

export const getAllRooms = (state) => state.rooms.allRooms;
export const getSelectedRoom = (state) => state.rooms.selectedRoom;
export const getUser = (state) => state.me;
export const getAllUsers = (state) => state.users.allUsers;

export const getAllOnetoOneRooms = createSelector([getAllRooms, getUser], (allRooms, user) => {
	let oneToOneRooms = {};
	Object.keys(allRooms).forEach((roomId) => {
		if (!allRooms[roomId].name) {
			let targetUser = null;
			allRooms[roomId].members.forEach((member) => {
				if (member.userId !== user._id) {
					targetUser = member.userId;
				}
			});
			oneToOneRooms[targetUser] = allRooms[roomId];
		}
	});
	return oneToOneRooms;
});

export const getEnrichedSelectedRoom = createSelector(
	[getSelectedRoom, getUser, getAllUsers],
	(selectedRoom, user, allUsers) => {
		if (selectedRoom && user) {
			selectedRoom.targetUser = {};
			selectedRoom.members = selectedRoom.members.map((member) => {
				if (member.userId != user._id && member !== undefined) {
					selectedRoom.targetUser = allUsers[member.userId];
				}
				return allUsers[member.userId];
			});
			const members = selectedRoom.members.filter(member => member !== undefined);
			selectedRoom.members = members
			return selectedRoom
		}
		return null;
	}
);

export const getOnlineUsersInSelectedRoom = createSelector(
	[getSelectedRoom, getUser, getAllUsers],
	//To trigger change when user status is changing we need to get all users in this component,
	(selectedRoom, currentUser, allUsers) => {
		if (selectedRoom) {
			const onlineMembers = selectedRoom.members.filter((member) => {
				if (member !== undefined) {
					if (member && member.userId == currentUser._id) {
						return currentUser.socketStatus == SOCKET_STATUS.CONNECTED;
					}
					return !member?.socketStatus || member?.socketStatus != SOCKET_STATUS.DISCONNECTED;
				}
			});
			return onlineMembers;
		}
		return [];
	}
);
