import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from '../../helpers/videoRoom/appPropTypes';
import { withRoomContext } from '../../context/VideoCall/RoomContext';
import * as stateActions from '../../actions/state.actions';
import { SET_CONSUMER_LAYER, SEND_MOUSE_EVENTS } from '../../actions/call.actions';
import PeerView from './PeerView';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { debounce } from 'lodash';

const Peer = (props) => {
	const {
		roomClient,
		peer,
		audioConsumer,
		videoConsumer,
		audioMuted,
		faceDetection,
		onSetStatsPeerId,
		isMe,
		me,
		setConsumerPreferredLayers,
		sendMouseEvents,
		allowMouse,
		disableAudioObserver
	} = props;

	const audioEnabled = Boolean(audioConsumer) && !audioConsumer.locallyPaused && !audioConsumer.remotelyPaused;

	const videoVisible = Boolean(videoConsumer) && !videoConsumer.locallyPaused && !videoConsumer.remotelyPaused;

	const handle = useFullScreenHandle();

	const [fullScreen, setFullScreen] = useState(false);
	const [isHovering, setIsHovering] = useState(false);

	const handlePositionChange = useCallback(
		debounce((e, obj) => {
			const { isHovering, fullScreen } = obj;

			if (fullScreen && isHovering) {
				const x = e.clientX;
				const y = e.clientY;

				if (
					window.store.getState()?.me?.sendMouseEvenet &&
					(window.store.getState()?.call?.peers[peer.id].pointerSharing === true ||
						Object.keys(window.store.getState().consumers).findIndex(
							(p) => window.store.getState().consumers[p].type === 'share'
						) > -1)
				) {
					sendMouseEvents({
						id: peer.id,
						producerId: me.id,
						coords: {
							x,
							y
						},
						resolution: {
							height: window.screen.availHeight,
							width: window.screen.availWidth
						}
					});
				}
			}
		}, 0.1),
		[]
	);

	const trackMouseMovement = (e, obj) => {
		/**
		 * track event if @name fullscreen and if mouse is hovering over the component.
		 */

		handlePositionChange(e, obj);
	};

	useEffect(() => {
		return () => {
			if (allowMouse)
				window.removeEventListener('mousemove', (e) => trackMouseMovement(e, { fullScreen, isHovering }));
		};
	}, []);
	if (allowMouse) window.addEventListener('mousemove', (e) => trackMouseMovement(e, { fullScreen, isHovering }));

	function onFullScreen() {
		if (!fullScreen) handle.enter();
		if (fullScreen) handle.exit();
		if (fullScreen) {
			setFullScreen(false);
		} else {
			setFullScreen(peer.id);
		}
	}
	function onFullscreenStateChange(state) {
		if (!state && !fullScreen) {
			return false;
		}
		if (state && !fullScreen) {
			setFullScreen(peer.id);
		}
		if (!state && fullScreen) {
			setFullScreen(false);
		}
	}

	const handleHover = () => {
		setIsHovering(!isHovering);
	};

	return (
		<div data-component="Peer" onMouseEnter={handleHover} onMouseLeave={handleHover}>
			<div className="indicators">
				<If condition={!audioEnabled}>
					<div className="icon mic-off" />
				</If>

				<If condition={!videoConsumer}>
					<div className="icon webcam-off" />
				</If>
			</div>
			<FullScreen handle={handle} onChange={onFullscreenStateChange}>
				<PeerView
					roomClient={roomClient}
					me={props.me}
					fullScreen={fullScreen}
					onFullScreen={onFullScreen}
					peer={peer}
					audioConsumerId={audioConsumer ? audioConsumer.id : null}
					videoConsumerId={videoConsumer ? videoConsumer.id : null}
					audioRtpParameters={audioConsumer ? audioConsumer.rtpParameters : null}
					videoRtpParameters={videoConsumer ? videoConsumer.rtpParameters : null}
					consumerSpatialLayers={videoConsumer ? videoConsumer.spatialLayers : null}
					consumerTemporalLayers={videoConsumer ? videoConsumer.temporalLayers : null}
					consumerCurrentSpatialLayer={videoConsumer ? videoConsumer.currentSpatialLayer : null}
					consumerCurrentTemporalLayer={videoConsumer ? videoConsumer.currentTemporalLayer : null}
					consumerPreferredSpatialLayer={videoConsumer ? videoConsumer.preferredSpatialLayer : null}
					consumerPreferredTemporalLayer={videoConsumer ? videoConsumer.preferredTemporalLayer : null}
					consumerPriority={videoConsumer ? videoConsumer.priority : null}
					audioTrack={audioConsumer ? audioConsumer.track : null}
					videoTrack={videoConsumer ? videoConsumer.track : null}
					audioMuted={audioMuted}
					videoVisible={videoVisible}
					videoMultiLayer={videoConsumer && videoConsumer.type !== 'simple'}
					audioCodec={audioConsumer ? audioConsumer.codec : null}
					videoCodec={videoConsumer ? videoConsumer.codec : null}
					audioScore={audioConsumer ? audioConsumer.score : null}
					videoScore={videoConsumer ? videoConsumer.score : null}
					faceDetection={faceDetection}
					onChangeVideoPreferredLayers={(spatialLayer, temporalLayer) => {
						setConsumerPreferredLayers(videoConsumer.id, spatialLayer, temporalLayer);
					}}
					onChangeVideoPriority={(priority) => {
						roomClient.setConsumerPriority(videoConsumer.id, priority);
					}}
					onRequestKeyFrame={() => {
						roomClient.requestConsumerKeyFrame(videoConsumer.id);
					}}
					onStatsClick={onSetStatsPeerId}
					audioProducer={props.audioProducer}
					videoProducer={props.videoProducer}
					disableAudioObserver={disableAudioObserver}
				/>
			</FullScreen>
		</div>
	);
};

Peer.propTypes = {
	roomClient: PropTypes.any.isRequired,
	peer: appPropTypes.Peer.isRequired,
	audioConsumer: appPropTypes.Consumer,
	videoConsumer: appPropTypes.Consumer,
	audioMuted: PropTypes.bool,
	faceDetection: PropTypes.bool.isRequired,
	onSetStatsPeerId: PropTypes.func.isRequired
};

const mapStateToProps = (state, { id }) => {
	const me = state.me;
	const peer = state.call.peers[id];
	const consumersArray = peer.consumers.map((consumerId) => state.consumers[consumerId]);
	let audioConsumer = {};
	// let videoConsumer = {};
	if (consumersArray) {
		audioConsumer = consumersArray.find((consumer) => consumer?.track.kind === 'audio');
		// videoConsumer = consumersArray.find(
		// 	(consumer) => consumer?.track.kind === 'video' && consumer?.type !== 'share'
		// );
	}

	//for actions buttons on fullscreen
	const producersArray = Object.values(state.producers);
	const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer = producersArray.find((producer) => producer.track.kind === 'video');

	return {
		audioProducer,
		videoProducer,
		me,
		peer,
		audioConsumer,
		// videoConsumer,
		audioMuted: me.audioMuted,
		faceDetection: state.room.faceDetection
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetStatsPeerId: (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId)),
		setConsumerPreferredLayers: (consumerId, spatialLayer, temporalLayer) =>
			dispatch({
				type: SET_CONSUMER_LAYER,
				payload: { consumerId, spatialLayer, temporalLayer }
			}),
		sendMouseEvents: (event) =>
			dispatch({
				type: SEND_MOUSE_EVENTS,
				payload: { event }
			})
	};
};

const PeerContainer = withRoomContext(connect(mapStateToProps, mapDispatchToProps)(Peer));

export default PeerContainer;
