import React from 'react';

function ThreeDotIcon(props) {
	return (
		<svg
			id="centerPanelSvg"
			style={{ ...props }}
			class={props.class}
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 384 384"
		>
			<g>
				<g>
					<circle cx="192" cy="42.667" r="42.667" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="192" cy="192" r="42.667" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="192" cy="341.333" r="42.667" />
				</g>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
}

export default ThreeDotIcon;
