import { store } from 'react-notifications-component';
import logo from '../images/logo.ico';
import addNotification from 'react-push-notification';
import { JOIN_CALL, INCOMING_CALL_INVITE } from '../actions/call.actions';

let mediaQueryDetectorElem;

export function initialize() {
	// Media query detector stuff.
	mediaQueryDetectorElem = document.getElementById('mediasoup-demo-app-media-query-detector');

	return Promise.resolve();
}

export function isDesktop() {
	return Boolean(mediaQueryDetectorElem.offsetParent);
}

export function isMobile() {
	return !mediaQueryDetectorElem.offsetParent;
}

export function createNotification({ type, title, message = ' ' }) {
	store.addNotification({
		title,
		message,
		type,
		insert: 'top',
		container: 'top-right',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration: 5000,
			onScreen: true,
			pauseOnHover: true
		}
	});
}

export function createPushNotification({ type, title, message = ' ', stick, nativeOnly = false }) {
	if (stick && window.inActive) {
		store.addNotification({
			title,
			message,
			type,
			insert: 'top',
			container: 'top-right',
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			dismiss: {
				duration: 1000000,
				// onScreen: false,
				pauseOnHover: true,
				showIcon: stick ? true : false
			}
		});
	}
	!window.inActive && !nativeOnly
		? store.addNotification({
			title,
			message,
			type,
			insert: 'top',
			container: 'top-right',
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			dismiss: {
				duration: stick ? 1000000 : 5000,
				// onScreen: stick ? false : true,
				pauseOnHover: true,
				showIcon: stick ? true : false
			}
		})
		: addNotification({
			title: !title ? message : title,
			message: title ? message : '',
			native: true,
			icon: logo
		});
}

export const sleep = (ms) => {
	return new Promise((res, rej) => {
		const timeOut = setTimeout(() => {
			res();
			clearTimeout(timeOut)
		}, ms);
	});
};


function hslToHex(h, s, l) {
	l /= 100;
	const a = (s * Math.min(l, 1 - l)) / 100;
	const f = (n) => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color)
			.toString(16)
			.padStart(2, '0'); // convert to Hex and prefix "0" if needed
	};
	return `${f(0)}${f(8)}${f(4)}`;
}

export function getRandomPastelColors() {
	let hue = Math.floor(Math.random() * 360);
	let light = Math.floor(Math.random() * 30) + 50;
	let saturation = Math.floor(Math.random() * 40) + 50;

	let pastel = 'hsl(' + hue + ', 100%, 87%)';
	console.log(hue, saturation, light);
	return hslToHex(hue, saturation, light);
}

export const imageCompressOptions = {
	maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
	maxWidthOrHeight: 150, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
	useWebWorker: true // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
};

export function getRoomId(user, loggedInUser, allRooms) {
	let loggedInUserId = loggedInUser._id;
	let targetRoomId;
	Object.keys(allRooms)
		.filter((roomId) => allRooms[roomId].isDmRoom)
		.map((roomId) => {
			let members = {};
			allRooms[roomId]?.members.map((member) => {
				members[member.userId] = member.userId;
			});
			if (members[user._id] && members[loggedInUserId]) {
				targetRoomId = roomId;
			}
		});
	return targetRoomId;
}

export function getCallColor(user, call) {
	try {
		if (user.activeCallId) {
			//this would be other active calls in the app
			return '#' + user.activeCallId.split('-')[0];
		}
		if (call.roomId) {
			//this would be the current he may have joined
			return '#' + call.roomId.split('-')[0];
		}
		return '#009687f5';
	} catch (e) {
		createNotification({ type: 'danger', message: e.message, title: 'Error while parsing the roomID' });
	}
}
export function getColorFromCallConneting(activeCallId) {
	return '#' + activeCallId.split('-')[0];
}

export function connectCall({
	e,
	call,
	roomId,
	loggedInUser,
	invitedUser,
	inviteToCall,
	addCallToTimer,
	startNewCall
}) {
	e && e.stopPropagation();
	if (window.store?.getState().call?.peers?.[invitedUser._id]) {
		createNotification({ type: 'danger', title: invitedUser.name + ' is already in this call' });
		return false;
	}
	if (call.roomId && call.roomId.length && call.roomId.includes('-')) {
		inviteToCall({
			videoRoomId: call.roomId,
			to: roomId,
			user: loggedInUser,
			invitedUser: invitedUser,
			invitedUserId: invitedUser._id,
			teamId: invitedUser.teamId
		});
	} else if (invitedUser.activeCallId && invitedUser.activeCallId.length && invitedUser.activeCallId.includes('-')) {
		window.store.dispatch({
			type: INCOMING_CALL_INVITE,
			payload: {
				videoRoomId: invitedUser.activeCallId,
				from: invitedUser._id
			}
		});
	} else {
		startNewCall({
			oneToOneRoomId: roomId,
			invitedUser: invitedUser,
			user: loggedInUser
		});
	}
}

export const imageMimeTypes = [
	'image/apng',
	'image/avif',
	'image/gif',
	'image/jpeg',
	'image/png',
	'image/svg+xml',
	'image/webp'
];

export const compressedMimeTypes = [
	'application/x-rar-compressed',
	'application/octet-stream',
	'application/zip',
	'application/octet-stream',
	'application/x-zip-compressed',
	'multipart/x-zip'
];

export const videoMimeTypes = [
	'video/x-flv',
	'video/mp4',
	'application/x-mpegURL',
	'video/MP2T',
	'video/3gpp',
	'video/quicktime',
	'video/x-msvideo',
	'video/x-ms-wmv'
];

export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const getWidthForPeer = (pLength) => {
	let availWidth = window.screen.availWidth;
	let rightSideBarPanel = parseInt(document.getElementsByClassName('right-panel')[0]?.style?.width);

	if (rightSideBarPanel <= 400) {
		if (availWidth <= 1600) {
			if (pLength <= 3) {
				return 280;
			} else if (pLength > 3 && pLength <= 6) {
				return 200;
			} else if (pLength > 6 && pLength <= 12) {
				return 120;
			} else if (pLength > 12 && pLength <= 20) {
				return 100;
			} else {
				return 100;
			}
		} else if (availWidth > 1600 && availWidth <= 2600) {
			if (pLength <= 3) {
				return 480;
			} else if (pLength > 3 && pLength <= 6) {
				return 400;
			} else if (pLength > 6 && pLength <= 12) {
				return 320;
			} else if (pLength > 12 && pLength <= 20) {
				return 280;
			} else {
				return 150;
			}
		} else {
			return 250;
		}
	} else if (rightSideBarPanel > 400 && rightSideBarPanel <= 900) {
		if (availWidth <= 1600) {
			if (pLength <= 3) {
				return 480;
			} else if (pLength > 3 && pLength <= 6) {
				return 400;
			} else if (pLength > 6 && pLength <= 12) {
				return 320;
			} else if (pLength > 12 && pLength <= 20) {
				return 280;
			} else {
				return 250;
			}
		} else if (availWidth > 1600 && availWidth <= 2600) {
			if (pLength <= 3) {
				return 680;
			} else if (pLength > 3 && pLength <= 6) {
				return 600;
			} else if (pLength > 6 && pLength <= 12) {
				return 520;
			} else if (pLength > 12 && pLength <= 20) {
				return 480;
			} else {
				return 350;
			}
		} else {
			return 250;
		}
	} else {
		if (availWidth <= 1600) {
			if (pLength <= 3) {
				return 580;
			} else if (pLength > 3 && pLength <= 6) {
				return 500;
			} else if (pLength > 6 && pLength <= 12) {
				return 420;
			} else if (pLength > 12 && pLength <= 20) {
				return 380;
			} else {
				return 350;
			}
		} else if (availWidth > 1600 && availWidth <= 2600) {
			if (pLength <= 3) {
				return 780;
			} else if (pLength > 3 && pLength <= 6) {
				return 700;
			} else if (pLength > 6 && pLength <= 12) {
				return 620;
			} else if (pLength > 12 && pLength <= 20) {
				return 580;
			} else {
				return 450;
			}
		} else {
			return 350;
		}
	}
};

export { getWidthForPeer };
