import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Spinner from 'react-spinner';
import clipboardCopy from 'clipboard-copy';
import hark from 'hark';
import Logger from '../../helpers/Logger';
import * as appPropTypes from '../../helpers/videoRoom/appPropTypes';
import * as cookiesManager from '../../helpers/cookiesManager';

import {
	SET_ENABLE_MIC,
	SET_DISABLE_MIC,
	SET_ENABLE_CALL_WEBCAM,
	SET_DISABLE_CALL_WEBCAM
} from '../../actions/call.actions';

import AvatarMale from '../../images/videoImages/Avatar_male.png';

const logger = new Logger('PeerView');

class PeerView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			audioVolume: 0, // Integer from 0 to 10.,
			showInfo: window.SHOW_INFO || false,
			videoResolutionWidth: null,
			videoResolutionHeight: null,
			videoCanPlay: false,
			videoElemPaused: false,
			maxSpatialLayer: null
		};

		// Latest received video track.
		// @type {MediaStreamTrack}
		this._audioTrack = null;

		// Latest received video track.
		// @type {MediaStreamTrack}
		this._videoTrack = null;

		// Hark instance.
		// @type {Object}
		this._hark = null;

		// Periodic timer for reading video resolution.
		this._videoResolutionPeriodicTimer = null;

		// requestAnimationFrame for face detection.
		this._faceDetectionRequestAnimationFrame = null;
	}

	render() {
		const {
			isMe,
			peer,
			audioProducerId,
			videoProducerId,
			audioConsumerId,
			videoConsumerId,
			videoRtpParameters,
			consumerSpatialLayers,
			consumerTemporalLayers,
			consumerCurrentSpatialLayer,
			consumerCurrentTemporalLayer,
			consumerPreferredSpatialLayer,
			consumerPreferredTemporalLayer,
			consumerPriority,
			audioMuted,
			videoVisible,
			videoMultiLayer,
			audioCodec,
			videoCodec,
			audioScore,
			videoScore,
			onChangeMaxSendingSpatialLayer,
			onChangeVideoPreferredLayers,
			onChangeVideoPriority,
			onRequestKeyFrame,
			onFullScreen,
			fullScreen,
			me,
			audioProducer,
			videoProducer,
			enableMic,
			disableMic,
			enableWebcam,
			disableWebcam,
			sendMouseEvent,
			disableAudioObserver
		} = this.props;

		const {
			audioVolume,
			showInfo,
			videoResolutionWidth,
			videoResolutionHeight,
			videoCanPlay,
			videoElemPaused,
			maxSpatialLayer
		} = this.state;

		let webcamState;
		let micState;
		if (me) {
			if (!me.canSendMic) micState = 'unsupported';
			else if (!audioProducer && !me.canSendMic) micState = 'unsupported';
			if (audioProducer && !audioProducer.paused) micState = 'on';
			else micState = 'off';

			if (!me.canSendWebcam) webcamState = 'unsupported';
			else if (videoProducer && videoProducer.type !== 'share') webcamState = 'on';
			else webcamState = 'off';
		}

		return (
			<div
				data-component="PeerView"
				id={!isMe && !fullScreen && 'peers-box'}
				style={{
					backgroundImage: isMe
						? `url('${window?.store?.getState()?.me?.lastSnap || AvatarMale}')`
						: `url(${window?.store?.getState()?.users?.allUsers[peer.id]?.lastSnap || AvatarMale})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover'
				}}
			>
				<div className="info icons-box">
					<div className="icons">
						{/* <div
							style={{ marginRight: !isMe && 7 }}
							className={classnames('icon', 'info', { on: showInfo })}
							onClick={() => this.setState({ showInfo: !showInfo })}
						/> */}

						{/* <div
							style={{ marginRight: !isMe && 7 }}
							className={classnames('icon', 'stats')}
							onClick={() => onStatsClick(peer.id)}
						/> */}
						{!isMe &&
							!fullScreen && [
								<div
									data-place="bottom"
									data-tip={fullScreen ? 'Exit fullscreen' : 'Fullscreen'}
									className={classnames('icon', 'fullscreen')}
									onClick={() => onFullScreen()}
								/>,
								<ReactTooltip type="light" effect="solid" delayShow={1500} delayHide={50} />
							]}
					</div>

					<div className={classnames('box', { visible: showInfo })}>
						<If condition={audioProducerId || audioConsumerId}>
							<h1>audio</h1>

							<If condition={audioProducerId}>
								<p>
									{'id: '}
									<span
										className="copiable"
										data-tip="Copy audio producer id to clipboard"
										onClick={() => clipboardCopy(`"${audioProducerId}"`)}
									>
										{audioProducerId}
									</span>
								</p>

								<ReactTooltip type="light" effect="solid" delayShow={1500} delayHide={50} />
							</If>

							<If condition={audioConsumerId}>
								<p>
									{'id: '}
									<span
										className="copiable"
										data-tip="Copy video producer id to clipboard"
										onClick={() => clipboardCopy(`"${audioConsumerId}"`)}
									>
										{audioConsumerId}
									</span>
								</p>

								<ReactTooltip type="light" effect="solid" delayShow={1500} delayHide={50} />
							</If>

							<If condition={audioCodec}>
								<p>codec: {audioCodec}</p>
							</If>

							<If condition={audioProducerId && audioScore}>
								{this._printProducerScore(audioProducerId, audioScore)}
							</If>

							<If condition={audioConsumerId && audioScore}>
								{this._printConsumerScore(audioConsumerId, audioScore)}
							</If>
						</If>

						<If condition={videoProducerId || videoConsumerId}>
							<h1>video</h1>

							<If condition={videoProducerId}>
								<p>
									{'id: '}
									<span
										className="copiable"
										data-tip="Copy audio consumer id to clipboard"
										onClick={() => clipboardCopy(`"${videoProducerId}"`)}
									>
										{videoProducerId}
									</span>
								</p>

								<ReactTooltip type="light" effect="solid" delayShow={1500} delayHide={50} />
							</If>

							<If condition={videoConsumerId}>
								<p>
									{'id: '}
									<span
										className="copiable"
										data-tip="Copy video consumer id to clipboard"
										onClick={() => clipboardCopy(`"${videoConsumerId}"`)}
									>
										{videoConsumerId}
									</span>
								</p>

								<ReactTooltip type="light" effect="solid" delayShow={1500} delayHide={50} />
							</If>

							<If condition={videoCodec}>
								<p>codec: {videoCodec}</p>
							</If>

							<If condition={videoVisible && videoResolutionWidth !== null}>
								<p>
									resolution: {videoResolutionWidth}x{videoResolutionHeight}
								</p>
							</If>

							<If condition={videoVisible && videoProducerId && videoRtpParameters.encodings.length > 1}>
								<p>
									max spatial layer: {maxSpatialLayer > -1 ? maxSpatialLayer : 'none'}
									<span> </span>
									<span
										className={classnames({
											clickable: maxSpatialLayer > -1
										})}
										onClick={(event) => {
											event.stopPropagation();

											const newMaxSpatialLayer = maxSpatialLayer - 1;

											onChangeMaxSendingSpatialLayer(newMaxSpatialLayer);
											this.setState({ maxSpatialLayer: newMaxSpatialLayer });
										}}
									>
										{'[ down ]'}
									</span>
									<span> </span>
									<span
										className={classnames({
											clickable: maxSpatialLayer < videoRtpParameters.encodings.length - 1
										})}
										onClick={(event) => {
											event.stopPropagation();

											const newMaxSpatialLayer = maxSpatialLayer + 1;

											onChangeMaxSendingSpatialLayer(newMaxSpatialLayer);
											this.setState({ maxSpatialLayer: newMaxSpatialLayer });
										}}
									>
										{'[ up ]'}
									</span>
								</p>
							</If>

							<If condition={!isMe && videoMultiLayer}>
								<p>
									{`current spatial-temporal layers: ${consumerCurrentSpatialLayer} ${consumerCurrentTemporalLayer}`}
								</p>
								<p>
									{`preferred spatial-temporal layers: ${consumerPreferredSpatialLayer} ${consumerPreferredTemporalLayer}`}
									<span> </span>
									<span
										className="clickable"
										onClick={(event) => {
											event.stopPropagation();

											let newPreferredSpatialLayer = consumerPreferredSpatialLayer;
											let newPreferredTemporalLayer;

											if (consumerPreferredTemporalLayer > 0) {
												newPreferredTemporalLayer = consumerPreferredTemporalLayer - 1;
											} else {
												if (consumerPreferredSpatialLayer > 0)
													newPreferredSpatialLayer = consumerPreferredSpatialLayer - 1;
												else newPreferredSpatialLayer = consumerSpatialLayers - 1;

												newPreferredTemporalLayer = consumerTemporalLayers - 1;
											}

											onChangeVideoPreferredLayers(
												newPreferredSpatialLayer,
												newPreferredTemporalLayer
											);
										}}
									>
										{'[ down ]'}
									</span>
									<span> </span>
									<span
										className="clickable"
										onClick={(event) => {
											event.stopPropagation();

											let newPreferredSpatialLayer = consumerPreferredSpatialLayer;
											let newPreferredTemporalLayer;

											if (consumerPreferredTemporalLayer < consumerTemporalLayers - 1) {
												newPreferredTemporalLayer = consumerPreferredTemporalLayer + 1;
											} else {
												if (consumerPreferredSpatialLayer < consumerSpatialLayers - 1)
													newPreferredSpatialLayer = consumerPreferredSpatialLayer + 1;
												else newPreferredSpatialLayer = 0;

												newPreferredTemporalLayer = 0;
											}

											onChangeVideoPreferredLayers(
												newPreferredSpatialLayer,
												newPreferredTemporalLayer
											);
										}}
									>
										{'[ up ]'}
									</span>
								</p>
							</If>

							<If condition={!isMe && videoCodec && consumerPriority > 0}>
								<p>
									{`priority: ${consumerPriority}`}
									<span> </span>
									<span
										className={classnames({
											clickable: consumerPriority > 1
										})}
										onClick={(event) => {
											event.stopPropagation();

											onChangeVideoPriority(consumerPriority - 1);
										}}
									>
										{'[ down ]'}
									</span>
									<span> </span>
									<span
										className={classnames({
											clickable: consumerPriority < 255
										})}
										onClick={(event) => {
											event.stopPropagation();

											onChangeVideoPriority(consumerPriority + 1);
										}}
									>
										{'[ up ]'}
									</span>
								</p>
							</If>

							<If condition={!isMe && videoCodec}>
								<p>
									<span
										className="clickable"
										onClick={(event) => {
											event.stopPropagation();

											if (!onRequestKeyFrame) return;

											onRequestKeyFrame();
										}}
									>
										{'[ request keyframe ]'}
									</span>
								</p>
							</If>

							<If condition={videoProducerId && videoScore}>
								{this._printProducerScore(videoProducerId, videoScore)}
							</If>

							<If condition={videoConsumerId && videoScore}>
								{this._printConsumerScore(videoConsumerId, videoScore)}
							</If>
						</If>
					</div>

					<div className={classnames('peer', { 'is-me': isMe })}>
						<Choose>
							<When condition={isMe}>
								{/* <EditableInput
									value={peer.displayName}
									propName="displayName"
									className="display-name editable circular"
									classLoading="loading"
									classInvalid="invalid"
									shouldBlockWhileLoading
									editProps={{
										maxLength: 20,
										autoCorrect: 'false',
										spellCheck: 'false'
									}}
									onChange={({ displayName }) => onChangeDisplayName(displayName)}
								/> */}
							</When>

							<Otherwise>
								<span className="display-name circular">{peer.displayName}</span>
								{me && !isMe && fullScreen && (
									<div data-component="Me">
										<div
											style={{
												position: 'absolute',
												right: 3,
												top: 'initial',
												left: 'initial',
												bottom: -6,
												display: 'flex',
												background: ' #00000054',
												padding: '2px 14px',
												borderRadius: 5
											}}
											className="circular controls"
										>
											{(peer.pointerSharing === true ||
												Object.keys(window.store.getState().consumers).findIndex(
													(p) => window.store.getState().consumers[p].type === 'share'
												) > -1) && (
												<div
													data-place="right"
													className={classnames('button', 'pointer-sharing', {
														on: me.sendMouseEvenet
													})}
													data-tip={'On/off mouse pointer sharing'}
													onClick={() => {
														sendMouseEvent(!me.sendMouseEvenet);
													}}
												/>
											)}
											<div
												style={{ borderRadius: 20, marginRight: 10, marginLeft: 4 }}
												className={classnames('button', 'mic', micState)}
												onClick={() => {
													console.log(micState, 'micState');
													micState === 'on' ? disableMic() : enableMic();
												}}
											/>
											<div
												style={{ borderRadius: 20 }}
												className={classnames('button', 'webcam', webcamState, {
													disabled: me.webcamInProgress || me.shareInProgress
												})}
												onClick={() => {
													if (webcamState === 'on') {
														cookiesManager.setDevices({ webcamEnabled: false });
														disableWebcam();
													} else {
														cookiesManager.setDevices({ webcamEnabled: true });
														enableWebcam();
													}
												}}
											/>
											<div
												title="Exit fullscreen"
												data-place="bottom"
												// data-tip={fullScreen ? 'Exit fullscreen' : 'Fullscreen'}
												className={classnames('button', 'fullscreen')}
												onClick={() => onFullScreen()}
												style={{
													backgroundColor: 'rgba(0,0,0,0)',
													boxShadow: 'none',
													width: 28,
													marginLeft: 4,
													height: 28,
													backgroundImage:
														'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiDQoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiDQoJIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8Zz4NCgk8Zz4NCgkJPGc+DQoJCQk8Zz4NCgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNOSwyLjZIM2MtMC4yLDAtMC40LDAuMi0wLjQsMC40djZjMCwwLjIsMC4yLDAuNCwwLjQsMC40aDAuOWMwLjIsMCwwLjQtMC4yLDAuNC0wLjRWNC4zSDkNCgkJCQkJYzAuMiwwLDAuNC0wLjIsMC40LTAuNFYzLjFDOS40LDIuOCw5LjIsMi42LDksMi42eiIvPg0KCQkJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMSwyLjZoLTZjLTAuMiwwLTAuNCwwLjItMC40LDAuNHYwLjljMCwwLjIsMC4yLDAuNCwwLjQsMC40aDQuN1Y5YzAsMC4yLDAuMiwwLjQsMC40LDAuNEgyMQ0KCQkJCQljMC4yLDAsMC40LTAuMiwwLjQtMC40di02QzIxLjQsMi44LDIxLjMsMi42LDIxLDIuNnoiLz4NCgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjEsMTQuNmgtMC45Yy0wLjIsMC0wLjQsMC4yLTAuNCwwLjR2NC43SDE1Yy0wLjIsMC0wLjQsMC4yLTAuNCwwLjR2MC45YzAsMC4yLDAuMiwwLjQsMC40LDAuNGg2DQoJCQkJCWMwLjIsMCwwLjQtMC4yLDAuNC0wLjR2LTZDMjEuNCwxNC43LDIxLjMsMTQuNiwyMSwxNC42eiIvPg0KCQkJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LDE5LjdINC4zVjE1YzAtMC4yLTAuMi0wLjQtMC40LTAuNEgzYy0wLjIsMC0wLjQsMC4yLTAuNCwwLjR2NmMwLDAuMiwwLjIsMC40LDAuNCwwLjRoNg0KCQkJCQljMC4yLDAsMC40LTAuMiwwLjQtMC40di0wLjlDOS40LDE5LjksOS4yLDE5LjcsOSwxOS43eiIvPg0KCQkJPC9nPg0KCQk8L2c+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=)',
													opacity: 0.9
												}}
											/>
										</div>
									</div>
								)}
							</Otherwise>
						</Choose>
						{/* 
						<div className='row'>
							<span
								className={classnames('device-icon', peer.device.flag)}
							/>
							<span className='device-version'>
								{peer.device.name} {peer.device.version || null}
							</span>
						</div> */}
					</div>
				</div>

				<video
					ref="videoElem"
					className={classnames({
						'is-me': isMe,
						hidden: !videoVisible || !videoCanPlay,
						'network-error': videoVisible && videoMultiLayer && consumerCurrentSpatialLayer === null
					})}
					autoPlay
					playsInline
					muted
					controls={false}
				/>

				<audio ref="audioElem" autoPlay playsInline muted={isMe || audioMuted} controls={false} />

				<canvas ref="canvas" className={classnames('face-detection', { 'is-me': isMe })} />

				{disableAudioObserver ? null : (
					<div className="volume-container">
						<div className={classnames('bar', `level${audioVolume}`)} />
					</div>
				)}

				<If condition={videoVisible && videoScore < 5}>
					<div className="spinner-container">
						<Spinner />
					</div>
				</If>

				<If condition={videoElemPaused}>
					<div className="video-elem-paused" />
				</If>
				<ReactTooltip type="light" effect="solid" delayShow={100} delayHide={100} delayUpdate={50} />
			</div>
		);
	}

	componentDidMount() {
		const { audioTrack, videoTrack } = this.props;
		this._setTracks(audioTrack, videoTrack);
	}

	componentWillUnmount() {
		if (this._hark) this._hark.stop();

		clearInterval(this._videoResolutionPeriodicTimer);
		cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

		const { videoElem } = this.refs;

		if (videoElem) {
			videoElem.oncanplay = null;
			videoElem.onplay = null;
			videoElem.onpause = null;
		}
	}

	componentWillUpdate() {
		const { isMe, audioTrack, videoTrack, videoRtpParameters } = this.props;

		const { maxSpatialLayer } = this.state;

		if (isMe && videoRtpParameters && maxSpatialLayer === null) {
			this.setState({
				maxSpatialLayer: videoRtpParameters.encodings.length - 1
			});
		} else if (isMe && !videoRtpParameters && maxSpatialLayer !== null) {
			this.setState({ maxSpatialLayer: null });
		}

		this._setTracks(audioTrack, videoTrack);
	}

	_setTracks(audioTrack, videoTrack) {
		const { faceDetection, isMe } = this.props;

		if (this._audioTrack === audioTrack && this._videoTrack === videoTrack) return;

		this._audioTrack = audioTrack;
		this._videoTrack = videoTrack;

		if (this._hark) this._hark.stop();

		this._stopVideoResolution();

		// if (faceDetection) this._stopFaceDetection();

		const { audioElem, videoElem } = this.refs;

		if (audioTrack) {
			const stream = new MediaStream();

			stream.addTrack(audioTrack);
			// if (!isMe) {
			// 	const recorder = new MediaRecorder(stream);

			// 	recorder.ondataavailable = async (event) => {
			// 		const blob = event.data;
			// 		console.log(await blob.arrayBuffer(), 'AUDIO TRACK BYTES');
			// 	};

			// 	// make data available event fire every one second
			// 	recorder.start(10000);
			// }
			audioElem.srcObject = stream;

			audioElem.play().catch((error) => {
				logger.warn('audioElem.play() failed:%o', error);
			});

			this._runHark(stream);
		} else {
			audioElem.srcObject = null;
		}

		if (videoTrack) {
			const stream = new MediaStream();

			stream.addTrack(videoTrack);

			videoElem.srcObject = stream;

			videoElem.oncanplay = () => {
				this.setState({ videoCanPlay: true });
			};

			videoElem.onplay = () => {
				// if (!isMe) {
				// 	const recorder = new MediaRecorder(stream);

				// 	// fires every one second and passes an BlobEvent
				// 	recorder.ondataavailable = async (event) => {
				// 		// get the Blob from the event
				// 		const blob = event.data;

				// 		console.log(await blob.arrayBuffer(), 'Video TRACK BYTES');

				// 		// and send that blob to the server...
				// 	};

				// 	// make data available event fire every one second
				// 	recorder.start(10000);
				// }
				this.setState({ videoElemPaused: false });

				audioElem.play().catch((error) => {
					logger.warn('audioElem.play() failed:%o', error);
				});
			};

			videoElem.onpause = () => this.setState({ videoElemPaused: true });

			videoElem.play().catch((error) => {
				logger.warn('videoElem.play() failed:%o', error);
			});

			// this._startVideoResolution();

			// if (faceDetection) this._startFaceDetection();
		} else {
			videoElem.srcObject = null;
		}
	}

	_runHark(stream) {
		if (!stream.getAudioTracks()[0]) throw new Error('_runHark() | given stream has no audio track');

		this._hark = hark(stream, { play: false });

		// eslint-disable-next-line no-unused-vars
		this._hark.on('volume_change', (dBs, threshold) => {
			// The exact formula to convert from dBs (-100..0) to linear (0..1) is:
			//   Math.pow(10, dBs / 20)
			// However it does not produce a visually useful output, so let exagerate
			// it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
			// minimize component renderings.
			let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);
			// console.log(audioVolume, 'audioVolume');
			if (audioVolume === 1) audioVolume = 0;

			if (audioVolume !== this.state.audioVolume) this.setState({ audioVolume });
		});
	}

	_startVideoResolution() {
		this._videoResolutionPeriodicTimer = setInterval(() => {
			const { videoResolutionWidth, videoResolutionHeight } = this.state;
			const { videoElem } = this.refs;

			if (videoElem.videoWidth !== videoResolutionWidth || videoElem.videoHeight !== videoResolutionHeight) {
				this.setState({
					videoResolutionWidth: videoElem.videoWidth,
					videoResolutionHeight: videoElem.videoHeight
				});
			}
		}, 500);
	}

	_stopVideoResolution() {
		clearInterval(this._videoResolutionPeriodicTimer);

		this.setState({
			videoResolutionWidth: null,
			videoResolutionHeight: null
		});
	}

	_printProducerScore(id, score) {
		const scores = Array.isArray(score) ? score : [score];

		return (
			<React.Fragment key={id}>
				<p>streams:</p>

				{scores
					.sort((a, b) => {
						if (a.rid) return a.rid > b.rid ? 1 : -1;
						else return a.ssrc > b.ssrc ? 1 : -1;
					})
					.map(
						(
							{ ssrc, rid, score },
							idx // eslint-disable-line no-shadow
						) => (
							<p key={idx} className="indent">
								<Choose>
									<When condition={rid !== undefined}>
										{`rid:${rid}, ssrc:${ssrc}, score:${score}`}
									</When>

									<Otherwise>{`ssrc:${ssrc}, score:${score}`}</Otherwise>
								</Choose>
							</p>
						)
					)}
			</React.Fragment>
		);
	}

	_printConsumerScore(id, score) {
		return (
			<p key={id}>
				{`score:${score.score}, producerScore:${score.producerScore}, producerScores:[${score.producerScores}]`}
			</p>
		);
	}
}

PeerView.propTypes = {
	isMe: PropTypes.bool,
	peer: PropTypes.oneOfType([appPropTypes.Me, appPropTypes.Peer]).isRequired,
	audioProducerId: PropTypes.string,
	videoProducerId: PropTypes.string,
	audioConsumerId: PropTypes.string,
	videoConsumerId: PropTypes.string,
	audioRtpParameters: PropTypes.object,
	videoRtpParameters: PropTypes.object,
	consumerSpatialLayers: PropTypes.number,
	consumerTemporalLayers: PropTypes.number,
	consumerCurrentSpatialLayer: PropTypes.number,
	consumerCurrentTemporalLayer: PropTypes.number,
	consumerPreferredSpatialLayer: PropTypes.number,
	consumerPreferredTemporalLayer: PropTypes.number,
	consumerPriority: PropTypes.number,
	audioTrack: PropTypes.any,
	videoTrack: PropTypes.any,
	audioMuted: PropTypes.bool,
	videoVisible: PropTypes.bool.isRequired,
	videoMultiLayer: PropTypes.bool,
	audioCodec: PropTypes.string,
	videoCodec: PropTypes.string,
	audioScore: PropTypes.any,
	videoScore: PropTypes.any,
	faceDetection: PropTypes.bool.isRequired,
	onChangeDisplayName: PropTypes.func,
	onChangeMaxSendingSpatialLayer: PropTypes.func,
	onChangeVideoPreferredLayers: PropTypes.func,
	onChangeVideoPriority: PropTypes.func,
	onRequestKeyFrame: PropTypes.func,
	onStatsClick: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		enableMic: () => {
			dispatch({
				type: SET_ENABLE_MIC
			});
		},
		disableMic: () => {
			dispatch({
				type: SET_DISABLE_MIC
			});
		},
		enableWebcam: () => {
			dispatch({
				type: SET_ENABLE_CALL_WEBCAM
			});
		},
		disableWebcam: () => {
			dispatch({
				type: SET_DISABLE_CALL_WEBCAM
			});
		},
		sendMouseEvent: (value) => {
			dispatch({ type: 'SET_SEND_MOUSE_EVENT', payload: value });
		}
	};
};

export default connect(null, mapDispatchToProps)(PeerView);
