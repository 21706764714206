import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import AudioCall from '../../../images/AudioCall.svg';
import VideoIcon from '../../../images/video.svg';
import KnockIcon from '../../../images/knock.svg';
import {
	connectCall,
	createNotification,
	getCallColor,
	getColorFromCallConneting,
	getRoomId
} from '../../../helpers/utils';
import UserStatusIcon from './UserStatusIcon';
import { STATUS, SOCKET_STATUS } from '../../../helpers/commonConsts';
import UserProfile from '../../HelperComponents/UserProfile';

const UnreadCount = ({ unreadCount }) => {
	return (
		<span className="unread-count">
			{unreadCount >= 9 ? (
				<span className="v-a:m">
					9<sup>+</sup>
				</span>
			) : (
				<span className="v-a:m">{unreadCount}</span>
			)}
		</span>
	);
};

const UserRoom = ({
	refereshUnreadMessageCount,
	allRooms,
	startNewCall,
	call,
	user,
	inviteToCall,
	knockUser,
	allUsers,
	selectRoom,
	selectedRoom,
	loadRoomMessage,
	makeRoom,
	loggedInUser,
	unreadCounts,
	addCallToTimer,
	me
}) => {
	const [parentDivWidth, setParentDivWidth] = useState();

	function updateWidth() {
		let parentElem = document.getElementsByClassName('left-panel')[0];
		setParentDivWidth && parentElem && parentElem.offsetWidth && setParentDivWidth(parentElem.offsetWidth);
	}
	useEffect(() => {
		window.addEventListener(
			'resize',
			() => {
				updateWidth();
			},
			false
		);

		updateWidth();
		return () => {
			window.removeEventListener(
				'resize',
				() => {
					updateWidth();
				},
				false
			);
		};
	}, []);

	async function createNewRoom() {
		let payload = {
			members: [user._id, loggedInUser._id],
			teamId: loggedInUser.teamId,
			roomType: 1
		};

		await makeRoom(payload);
	}

	useEffect(() => {}, []);

	const roomId = getRoomId(user, loggedInUser, allRooms);
	return [
		<div
			key={roomId}
			className={`m-b:.4 p-b:.1 p-t:.1 p-r:.4 p-l:.4 user-item cursor v-a:m avatar-selector  ${
				selectedRoom && roomId && roomId == selectedRoom.roomId ? 'active-group' : 'group'
			} `}
			onClick={async () => {
				if (roomId) {
					selectRoom(roomId);
					loadRoomMessage(roomId);
					localStorage.setItem(roomId, 0);
					refereshUnreadMessageCount();
				} else {
					createNewRoom();
				}
			}}
		>
			{roomId && unreadCounts[roomId] && parseInt(unreadCounts[roomId]) >= 1 ? (
				<UnreadCount unreadCount={unreadCounts[roomId]} />
			) : null}

			<div className="m-r:.6 d:i-b v-a:m">
				{user.profilePic ? (
					<UserProfile profilePic={user.profilePic} />
				) : user.lastSnap ? (
					<div
						className="d:i-b v-a:m usericons-leftpanel"
						style={{
							backgroundImage: `url(${user.lastSnap})`
						}}
					></div>
				) : (
					<Avatar size="21" textSizeRatio={2.01} round={true} name={user.name || '#'} className="circular" />
				)}
			</div>
			<div className="v-a:m d:i-b crcular f-w:100 t-t:l truncate-username">{user.name || 'Na'}</div>
			<div className={`fl:r user-online-icon-box ${user.blink && 'blink_div'}`} style={{ marginTop: 1 }}>
				{!user.activeCall && user.callConnecting ? (
					<UserStatusIcon
						status={user.status}
						socketStatus={user.socketStatus}
						onCall={user.callConnecting}
						callColor={`#${user.color}`}
					/>
				) : (
					<UserStatusIcon
						status={user.status}
						socketStatus={user.socketStatus}
						onCall={user.activeCallId}
						callColor={`#${user.color}`}
					/>
				)}
			</div>

			{(user.activeCallId && call.roomId && user.activeCallId == call.roomId) ||
			me.status == 6 ? null : user.socketStatus == SOCKET_STATUS.CONNECTED && user.status == STATUS.BUSY ? (
				<div className="buttons-overlay-users-left-panel">
					<div
						className="user-hvr-icon-cont d:i-b m-r:.1"
						//add knock image
						style={{ backgroundImage: `url(${KnockIcon})` }}
						onClick={(e) => {
							e.stopPropagation();
							knockUser({ from: loggedInUser._id, to: user._id });
						}}
					/>
				</div>
			) : null}

			{user.callConnecting ||
			user.socketStatus === SOCKET_STATUS.DISCONNECTED ||
			user.socketStatus === SOCKET_STATUS.CONNECTING ||
			user.status === STATUS.BUSY ||
			user.status === STATUS.AWAY ||
			(user.activeCallId && call.roomId && user.activeCallId == call.roomId) ||
			me.status == 6 ? null : (
				<div className="buttons-overlay-users-left-panel">
					<div
						className="user-hvr-icon-cont d:i-b m-r:.1"
						style={{ backgroundImage: `url(${AudioCall})` }}
						onClick={(e) =>
							connectCall({
								e,
								call,
								loggedInUser: loggedInUser,
								inviteToCall,
								invitedUser: user,
								startNewCall,
								roomId,
								addCallToTimer
							})
						}
					/>
					<div
						className="user-hvr-icon-cont user-hvr-icon-cont-video  d:i-b m-r:.5"
						style={{ backgroundImage: `url(${VideoIcon})` }}
						onClick={(e) =>
							connectCall({
								e,
								call,
								loggedInUser: loggedInUser,
								inviteToCall,
								invitedUser: user,
								startNewCall,
								roomId,
								addCallToTimer
							})
						}
					/>
				</div>
			)}
		</div>,

		// <div
		// 	className={`selected-room-bg`}
		// 	style={{
		// 		width: parentDivWidth,
		// 		background: selectedRoom && roomId && roomId == selectedRoom.roomId && parentDivWidth ? null : 'none'
		// 	}}
		// />,
		<div
			className={`${user.blink && 'blink_div'} selected-room-bg`}
			style={{
				borderRadius: 90,
				background: user.activeCallId && `#${user.color}`,
				width: 5,
				right: 5,
				left: 'initial'
			}}
		></div>
	];
};

export default UserRoom;
