import isElectron from 'is-electron';
import { SET_DISABLE_CALL_SCREEN_SHARE } from '../../../actions/call.actions'

let electron = null;

if (isElectron() && typeof window?.require === 'function') {
	electron = window.require('electron');
}

async function enableShare(media = null) {
	this.logger.debug('enableShare()');
	if (this?._shareProducer) return;
	// else if (this?._webcamProducer) await this.disableWebcam();
	console.log(this?._mediasoupDevice, 'this?._mediasoupDevice');
	if (!this?._mediasoupDevice?.canProduce('video')) {
		this.logger.error('enableShare() | cannot produce video');
		return;
	}

	let track;

	try {
		this.logger.debug('enableShare() | calling getUserMedia()');

		let stream,
			screenInfo;

		// Electron
		if (isElectron()) {
			await Promise.resolve()
				.then(async () => {
					return await window.ipcRenderer.invoke('capture-desktop', { types: ['window', 'screen'] });;
				})
				.then(async (sources) => {
					console.log(sources, 'sources')
					for (const source of sources) {
						if (source.name === media.name) {
							const stream = await navigator.mediaDevices.getUserMedia({
								audio: false,
								video: {
									mandatory: {
										chromeMediaSource: 'desktop',
										chromeMediaSourceId: source.id
									}
								}
							});
							return { s: stream, info: source };
						}
					}
				})
				.then(async ({ s, info }) => {
					console.log(s);
					console.log(info);
					const { display_id } = info;
					let handleId;
					if (display_id?.length) {

						console.log(info.id, 'info.id')
					} else {
						handleId = Number(info.id.split(':')[1])
					}
					console.log(primaryDisplay, 'primaryDisplay')

					const { allScreens, primaryDisplay, region } = await window.ipcRenderer.invoke('screensInfo', handleId);
					console.log(allScreens, 'allScreens')
					const screen = allScreens.find(screen => info.display_id == screen.id)
					if (!screen) {
						console.log(region, 'region')
						window.store.dispatch({ type: 'SET_ALLOW_MOUSE_POINTER', payload: false })
						window.store.dispatch({ type: 'SET_MOUSE_POINTER_ENABLED', payload: false })
						screenInfo = {
							width: region.width, height: region.height, left: region.left, top: region.top
						}

					} else {
						window.store.dispatch({ type: 'SET_ALLOW_MOUSE_POINTER', payload: true })
						const { width, height } = screen.size
						const { x, y } = screen.workArea
						screenInfo = {
							width, height, left: x, top: y
						}
					}
					stream = s;
				});
		} else {
			stream = await navigator.mediaDevices.getDisplayMedia({
				video: {
					cursor: true,
					frameRate: 30
				}
			});
		}
		// May mean cancelled (in some implementations).
		if (!stream) {
			throw new Error('canceled')
		}

		track = stream.getVideoTracks()[0];
		track.consumerType = 'share'

		const { width, height } = track.getSettings();
		console.log(screenInfo, 'screenInfo')
		this._shareProducer = await this._sendTransport.produce({
			track,
			// encodings,
			// codecOptions,
			// codec,
			appData: {
				share: true,
				appData: {
					source: 'screen',
					width: screenInfo?.width || width,
					height: screenInfo?.height || height,
					left: screenInfo?.left || 0,
					top: screenInfo?.top || 0,
					screenWidth: 0,
					screenHeight: 0
				}
			}
		});

		this._shareProducer.on('transportclose', async () => {
			window.store.dispatch({
				type: SET_DISABLE_CALL_SCREEN_SHARE,
			})
		});

		this._shareProducer.on('trackended', () => {

			window.store.dispatch({
				type: SET_DISABLE_CALL_SCREEN_SHARE,
			})
			throw new Error('Share disconnected!');

		});
	} catch (error) {
		console.log(error)
		this.logger.error('enableShare() | failed:%o', error);
		if (track) track.stop();
		if (error.name !== 'NotAllowedError') {
			throw new Error(`Error sharing: ${error}`);
		} else {
			throw new Error('canceled')
		}
	}
}

async function disableShare() {
	this.logger.debug('disableShare()');
	if (!this._shareProducer) return;
	this._shareProducer.close();
	try {
		await this._protoo.request('closeProducer', { producerId: this._shareProducer.id });
	} catch (error) {
		throw new Error(`Error closing server-side share Producer: ${error}`);
	}
}

export { enableShare, disableShare };
