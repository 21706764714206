import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { login, UPDATE_MODAL } from '../../actions/user.actions';
import { Form, Button, Alert } from 'antd';
import '../../css/login.scss';
import illustration from '../../images/illustrations.svg';
import logo from '../../images/logo.svg';
import Toasts from '../toast/Toasts.js';
import { checkIfAdminExists, createAdmin } from '../../helpers/api';
import CreateNewUser from '../ModalComponents/CreateNewUser';
import {
	useLocation,
	Link,
	Redirect
} from "react-router-dom";

let Login = ({ login, updateModal }) => {
	const [adminAvailable, setAdminAvailable] = useState(true);
	const [redirect, setIsRedirect] = useState(false)
	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	let query = useQuery();
	let email = query.get('email')
	let verified = query.get('verified')
	useEffect(() => {
		(async () => {
			try {
				let adminExists = await checkIfAdminExists();
				if (adminExists) {
					setAdminAvailable(false);
				}
			} catch (e) {
				setAdminAvailable(true);
			}
		})();
	}, []);
	return (
		<>
			{
				window?.store?.getState()?.me?.isLoggedIn ? <Redirect
					to={{
						pathname: "/app",
					}}
				/> : null
			}

			<Form
				onFinish={async (values) => {
					try {
						if (!values.userValue && email && email !== 'undefined') {
							values.userValue = email;
						}
						values.cb = () => setIsRedirect(true)
						await login(values);
					} catch (error) {

					}
				}}
			>
				{
					redirect ? <Redirect
						to={{
							pathname: "/app"
						}}
					/> : null
				}
				{verified === 'false' ? <Alert
					message="Warning"
					description="Please verify your email."
					type="warning"
					showIcon
				/> : null}
				<div className="circular login-box">
					<span style={{ position: 'absolute', top: 20, right: 15, width: 'auto' }}>
						<Toasts />
					</span>
					<div className="illustration-bg" style={{ backgroundImage: `url(${illustration})` }}>
						<div className="login-right">
							<div>
								<div className="f:4 p:.7 p-l:.9">
									<img src={logo} width="85px" />
								</div>
								<div className=" p:.5 p-l:1 p-r:.8 p-b:1 m-t:.5">
									<div className="m-b:1">
										{/* <div className="t-a:l f-w:100 sub-title2 f:1">Username</div> */}
										<div className="p-t:.2 circular">
											<Form.Item name="userValue">
												<input
													id="login-input"
													placeholder="Email"
													type="email"
													className="ant-input login-input circular f-w:100 p-l:.1"
													defaultValue={!email || email === 'undefined' ? '' : email}
												/>
											</Form.Item>
										</div>
									</div>

									<div className="m-b:1">
										{/* <div className="t-a:l f-w:100 sub-title2 f:1">Password</div> */}
										<div className="p-t:.2 circular">
											<Form.Item name="password">
												<input
													id="login-input"
													placeholder="Password"
													type="password"
													className="ant-input login-input circular f-w:100 p-l:.1"
												/>
											</Form.Item>
										</div>
									</div>

									<div className="m-t:5 t-a:l">
										<Form.Item>
											<Button
												type="primary"
												className="circular login-btn f-w:400 sub-title"
												htmlType="submit"
											>
												Login
											</Button>
											{/* <div className="forget-password m-l:4">Forget password</div> */}
											<Link to={'/signup'} ><div className="forget-password m-l:4 cursor">Signup</div></Link>
											{/* 
										{adminAvailable ? (
											<div className="forget-password m-l:4 cursor">Forgot password</div>
										) : (
											<div
												className="forget-password m-l:4 cursor"
												onClick={() =>
													updateModal({
														show: true,
														createAdmin: createAdmin,
														ModalBody: CreateNewUser,
														title: 'Sign up'
													})
												}
											>
												Sign up
											</div>
										)} */}
										</Form.Item>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Form></>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload }),
		login: (payload) => dispatch(login(payload))
	};
};
Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login;
