export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const ROOM_JOINED_SOUND_ON = 'ROOM_JOINED_SOUND_ON';
export const ROOM_JOINED_SOUND_OFF = 'ROOM_JOINED_SOUND_OFF';
export const NEW_MESSAGE_SOUND_ON = 'NEW_MESSAGE_SOUND_ON';
export const NEW_MESSAGE_SOUND_OFF = 'NEW_MESSAGE_SOUND_OFF';
export const KNOCK_SOUND_ON = 'KNOCK_SOUND_ON';
export const KNOCK_SOUND_OFF = 'KNOCK_SOUND_OFF';
export const TRY_LOGIN = 'TRY_LOGIN';
export const TRY_LOGOUT = 'TRY_LOGOUT';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const UPDATE_PIC = 'UPDATE_PIC';
export const UPDATE_CURRENT_USER_PROFILE = 'UPDATE_CURRENT_USER_PROFILE';
export const UPDATE_PROFILE_PIC = 'UPDATE_PROFILE_PIC';
export const PROFILE_PIC = 'PROFILE_PIC';
export const CHANGE_NAME = 'CHANGE_NAME';

export const USER_LOGIN = 'LOGIN';
export const USER_LOGIN_EVENT = 'USER_LOGIN_EVENT';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const MODAL_UPDATED = 'MODAL_UPDATED';
export const SOCKET_STATUS = 'SOCKET_STATUS';
export const STATUS_CHANGED = 'STATUS_CHANGED';
export const KNOCK_USER = 'KNOCK_USER';
export const KNOCK_RECIEVED = 'KNOCK_RECIEVED';
export const FILE_ADDED = 'FILE_ADDED';
export const FILE_REMOVED = 'FILE_REMOVED';
export const MOUSE_EVENT = 'MOUSE_EVENT';
export const MOUSE_EVENT_RECIEVED = 'MOUSE_EVENT_RECIEVED';
export const MOUSE_EVENT_START = 'MOUSE_EVENT_START';
export const FORCE_SOCKET_DISCONNECT = 'FORCE_SOCKET_DISCONNECT';
export const SWITCH_USER_TEAM = 'SWITCH_USER_TEAM';

// SAGA
export const SWITCH_TEAM = 'SWITCH_TEAM';


export function login({ userValue, password, cb }) {
	return {
		type: TRY_LOGIN,
		payload: { userValue, password, cb }
	};
}

export function logout() {
	return {
		type: TRY_LOGOUT
	};
}

export function changeStatus(status) {
	return {
		type: STATUS_CHANGED,
		status
	};
}
export function changeName(name) {
	return {
		type: CHANGE_NAME,
		name
	};
}
