import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Peer from './Peer';

// import { getWidthForPeer } from '../../helpers/utils';
import { Appear } from './transitions';
import classnames from 'classnames';
function OtherPeer({
	id,
	activeSpeakerId,
	peers,
	shareConsumer,
	videoConsumer,
	consumerShareScreensLength,
	meShareScreenLength
}) {
	return (
		<>
			<Appear key={id} duration={1000}>
				<div
					style={{
						borderWidth: 3
						// width: getWidthForPeer(peers?.length + consumerShareScreensLength + meShareScreenLength)
					}}
					className={classnames('peer-container peer-main-container', {
						'active-speaker': id === activeSpeakerId
					})}
				>
					<Peer id={id} videoConsumer={videoConsumer} allowMouse={false} />
				</div>
			</Appear>
			{shareConsumer ? (
				<Appear key={id + shareConsumer.id} duration={1000}>
					<div
						style={{
							borderWidth: 3
							// width: getWidthForPeer(peers?.length + consumerShareScreensLength + meShareScreenLength)
						}}
						className={classnames('peer-container peer-main-container', {
							'active-speaker': id === activeSpeakerId
						})}
					>
						<Peer id={id} videoConsumer={shareConsumer} allowMouse={true} disableAudioObserver={true} />
					</div>
				</Appear>
			) : null}
		</>
	);
}

const mapStateToProps = (state, { id }) => {
	const peersArray = Object.keys(state.call.peers).length > 0 ? Object.values(state.call.peers) : [];
	const peer = state.call.peers[id];

	const producersArray = Object.values(state.producers);

	const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer = producersArray.find(
		(producer) => producer.track.kind === 'video' && producer?.type !== 'share'
	);
	const shareProducer = producersArray.find(
		(producer) => producer.track.kind === 'video' && producer?.type === 'share'
	);

	const consumersArray = peer.consumers.map((consumerId) => state.consumers[consumerId]);
	let audioConsumer = {};
	let videoConsumer = {};
	let shareConsumer = {};
	if (consumersArray) {
		audioConsumer = consumersArray.find((consumer) => consumer?.track.kind === 'audio');
		videoConsumer = consumersArray.find(
			(consumer) => consumer?.track.kind === 'video' && consumer?.type !== 'share'
		);
		shareConsumer = consumersArray.find(
			(consumer) => consumer?.track.kind === 'video' && consumer?.type === 'share'
		);
	}

	return {
		audioProducer: audioProducer,
		videoProducer: videoProducer,
		shareProducer: shareProducer,
		audioConsumer,
		videoConsumer,
		shareConsumer,
		peers: peersArray
	};
};

export default connect(mapStateToProps, null)(OtherPeer);
