import React from 'react';
import UserStatusIcon from '../LeftPanel/Rooms/UserStatusIcon';
import Crown from '../../images/crown.png';
import LockImg from '../../images/Lock.svg';
import UserProfile from '../HelperComponents/UserProfile';
export default function Member({
	hideAdmin,
	key,
	name,
	lastSnap,
	socketStatus,
	status,
	isAdmin,
	disabled,
	onCall,
	callColor,
	profilePic
}) {
	return (
		<div key={key} title={disabled && 'This user is disabled from the team'}>
			<div>
				<div className="d:i-b v-a:t dm-sans">
					{profilePic ? (
						<UserProfile
							styleProps={{ width: 33, height: 33, verticalAlign: 'unset' }}
							profilePic={profilePic}
							classNames="m-r:9"
						/>
					) : (
						<div
							className="d:i-b m-r:9"
							style={{
								backgroundColor: '#14141c',
								backgroundImage: `url(${lastSnap})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								width: 33,
								height: 33,
								borderRadius: 100
							}}
						></div>
					)}
					<div
						className="d:i-b m-r:.8 v-a:m member-staus-bar"
						id="member-staus-bar"
						style={{ marginLeft: -80 }}
					>
						{!disabled ? (
							<UserStatusIcon
								status={status}
								socketStatus={socketStatus}
								callColor={callColor}
								membersList={true}
								onCall={onCall}
							/>
						) : null}
					</div>
				</div>
				<div className="v-a:t d:i-b f-w:100 circular" style={{ marginTop: 4, marginLeft: disabled && 9 }}>
					{name}
				</div>
				{isAdmin && !hideAdmin ? (
					<img
						style={{ marginTop: 9 }}
						title="Admin"
						className="v-a:t d:i-b m-l:.5"
						src={Crown}
						width="12px"
						height="12px"
					/>
				) : null}

				{disabled ? (
					<img
						style={{ marginTop: 9 }}
						title="Admin"
						className="v-a:t d:i-b m-l:.5"
						src={LockImg}
						width="12px"
						height="12px"
					/>
				) : null}
			</div>
		</div>
	);
}
