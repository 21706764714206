import { SET_CONSUMER_PAUSED, SET_CONSUMER_RESUMED } from '../../../actions/call.actions'
import { notify } from '../../../actions/request.actions';

async function pauseConsumer(consumer) {
	try {
		if (consumer.paused) return;
		await this._protoo.request('pauseConsumer', { consumerId: consumer.id });

		consumer.pause();

		window.store.dispatch({
			type: SET_CONSUMER_PAUSED,
			payload: { consumerId: consumer.id, originator: 'local' }
		});

	} catch (error) {
		this.logger.error('_pauseConsumer() | failed:%o', error);
		notify({
			type: 'error',
			text: `Error pausing Consumer: ${error}`
		});
	}
}
async function resumeConsumer(consumer) {
	try {
		if (!consumer.paused) return;
		await this._protoo.request('resumeConsumer', { consumerId: consumer.id });
		consumer.resume();
		window.store.dispatch({
			type: SET_CONSUMER_RESUMED,
			payload: { consumerId: consumer.id, originator: 'local' }
		});
	} catch (error) {
		this.logger.error('resumeConsumer() | failed:%o', error);
		notify({
			type: 'error',
			text: `Error resuming Consumer: ${error}`
		});
	}
}

async function setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer) {
	this.logger.debug(
		'setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]',
		consumerId,
		spatialLayer,
		temporalLayer
	);

	try {
		await this._protoo.request('setConsumerPreferredLayers', { consumerId, spatialLayer, temporalLayer });
	} catch (error) {
		this.logger.error('setConsumerPreferredLayers() | failed:%o', error);

	}
}

export { pauseConsumer, resumeConsumer, setConsumerPreferredLayers };
