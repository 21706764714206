import protooClient from 'protoo-client';

let protoo = null;

const initProto = async ({ url }) => {
	return new Promise((resolve, reject) => {
		const protooTransport = new protooClient.WebSocketTransport(url);

		protoo = new protooClient.Peer(protooTransport);

		protoo.on('open', async () => {
			resolve(protoo);
		});

		protoo.on('failed', (err) => {
			console.log(err, 'PROTO ERROR');
			reject(err);
		});
	});
};

export { initProto };
