import React from 'react';
import ThreeDotIcon from './SvgComponents/ThreeDotIcon';
import InfoIcon from './SvgComponents/InfoIcon';
import ReactTooltip from 'react-tooltip';
import AddMemberToGroup from '../ModalComponents/AddMemberToGroup';

function ActiveHeader({ selectedRoom, updateModal, setMembersView, membersView, allRooms }) {
	return (
		<div className="active-header-center p:.8 p-l:2 p-r:2 circular truncate p-t:1">
			<div className="m-b:.0 f-w:500 f:1 section-header">
				<span className="m-r:.5">#</span> <span>{selectedRoom.name || 'Not available'}</span>
			</div>
			<span className="truncate">
				<div
					onClick={() => setMembersView(!membersView)}
					className={`${!membersView && 'sub-title2'} f-w:100 m-l:4 f:.8 d:i-b m-r:.9 cursor`}
				>
					{allRooms[selectedRoom.roomId]?.members?.length} members{' '}
				</div>
				<div
					className="sub-title f-w:100 f:.8 d:i-b cursor"
					onClick={() =>
						updateModal({
							modalWidth: 800,
							show: true,
							selectedRoom: selectedRoom,
							members: allRooms[selectedRoom.roomId].members,
							groupName: selectedRoom.name,
							ModalBody: AddMemberToGroup,
							title: `Add a new member to ${selectedRoom.name}`
						})
					}
				>
					+ Add member{' '}
				</div>
			</span>
		</div>
	);
}

export default ActiveHeader;
