import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './LargeNotification.scss';
import KnockSvg from '../../images/knock.svg';
import { KNOCK_SOUND_OFF } from '../../actions/user.actions';
import { createNotification, createPushNotification } from '../../helpers/utils';
import { CALL_INVITE, JOIN_CALL, START_NEW_CALL, INCOMING_CALL_INVITE } from '../../actions/call.actions';
import { ADD_CALL_TO_TIMER } from '../../actions/timer.actions';
import {
	LOAD_ACTIVE_ROOMS,
	LOAD_MESSAGE,
	REFERESH_UNREAD_NOTIFICATION_COUNT,
	SELECT_ROOM
} from '../../actions/room.actions';
import Draggable from 'react-draggable';

function LargeNotification({
	allUsers,
	removeKnockFrom,
	knockFrom,
	allRooms,
	me,
	call,
	addCallToTimer,
	inviteToCall,
	startNewCall,
	selectRoom,
	loadActiveRooms,
	refereshUnreadMessageCount,
	loadRoomMessage,
	knockedAt
}) {
	let knockFromUser = allUsers?.[knockFrom];
	function getRoomId() {
		let loggedInUserId = me._id;
		let targetRoomId;
		Object.keys(allRooms)
			.filter((roomId) => allRooms[roomId].isDmRoom)
			.map((roomId) => {
				let members = {};
				allRooms[roomId]?.members.map((member) => {
					members[member.userId] = member.userId;
				});
				if (members[knockFrom] && members[loggedInUserId]) {
					targetRoomId = roomId;
				}
			});
		return targetRoomId;
	}
	const roomId = getRoomId();

	function sendMessage(roomId) {
		selectRoom(roomId);
		loadRoomMessage(roomId);
		localStorage.setItem(roomId, 0);
		refereshUnreadMessageCount();
		loadActiveRooms();
	}

	function connectCall() {
		if (window.store?.getState()?.peers?.[me._id]) {
			createNotification({ type: 'danger', title: allRooms?.[knockFrom]?.name + ' is already in this call' });
			return false;
		}

		const activeCallId = window.store.getState().users.allUsers[knockFrom].activeCallId;

		if (call.roomId && call.roomId.length && call.roomId.includes('-')) {
			inviteToCall({
				videoRoomId: call.roomId,
				to: roomId,
				user: me,
				invitedUser: knockFromUser,
				invitedUserId: knockFromUser._id,
				teamId: me.teamId
			});
		} else if (activeCallId && activeCallId.includes('-')) {

			window.store.dispatch({
				type: INCOMING_CALL_INVITE,
				payload: {
					videoRoomId: me.activeCallId,
					from: knockFromUser._id
				}
			});
		} else {
			startNewCall({
				oneToOneRoomId: roomId,
				invitedUser: knockFromUser,
				user: me
			});
		}
	}


	return knockFrom ? (
		<div className="large-notif">
			<Draggable>
				<div className="large-notif-cont">
					<div className="user-info product">
						<div
							className="user-img d:i-b v-a:m m-r:.8"
							style={{ background: `url(${allUsers?.[knockFrom]?.lastSnap})` }}
						></div>
						<img src={KnockSvg} width="20px" height="20px" className="knock-img" />
						<div className="d:i-b v-a:m m-l:.8 f:1">
							{' '}
							{allUsers?.[knockFrom]?.name.toUpperCase()} is trying to call you.
							<div style={{ fontSize: 13 }} className="sub-title">
								{knockedAt}{' '}
							</div>
						</div>
						<div className="fl:r v-a:m m-t:.8">
							<div
								className="d:i-b send-message m-r:3 v-a:m cursor"
								onClick={() => {
									sendMessage(roomId);
									const timeOut = setTimeout(() => {
										removeKnockFrom();
										clearTimeout(timeOut)
									}, 100);
								}}
							>
								Send a message
							</div>
							<div
								style={{ background: '#0a6820', paddingRight: 40, paddingLeft: 40 }}
								className="d:i-b button m-r:3 ant-btn call-btn circular f-w:100 v-a:m cursor"
								type="button"
								onClick={() => {
									connectCall();
									const timeOut = setTimeout(() => {
										removeKnockFrom();
										clearTimeout(timeOut)
									}, 100);
								}}
							>
								Call
							</div>
							<div
								className="d:i-b call-btn ant-btn circular f-w:100 product v-a:m cursor"
								onClick={() => removeKnockFrom()}
							>
								Dismiss
							</div>
						</div>
					</div>
				</div>
			</Draggable>
		</div>
	) : null;
}

const mapStateToProps = (state) => {
	return {
		call: state.call,
		me: state?.me,
		allRooms: state.rooms.allRooms,
		allUsers: state?.users?.allUsers,
		knockFrom: state?.me?.knockFrom?.from,
		knockedAt: state?.me?.knockFrom?.at
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		refereshUnreadMessageCount: () => dispatch({ type: REFERESH_UNREAD_NOTIFICATION_COUNT }),
		selectRoom: (payload) => dispatch({ type: SELECT_ROOM, payload }),
		loadActiveRooms: () => dispatch({ type: LOAD_ACTIVE_ROOMS }),
		removeKnockFrom: () => dispatch({ type: KNOCK_SOUND_OFF, payload: { knockFrom: {} } }),
		inviteToCall: (payload) => dispatch({ type: CALL_INVITE, payload }),
		startNewCall: (payload) => dispatch({ type: START_NEW_CALL, payload }),
		addCallToTimer: (payload) => dispatch({ type: ADD_CALL_TO_TIMER, payload }),
		loadRoomMessage: (payload) =>
			dispatch({
				type: LOAD_MESSAGE,
				payload: {
					roomId: payload
				}
			})
	};
};
LargeNotification = connect(mapStateToProps, mapDispatchToProps)(LargeNotification);
export default LargeNotification;
