import React from 'react';
import { TeamHeader } from './TeamHeader';
import { connect } from 'react-redux';
import { TRY_LOGOUT, UPDATE_MODAL, KNOCK_USER } from '../../actions/user.actions';
import Search from './Search';
import Channels from './Channels';
import UserRooms from './Rooms';
import Invite from '../Invite'
import Meetings from './Meetings';
import { START_NEW_CALL, JOIN_CALL, CALL_INVITE } from '../../actions/call.actions';
import {
	SELECT_ROOM,
	CREATE_ROOM,
	LOAD_MESSAGE,
	REFERESH_UNREAD_NOTIFICATION_COUNT,
	LOAD_ACTIVE_ROOMS
} from '../../actions/room.actions';
import './LeftPanel.scss';
import { ADD_CALL_TO_TIMER, ADD_JOINED_CALLS } from '../../actions/timer.actions';

let LeftPanel = ({
	allRooms,
	sendMessage,
	allUsers,
	logout,
	call,
	startNewCall,
	selectedRoom,
	selectRoom,
	me,
	search,
	makeRoom,
	inviteToCall,
	knockUser,
	loadRoomMessage,
	unreadCounts,
	refereshUnreadMessageCount,
	updateModal,
	loadActiveRooms,
	addCallToTimer
}) => {
	return (
		<div className="left-panel-card">
			<TeamHeader allUsers={allUsers} logout={logout} user={me} updateModal={updateModal} call={call} />
			<div className="dm-rooms-container">
				<Search />
				{/* <Meetings /> */}
				<Channels
					refereshUnreadMessageCount={refereshUnreadMessageCount}
					allRooms={allRooms}
					sendMessage={sendMessage}
					selectedRoom={selectedRoom}
					selectRoom={selectRoom}
					loadRoomMessage={loadRoomMessage}
					search={search}
					unreadCounts={unreadCounts}
					loadActiveRooms={loadActiveRooms}
				/>
				<UserRooms
					updateModal={updateModal}
					addCallToTimer={addCallToTimer}
					loadActiveRooms={loadActiveRooms}
					refereshUnreadMessageCount={refereshUnreadMessageCount}
					inviteToCall={inviteToCall}
					knockUser={knockUser}
					makeRoom={makeRoom}
					allUsers={allUsers}
					allRooms={allRooms}
					call={call}
					startNewCall={startNewCall}
					selectRoom={selectRoom}
					selectedRoom={selectedRoom}
					user={allUsers[me._id]}
					loadRoomMessage={loadRoomMessage}
					search={search}
					unreadCounts={unreadCounts}
				/>
				<Invite />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		allUsers: state.users.allUsers,
		allRooms: state.rooms.allRooms,
		selectedRoom: state.rooms.selectedRoom,
		call: state.call,
		me: state.me,
		search: state.rooms.search,
		unreadCounts: JSON.parse(JSON.stringify(state.rooms.unreadCounts))
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload }),
		inviteToCall: (payload) => dispatch({ type: CALL_INVITE, payload }),
		startNewCall: (payload) => dispatch({ type: START_NEW_CALL, payload }),
		addCallToTimer: (payload) => dispatch({ type: ADD_CALL_TO_TIMER, payload }),
		selectRoom: (payload) => dispatch({ type: SELECT_ROOM, payload }),
		loadActiveRooms: () => dispatch({ type: LOAD_ACTIVE_ROOMS }),
		makeRoom: (payload) => dispatch({ type: CREATE_ROOM, payload }),
		knockUser: (payload) => dispatch({ type: KNOCK_USER, payload }),
		refereshUnreadMessageCount: () => dispatch({ type: REFERESH_UNREAD_NOTIFICATION_COUNT }),
		loadRoomMessage: (payload) =>
			dispatch({
				type: LOAD_MESSAGE,
				payload: {
					roomId: payload
				}
			})
	};
};
LeftPanel = connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
export default LeftPanel;
