import React, { useEffect, useState } from 'react';
import CreateNewUser from '../../ModalComponents/CreateNewUser';
import PlusIcon from '../SvgComponents/PlusIcon';
import UserRoom from './UserRoom';

function UserRooms({
	updateModal,
	inviteToCall,
	knockUser,
	allUsers,
	call,
	startNewCall,
	allRooms,
	user,
	selectRoom,
	selectedRoom,
	search,
	makeRoom,
	loadRoomMessage,
	refereshUnreadMessageCount,
	unreadCounts,
	addCallToTimer
}) {
	function getRoomId(userId) {
		let loggedInUserId = user._id;
		let targetRoomId;
		Object.keys(allRooms)
			.filter((roomId) => allRooms[roomId].isDmRoom)
			.map((roomId) => {
				let members = {};
				allRooms[roomId]?.members.map((member) => {
					members[member.userId] = member.userId;
				});
				if (members[userId] && members[loggedInUserId]) {
					targetRoomId = roomId;
				}
			});
		return targetRoomId;
	}
	useEffect(() => {}, [
		allRooms,
		selectedRoom?.lastChatMessage?.timestamp,
		allRooms[selectedRoom?._id]?.lastChatMessage?.timestamp
	]);
	useEffect(() => {
		if (selectedRoom && selectedRoom._id) {
			//temp workaround to show unread messages count
			// let oldCount = JSON.parse(localStorage.parse(action.payload.to)) || 0;
			localStorage.setItem(selectedRoom._id, 0);
		}
	}, []);
	return (
		<div className="p:1 circular p-t:.5">
			<div>
				<div className="f-w:100 sub-title2 truncate">DM</div>
				{user?.isAdmin ? (
					<div
						className="fl:r channel-plus-icon-box"
						data-tip
						data-for="suser2"
						onClick={() =>
							user.isAdmin &&
							updateModal({ show: true, ModalBody: CreateNewUser, title: 'Add a new  user' })
						}
					>
						<PlusIcon width="9px" height="9px" />
					</div>
				) : null}
			</div>
			<div className="m-t:.6" style={{ paddingBottom: '2rem' }}>
				{Object.keys(allUsers)
					.filter((userId) => {
						if (!allUsers[userId].userId) {
							return false;
						}
						if (userId == user._id) {
							return false;
						}
						if (!allUsers[userId].name && search === '') {
							return true;
						}
						if (search) {
							var searchValue = new RegExp(search.toLowerCase());
							if (searchValue.test(allUsers[userId].name.toLowerCase())) {
								return true;
							}
							return false;
						}
						return true;

						// if (!!!allRooms[roomId].name) {
						// 	return true;
						// }
						// if (search) {
						// 	var searchValue = new RegExp(search);
						// 	if (searchValue.test(allRooms[roomId].name)) {
						// 		return true;
						// 	}
						// }
					})
					.sort((userId1, userId2) => {
						let room1 = getRoomId(userId1);
						let room2 = getRoomId(userId2);
						if (
							room1 &&
							room2 &&
							allRooms[room1]?.lastChatMessage?.timestamp &&
							allRooms[room2]?.lastChatMessage?.timestamp
						) {
							return (
								new Date(allRooms[room2]?.lastChatMessage?.timestamp).getTime() -
								new Date(allRooms[room1]?.lastChatMessage?.timestamp).getTime()
							);
						}
						if (allRooms[room1]?.lastChatMessage?.timestamp) {
							return -1;
						}
						if (allRooms[room2]?.lastChatMessage?.timestamp) {
							return 1;
						}
					})
					.map((userId, i) => {
						return (
							<UserRoom
								key={i}
								addCallToTimer={addCallToTimer}
								refereshUnreadMessageCount={refereshUnreadMessageCount}
								inviteToCall={inviteToCall}
								knockUser={knockUser}
								makeRoom={makeRoom}
								allRooms={allRooms}
								user={allUsers[userId]}
								startNewCall={startNewCall}
								call={call}
								loggedInUser={user}
								allUsers={allUsers}
								selectRoom={selectRoom}
								selectedRoom={selectedRoom}
								loadRoomMessage={loadRoomMessage}
								unreadCounts={unreadCounts}
								me={user}
							></UserRoom>
						);
					})}
			</div>
		</div>
	);
}

export default UserRooms;
