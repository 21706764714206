import React from 'react';
import { connect } from 'react-redux';
import PanelSettings from '../ModalComponents/PanelSettings';
import SettingsIcon from './SvgComponents/SettingsIcon';
import Avatar from 'react-avatar';
import DotSvg from './SvgComponents/DotSvg';
import CallSvg from './SvgComponents/CallSvg';
import { createNotification } from '../../helpers/utils';
import { STATUS, SOCKET_STATUS } from '../../helpers/commonConsts';
import UserStatusIcon from '../LeftPanel/Rooms/UserStatusIcon';
import { useWindowWidth } from '@react-hook/window-size';
import MemberProfile from '../ModalComponents/MemberProfile';
import UserProfile from '../HelperComponents/UserProfile';

export const UserStatus = ({ status, socketStatus, onCall = false, callColor, centerPanel }) => {
	let statusText = 'online';
	if (socketStatus == SOCKET_STATUS.DISCONNECTED) {
		statusText = 'offline';
	} else if (status == SOCKET_STATUS.CONNECTING) {
		statusText = 'connecting';
	} else if (status == STATUS.BUSY) {
		statusText = 'busy';
	} else if (status == STATUS.AWAY) {
		statusText = 'away';
	}

	//Keep this if here only
	if (onCall) {
		statusText = 'on call';
	}
	return (
		<div className="sub-title2 f-w:100 f:.8 m-r:.9">
			<div className="user-online-icon-box d:i-b m-r:.3 v-a:m m-t:.0" style={{ marginLeft: !centerPanel && -13 }}>
				<UserStatusIcon
					onCall={onCall}
					status={status}
					socketStatus={socketStatus}
					callColor={callColor}
					teamHeader={!centerPanel}
					centerPanel={centerPanel}
				/>
			</div>
			<div className="d:i-b v-a:m m-t:-.1">{statusText}</div>
		</div>
	);
};

export const TeamHeader = ({ allUsers, user, updateModal, call }) => {
	const windowWidth = useWindowWidth();
	const getCallColor = () => {
		try {
			if (user.activeCallId) {
				//this would be other active calls in the app
				return '#' + user.activeCallId.split('-')[0];
			}
			if (call.roomId) {
				//this would be the current he may have joined
				return '#' + call.roomId.split('-')[0];
			}
			return '#009687f5';
		} catch (e) {
			createNotification({ type: 'danger', message: e.message, title: 'Error while parsing the roomID' });
		}
	};

	return (
		<div className="circular p-t:1 p:1 team-header">
			<div
				className={`v-a:m d:i-b`}
				style={{ marginTop: 2 }}
				data-tip
				data-for="status-change"
				data-event="click focus"
				onClick={() => {
					// updateModal({
					// 	show: true,
					// 	ModalBody: MemberProfile,
					// 	userProfile: allUsers?.[user._id],
					// 	noHeader: true,
					// 	noPadding: true,
					// 	isLoggedInUser: true,
					// 	crossBg: true
					// 	// modalWidth: 800
					// });
				}}
			>
				<div className="m-r:.6 d:i-b v-a:m avatar-selector avatar-selector-no-bs">
					{user.profilePic ? (
						<UserProfile styleProps={{ width: 35, height: 35 }} profilePic={user.profilePic} />
					) : (
						<Avatar
							size={windowWidth <= 990 ? 30 : 40}
							textSizeRatio={windowWidth <= 990 ? 2.3 : 2.01}
							round={true}
							name={user?.name || user.username || '#'}
							className="product"
						/>
					)}
				</div>
				<div className="d:i-b v-a:m">
					<div className="f-w:500 circular f:.9">{user?.name || user.username}</div>
					<div
						className="f-w:100 f:.7 sub-title m-t:.0 cursor"
						data-tip
						data-for="status-change"
						data-event="click focus"
					>
						<UserStatus
							status={allUsers?.[user._id]?.status}
							socketStatus={user.socketStatus}
							onCall={allUsers?.[user._id]?.activeCallId}
							callColor={`#${allUsers?.[user._id]?.color}`}
						/>
					</div>
				</div>
			</div>

			{/* <div
				className={`fl:r cursor ${windowWidth > 990 ? ' m-t:.9' : ' m-t:.8'}`}
				onClick={() => {
					updateModal({ show: true, ModalBody: PanelSettings, title: 'Panel settings' });
				}}
				data-tip
				data-for="setting3"
			>
				<SettingsIcon
					width={windowWidth <= 990 ? '0.8em' : '1rem'}
					height={windowWidth <= 990 ? '0.8em' : '1rem'}
				/>
			</div> */}
		</div>
	);
};

export default TeamHeader;
