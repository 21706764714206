import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import CrossSvg from '../../images/cross.svg';
import { Dropdown, Select, Button } from 'antd';
import { CREATE_ROOM } from '../../actions/room.actions';
import { connect } from 'react-redux';
import { createNotification } from '../../helpers/utils';
import SelectInput from '../Common/SelectInput/SelectInput';


import '../ModalComponents/AddMemberToGroup.scss';


function Modal({ setmodal, allUsers, createRoom, user, modal }) {
	const [users, setusers] = useState([]);
	const [roomName, setRoomName] = useState('');
	const [selectedUsers, setSelectedUsers] = useState([])
	const [allList, setAllList] = useState(null)
	function createNewRoom() {
		if (roomName && roomName.length) {
			createRoom({
				name: roomName,
				members: selectedUsers,
				roomType: 1,
				teamId: user.teamId
			});
		} else {
			createNotification({ type: 'danger', title: 'All the fields are required to fill' });
		}
	}

	const renderBg = () => {
		return (
			<div onClick={() => setmodal(false)} style={{
				position: 'fixed',
				top: '50%',
				left: '50%',
				height: '100vh',
				width: '100vw',
				background: 'rgba(0,0,0,0.2)',
				transform: 'translate(-50%, -50%)',
				zIndex: -1
			}}></div>
		)
	}

	const handleChange = (keys, users) => {
		setSelectedUsers(keys)
	}

	useEffect(() => {
		const allUsersArr = Object.values(allUsers);
		if (allUsersArr && Array.isArray(allUsersArr)) {

			const newList = [];
			allUsersArr.forEach(u => {
				const image = {
					type: u?.profilePic ? 'blob' : 'base64',
					src: u?.profilePic || u?.lastSnap || null
				}
				if (user?._id !== u._id) {
					newList.push({
						key: u._id,
						value: u?.name || u?.username,
						image
					})

				}

			})
			console.log(newList)
			setAllList(newList)
		}
	}, [])

	const handleRemoveAll = () => {
		setSelectedUsers([])
	}

	return (
		<div className="modal-main circular">
			{renderBg()}
			<Draggable>
				<div className="modal-body-cont">
					<div className="modal-header p:.7 p-l:.9">
						{modal.title}
						<div className="fl:r m-t:0 cursor" style={{ marginTop: -2 }} onClick={() => setmodal(false)}>
							<div className="cross-icon d:i-b" style={{ backgroundImage: `url(${CrossSvg})` }} />
						</div>
					</div>
					<div className="modal-body p:.5 p-l:1 p-r:.8 p-b:1 m-t:.5">
						<div className="m-b:1">
							<div className="t-a:l f-w:100 sub-title2 f:.8">Group name</div>
							<div className="p-t:.2 circular">
								<span
									class="ant-input-affix-wrapper search-box-left-panel"
									style={{ borderRadius: 6, padding: '2px 12px ' }}
								>
									<input
										style={{ padding: 5 }}
										type="text"
										className="ant-input circular f-w:100 p-l:.1 search-left-panel"
										onChange={(e) => setRoomName(e.target.value)}
										placeholder="Add Group Name"
									/>
								</span>
							</div>
						</div>

						<div className="t-a:l f-w:100 sub-title2 f:.8">Members</div>
						<SelectInput allList={allList} placeholder="Add Members" selected={selectedUsers} onChange={handleChange} onSelect={handleChange} onRemove={handleChange} onRemoveAll={handleRemoveAll} />

						<div className="t-a:r m-t:2">
							<Button
								type="primary"
								className="circular create-btn f-w:100 sub-title"
								size="middle"
								onClick={() => {
									createNewRoom();
								}}
							>
								Create
							</Button>
						</div>
					</div>
				</div>
			</Draggable>
		</div>
	);
}


const mapStateToProps = (state) => {
	return {
		allUsers: state.users.allUsers,
		user: state.me
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createRoom: (payload) => dispatch({ type: CREATE_ROOM, payload })
	};
};

Modal = connect(mapStateToProps, mapDispatchToProps)(Modal);
export default Modal;
