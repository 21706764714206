import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames';

import Selected from '../SingleOption/Selected';
import {
    CloseCircleTwoTone
} from '@ant-design/icons';

import './Input.scss'

function Input({ placeholder, onChange, selectedList, selectedStatic, allList, removeOption, onRemoveAll }) {

    const inputParentRef = useRef(null);
    const inputRef = useRef(null);

    const [isHovering, setIsHovering] = useState(false);

    const [dynamicList, setDynamicList] = useState([])

    const handleInputSelect = () => {
        if (inputRef?.current) {
            inputRef.current?.focus()
        }
    }

    const handleMouseHover = () => {
        setIsHovering(!isHovering)
    }

    useEffect(() => {
        if (selectedList?.length) {
            let list = selectedList.filter(x => !selectedStatic.includes(x));
            setDynamicList(list)
        }
    }, [selectedList])

    return (
        <div className={classNames('input', {
            'input--hovering': isHovering
        })} ref={inputParentRef} onClick={handleInputSelect} onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover}>
            <div className='selected_container'>
                {
                    selectedList && Array.isArray(selectedList) ? selectedList.map((selected, i) => (
                        <Selected selected={selected} allList={allList} key={selected} removeOption={removeOption} isStatic={selectedStatic.includes(selected)} />
                    )) : null
                }
            </div>
            <input onChange={onChange} placeholder={placeholder} className='select_input_main' ref={inputRef} />
            {dynamicList && Array.isArray(dynamicList) && dynamicList.length ? <div className='remove_all' onClick={onRemoveAll}>
                <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '16px' }} />
            </div> : null}
        </div>
    )
}

Input.propTypes = {}

export default React.memo(Input)
