import { notify } from '../../../../actions/request.actions';
import {
	SET_AUDIO_ONLY_IN_PROGRESS,
	SET_AUDIO_ONLY_STATE,
	ADD_PRODUCER,
	REMOVE_PRODUCER,
	SET_PRODUCER_PAUSED,
	SET_PRODUCER_RESUMED,
	SET_AUDIO_MUTED_STATE,
	SET_MEDIA_CAPABILITIES
} from '../../../../actions/call.actions';

const muteMic = async ({ roomClient, store }) => {
	try {
		roomClient.logger.debug('muteMic()');
		await roomClient.muteMic();
		store.dispatch({
			type: SET_PRODUCER_PAUSED,
			payload: { producerId: roomClient._micProducer.id }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
};

const unmuteMic = async ({ roomClient, store }) => {
	try {
		if ((!roomClient._micProducer || roomClient._micProducer.closed)) {
			roomClient._micProducer = null;
			await enableMic({ roomClient, store })
		}
		await roomClient.unmuteMic();
		store.dispatch({
			type: SET_PRODUCER_RESUMED,
			payload: { producerId: roomClient._micProducer.id }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
};

const muteAudio = async ({ store }) => {
	try {
		store.dispatch({
			type: SET_AUDIO_MUTED_STATE,
			payload: { enabled: true }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
};

const unmuteAudio = async ({ roomClient, store }) => {
	try {
		store.dispatch({
			type: SET_AUDIO_MUTED_STATE,
			payload: { enabled: false }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
};

const enableMic = async ({ roomClient, store }) => {
	try {
		if (roomClient._micProducer) {
			await unmuteMic({ roomClient, store })
			return
		}
		await roomClient.enableMic();
		const producer = {
			id: roomClient._micProducer.id,
			paused: roomClient._micProducer.paused,
			track: roomClient._micProducer.track,
			rtpParameters: roomClient._micProducer.rtpParameters,
			codec: roomClient._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
		};
		store.dispatch({
			type: ADD_PRODUCER,
			payload: { producer }
		});
		// await unmuteMic({ roomClient, store })
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
};

const disableMic = async ({ roomClient, store }) => {
	try {
		await roomClient.disableMic();
		store.dispatch({
			type: REMOVE_PRODUCER,
			payload: { producerId: roomClient._micProducer.id }
		});

	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	} finally {

		// roomClient._micProducer = null;
	}
};

const enableAudioOnly = async ({ roomClient, store }) => {
	try {
		store.dispatch({
			type: SET_AUDIO_ONLY_IN_PROGRESS,
			payload: { flag: true }
		});

		await roomClient.enableAudioOnly({ store });

		store.dispatch({
			type: SET_AUDIO_ONLY_STATE,
			payload: { enabled: true }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	} finally {
		store.dispatch({
			type: SET_AUDIO_ONLY_IN_PROGRESS,
			payload: { flag: false }
		});
	}
};

const disableAudioOnly = async ({ roomClient, store }) => {
	try {
		store.dispatch({
			type: SET_AUDIO_ONLY_IN_PROGRESS,
			payload: { flag: true }
		});
		await roomClient.disableAudioOnly({ store });
		store.dispatch({
			type: SET_AUDIO_ONLY_STATE,
			payload: { enabled: false }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	} finally {
		store.dispatch({
			type: SET_AUDIO_ONLY_IN_PROGRESS,
			payload: { flag: false }
		});
	}
};

export { muteMic, enableMic, disableMic, unmuteMic, disableAudioOnly, enableAudioOnly, muteAudio, unmuteAudio };
