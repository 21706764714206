import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Image from './Image';

import {
    CloseCircleTwoTone
} from '@ant-design/icons';

import './Selected.scss'

function Selected({ selected, allList, removeOption, isStatic }) {
    const [user, setUser] = useState(null)

    useEffect(() => {
        const user = allList.find(({ key }) => key === selected)
        if (user) {
            setUser(user)
        }
    }, [])

    const handleRemoveOption = (e) => {
        e.stopPropagation()
        removeOption && removeOption(user?.key, user?.value)
    }

    return (
        user ? <div className={classNames('selected')} title="Remove Option" onClick={isStatic ? null : handleRemoveOption} style={isStatic ? {
            cursor: 'not-allowed',
        } : {}}>
            <Image data={user} imageStyleProps={{
                height: '0.9rem',
                width: '0.9rem'
            }} />
            <div className={classNames('name')} title={user.value}>{user.value}</div>
            {!isStatic ? <div className={classNames('remove')}>
                <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '12px' }} />
            </div> : null}
        </div> : null
    )
}

Selected.propTypes = {}

export default Selected
