import { END_CALL, PEER_CALL_JOINED, PEER_CALL_LEFT } from '../actions/call.actions';
import { UPDATE_PROFILE_PIC } from '../actions/user.actions';
import {
	LOAD_USER_LIST,
	RENDER_USERS,
	USER_STATUS_UPDATE,
	PIC_UPDATED,
	USER_CALL_STATUS_UPDATE,
	USER_SOCKET_STATUS_UPDATE,
	CALL_CONNECTING_STATUS,
	USER_NAME_CHANGE
} from '../actions/users.actions';
import { RESET_ALL_STATES } from '../actions/init.actions'

const initialState = {
	allUsers: {}
};

export default function usersReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_USER_LIST:
			return {
				...state,
				loading: true
			};
		case RENDER_USERS:
			let users = {};
			action.userList.forEach((user) => {
				users[user._id] = user;
			});
			return {
				...state,
				loading: false,
				allUsers: users
			};

		case PIC_UPDATED:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						lastSnap: action.payload.imgData
					}
				}
			};

		case UPDATE_PROFILE_PIC:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						profilePic: action.payload.profilePic
					}
				}
			};
		case USER_STATUS_UPDATE:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						status: action.payload.status
					}
				}
			};
		case USER_NAME_CHANGE:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						name: action.payload.name
					}
				}
			};

		case USER_CALL_STATUS_UPDATE:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						color: action.payload.color || null,
						blink: action.payload.blink || null,
						activeCallId: action.payload.callRoomId || null,
					}
				}
			};

		case PEER_CALL_JOINED:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.peerId]: {
						...state.allUsers[action.payload.peerId],
						activeCallId: action.payload.callRoomId,
						serverUrl: action.payload.serverUrl,
						color: action.payload.color
					}
				}
			};

		case PEER_CALL_LEFT:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.peerId]: {
						...state.allUsers[action.payload.peerId],
						activeCallId: false,
						serverUrl: null,
						color: null
					}
				}
			};

		case USER_SOCKET_STATUS_UPDATE:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						socketStatus: action.payload.socketStatus || null
					}
				}
			};
		case CALL_CONNECTING_STATUS:
			return {
				...state,
				allUsers: {
					...state.allUsers,
					[action.payload.userId]: {
						...state.allUsers[action.payload.userId],
						callConnecting: action.payload.callConnecting || null
					}
				}
			};

		case RESET_ALL_STATES: {
			return initialState
		}

		default:
			return state;
	}
}
