import { call, put, take, fork, cancel, all } from 'redux-saga/effects';
import {
	START_SNAP_TIMER,
	STOP_SNAP_TIMER,
	TICK_SNAP_TIMER
} from '../actions/timer.actions';


const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function* snapTick() {
	while (true) {
		yield call(delay, 1000 * 60);
		yield put({ type: TICK_SNAP_TIMER });
	}
}

function* snapTimer() {
	while (yield take(START_SNAP_TIMER)) {
		// starts the task in the background
		const bgSyncTask = yield fork(snapTick);
		// wait for the user stop action
		yield take(STOP_SNAP_TIMER);
		// user clicked stop. cancel the background task
		// this will throw a SagaCancellationException into task
		yield cancel(bgSyncTask);
	}
}


function* callTimer() {
	// try {
	// 	while (yield take(START_CALL_TIMER)) {
	// 		// starts the task in the background
	// 		const bgSyncTask = yield fork(callTick);
	// 		// wait for the user stop action
	// 		yield take(STOP_CALL_TIMER);
	// 		// user clicked stop. cancel the background task
	// 		// this will throw a SagaCancellationException into task
	// 		yield cancel(bgSyncTask);
	// 	}
	// } catch (e) {
	// 	console.log(e);
	// }
}
// function* addJoinedCalls(action) {
// 	yield put({ type: ADD_CALLS, payload: { roomId: action.payload.roomId } });
// }

// function* addCalls() {
// 	try {
// 		yield takeEvery([ADD_JOINED_CALLS], addJoinedCalls);
// 	} catch (e) {
// 		console.log(e);
// 	}
// }

export default function* timerSaga() {
	yield all([snapTimer(), callTimer()]);
}
