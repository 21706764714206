import React from 'react';
import { FILE_REMOVED } from '../../actions/user.actions';
import { getEnrichedSelectedRoom } from '../../selectors/room.selector';
import CrossSvg from '../../images/cross.svg';
import ZipSvg from '../../images/Zip.svg';
import FileIcon from '../../images/File.svg';

import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import '../../css/App.scss';
import { sendMessage, uploadFile } from '../../helpers/api';
import { compressedMimeTypes, createNotification, imageMimeTypes } from '../../helpers/utils';
import { ATTACHMENT_TYPE } from '../../helpers/commonConsts';
import { Space, Spin } from 'antd';

function InputFile({ allUsers, allRooms, me, selectedRoom, removeFile }) {
	const [fileType, setFileType] = useState('');
	const [message, setMessage] = useState('');
	const [attachmentType, setAttachmentType] = useState(ATTACHMENT_TYPE.FILES);
	const [dimensions, setDimensions] = useState({ width: 100, height: 100 });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		(async () => {
			if (me.file) {
				let mimeType = me.file.type;
				console.log(mimeType);
				if (imageMimeTypes.indexOf(mimeType) >= 0) {
					const image = new Image();
					let fr = new FileReader();

					fr.onload = function () {
						if (fr !== null && typeof fr.result == 'string') {
							image.src = fr.result;
						}
					};
					fr.readAsDataURL(me.file);

					image.onload = async function () {
						prepareWidthAndHeight(image.width, image.height);
						setFileType('image');
						setAttachmentType(ATTACHMENT_TYPE.IMAGE);
					};
				}

				if (compressedMimeTypes.indexOf(mimeType) >= 0) {
					setFileType('compressed');
					setAttachmentType(ATTACHMENT_TYPE.COMPRESSED);
				}
			}
		})();
	}, [me.file]);

	function renderFile(type) {
		switch (type) {
			case 'image':
				return (
					<div style={{ marginTop: -dimensions.height * 0.7 }} className="m-l:3">
						<img
							id="file-preview"
							src={URL.createObjectURL(me.file)}
							style={{ width: dimensions.width, height: dimensions.height }}
						/>
					</div>
				);

			case 'compressed':
				return (
					<div style={{ marginTop: -65, marginLeft: 10 }}>
						<img id="file-preview" src={ZipSvg} style={{ width: 100, height: 'auto' }} />
					</div>
				);

			default:
				return (
					<div style={{ marginTop: -65, marginLeft: 10 }} className="m-l:3">
						<img id="file-preview" src={FileIcon} style={{ width: 100, height: 'auto' }} />
					</div>
				);
		}
	}

	async function uploadAttachment() {
		try {
			const formData = new FormData();
			formData.append('attachment', me.file);
			setLoading(true);
			let file = await uploadFile(formData);
			if (file.success) {
				console.log(file.data);
				// const timestamp = Date.now();
				let newMessage = {
					to: selectedRoom._id,
					from: me._id,
					message: { text: message },
					type: 1,
					// timestamp,
					isDm: selectedRoom.isDmRoom,
					// chatMessageId: `${timestamp}${Math.ceil(Math.random() * 100)}`,
					attachments: [
						{
							attachmentType: attachmentType,
							fileName: file.data.originalname,
							mimetype: file.data.mimetype,
							name: file.data.filename,
							size: file.data.size
						}
					]
				};
				let sendMsg = await sendMessage(newMessage, selectedRoom.roomId);
				if (sendMsg.success) {
					setLoading(false);
					removeFile({ file: '' });
					createNotification({ message: 'File Uploaded', type: 'success' });
				} else {
					throw new Error(sendMsg.error);
				}
			}
		} catch (e) {
			setLoading(false);
			createNotification({ title: 'Failed to upload the file', message: e.message, type: 'danger' });
		}
	}

	function prepareWidthAndHeight(width, height) {
		let ar = height / width;
		if (width >= height) {
			if (width <= 200) {
				setDimensions({ width, height });
			} else if (width <= 600 && width - height >= 400) {
				setDimensions({ width: width * 0.6, height: height * 0.6 });
			} else if (width <= 600) {
				setDimensions({ width: width * 0.3, height: height * 0.3 });
			} else if (width > 600 && width < 1200) {
				setDimensions({ width: width * 0.5, height: height * 0.5 });
			} else if (width > 1200) {
				setDimensions({ width: 150, height: 150 * ar });
			}
		} else {
			if (height <= 200) {
				setDimensions({ width, height });
			} else if (height <= 600 && height - width >= 400) {
				setDimensions({ width: width * 0.5, height: height * 0.5 });
			} else if (height <= 600) {
				setDimensions({ width: width * 0.2, height: height * 0.2 });
			} else if (height > 600 && width < 1200) {
				setDimensions({ width: width * 0.15, height: height * 0.15 });
			} else if (height > 1200) {
				setDimensions({ height: 150, height: 150 * ar });
			}
		}
	}
	return me.file ? (
		<div id="file-input-overlay">
			<div className="modal-main circular" style={{ width: 750 }}>
				<div
					className="modal-body-cont p-b:0"
					style={{ boxShadow: 'none', background: '#212529', borderRadius: 7 }}
				>
					<div className="modal-header p:.7 p-l:.9 dm-sans" style={{ background: 'none' }}>
						<div
							className="fl:r m-t:0 cursor"
							style={{ marginTop: 0 }}
							onClick={() => removeFile({ file: '' })}
						>
							<div className="cross-icon d:i-b" style={{ backgroundImage: `url(${CrossSvg})` }} />
						</div>
					</div>
					<div style={{ padding: 0 }} className="t-a:l">
						{renderFile(fileType)}
						<div className="m-t:1 m-l:3 circular f:2 truncate p-r:6 f-w:600">{me.file.name}</div>
						<div
							className="m-t:.0 m-l:3 circular f-w:100 truncate p-r:6"
							style={{ marginTop: -2, fontSize: 13 }}
						>
							<span style={{ opacity: 0.5 }}>Upload to </span>
							<span className="f-w:5x00">
								{selectedRoom?.name || '@' + selectedRoom?.targetUser?.username}
							</span>
						</div>
						<div className="m-t:2 m-l:3 f-w:100">
							Add a comment <span style={{ opacity: 0.5 }}>(Optional)</span>
						</div>
						<div className="m:1 m-l:3 m-t:.3">
							<TextareaAutosize
								value={message}
								style={{
									padding: '8px 15px',
									borderRadius: 3,
									resize: 'none',
									outline: 'none',
									boxShadow: 'none'
								}}
								onChange={(e) => {
									setMessage(e.target.value);
								}}
								type="text"
								className="ant-input circular f-w:300 p-l:.1 search-left-panel"
							/>
						</div>

						<div
							className="t-a:l m-t:3 t-a:r p:2"
							style={{ borderRadius: '0px 0px 6px 6px', background: '#282d32' }}
						>
							<span
								type="text"
								className="circular link-btn f-w:400 sub-title cursor m-r:4 cancel-btn"
								onClick={() => !loading && removeFile({ file: '' })}
							>
								Cancel
							</span>

							<button
								type="primary"
								className="ant-btn ant-btn-primary"
								id="upload-button"
								onClick={() => !loading && uploadAttachment()}
							>
								{loading ? (
									<span>
										<Spin
											size="small"
											className="v-a:m m-r:.2"
											style={{ marginTop: 3, marginRight: 10 }}
										/>
										<span className="v-a:m">Uploading</span>
									</span>
								) : (
									'Upload'
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
}

const mapStateToProps = (state) => {
	return {
		allRooms: state.rooms.allRooms,
		selectedRoom: getEnrichedSelectedRoom(state),
		allUsers: state.users.allUsers,
		me: state.me
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeFile: (payload) => dispatch({ type: FILE_REMOVED, payload })
	};
};

InputFile = connect(mapStateToProps, mapDispatchToProps)(InputFile);

export default InputFile;
