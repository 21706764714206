import {
	RESET_CALL_TIMER,
	RESET_SNAP_TIMER,
	START_CALL_TIMER,
	START_SNAP_TIMER,
	STOP_CALL_TIMER,
	STOP_SNAP_TIMER,
	TICK_CALL_TIMER,
	TICK_SNAP_TIMER,
	ADD_CALL_TO_TIMER,
	REMOVE_CALL_FROM_TIMER
} from '../actions/timer.actions';
import { RESET_ALL_STATES } from '../actions/init.actions'

const initialState = {
	snap: {
		status: 'Stopped',
		minutes: 0,
		lastSnapTime: ''
	},
	callTimers: {
		status: 'Stopped',
		seconds: 0,
		callsStarted: {}
	}
};
export default function timerReducer(state = initialState, action) {
	switch (action.type) {
		case START_CALL_TIMER:
			return { ...state, callTimers: { ...state.callTimers, status: 'Running' } };

		case STOP_CALL_TIMER:
			return { ...state, callTimers: { ...state.callTimers, status: 'Stopped' } };

		case TICK_CALL_TIMER:
			return {
				...state,
				callTimers: { ...state.callTimers, seconds: state.callTimers.seconds + 10 }
			};

		case ADD_CALL_TO_TIMER:
			return {
				...state,
				callTimers: {
					...state.callTimers,
					callsStarted: {
						...action.payload,
						startedAt: state.callTimers.seconds
					}
				}
			};

		case REMOVE_CALL_FROM_TIMER:
			return {
				...state,
				callTimers: {
					...state.callTimers,
					callsStarted: {}
				}
			};

		case RESET_CALL_TIMER:
			return { ...state, callTimers: { ...state.callTimers, seconds: 0 } };

		case START_SNAP_TIMER:
			return { ...state, snap: { ...state.snap, status: 'Running' } };

		case STOP_SNAP_TIMER:
			return { ...state, snap: { ...state.snap, status: 'Stopped' } };

		case TICK_SNAP_TIMER:
			let snapTimer = 3,
				settings = JSON.parse(window.localStorage.getItem('settings'));
			if (settings?.snap) {
				snapTimer = Number(settings.snap);
			}
			return {
				...state,
				snap: { ...state.snap, minutes: state.snap.minutes >= snapTimer ? 0 : state.snap.minutes + 1 }
			};

		case RESET_SNAP_TIMER:
			return { ...state, snap: { ...state.snap, minutes: 0, lastSnapTime: Date.now() } };
		case RESET_ALL_STATES: {
			return initialState
		}
		default:
			return state;
	}
}
