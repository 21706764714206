import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../css/VideoCall.css';
import StartMMeeting from './VideoCall.jsx';
function MediaSoupContainer({ endCall, store, sendMouseEvent }) {
	useEffect(() => {
		window.store.sendMouseEvent = sendMouseEvent;
		// startMeeting(endCall, window.store);
	}, []);
	return <StartMMeeting endCall={endCall} />;
}

const mapStateToProps = (state) => {
	return {
		store: state
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSoupContainer);
