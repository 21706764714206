import React, { useEffect } from 'react';
import ActiveHeader from './ActiveHeader';
import ChatMessages from './ChatMessages';
import './CentralPanel.scss';
import InputMessage from './InputMessage';
import { Gluejar } from '@charliewilco/gluejar';
import PasteImage from '../ModalComponents/PasteImage';
import { connect } from 'react-redux';
import { SEND_MESSAGE } from '../../actions/room.actions';
import { getEnrichedSelectedRoom } from '../../selectors/room.selector';
import ProfileHeader from './ProfileHeader';
import { useState } from 'react';
import AudioNotification from '../Common/AudioNotification';

import NewMessageAudio from './NewMessage1.mp3';
import KnockAudio from './knock.mp3';

import { FILE_ADDED, FILE_REMOVED, UPDATE_MODAL } from '../../actions/user.actions';

function CenterPanel({
	sendMessage,
	selectedRoom,
	messages,
	allUsers,
	me,
	allRooms,
	newMessage,
	updateModal,
	addFile
}) {
	const [files, setFiles] = useState('');
	const [active, setActive] = useState(false);
	const [activeReply, setActiveReply] = useState('');
	const [activeEdit, setActiveEdit] = useState('');

	function clearImage() {
		setFiles('');
	}

	const [membersView, setMembersView] = useState(false);

	useEffect(() => {
		setMembersView(false);
		setActiveReply('');
		setActiveEdit('');
	}, [selectedRoom?._id]);

	return [
		<div className="center-panel-card">
			{selectedRoom && selectedRoom.name && (
				<ActiveHeader
					updateModal={updateModal}
					selectedRoom={selectedRoom}
					setMembersView={setMembersView}
					membersView={membersView}
					allRooms={allRooms}
				/>
			)}
			{selectedRoom && !selectedRoom.name && (
				<ProfileHeader selectedRoom={selectedRoom} updateModal={updateModal} allUsers={allUsers} />
			)}

			<ChatMessages
				activeEdit={activeEdit}
				setActiveEdit={setActiveEdit}
				allRooms={allRooms}
				me={me}
				updateModal={updateModal}
				hasNoMoreMessages={selectedRoom && allRooms[selectedRoom?._id]?.hasNoMoreMessages}
				selectedRoom={selectedRoom}
				allUsers={allUsers}
				messages={(selectedRoom && messages[selectedRoom._id]) || []}
				membersCount={selectedRoom?.members.length}
				allMembers={selectedRoom && selectedRoom?.roomId && allRooms[selectedRoom?.roomId]?.members}
				membersView={membersView}
				activeReply={activeReply}
				setActiveReply={setActiveReply}
			/>

			<InputMessage
				activeEdit={activeEdit}
				setActiveEdit={setActiveEdit}
				addFile={addFile}
				sendMessage={sendMessage}
				selectedRoom={selectedRoom}
				user={me}
				membersView={membersView}
				activeReply={activeReply}
				setActiveReply={setActiveReply}
			/>
			{newMessage ? <AudioNotification id="NewMessageAudio" src={NewMessageAudio} autoPlay /> : null}
			{me.knockSound && !window.inActive ? <AudioNotification id="KnockAudio" src={KnockAudio} autoPlay isMe={me?.knockFrom?.from} /> : null}
			{/* {newMessage ? <audio id="NewMessageAudio" src={NewMessageAudio} autoPlay></audio> : null}
			{me.knockSound && !window.inActive ? <audio id="KnockAudio" src={KnockAudio} autoPlay></audio> : null} */}
			<Gluejar
				onPaste={(files) => {
					setFiles(files?.images?.[files.images.length - 1]);
				}}
				onError={(err) => console.log(err)}
			/>
		</div>,

		active ? (
			<PasteImage
				image={files}
				sendMessage={sendMessage}
				selectedRoom={selectedRoom}
				user={me}
				clearFiles={() => {
					setActive(false);
				}}
			/>
		) : null
	];
}
const mapStateToProps = (state) => {
	return {
		allRooms: state.rooms.allRooms,
		selectedRoom: getEnrichedSelectedRoom(state),
		messages: state.rooms.messages,
		allUsers: state.users.allUsers,
		me: state.me,
		newMessage: state.rooms.newMessage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendMessage: (payload) => dispatch({ type: SEND_MESSAGE, payload }),
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload }),
		addFile: (payload) => dispatch({ type: FILE_ADDED, payload }),
		removeFile: (payload) => dispatch({ type: FILE_REMOVED, payload })
	};
};

CenterPanel = connect(mapStateToProps, mapDispatchToProps)(CenterPanel);

export default CenterPanel;
