import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import * as cookiesManager from '../../helpers/cookiesManager';
import * as appPropTypes from '../../helpers/videoRoom/appPropTypes';
import { withRoomContext } from '../../context/VideoCall/RoomContext';
import * as stateActions from '../../actions/state.actions';
import PeerView from './PeerView';

import isElectron from 'is-electron';
import ElectronCompLoader from '../ElectronComponents/ElectronCompLoader';

import {
	SET_MUTE_MIC,
	SET_UNMUTE_MIC,
	SET_ENABLE_CALL_WEBCAM,
	SET_DISABLE_CALL_WEBCAM,
	SET_ENABLE_CALL_SCREEN_SHARE,
	SET_DISABLE_CALL_SCREEN_SHARE
} from '../../actions/call.actions';

class Me extends React.Component {
	constructor(props) {
		super(props);

		this._mounted = false;
		this._rootNode = null;
		this.state = { isModalVisible: false };
		this.screenShareBtnFunc = this.screenShareBtnFunc.bind(this);
		this.handleShare = this.handleShare.bind(this);
		this.setIsModalVisible = this.setIsModalVisible.bind(this);
	}

	setIsModalVisible(val) {
		this.setState({ isModalVisible: val });
	}

	// const [isModalVisible, setIsModalVisible] = useState(false);

	screenShareBtnFunc({ shareState, roomClient, isWindowScreenShareOpen, handleShareScreen }) {
		if (isElectron()) {
			handleShareScreen(!isWindowScreenShareOpen);
			if (shareState === 'on') {
				this.setIsModalVisible(false);
				this.props.disableShare();
			} else {
				this.setIsModalVisible(true);
			}
		} else {
			if (shareState === 'on') this.props.disableShare();
			else this.props.enableShare();
		}
	}

	handleShare = ({ handleShareScreen, isWindowScreenShareOpen }) => {
		return (media, event) => {
			handleShareScreen(!isWindowScreenShareOpen);
			if (event === 'close') {
				return;
			}
			// this.props.enableShare.call(media);
			this.props.enableShare(media);
		};
	};

	render() {
		const {
			roomClient,
			connected,
			me,
			audioProducer,
			videoProducer,
			shareProducer,
			faceDetection,
			onSetStatsPeerId,
			handleShareScreen,
			muteMic,
			unmuteMic,
			enableWebcam,
			disableWebcam,
			hideControls,
			isSharing,
			disableAudioObserver
		} = this.props;

		let micState;

		if (!me.canSendMic) micState = 'unsupported';
		else if (!audioProducer && !me.canSendMic) micState = 'unsupported';
		else if (audioProducer && !audioProducer.paused) micState = 'on';
		else micState = 'off';

		let webcamState;

		if (!me.canSendWebcam) webcamState = 'unsupported';
		else if (videoProducer && videoProducer.type !== 'share') webcamState = 'on';
		else webcamState = 'off';

		let changeWebcamState;

		if (Boolean(videoProducer) && videoProducer.type !== 'share' && me.canChangeWebcam) changeWebcamState = 'on';
		else changeWebcamState = 'unsupported';

		let shareState;

		if (Boolean(shareProducer) && shareProducer.type === 'share') shareState = 'on';
		else shareState = 'off';

		if (isSharing) shareState = 'on';

		const videoVisible = Boolean(videoProducer) && !videoProducer.paused;

		let tip;

		if (!me.displayNameSet) tip = 'Click on your name to change it';

		return (
			<div data-component="Me" ref={(node) => (this._rootNode = node)} data-tip={tip} data-tip-disable={!tip}>
				<If condition={connected}>
					{!hideControls ? (
						<div
							className="controls"
							style={{
								top: '1rem',
								left: '1rem',
								display: 'block'
							}}
						>
							<div
								style={{ borderRadius: 20 }}
								className={classnames('button', 'mic', micState)}
								onClick={() => {
									micState === 'on' ? muteMic() : unmuteMic();
								}}
							/>
							<div
								style={{ borderRadius: 20 }}
								className={classnames('button', 'webcam', webcamState, {
									disabled: me.webcamInProgress || me.shareInProgress
								})}
								onClick={() => {
									if (webcamState === 'on') {
										cookiesManager.setDevices({ webcamEnabled: false });
										disableWebcam();
									} else {
										cookiesManager.setDevices({ webcamEnabled: true });
										enableWebcam();
									}
								}}
							/>

							{/* <div
							className={classnames('button', 'change-webcam', changeWebcamState, {
								disabled: me.webcamInProgress || me.shareInProgress
							})}
							onClick={() => roomClient.changeWebcam()}
						/> */}
							{me.isWindowScreenShareOpen ? (
								<ElectronCompLoader
									name="ScreenShareWindow.js"
									options={{
										isModalVisible: this.state.isModalVisible,
										setIsModalVisible: this.setIsModalVisible,
										handleShareStream: this.handleShare({
											handleShareScreen,
											isWindowScreenShareOpen: me.isWindowScreenShareOpen
										}),
										roomClient
									}}
								/>
							) : null}
							<div
								style={{ borderRadius: 20 }}
								className={classnames('button', 'share', shareState, {
									disabled: me.shareInProgress || me.webcamInProgress
								})}
								onClick={() => {
									this.screenShareBtnFunc({
										shareState,
										roomClient,
										isWindowScreenShareOpen: me.isWindowScreenShareOpen,
										me,
										handleShareScreen
									});
								}}
							/>
						</div>
					) : null}
				</If>

				<PeerView
					shareState={shareState}
					isMe
					peer={me}
					me={me}
					roomClient={roomClient}
					audioProducerId={audioProducer ? audioProducer.id : null}
					videoProducerId={videoProducer ? videoProducer.id : null}
					shareProducerId={shareProducer ? shareProducer.id : null}
					audioRtpParameters={audioProducer ? audioProducer.rtpParameters : null}
					videoRtpParameters={videoProducer ? videoProducer.rtpParameters : null}
					shareVideoRtpParameters={shareProducer ? shareProducer.rtpParameters : null}
					audioTrack={audioProducer ? audioProducer.track : null}
					videoTrack={videoProducer ? videoProducer.track : null}
					shareVideoTrack={shareProducer ? shareProducer.track : null}
					videoVisible={videoVisible}
					audioCodec={audioProducer ? audioProducer.codec : null}
					videoCodec={videoProducer ? videoProducer.codec : null}
					shareVideoCodec={shareProducer ? shareProducer.codec : null}
					audioScore={audioProducer ? audioProducer.score : null}
					audioProducer={audioProducer}
					videoScore={videoProducer ? videoProducer.score : null}
					shareVideoScore={shareProducer ? shareProducer.score : null}
					faceDetection={faceDetection}
					onChangeDisplayName={(displayName) => {
						roomClient.changeDisplayName(displayName);
					}}
					onChangeMaxSendingSpatialLayer={(spatialLayer) => {
						roomClient.setMaxSendingSpatialLayer(spatialLayer);
					}}
					onStatsClick={onSetStatsPeerId}
					disableAudioObserver={disableAudioObserver}
				/>

				<ReactTooltip type="light" effect="solid" delayShow={100} delayHide={100} delayUpdate={50} />
			</div>
		);
	}

	componentDidMount() {
		this._mounted = true;

		const timeOut = setTimeout(() => {
			if (!this._mounted || this.props.me.displayNameSet) return;
			clearTimeout(timeOut);
			ReactTooltip.show(this._rootNode);
		}, 4000);
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.me.displayNameSet && this.props.me.displayNameSet) ReactTooltip.hide(this._rootNode);
	}
}

Me.propTypes = {
	roomClient: PropTypes.any.isRequired,
	connected: PropTypes.bool.isRequired,
	me: appPropTypes.Me.isRequired,
	audioProducer: appPropTypes.Producer,
	videoProducer: appPropTypes.Producer,
	faceDetection: PropTypes.bool.isRequired,
	onSetStatsPeerId: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		connected: state.room.state === 'connected',
		me: state.me,
		faceDetection: state.room.faceDetection
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetStatsPeerId: (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId)),
		handleShareScreen: (value) => dispatch(stateActions.toggleWindowScreenShare(value)),
		muteMic: () => dispatch({ type: SET_MUTE_MIC }),
		unmuteMic: () => dispatch({ type: SET_UNMUTE_MIC }),
		enableWebcam: () => dispatch({ type: SET_ENABLE_CALL_WEBCAM }),
		disableWebcam: () => dispatch({ type: SET_DISABLE_CALL_WEBCAM }),
		enableShare: (media) => dispatch({ type: SET_ENABLE_CALL_SCREEN_SHARE, payload: { media } }),
		disableShare: () => dispatch({ type: SET_DISABLE_CALL_SCREEN_SHARE })
	};
};

export default withRoomContext(connect(mapStateToProps, mapDispatchToProps)(Me));
