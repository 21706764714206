import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
// import "./css/App.css";
import 'shed-css/dist/index.css';
import App from './App';

import rootReducer from './reducers/';
import rootSaga from './sagas';

import { composeWithDevTools } from 'redux-devtools-extension';

const isDev = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();
let store;

let composeFunc = isDev ? composeWithDevTools : compose;

if (process.env.NODE_ENV === 'development') {
	store = createStore(rootReducer, composeFunc(applyMiddleware(sagaMiddleware), applyMiddleware(logger)));
} else {
	store = createStore(rootReducer, composeFunc(applyMiddleware(sagaMiddleware)));
}

store = createStore(rootReducer, composeFunc(applyMiddleware(sagaMiddleware)));
window.store = store;
sagaMiddleware.run(rootSaga);

const isMigrated = localStorage.getItem('isMigrated');
if (!isMigrated) {
	localStorage.clear();
	localStorage.setItem('isMigrated', true)
}

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
