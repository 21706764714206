import { STATUS, SOCKET_STATUS } from '../../../helpers/commonConsts';
import DotSvg from '../SvgComponents/DotSvg';
import LoadingDotsAnimated from '../../../images/LoadingDotsAnimated.svg';
import AwayIcon from '../../../images/Away.svg';

import CallSvg from '../SvgComponents/CallSvg';
import OfflineSvg from '../../../images/offline.svg';

const UserStatusIcon = ({
	status,
	socketStatus,
	onCall = false,
	callColor,
	teamHeader,
	righPanel,
	centerPanel,
	membersList
}) => {
	if (socketStatus == SOCKET_STATUS.CONNECTING) {
		return (
			<div className={!centerPanel && 'd:i-b'}>
				<img
					src={LoadingDotsAnimated}
					width="11px"
					height="11px"
					style={{
						boxShadow: teamHeader ? '0px 0px 0px 5px #1c1c26' : membersList && '0px 0px 0px 5px #262a2e',
						borderRadius: '100px',
						background: teamHeader && 'rgb(28, 28, 38)'
					}}
				/>
			</div>
		);
	}

	if (!socketStatus || socketStatus == SOCKET_STATUS.DISCONNECTED || status === STATUS.AWAY) {
		return (
			<div className={!centerPanel && 'd:i-b'}>
				<DotSvg
					offline={true}
					width="10px"
					height="10px"
					background={teamHeader && 'rgb(28, 28, 38)'}
					borderRadius={(teamHeader || membersList) && '100px'}
					boxShadow={teamHeader ? '0px 0px 0px 5px #1c1c26' : membersList && '0px 0px 0px 5px #262a2e	'}
				/>
			</div>
		);
	}

	if (onCall) {
		return (
			<CallSvg
				width="10px"
				height="10px"
				color={callColor}
				borderRadius={'100px'}
				background={teamHeader && 'rgb(28, 28, 38)'}
				boxShadow={teamHeader ? '0px 0px 0px 5px #1c1c26' : membersList && '0px 0px 0px 5px #262a2e	'}
				background={teamHeader && 'rgb(28, 28, 38)'}
			/>
		);
	}

	return (
		<div className={!centerPanel && 'd:i-b'}>
			{status === STATUS.ONLINE && (
				<DotSvg
					width="10px"
					height="10px"
					background={teamHeader && 'rgb(28, 28, 38)'}
					borderRadius={(teamHeader || membersList) && '100px'}
					boxShadow={teamHeader ? '0px 0px 0px 5px #1c1c26' : membersList && '0px 0px 0px 5px #262a2e	'}
				/>
			)}

			{status === STATUS.BUSY && (
				<img
					src={AwayIcon}
					width="10px"
					height="10px"
					style={{
						verticalAlign: teamHeader || righPanel || centerPanel ? 'initial' : null,
						boxShadow: teamHeader ? '0px 0px 0px 5px #1c1c26' : membersList && '0px 0px 0px 5px #262a2e	',
						borderRadius: (teamHeader || membersList) && '100px',
						background: teamHeader && 'rgb(28, 28, 38)'
					}}
				/>
			)}
		</div>
	);
};

export default UserStatusIcon;
