import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { LOAD_MESSAGE } from '../../actions/room.actions';
import { sleep } from '../../helpers/utils';
import ChatBox from '../../images/ice.png';
import AllMembers from '../MembersList/AllMembers';
import ChatMessage from './ChatMessage';
import { ContextMenuTrigger } from 'react-contextmenu';
import { hideMenu } from 'react-contextmenu/modules/actions';
import DeleteMessage from '../ModalComponents/DeleteMessage/DeleteMessage';
import MemberProfile from '../ModalComponents/MemberProfile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';

function ChatMessages({
	messages,
	allUsers,
	selectedRoom,
	dispatch,
	hasNoMoreMessages,
	updateModal,
	membersView,
	allMembers,
	membersCount,
	me,
	setActiveReply,
	activeReply,
	allRooms,
	activeEdit,
	setActiveEdit,
	loadRoomMessage
}) {
	const [chatBoxHeight, setChatBoxHeight] = useState(null);

	const containerRef = useRef(null);
	const getPreviousMessage = (index) => {
		if (index == messages.length) {
			return null;
		}
		const message = messages?.[index + 1];
		if (!message) return null;
		return { ...message, fromName: get(allUsers, `${message.from}.name`) };
	};

	const getNextMessage = (index) => {
		const message = messages?.[index - 1];
		if (!message) {
			return null;
		}
		return { ...message, fromName: get(allUsers, `${message.from}.name`) };
	};

	const loadMessage = () => {
		// if (messageCount == messages.length) {
		// 	setHasMore(false)
		// }

		loadRoomMessage({
			roomId: selectedRoom.roomId,
			limit: 100,
			skip: messages?.length || 0,
			append: true
		});
	};

	const [parentHeight, setParentHeight] = useState(null);
	const [loadChat, setLoadChat] = useState(false);

	useEffect(() => {

		window.addEventListener('resize', () => {
			getHeight();
		});

		(async () => {
			if (containerRef && containerRef.current) {
				await sleep(100);
				const element = containerRef.current;
				setParentHeight(containerRef.current?.clientHeight || null);
				setLoadChat(true);
				element &&
					element.scroll({
						top: element.scrollHeight,
						left: 0
					});
			}
		})();
		// document.addEventListener('click', hideContextMenu);
		return () => {
			window.removeEventListener('resize', () => {
				getHeight();
			});
			// document.removeEventListener('click', hideContextMenu);
		}

	}, [containerRef, messages]);




	const hideContextMenu = (e) => {
		alert("test")
		let conetxtMenu = document.getElementsByClassName('react-contextmenu')[0];
		if (
			containerRef.current &&
			containerRef.current.contains(e.target) &&
			conetxtMenu &&
			!conetxtMenu.contains(e.target)
		) {
			hideMenu();
		}
	}

	useEffect(() => {
		getHeight();
	}, []);

	function getHeight() {
		let chatBox = document.getElementsByClassName('chat-box')[0];
		let inputBox = document.getElementsByClassName('input-center-panel')[0];

		if (chatBox && chatBox.offsetHeight) {
			setChatBoxHeight(chatBox.offsetHeight + inputBox.offsetHeight);
		}
	}

	function userProfile(userId) {
		updateModal({
			show: true,
			ModalBody: MemberProfile,
			userProfile: allUsers?.[userId],
			noHeader: true,
			noPadding: true,
			crossBg: true
			// modalWidth: 800
		});
	}

	return [
		<div
			ref={containerRef}
			className="chat-box p:1 p-t:1 p-r:1 p-l:2"
			style={{
				width: membersView && '60%',
				paddingBottom: (activeReply || activeEdit) && '60px',
				height: containerRef.current?.parentheight
			}}
		>
			{/* {messages && !hasNoMoreMessages && ( */}
			{/* {messages && (
				<span
					// className="sub-title2 f-w:100 f:.8 m-l:.2 "
					style={{ cursor: 'pointer', alignItems: 'center !imporent' }}
					onClick={() => {
						// console.log(messages?.length);
						debugger
						loadRoomMessage({
							roomId: selectedRoom.roomId,
							limit: 100,
							skip: messages?.length || 0
						})
						// loadMoreMessage({ limit: 100, skip: messages?.length || 0 })
						// dispatch({ type: LOAD_MORE_MESSAGE, payload: { limit: 100, skip: messages?.length || 0 } })
					}}
				>
					<div style={{ textAlign: 'center' }}>
						Load more
					</div>
				</span>
			)} */}

			{messages?.length == 0 && hasNoMoreMessages ? (
				<div className="chatbox-ice">
					<img src={ChatBox} width="40%" style={{ minWidth: 180 }} />
					<div className="p-t:3 sub-title2 f-w:400 f:1">Break the ice</div>
				</div>
			) : (
				<InfiniteScroll
					dataLength={messages.length}
					hasMore={!hasNoMoreMessages}
					next={() => loadMessage()}
					height={parentHeight - 40}
					loader={
						<p style={{ textAlign: 'center' }}>
							<Spin size="large" />
						</p>
					}
					endMessage={messages.length > 20 && <p style={{ textAlign: 'center' }}>No More Message Found! </p>}
					style={{ display: 'flex', flexDirection: 'column-reverse', overflowX: 'hidden' }} //To put endMessage and loader to the top.
					inverse={true}
					scrollableTarget="scrollableDiv"
					scrollThreshold={'555px'}
					initialScrollY={0}
				>
					{messages.map((message, i) => {
						const fromName =
							get(allUsers, `${message.from}.name`) || get(allUsers, `${message.from}.username`);
						const profilePic = get(allUsers, `${message.from}.profilePic`);
						return (
							<ContextMenuTrigger
								key={i}
								id="chat_message_context"
								isMessageAuthor={me._id == message.from}
								message={{ ...message, fromName }}
								loggedInUser={me}
								collect={(p) => p}
								reply={(message) => {
									setActiveEdit('');
									setActiveReply(message);
									let inputBox = document.getElementsByClassName('search-left-panel')[1];
									if (inputBox) {
										inputBox.focus();
									}
								}}
								copy={() => { }}
								edit={(message) => {
									setActiveReply('');
									setActiveEdit(message);
								}}
								delete={(message) => {
									updateModal({
										message: message,
										profilePic: profilePic,
										show: true,
										ModalBody: DeleteMessage,
										v2: true
									});
								}}
							>
								<div
									id={
										((activeReply && activeReply._id == message._id) ||
											(activeEdit && activeEdit._id == message._id)) &&
										'active-reply'
									}
								>
									<ChatMessage
										userProfile={userProfile}
										profilePic={profilePic}
										setActiveEdit={setActiveEdit}
										isMessageAuthor={me._id == message.from}
										setActiveReply={setActiveReply}
										allUsers={allUsers}
										key={message._id}
										nextMessage={getNextMessage(i)}
										previousMessage={getPreviousMessage(i)}
										message={{ ...message, fromName }}
										updateModal={updateModal}
									/>
								</div>
							</ContextMenuTrigger>
						);
					})}
				</InfiniteScroll>
			)}
		</div>,
		membersView && selectedRoom && (
			<div className="members-list fl:r" style={{ width: '40%', height: `calc(${chatBoxHeight}px - 1.2rem)` }}>
				<AllMembers
					admins={allRooms[selectedRoom.roomId].admins}
					allMembers={allMembers}
					membersCount={membersCount}
					chatBoxHeight={chatBoxHeight}
					selectedRoom={selectedRoom}
				/>
			</div>
		)
	];
}

const mapDispatchToProps = (dispatch) => {
	return {
		loadRoomMessage: (payload) => {
			dispatch({
				type: LOAD_MESSAGE,
				payload
			});
		}
		// loadMoreMessage: (payload) => dispatch({ type: LOAD_MORE_MESSAGE, payload }),
	};
};

export default connect(null, mapDispatchToProps)(ChatMessages);
