import { SET_WEBCAM_IN_PROGRESS, ADD_PRODUCER, REMOVE_PRODUCER } from '../../../../actions/call.actions';

import { notify } from '../../../../actions/request.actions';

const enableWebcam = async ({ roomClient, store }) => {
	try {
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			payload: { flag: true }
		});
		await roomClient.enableWebcam();
		console.log(roomClient, 'roomClient');
		const producer = {
			id: roomClient._webcamProducer.id,
			deviceLabel: roomClient._webcam.device.label,
			type: roomClient._getWebcamType(roomClient._webcam.device),
			paused: roomClient._webcamProducer.paused,
			track: roomClient._webcamProducer.track,
			rtpParameters: roomClient._webcamProducer._rtpParameters,
			codec: await roomClient._webcamProducer._rtpParameters.codecs?.[0].mimeType.split('/')[1]
		};
		store.dispatch({
			type: ADD_PRODUCER,
			payload: { producer }
		});
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			payload: { flag: false }
		});
	} catch (error) {
		console.log(error, 'error');
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			payload: { flag: false }
		});
		notify({
			type: 'error',
			text: error.message
		});
	}
};

const disableWebcam = async ({ roomClient, store }) => {
	try {
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			payload: { flag: true }
		});
		try {
			await roomClient.disableWebcam();
		} catch (error) {
			console.log(error);
		}
		store.dispatch({
			type: REMOVE_PRODUCER,
			payload: { producerId: roomClient._webcamProducer.id }
		});
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			payload: { flag: false }
		});
	} catch (error) {
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			payload: { flag: false }
		});
		notify({
			type: 'error',
			text: error.message
		});
	} finally {
		if (roomClient?._webcamProducer?.id) {
			roomClient._webcamProducer = null;
		}
	}
};
const changeWebcam = async ({ roomClient, store }) => {
	store.dispatch({
		type: SET_WEBCAM_IN_PROGRESS,
		flag: true
	});
	try {
		await roomClient.changeWebcam();
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	} finally {
		store.dispatch({
			type: SET_WEBCAM_IN_PROGRESS,
			flag: false
		});
	}
};

export { enableWebcam, disableWebcam, changeWebcam };
