import {
	JOIN_CALL,
	START_NEW_CALL,
	LEAVE_CALL,
	SEND_CALL_INVITE,
	INCOMING_CALL,
	NEW_CALL_STARTED,
	END_CALL,
	CALL_ROOM_INFO,
	ATTEMPTING_CALL
} from '../actions/call.actions.js';
import { RESET_ALL_STATES } from '../actions/init.actions'

const initialState = {
	roomId: null,
	incominCallFrom: null,
	addedUsers: [],
	peers: {},
	canProduce: true,
	canConsume: true
};

export default function usersReducer(state = initialState, action) {
	switch (action.type) {
		case JOIN_CALL:
			return {
				...state,
				roomId: action.payload.roomId,
				lastCallUserId: action.payload.lastCallUserId,
				serverUrl: action.payload.callServerUrl,
				color: action.payload.color
			};

		case NEW_CALL_STARTED:
			return {
				...state,
				roomId: action.payload.roomId,
				addedUsers: [action.payload.invitedUserId],
				incominCallFrom: null
			};

		case CALL_ROOM_INFO:
			return {
				...state,
				roomId: action.payload.callRoomId,
				serverUrl: action.payload.callServerUrl,
				color: action.payload.color
			};

		case LEAVE_CALL:
			return initialState;

		case SEND_CALL_INVITE:
			return {
				...state,
				addedUsers: [...state.addedUsers, action.payload.invitedUserId]
			};

		case INCOMING_CALL:
			return {
				...state,
				incominCallFrom: action.payload.userId,
				roomId: action.payload.roomId
			};
		case END_CALL:
			return initialState;
		case 'ADD_PEER': {
			const { peer } = action.payload;
			const peers = {
				...state.peers,
				[peer.id]: peer
			};
			return { ...state, peers };
		}
		case 'REMOVE_PEER': {
			const { peerId } = action.payload;
			let newState = { ...state };

			delete newState.peers[peerId];

			return newState;
		}
		case 'SET_PEER_DISPLAY_NAME': {
			const { displayName, peerId } = action.payload;
			const peer = state.peers[peerId];

			if (!peer) throw new Error('no Peer found');

			const newPeer = { ...peer, displayName };
			const peers = {
				...state.peers,
				[newPeer.id]: peer
			};
			return { ...state, peers };
		}
		case 'ADD_CONSUMER_ID': {
			const { consumer, peerId } = action.payload;

			const peer = state.peers[peerId];

			if (!peer) throw new Error('no Peer found for new Consumer');

			const newConsumers = [...peer.consumers, consumer.id];
			peer.consumers = newConsumers;
			const newPeer = { ...peer };
			const peers = {
				...state.peers,
				[newPeer.id]: peer
			};

			return { ...state, peers };
		}
		case 'REMOVE_CONSUMER_ID': {
			const { consumerId, peerId } = action.payload;

			const peer = state.peers[peerId];

			// NOTE: This means that the Peer was closed before, so it's ok.
			if (!peer) return state;

			const idx = peer?.consumers.indexOf(consumerId);

			if (idx && idx === -1) throw new Error('Consumer not found');

			const newConsumers = peer.consumers.slice();

			newConsumers.splice(idx, 1);

			const newPeer = { ...peer, consumers: newConsumers };
			const peers = {
				...state.peers,
				[newPeer.id]: newPeer
			};

			return { ...state, peers };
		}
		case 'ADD_DATA_CONSUMER': {
			const { dataConsumer, peerId } = action.payload;

			// special case for bot DataConsumer.
			if (!peerId) return state;

			const peer = state.peers[peerId];

			if (!peer) throw new Error('no Peer found for new DataConsumer');

			const newDataConsumers = [...peer.dataConsumers, dataConsumer.id];
			const newPeer = { ...peer, dataConsumers: newDataConsumers };
			const peers = {
				...state.peers,
				[newPeer.id]: newPeer
			};

			return { ...state, peers };
		}
		case 'REMOVE_DATA_CONSUMER': {
			const { dataConsumerId, peerId } = action.payload;

			// special case for bot DataConsumer.
			if (!peerId) return state;

			const peer = state.peers[peerId];

			// NOTE: This means that the Peer was closed before, so it's ok.
			if (!peer) return state;

			const idx = peer.dataConsumers.indexOf(dataConsumerId);

			if (idx === -1) throw new Error('DataConsumer not found');

			const newDataConsumers = peer.dataConsumers.slice();

			newDataConsumers.splice(idx, 1);

			const newPeer = { ...peer, dataConsumers: newDataConsumers };
			const peers = {
				...state.peers,
				[newPeer.id]: newPeer
			};

			return { ...state, peers };
		}

		case 'UPDATE_POINTER_SHARING': {
			const { pointerSharing, id } = action.payload;
			if (!id) return state;

			const peer = { ...state.peers[id], pointerSharing: pointerSharing };

			if (!peer) throw new Error('no Peer found for new DataConsumer');
			const peers = {
				...state.peers,
				[peer.id]: peer
			};

			return { ...state, peers };
		}
		case RESET_ALL_STATES: {
			return initialState
		}

		default:
			return state;
	}
}
