import React, { useEffect } from 'react';
import AttachmentIcon from './SvgComponents/AttachmentIcon';
import { useState } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import EmojiSvg from './SvgComponents/EmojiSvg';
import TextareaAutosize from 'react-textarea-autosize';
import Cross from '../../images/cross.svg';
import CrossIcon from './SvgComponents/CrossIcon';
import { ATTACHMENT_TYPE } from '../../helpers/commonConsts';
import AddFiles from './SvgComponents/AddFiles';
import { useRef } from 'react';
import InputFile from './InputFile';
import { editMessage, sendMessage } from '../../helpers/api';
import ReplyIcon from './SvgComponents/ReplyIcon';
import EditIcon from './SvgComponents/EditIcon';
import { createNotification } from '../../helpers/utils';

const InputMessage = ({
	selectedRoom,
	// sendMessage,
	user,
	membersView,
	setActiveReply,
	activeReply,
	addFile,
	activeEdit,
	setActiveEdit
}) => {
	useEffect(() => {
		if (activeEdit) {
			setValue(activeEdit.message.text);
		} else {
			setValue('');
		}
	}, [activeEdit]);

	const [focus, setFocus] = useState(false);
	const [emoji, setEmoji] = useState(false);
	const [value, setValue] = useState('');
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.emoji-trigger-box') && !e.target.closest('.emoji-picker') && emoji) {
			setEmoji(false);
		}
	});
	const inputFile = useRef(null);

	function handleFiles(e) {
		e.stopPropagation();
		e.preventDefault();
		var file = e.target.files[0];
		addFile({ file });
		inputFile.current.value = '';
	}

	async function updateMessage() {
		if (activeEdit) {
			try {
				let result = await editMessage(activeEdit._id, { message: { text: value } });
				if (result.success) {
					createNotification({ message: 'Message updated', type: 'success' });
					setActiveReply('');
					setActiveEdit('');
					setValue('');
				} else {
					throw new Error(result.error);
				}
			} catch (e) {
				createNotification({ title: 'Failed to edit the message', message: e.message, type: 'danger' });
			}
		}
	}

	return [
		<div className="p:1 circular input-center-panel" style={{ width: membersView && '60%' }}>
			{activeReply ? (
				<div className="reply-tag">
					<ReplyIcon width="1em" height="1em" display="inlineBlock" marginRight="5px" marginBottom="-2px" />
					Replying to <b>{activeReply?.fromName}</b>
					<div onClick={() => setActiveReply('')} className="fl:r cursor close-reply">
						<CrossIcon
							id="cross_svg_reply"
							width="8px"
							height="8px"
							fill="#2f3643"
							marginLeft="4px"
							verticalAlign="top"
							marginTop="4"
						/>
					</div>
				</div>
			) : null}

			{activeEdit ? (
				<div className="reply-tag">
					<EditIcon
						width="0.75em"
						height="0.75em"
						display="inlineBlock"
						marginRight="7px"
						marginBottom="0px"
					/>
					Edit message
					<div onClick={() => setActiveEdit('')} className="fl:r cursor close-reply">
						<CrossIcon
							id="cross_svg_reply"
							width="8px"
							height="8px"
							fill="#2f3643"
							marginLeft="4px"
							verticalAlign="top"
							marginTop="4"
						/>
					</div>
				</div>
			) : null}
			<span
				id={focus && 'input-container'}
				className="ant-input-affix-wrapper search-box-left-panel"
				style={{
					borderRadius: activeReply || activeEdit ? '0px 0px 4px 4px' : 4,
					padding: '4px 12px',
					paddingRight: 1
				}}
			>
				<div
					className="ant-input-prefix file-input"
					onClick={() => {
						inputFile.current.click();
					}}
				>
					<span
						role="img"
						aria-label="user"
						className="anticon anticon-user site-form-item-icon cursor emojiIcon"
					>
						<AddFiles width="1em" height="1em" />
					</span>
				</div>
				{emoji && (
					<div className="emoji-picker">
						<Picker theme="dark" onSelect={(emoji) => setValue(value + emoji.native)} />
					</div>
				)}

				<TextareaAutosize
					rows={focus ? 2 : 1}
					value={value}
					onFocus={() => setFocus(true)}
					onBlur={() => {
						setFocus(false);
						setEmoji(false);
					}}
					style={{ padding: 5, resize: 'none' }}
					placeholder="Message"
					onChange={(e) => {
						// if (e.nativeEvent.inputType !== 'insertLineBreak') {
						setValue(e.target.value);
						// }
					}}
					type="text"
					className="ant-input circular f-w:300 p-l:.1 search-left-panel"
					onKeyDown={(e, d) => {
						if (
							e.keyCode === 13 &&
							!e.shiftKey &&
							e.currentTarget.value &&
							e.currentTarget.value.replace(/\s/g, '').length
						) {
							e.preventDefault();
							if (activeEdit) {
								updateMessage();
							} else {
								(async () => {
									// const timestamp = Date.now();
									const res = await sendMessage(
										{
											to: selectedRoom._id,
											from: user._id,
											message: { text: e.currentTarget.value },
											type: 1,
											// timestamp,
											isDm: selectedRoom.isDmRoom,
											// chatMessageId: `${timestamp}${Math.ceil(Math.random() * 100)}`,
											attachments: activeReply
												? [
													{
														attachmentType: ATTACHMENT_TYPE.TEXT,
														replyTo: {
															messageId: activeReply._id,
															message: activeReply.message.text,
															from: activeReply.from
														}
													}
												]
												: []
										},
										selectedRoom.roomId
									);
									setActiveReply('');
									setActiveEdit('');
									setValue('');

									if (res && res.success) {
										res.data.meId = user._id;
										window.store.dispatch({
											type: 'MESSAGE',
											payload: res.data
										})
									}

								})()
							}
						}
					}}
				/>
				<div
					className="ant-input-prefix emoji-trigger-box p-l:.4 p-r:.4"
					onClick={() => {
						setEmoji(true);
					}}
				>
					<span
						role="img"
						aria-label="user"
						className="anticon anticon-user site-form-item-icon cursor emojiIcon"
					>
						<EmojiSvg width="1em" height="1em" fill={emoji && 'white'} />
					</span>
					<input
						type="file"
						id="file"
						ref={inputFile}
						style={{ display: 'none' }}
						onChange={(e) => handleFiles(e)}
					/>
				</div>
			</span>
		</div>
	];
};

export default InputMessage;
