import React from 'react';
import { SERVER_URL } from '../../config';

function UserProfile({ profilePic, styleProps, classNames }) {
	return (
		<div
			className={`d:i-b v-a:m usericons-leftpanel cursor ${classNames}`}
			title="profile"
			style={{
				backgroundImage: `url(${SERVER_URL + '/' + profilePic})`,
				...styleProps
			}}
		></div>
	);
}

export default UserProfile;
