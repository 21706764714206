import { Button } from 'antd';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { CHANGE_NAME, UPDATE_MODAL } from '../../actions/user.actions';
import { changeUserName, resetPassword } from '../../helpers/api';
import { createNotification } from '../../helpers/utils';
import './PanelSettings.scss';
import MemberProfile from '../ModalComponents/MemberProfile';
function PanelSettings({
	updateDragAndResize,
	updateModal,
	updateCenterPanelLocation,
	changeUserNameState,
	allUsers,
	me
}) {
	function useStickyState(defaultValue, key) {
		const [value, setValue] = useState(() => {
			const stickyValue = window.localStorage.getItem(key);
			return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
		});

		useEffect(() => {
			window.localStorage.setItem(key, JSON.stringify(value));
		}, [key, value]);
		return [value, setValue];
	}

	const marks = {
		35: '3m',
		68: '5m',
		100: '7m'
	};
	const positions = {
		35: 'Center',

		100: 'Right'
	};

	const photoEffect = {
		35: 'Minecraft',
		68: 'Normal',
		100: 'Blur'
	};

	const style = { width: 140 };

	function getConvertedValue() {
		if (settings.snap) {
			switch (settings.snap) {
				case 3:
					return 35;
				case 5:
					return 68;
				case 7:
					return 100;
				default:
					return 35;
			}
		}
	}

	function getConvertedValueFoPosition() {
		if (settings.position) {
			switch (settings.position) {
				case 1:
					return 35;
				case 2:
					return 100;
				default:
					return 35;
			}
		}
	}

	function getConvertedValueFoPhotoEffect() {
		if (settings.photoEffect) {
			switch (settings.photoEffect) {
				case 1:
					return 35;
				case 2:
					return 68;
				case 3:
					return 100;
				default:
					return 68;
			}
		}
	}
	const [settings, updateSettings] = useStickyState(
		{ resize: true, drag: false, dark: true, photoEffect: 1 },
		'settings'
	);
	return (
		<div className="panel-settings m-t:.6">
			<div className="m-b:2 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Change Avatar Image</div>
				<div className="fl:r v-a:t" style={{ marginTop: 2 }}>
					<Button
						className="submit-btn circular f-w:100"
						size="middle"
						onClick={() => {
							updateModal({
								show: true,
								ModalBody: MemberProfile,
								userProfile: allUsers?.[me._id],
								noHeader: true,
								noPadding: true,
								isLoggedInUser: true,
								crossBg: true
								// modalWidth: 800
							});
						}}
					>
						Change
					</Button>
				</div>
			</div>

			<div className="m-b:2 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Enable resizable panels</div>
				<div className="fl:r v-a:t" style={{ marginTop: 2 }}>
					<Switch
						height={20}
						width={43}
						onColor={'#3ba55d'}
						checked={settings.resize}
						onChange={(checked) => {
							updateSettings({ ...settings, resize: checked });
							updateDragAndResize({ resize: checked });
						}}
					/>
				</div>
			</div>

			<div className="m-b:2 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Enable draggable panels</div>
				<div className="fl:r v-a:t" style={{ marginTop: 2 }}>
					<Switch
						height={20}
						width={43}
						onColor={'#3ba55d'}
						checked={settings.drag}
						onChange={(checked) => {
							updateSettings({ ...settings, drag: checked });
							updateDragAndResize({ drag: checked });
						}}
					/>
				</div>
			</div>

			<div className="m-b:2 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Dark theme</div>
				<div className="fl:r v-a:t" style={{ marginTop: 2, opacity: 0.2 }}>
					<Switch height={20} width={43} onColor={'#3ba55d'} checked={settings?.dark} />
				</div>
			</div>
			<div className="m-b:6 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Camera snap interval</div>
				<div className="fl:r v-a:t" style={{ marginTop: 7, marginRight: 7 }}>
					<div style={style} className="circular">
						<Slider
							className="dm-sans"
							min={35}
							marks={marks}
							included={false}
							defaultValue={getConvertedValue()}
							max={100}
							step={null}
							onChange={(val) => {
								updateSettings({ ...settings, snap: val == 35 ? 3 : val == 68 ? 5 : 7 });
							}}
						/>
					</div>
				</div>
			</div>

			<div className="m-b:6 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Photo Effect</div>
				<div className="fl:r v-a:t" style={{ marginTop: 7, marginRight: 7 }}>
					<div style={style} className="circular">
						<Slider
							className="dm-sans"
							min={35}
							marks={photoEffect}
							included={false}
							defaultValue={getConvertedValueFoPhotoEffect()}
							max={100}
							step={null}
							onChange={(val) => {
								updateSettings({ ...settings, photoEffect: val == 35 ? 1 : val == 68 ? 2 : 3 });
							}}
						/>
					</div>
				</div>
			</div>

			<div className="m-b:6 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Chat Window Position</div>
				<div className="fl:r v-a:t" style={{ marginTop: 7, marginRight: 7 }}>
					<div style={style} className="circular">
						<Slider
							className="dm-sans"
							min={35}
							marks={positions}
							included={false}
							defaultValue={getConvertedValueFoPosition()}
							max={100}
							step={null}
							onChange={(val) => {
								updateSettings({ ...settings, position: val == 35 ? 1 : 2 });
								updateCenterPanelLocation({ position: val == 35 ? 1 : 2 });
							}}
							t
						/>
					</div>
				</div>
			</div>

			<div className="m-b:2 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Change Name</div>
			</div>

			<div className="m-b:2 settings-list t-a:l">
				<span>
					<input
						autocomplete="off"
						id="name"
						style={{ padding: 5, width: '70%' }}
						placeholder="name"
						type="text"
						className="ant-input circular f-w:100 p-l:.1 search-left-panel"
					// value={value}
					// onChange={onChange}
					/>
				</span>
				<span>
					<Button
						className="submit-btn circular f-w:100  m-r:1  m-l:4"
						size="middle"
						onClick={async () => {
							if (document.getElementById('name').value) {
								const resp = await changeUserName(document.getElementById('name').value);
								if (resp.success) {
									createNotification({
										type: 'success',
										message: 'Please login Again to See the changes!',
										title: 'Success'
									});
									changeUserNameState({ name: document.getElementById('name').value });
								} else {
									createNotification({ type: 'danger', message: 'Error', title: 'Change Name' });
								}
							} else {
								createNotification({ type: 'danger', message: 'invalid input', title: 'Change Name' });
							}
							document.getElementById('name').value = '';
						}}
					>
						Submit
					</Button>
				</span>
			</div>

			<div className="m-b:2 settings-list t-a:l"></div>

			<div className="m-b:2 settings-list t-a:l">
				<div className="t-a:l f-w:100 sub-title circular d:i-b t-a:l">Reset Password</div>
			</div>

			<div className="m-b:2 settings-list t-a:l">
				<span>
					<input
						autocomplete="off"
						id="password"
						style={{ padding: 5, width: '70%' }}
						placeholder="password"
						type="text"
						className="ant-input circular f-w:100 p-l:.1 search-left-panel"
					// value={value}
					// onChange={onChange}
					/>
				</span>
				<span>
					<Button
						className="submit-btn circular f-w:100  m-r:1 m-l:4"
						size="middle"
						onClick={async () => {
							if (document.getElementById('password').value) {
								const resp = await resetPassword(document.getElementById('password').value);
								if (resp.success) {
									createNotification({
										type: 'success',
										message: 'Success',
										title: 'Password Reset'
									});
								} else {
									createNotification({ type: 'danger', message: 'Error', title: 'Password Reset' });
								}
							} else {
								createNotification({
									type: 'danger',
									message: 'invalid input',
									title: 'Password Reset'
								});
							}
							document.getElementById('password').value = '';
						}}
					>
						Reset
					</Button>
				</span>
			</div>

			<div className="t-a:l m-t:5 p-b:.5">
				<span
					type="text"
					className="circular link-btn f-w:100 sub-title cursor"
					onClick={() => {
						updateSettings({ resize: true, drag: true, dark: true, position: 35 });
						updateDragAndResize({ drag: true, resize: true });
						updateCenterPanelLocation({ position: 35 });
					}}
				>
					Reset settings
				</span>

				<button
					type="button"
					className="close-button-border ant-btn ant-btn-success circular f-w:100 fl:r"
					onClick={() => updateModal({ show: false, ModalBody: null })}
				>
					<span>Close</span>
				</button>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateModal: (payload) => dispatch({ type: UPDATE_MODAL, payload }),
		changeUserNameState: (payload) => dispatch({ type: CHANGE_NAME, ...payload })
	};
};
const mapStateToProps = (state) => {
	return {
		allUsers: state.users.allUsers,
		me: state.me
	};
};

PanelSettings = connect(mapStateToProps, mapDispatchToProps)(PanelSettings);

export default PanelSettings;
