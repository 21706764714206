import React, { useState } from 'react';
import usePermissions from './hooks/usePermissions';
import RGL, { WidthProvider } from 'react-grid-layout';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import './css/App.scss';
import './css/Notifications.scss';

import './css/ScrollBar.scss';
import 'react-grid-layout/css/styles.css';
import Home from './components/Home';
import LeftPanel from './components/LeftPanel/index';
import { Button } from 'antd';
import { connect } from 'react-redux';
import Login from './components/LoginSignup/Login';
import Signup from './components/LoginSignup/Signup';
import CenterPanel from './components/CenterPanel/index';
import RightPanel from './components/RightPanel/index';
import { useEffect } from 'react';
import Modal from './components/Modal/Modal';
import { bindActionCreators } from 'redux';
import { addToast } from '../src/actions/toast.actions';
import { changeStatus, TRY_LOGOUT } from '../src/actions/user.actions';
import ToolTips from './ToolTips';
import { GlobalHotKeys, IgnoreKeys } from 'react-hotkeys';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import CreateNewUser from './components/ModalComponents/CreateNewUser';
import ModalContainer from './components/ModalComponents/ModalContainer';
import LargeNotification from './components/ModalComponents/LargeNotification';
import DisconnectedNotification from './components/ModalComponents/DisconnectedNotification';
import MembersContextMenu from './components/MembersList/MembersContextMenu';
import ChatMessageConextMenu from './components/CenterPanel/ChatMessageConextMenu';
import InputFile from './components/CenterPanel/InputFile';
import TeamSvg from './images/teambig.svg';
import { SERVER_URL } from './config';
import Overlay from './components/Common/Overlay/Overlay'
import TeamsToggle from './components/LeftPanel/TeamsToggle';
import { Background, Container } from './components/Background'

const ReactGridLayout = WidthProvider(RGL);

function App({ me, changeStatus, logout }) {
	const [modal, setmodal] = useState(false);
	window.setmodal = setmodal;
	const [theme, setTheme] = useState('dark');
	function useStickyState(defaultValue, key) {
		const [value, setValue] = useState(() => {
			const stickyValue = window.localStorage.getItem(key);
			return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
		});

		useEffect(() => {
			window.localStorage.setItem(key, JSON.stringify(value));
		}, [key, value]);
		return [value, setValue];
	}

	const [layout, setLayout] = useStickyState(
		[
			{
				i: 'left-panel',
				x: 0,
				y: 0,
				w: 2,
				h: 1,
				isDraggable: false,
				isResizable: false
			},
			{
				i: 'center-panel',
				x: 3,
				y: 0,
				w: 5,
				h: 1,
				minH: 1,
				maxH: 1,
				maxW: 7,
				minW: 3,
				isDraggable: false,
				isResizable: true,
				resizeHandles: ['ne']
			},
			{
				i: 'right-panel',
				x: 6,
				y: 0,
				w: 5,
				h: 1,
				minH: 1,
				maxH: 1,
				maxW: 7,
				minW: 1,
				isDraggable: false,
				isResizable: false
			}
		],
		'layout'
	);


	function updateDragAndResize({ drag = '', resize = '' }) {
		let newLayout = layout;
		newLayout = newLayout.map((panel) => {
			if (panel.i == 'center-panel') {
				return {
					...panel,
					isDraggable: drag === '' ? panel.isDraggable : drag,
					isResizable: resize === '' ? panel.isResizable : resize
				};
			}
			return panel;
		});
		setLayout([...newLayout]);
	}

	function updateCenterPanelLocation({ position }) {
		let newLayout = layout;
		newLayout = newLayout.map((panel) => {
			if (position == 2) {
				if (panel.i == 'right-panel') {
					panel = {
						...panel,
						i: 'center-panel',
						minH: 1,
						maxH: 1,
						maxW: 7,
						minW: 3,
						isDraggable: false,
						isResizable: true,
						resizeHandles: ['ne']
					};
				} else if (panel.i == 'center-panel') {
					panel = {
						...panel,
						i: 'right-panel',
						minH: 1,
						maxH: 1,
						maxW: 7,
						minW: 1,
						isDraggable: false,
						isResizable: false,
						resizeHandles: ['']
					};
				}
			} else {
				if (panel.i == 'right-panel') {
					panel = {
						...panel,
						i: 'center-panel',
						minH: 1,
						maxH: 1,
						maxW: 7,
						minW: 3,
						isDraggable: false,
						isResizable: true,
						resizeHandles: ['ne']
					};
				} else if (panel.i == 'center-panel') {
					panel = {
						...panel,
						i: 'right-panel',
						minH: 1,
						maxH: 1,
						maxW: 7,
						minW: 1,
						isDraggable: false,
						isResizable: false,
						resizeHandles: ['']
					};
				}
			}

			return panel;
		});
		setLayout([...newLayout]);
	}
	return (

		<Router>
			<Switch>
				<Route path="/app">
					{me.isLoggedIn === false ? <Redirect to="/login" /> : <MainApp {...{
						me, changeStatus, logout, setmodal, modal, updateDragAndResize, updateCenterPanelLocation, layout,
						setLayout, theme
					}} />}
				</Route>
				<Route path="/signup">
					<div className={`App ${theme}`}>
						{modal && <CreateNewUser setmodal={setmodal} modal={modal} />}
						<ReactNotification />
						<ModalContainer
							updateDragAndResize={updateDragAndResize}
							updateCenterPanelLocation={updateCenterPanelLocation}
						/>
						<Signup />
					</div>
				</Route>
				<Route path="/login">
					<div className={`App ${theme}`}>
						{modal && <CreateNewUser setmodal={setmodal} modal={modal} />}
						<ReactNotification />
						<ModalContainer
							updateDragAndResize={updateDragAndResize}
							updateCenterPanelLocation={updateCenterPanelLocation}
						/>
						<Login />
					</div>
				</Route>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</Router>

	);
}

const MainApp = ({ me, changeStatus, logout, setmodal, modal, updateDragAndResize, updateCenterPanelLocation, layout,
	setLayout, theme }) => {
	const audioPermission = usePermissions({ eventName: 'audio', name: 'microphone' });
	const videoPermission = usePermissions({ eventName: 'video', name: 'camera' });

	useEffect(() => {
		document.addEventListener('visibilitychange', function () {
			if (document.hidden) {
				window.inActive = true;
			} else {
				window.inActive = false;
			}
		});
	}, []);



	const [height, setHeight] = useState(window.innerHeight - 1);

	window.addEventListener('resize', (e) => {
		setHeight(window.innerHeight - 1);
	});


	function onLayoutChange(data) {
		let leftPanel,
			rightPanel,
			centerPanel,
			orderedLayout = layout;
		data.sort((data1, data2) => data1.x - data2.x);
		orderedLayout.map((pane, i) => {
			if (pane.i == 'center-panel') {
				centerPanel = { ...pane, pos: i };
			} else if (pane.i == 'right-panel') {
				rightPanel = { ...pane, pos: i };
			} else {
				leftPanel = { ...pane, pos: i };
			}
		});

		data.map((pane, i) => {
			if (i == 0 && pane.i == 'center-panel') {
				data[1].x = data[0].w;
				data[2].x = data[0].w + leftPanel.w;
			}
			if (i == 2 && pane.i == 'center-panel') {
				data[1].w = 12 - (leftPanel.w + data[2].w);
				data[2].x = leftPanel.w + data[1].w;
			}
			if (data[0].i == 'center-panel' && pane.i == 'right-panel' && rightPanel.w == pane.w) {
				data[i].w = 12 - (leftPanel.w + data[0].w);
			}
			if (data[1].i == 'center-panel' && pane.i == 'right-panel' && rightPanel.w == pane.w) {
				data[i].w = 12 - (leftPanel.w + data[1].w);
			}
		});
		setLayout([...data]);
	}

	const keyMap = {
		KEY_SEARCH: 'ctrl+i'
	};

	const keyHandlers = {
		KEY_SEARCH: () => {
			document.querySelector('#search').focus();
		}
	};


	if (me && me.disabled === true) {
		return (
			<div style={{ height: '100vh', width: '100vw', background: '#14141c' }}>
				<div
					className="circular f-w:300 v-middle t-a:c m-t:1"
					style={{
						width: 'auto',
						height: 'auto',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						padding: '30px 55px',
						borderRadius: 20,
						background: '#21212e'
					}}
				>
					<div style={{ width: '25vw' }} className="t-a:l">
						<div className="v-a:m d:i-b" style={{ marginTop: 2 }}>
							<div className="m-r:.6 d:i-b v-a:m avatar-selector avatar-selector-no-bs">
								<div
									className="d:i-b v-a:m usericons-leftpanel cursor undefined"
									title="profile"
									style={{
										backgroundImage: me.profilePic
											? `url(${SERVER_URL + '/' + me.profilePic})`
											: `url(${me.lastSnap})`,
										width: 45,
										height: 45
									}}
								></div>
							</div>
							<div className="d:i-b v-a:m">
								<div className="f-w:500 circular f:1 m-l:.3">{me.username}</div>
							</div>
						</div>
						<Button
							type="danger"
							className="circular f-w:100 danger-color fl:r m-t:.7"
							danger
							size="middle"
							onClick={() => {
								logout();
							}}
							style={{ wordSpacing: -4, position: 'absolute', right: 40 }}
						>
							Log out
						</Button>
					</div>
					<img src={TeamSvg} style={{ width: '25vw', height: '25vh' }} className="m-t:5" />
					<h2 className="m-t:9 m-b:2 f-w:400" style={{ wordSpacing: -9 }}>
						You have no active team currently
					</h2>
				</div>
			</div>
		);
	}
	return (

		<GlobalHotKeys keyMap={keyMap} handlers={keyHandlers}>
			<Overlay isPromptPermission={audioPermission === 'prompt' || videoPermission === 'prompt'} />
			<ChatMessageConextMenu />
			<MembersContextMenu />
			<ReactNotification />
			<div className={`App ${theme}`}>
				{me.file && <InputFile />}
				<ToolTips me={me} changeStatus={changeStatus} />
				{modal && <Modal setmodal={setmodal} modal={modal} />}
				<LargeNotification />
				<DisconnectedNotification />
				<ModalContainer
					updateDragAndResize={updateDragAndResize}
					updateCenterPanelLocation={updateCenterPanelLocation}
					isDraggable={false}
				/>
				{/* <div key="team-panel" className="team-panel">
					<TeamsToggle />
				</div> */}
				<ReactGridLayout
					// useCSSTransforms={false}
					compactType="horizontal"
					className="layout"
					autoSize={true}
					preventCollision={false}
					isBounded={true}
					onResize={onLayoutChange}
					onDragStop={onLayoutChange}
					layout={layout}
					cols={12}
					width={'1200'}
					rowHeight={height}
					style={{ height: '100vh' }}
					margin={[0, 0]}
				>
					<div key="left-panel" className="left-panel">
						<LeftPanel />
					</div>

					<div key="center-panel" className="center-panel">
						<CenterPanel />
					</div>
					<div key="right-panel" className="right-panel">
						<RightPanel />
					</div>
				</ReactGridLayout>
			</div>
		</GlobalHotKeys>

	)
}

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch({ type: TRY_LOGOUT }),
	...bindActionCreators({ addToast, changeStatus }, dispatch)
});

const mapStateToProps = (state) => {
	return {
		me: state.me
	};
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
