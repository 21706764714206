import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import isElectron from 'is-electron';
import {
	LOGGED_IN,
	MODAL_UPDATED,
	UPDATE_MODAL,
	USER_LOGIN,
	KNOCK_RECIEVED,
	KNOCK_USER,
	KNOCK_SOUND_ON,
	KNOCK_SOUND_OFF,
	MOUSE_EVENT_RECIEVED,
	MOUSE_EVENT_START,
	UPDATE_PROFILE_PIC,
	PROFILE_PIC,
	SWITCH_TEAM,
	SWITCH_USER_TEAM
} from '../actions/user.actions';
import { LOAD_ACTIVE_ROOMS } from '../actions/room.actions'
import { LOAD_USER_LIST, RENDER_USERS } from '../actions/users.actions';
import { getActiveRooms, getUsers } from '../helpers/api';
import { createNotification, createPushNotification, sleep } from '../helpers/utils';
import moment from 'moment';
import { RESET_ALL_STATES } from '../actions/init.actions';

export function* fetchUserList() {
	try {
		const teamId = window.store.getState().me.teamId
		const users = yield call(getUsers, teamId);
		yield put({ type: RENDER_USERS, userList: users.data });
		// const rooms = yield call(getActiveRooms);
		// yield put({
		// 	type: LOAD_ACTIVE_ROOMS_SUCCESS,
		// 	payload: { allRooms: rooms.data, from: 'user.saga' }
		// });
		// const activeRoomId = localStorage.getItem('activeRoom') || get(rooms, `data[${0}]roomId`);
		// const state = yield select();
		// if (activeRoomId) {
		// 	if (state.rooms.allRooms[activeRoomId].requiresHistory) {
		// 		yield put(loadRoomMessage(activeRoomId));
		// 	}
		// 	yield put(selectRoom(activeRoomId));
		// }
		const initialUser = JSON.parse(localStorage.getItem('user'));
		let usersExists = false;
		if (initialUser) {
			users.data.map((user) => {
				if (user._id == initialUser._id) {
					usersExists = true;
					localStorage.setItem('user', JSON.stringify({ ...initialUser, lastSnap: user.lastSnap }));
				}
			});
		}
	} catch (error) { }
}
export function* updateModalData(action) {
	let { show, ModalBody } = action.payload;
	if (show) {
		yield put({ type: MODAL_UPDATED, payload: { show: true, ModalBody, ...action.payload } });
	} else {
		yield put({ type: MODAL_UPDATED, payload: { show: false, ModalBody: null } });
	}
}

export function* updateModal(action) {
	try {
		yield takeEvery([UPDATE_MODAL], updateModalData);
	} catch (e) {
		createNotification({ type: 'danger', title: 'Could not update the modal', messsage: e.messsage });
	}
}

export function* loadUserList() {
	yield takeEvery([USER_LOGIN], fetchUserList);
}

export function* playKnockSound() {
	yield put({ type: KNOCK_SOUND_ON });
	sleep(2000).then(() => {
		window.store.dispatch({ type: KNOCK_SOUND_OFF });
	});
}

export function* updateProfileImage(action) {
	yield put({
		type: UPDATE_PROFILE_PIC,
		payload: { profilePic: action.payload.profilePic, userId: action.payload.userId }
	});
}

export function* playKnockSoundWithNotification(action) {
	let state = yield select();
	if (window.inActive && !isElectron()) {
		createPushNotification({
			type: 'info',
			title: state?.users?.allUsers?.[action.payload.from]?.name.toUpperCase() + ' is trying to call you.',
			message: moment().format('hh:mm a'),
			nativeOnly: true
		});
	}
	if (isElectron()) {
		yield call(window.ipcRenderer.invoke, 'play-audio', { src: '', id: 'KnockAudio', isMe: false, title: state?.users?.allUsers?.[action.payload.from]?.name.toUpperCase() + ' is trying to call you.', message: moment().format('hh:mm a'), });

	}
	yield put({
		type: KNOCK_SOUND_ON,
		payload: { knockFrom: { from: action.payload.from, at: moment().format('hh:mm a') } }
	});
	sleep(2000).then(() => {
		window.store.dispatch({ type: KNOCK_SOUND_OFF });
	});
}

export function* getMouseEvent(action) {
	// MOUSE_EVENT_RECIEVED
	console.log(action, 'Event');
	yield put({
		type: MOUSE_EVENT_START,
		payload: { mEventFrom: { from: action.payload.from, at: moment().format('hh:mm a') } }
	});
}
export function* switchUserTeam(action) {
	yield put({
		type: RESET_ALL_STATES
	});
	localStorage.removeItem('activeRoom')
	yield put({
		type: SWITCH_USER_TEAM,
		payload: { teamId: action.payload.teamId }
	});
	yield put({
		type: USER_LOGIN
	});
	yield put({
		type: LOGGED_IN,
		payload: { user: window.store.getState().me }
	});
	yield put({
		type: LOAD_ACTIVE_ROOMS
	});
}

export function* handleKnock() {
	yield takeLatest([KNOCK_USER], playKnockSound);
}

export function* handleKnockReceived() {
	yield takeLatest(KNOCK_RECIEVED, playKnockSoundWithNotification);
}
export function* handleMouseEventRecived() {
	yield takeLatest(MOUSE_EVENT_RECIEVED, getMouseEvent);
}

export function* updateProfilePic() {
	yield takeLatest(PROFILE_PIC, updateProfileImage);
}

export function* switchTeam() {
	yield takeLatest(SWITCH_TEAM, switchUserTeam);
}

export default function* userSaga() {
	yield all([loadUserList(), updateModal(), handleKnock(), handleKnockReceived(), updateProfilePic(), switchTeam()]);
}

