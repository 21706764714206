import React, { useState, useEffect } from "react";

import "./Overlay.scss";

export default function Overlay({ isPromptPermission }) {
    const [isIos, setIsIos] = useState(false);

    useEffect(() => {
       const isIos = iOS();
        if (isIos) {
            setIsIos(isIos)
        }
    }, [])


    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator?.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    return (
        isIos ? '' : <div className="overlay" style={{ display: isPromptPermission ? 'block' : 'none' }}>
        </div>
    );
}
