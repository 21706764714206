module.exports = {
	STATUS: {
		ONLINE: 1,
		BUSY: 2,
		AWAY: 6
	},
	SOCKET_STATUS: {
		CONNECTING: 'connecting',
		CONNECTED: 'connected',
		DISCONNECTED: 'disconnected'
	},
	ATTACHMENT_TYPE: {
		TEXT: 1,
		IMAGE: 2,
		VIDEO: 3,
		AUDIO: 4,
		COMPRESSED: 5,
		FILES: 6
	}
};
