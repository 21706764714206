
import {
	LOAD_ACTIVE_ROOMS_SUCCESS,
	SELECT_ROOM,
	MESSAGE,
	SEND_MESSAGE,
	LOAD_MESSAGE,
	LOAD_MESSAGE_SUCCESS,
	NEW_ROOM_CREATED,
	SEARCH,
	INITIALIZE_UNREAD_NOTIFICATION_COUNT,
	REFERESH_UNREAD_NOTIFICATION_COUNT
} from '../actions/room.actions';

import { get } from 'lodash';
import { createNotification, createPushNotification } from '../helpers/utils';
import {
	ROOM_JOINED_SOUND_ON,
	ROOM_JOINED_SOUND_OFF,
	LOGGED_IN,
	NEW_MESSAGE_SOUND_OFF,
	NEW_MESSAGE_SOUND_ON
} from '../actions/user.actions';
import { RESET_ALL_STATES } from '../actions/init.actions'
const initialState = {
	allRooms: {},
	selectedRoom: null,
	messages: {},
	search: '',
	unreadCounts: {}
};
export default function roomsReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_ACTIVE_ROOMS_SUCCESS:
			let rooms = {};
			action.payload.allRooms.forEach((room) => {
				rooms[room.roomId] = room;
				rooms[room.roomId].admins = [];
				room?.members?.map((member) => {
					member.isAdmin && rooms[room.roomId].admins.push(member.userId);
				});
				rooms[room.roomId].requiresHistory = get(rooms, `[${room.roomId}].requiresHistory`, true);
				if (state.allRooms[room.roomId] && state.allRooms[room.roomId].hasOwnProperty('hasNoMoreMessages')) {
					rooms[room.roomId]['hasNoMoreMessages'] = state.allRooms[room.roomId]['hasNoMoreMessages'];
				}
			});

			return {
				...state,
				allRooms: rooms
			};
		case SELECT_ROOM:
			return {
				...state,
				selectedRoom: state.allRooms[action.payload]
			};
		case MESSAGE:
			if (!state.messages[action.payload.roomId || action.payload.to]) {
				state.messages[action.payload.roomId || action.payload.to] = [];
			}
			if (action.payload.loggedInUserId !== action.payload.from && action.payload?.allUsers) {
				createPushNotification({
					type: 'info',
					title: action.payload.message.text,
					message: action.payload.to
						? action.payload.allUsers[action.payload.from]?.name ||
						action.payload.allUsers[action.payload.from]?.username +
						' (#' +
						state.allRooms[action.payload.to]?.name +
						')'
						: action.payload.allUsers[action.payload.from]?.name ||
						action.payload.allUsers[action.payload.from]?.username
				});
			}

			//temp workaround to show unread messages count
			let actionId = action.payload.roomId || action.payload.to;
			if (state?.selectedRoom?._id == actionId) {
				localStorage.setItem(actionId, 0);
			} else {
				let oldCount = JSON.parse(localStorage.getItem(actionId)) || 0;
				localStorage.setItem(actionId, oldCount + 1);
			}
			let allRooms = state.allRooms;
			Object.keys(allRooms).map((roomId) => {
				state.unreadCounts[roomId] = JSON.parse(localStorage.getItem(roomId)) || 0;
			});

			const obj = {
				...state,
				allRooms: {
					...state.allRooms,
					[actionId]: {
						...state.allRooms[actionId],
						lastChatMessage: action.payload
					}
				},
				unreadCounts: state.unreadCounts,
				messages: {
					...state.messages,
					[action.payload.roomId || action.payload.to]: [
						action.payload,
						...state.messages[action.payload.roomId || action.payload.to]
					]

				}
			}
			const allowMsg = action.payload?.meId && action.payload?.to ? action.payload?.meId === action.payload?.from : action.payload.loggedInUserId ? action.payload.loggedInUserId !== action.payload.from : false;

			if (allowMsg) {
				return obj;
			} else {
				return state
			}

		case SEND_MESSAGE:
			if (!state.messages[action.payload.to]) {
				state.messages[action.payload.to] = [];
			}
			return {
				...state,
				messages: {
					...state.messages,
					[action.payload.to]: [...state.messages[action.payload.to], action.payload]
				}
			};
		case LOAD_MESSAGE_SUCCESS:
			const isAppend = action.payload.isAppend;
			const loadMsg = (roomMessages, messages) => {
				if (Array.isArray(messages) && messages?.length && isAppend) {
					return [...messages, ...roomMessages.reverse()];
					// return [...messages, ...roomMessages]
				} else {
					return roomMessages.reverse();
					// return roomMessages
				}
			};
			return {
				...state,
				allRooms: {
					...state.allRooms,
					[action.payload.roomId]: {
						...state.allRooms[action.payload.roomId],
						requiresHistory: !!action.payload.setRequiredHistoryFalse,
						hasNoMoreMessages: action.payload.hasNoMoreMessages
					}
				},
				messages: {
					...state.messages,
					[action.payload.roomId]: loadMsg(action.payload.roomMessages, state.messages[action.payload.roomId])
				}
			};
		case SEARCH:
			return {
				...state,
				search: action.payload
			};
		case NEW_ROOM_CREATED:
			return {
				...state,
				allRooms: {
					...state.allRooms,
					[action.payload.roomId]: action.payload
				}
			};

		case ROOM_JOINED_SOUND_ON:
			return {
				...state,
				roomJoined: true
			};

		case ROOM_JOINED_SOUND_OFF:
			return {
				...state,
				roomJoined: false
			};

		case NEW_MESSAGE_SOUND_ON:
			return {
				...state,
				newMessage: true
			};

		case NEW_MESSAGE_SOUND_OFF:
			return {
				...state,
				newMessage: false
			};

		//tmp workaround for unread message count
		case REFERESH_UNREAD_NOTIFICATION_COUNT:
			Object.keys(state.allRooms).map((roomId) => {
				state.unreadCounts[roomId] = JSON.parse(localStorage.getItem(roomId)) || 0;
			});
			return {
				...state,
				unreadCounts: state.unreadCounts
			};
		case RESET_ALL_STATES: {
			return initialState
		}

		default:
			return state;
	}
}
