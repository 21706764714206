import React from 'react'
import { Row, Col } from 'antd';
import Image from './Image';

import './SingleOption.scss'

function SingleOption({ data, imageStyleProps, onSelect, selectedList = [] }) {

    const handleOnClick = () => {
        onSelect(data.key, data.value)
    }

    return (
        selectedList && Array.isArray([...selectedList]) && [...selectedList].includes(data.key) ? null : <Row justify="start" className='single_option' onClick={handleOnClick}>
            <Col span={2}>
                <Image imageStyleProps={imageStyleProps} data={data} />
            </Col>
            <Col span={22} className={'single_option--txt'}>{data.value}</Col>
        </Row>
    )
}

SingleOption.propTypes = {}

export default SingleOption
