import { all, call, put, takeEvery, takeLatest, take, select } from 'redux-saga/effects';
import {
	LOAD_ROOM_INFO,
	LOAD_ACTIVE_ROOMS,
	LOAD_ACTIVE_ROOMS_SUCCESS,
	LOAD_ACTIVE_ROOMS_FAILED,
	MESSAGE,
	SELECT_ROOM,
	LOAD_MESSAGE,
	LOAD_MESSAGE_FAILED,
	LOAD_MESSAGE_SUCCESS,
	CREATE_ROOM,
	CREATE_ROOM_FAILED,
	CREATE_ROOM_SUCCESS,
	NEW_ROOM,
	NEW_ROOM_CREATED,
	REFERESH_UNREAD_NOTIFICATION_COUNT,
	DELETE_MESSAGE,
	EDIT_MESSAGE
} from '../actions/room.actions';
import {
	LOGGED_IN,
	ROOM_JOINED_SOUND_ON,
	ROOM_JOINED_SOUND_OFF,
	NEW_MESSAGE_SOUND_ON,
	NEW_MESSAGE_SOUND_OFF
} from './../actions/user.actions';

import { getActiveRooms, getMessageHistory, createRoom, getRoomInfo } from '../helpers/api';
import { addToast } from '../actions/toast.actions';
import { createNotification } from '../helpers/utils';

export function* fetchRoomMessages(action) {
	try {
		console.log(action, 'action');
		const isAppend = action.payload?.append;
		const response = yield call(
			getMessageHistory,
			action.payload.roomId,
			action.payload?.limit,
			action.payload?.skip
		);
		yield put({
			type: LOAD_MESSAGE_SUCCESS,
			payload: {
				roomMessages: response.data,
				roomId: action.payload.roomId,
				setRequiredHistoryFalse: false,
				hasNoMoreMessages: response.hasNoMoreMessages,
				isAppend
			}
		});
	} catch (error) {
		yield put({
			type: LOAD_MESSAGE_FAILED,
			payload: { message: error.message }
		});
	}
}

export function* fetchNewRoomInfo(action) {
	try {
		const response = yield call(getRoomInfo, action.payload.roomId);
		yield put({
			type: NEW_ROOM_CREATED,
			payload: response
		});
	} catch (error) { }
}

export function* fetchActiveRooms() {
	try {
		const teamId = window.store.getState().me.teamId
		const response = yield call(getActiveRooms, teamId);
		yield put({
			type: LOAD_ACTIVE_ROOMS_SUCCESS,
			payload: { allRooms: response.data, from: 'room.saga' }
		});
	} catch (error) {
		yield put({
			type: LOAD_ACTIVE_ROOMS_FAILED,
			payload: { message: error.message }
		});
	}
}

export function* createNewRoom(action) {
	try {
		const response = yield call(createRoom, action.payload);
		yield put({ type: NEW_ROOM_CREATED, payload: response.data });
		window.setmodal(false);
		createNotification({ type: 'success', message: 'New Room created' });
		yield put({ type: LOAD_ACTIVE_ROOMS });
	} catch (error) {
		yield put({
			type: CREATE_ROOM_FAILED,
			payload: { message: error.message }
		});
	}
}

export function* deleteOrEditMessage(action) {
	let state = yield select();
	action.payload.roomId = state.rooms.selectedRoom.roomId;
	const response = yield call(getMessageHistory, action.payload.roomId);
	yield put({
		type: LOAD_MESSAGE_SUCCESS,
		payload: {
			roomMessages: response.data,
			roomId: action.payload.roomId,
			setRequiredHistoryFalse: false,
			hasNoMoreMessages: response.hasNoMoreMessages
		}
	});
}

export function* roomJoinedSoundNotification() {
	yield put({ type: ROOM_JOINED_SOUND_ON });
}

export function* roomJoinedSoundNotificationOff() {
	yield put({ type: ROOM_JOINED_SOUND_OFF });
}

export function* newMessageNotificationOff() {
	yield put({ type: NEW_MESSAGE_SOUND_OFF });
}

export function* newMessageNotificationOn() {
	yield put({ type: NEW_MESSAGE_SOUND_ON });
}

export function* makeRoom() {
	yield takeEvery([CREATE_ROOM], createNewRoom);
}

export function* roomJoinedSounOn() {
	yield takeLatest([ROOM_JOINED_SOUND_ON], roomJoinedSoundNotification);
}

export function* roomJoinedSoundOff() {
	yield takeLatest([ROOM_JOINED_SOUND_OFF], roomJoinedSoundNotificationOff);
}

export function* newMessageSoundOn() {
	yield takeLatest([NEW_MESSAGE_SOUND_ON], newMessageNotificationOn);
}

export function* newMessageSoundOff() {
	yield takeLatest([NEW_MESSAGE_SOUND_OFF], newMessageNotificationOff);
}

export function* loadRoomMessages() {
	yield takeEvery([LOAD_MESSAGE], fetchRoomMessages);
}
export function* loadActiveRooms() {
	yield takeEvery([LOAD_ACTIVE_ROOMS], fetchActiveRooms);
}

export function* loadNewRoomInfo() {
	yield takeEvery([NEW_ROOM], fetchNewRoomInfo);
}

export function* deleteOrEditMessageListener() {
	yield takeEvery([DELETE_MESSAGE, EDIT_MESSAGE], deleteOrEditMessage);
}

//tmp workaround for unread message count
export function* refreshUnreadCount() {
	yield put({ type: REFERESH_UNREAD_NOTIFICATION_COUNT });
}

export function* handleSelectRoom() {
	yield takeEvery([SELECT_ROOM], (action) => {
		localStorage.setItem('activeRoom', action.payload);
	});
}

export default function* roomSaga() {
	yield all([
		loadRoomMessages(),
		handleSelectRoom(),
		loadActiveRooms(),
		makeRoom(),
		loadNewRoomInfo(),
		deleteOrEditMessageListener()
	]);
}
