import { ADD_PRODUCER, REMOVE_PRODUCER, SET_SHARE_IN_PROGRESS } from '../../../../actions/call.actions';

import { notify } from '../../../../actions/request.actions';

const enableShare = async ({ roomClient, store, action }) => {
	const media = action.payload.media;
	try {
		store.dispatch({
			type: SET_SHARE_IN_PROGRESS,
			payload: { flag: true }
		});
		await roomClient.enableShare(media);
		const producer = {
			id: roomClient._shareProducer.id,
			type: 'share',
			paused: roomClient._shareProducer.paused,
			track: roomClient._shareProducer.track,
			rtpParameters: roomClient._shareProducer.rtpParameters,
			codec: roomClient._shareProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
			appData: roomClient._shareProducer.appData.appData
		};
		store.dispatch({
			type: ADD_PRODUCER,
			payload: { producer }
		});
	} catch (error) {
		console.log(error.message)
		if (error.message === 'canceled') {
			notify({
				type: 'info',
				text: 'Screen share canceled!'
			});
		} else {
			notify({
				type: 'error',
				text: error.message
			});
		}
	} finally {
		store.dispatch({
			type: SET_SHARE_IN_PROGRESS,
			payload: { flag: false }
		});

	}
};

const disableShare = async ({ roomClient, store }) => {
	try {
		store.dispatch({
			type: REMOVE_PRODUCER,
			payload: { producerId: roomClient._shareProducer.id }
		});
		await roomClient.disableShare();
		roomClient._shareProducer = null;
		console.log(roomClient);
	} catch (error) {
		console.log(error, 'error');
		notify({
			type: 'error',
			text: error.message
		});
	}
};

export { enableShare, disableShare };
