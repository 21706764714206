import domready from 'domready';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import randomString from 'random-string';
import Logger from '../../helpers/Logger';
import * as utils from '../../helpers/utils';
import deviceInfo from '../../helpers/deviceInfo';
import * as stateActions from '../../actions/state.actions';
import Room from './Room';

function StartMeeting({ endCall, store }) {
	const logger = new Logger();
	const [init, setInit] = useState(false);
	useEffect(() => {
		(async () => {
			if (document.getElementById('mediasoup-demo-app-container')) {
				await utils.initialize();
				setInit(true);
			}
		})();
	}, []);
	return init ? <Run logger={logger} endCall={endCall} /> : null;
}

function Run({ logger, endCall }) {
	useEffect(() => {
		(async () => {
			logger.debug('run() [environment:%s]', process.env.NODE_ENV);
			const peerId = window.store.getState().me._id;
			let roomId = window.store.getState().call.roomId;
			let displayName = window.store.getState().me.name;

			if (!roomId) {
				roomId = randomString({ length: 8 }).toLowerCase();
			}

			let displayNameSet;

			// If displayName was provided via URL or Cookie, we are done.
			if (displayName) {
				displayNameSet = true;
			}
			// Otherwise pick a random name and mark as "not set".
			else {
				displayNameSet = false;
				displayName = 'Unknown';
			}
			const device = deviceInfo();

			window.store.dispatch(stateActions.setMe({ peerId, displayName, displayNameSet, device }));
		})();
	}, []);
	return <Room endCall={endCall} />;
}

export default connect()(StartMeeting);
