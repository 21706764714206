import React, { useState, useRef } from 'react';
import SearchIcon from './SvgComponents/SearchIcon';
import { search } from '../../actions/room.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

const Search = ({ search, value }) => {
	const delayedQuery = _.debounce((q) => search(q), 0);

	const onChange = (e) => {
		delayedQuery(e.target.value);
	};

	return (
		<div className="p:1 circular">
			<span
				className="ant-input-affix-wrapper search-box-left-panel"
				style={{ borderRadius: 4, padding: '2px 12px', opacity: 0.75 }}
			>
				<span className="ant-input-prefix">
					<span role="img" aria-label="user" className="anticon anticon-user site-form-item-icon">
						<SearchIcon width="1em" height="1em" />
					</span>
				</span>
				<input
					autocomplete="off"
					id="search"
					style={{ padding: 5 }}
					placeholder="Search"
					type="text"
					className="ant-input circular f-w:100 p-l:.1 search-left-panel"
					value={value}
					onChange={onChange}
				/>
			</span>
		</div>
	);
};

function mapStateToProps() {
	//return { value: allRooms.value };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ search }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
