export const LOAD_USER_LIST = 'LOAD_USER_LIST';
export const RENDER_USERS = 'RENDER_USERS';
export const USER = 'RENDER_USERS';
export const PIC_UPDATED = 'PIC_UPDATED';
export const USER_STATUS_UPDATE = 'USER_STATUS_UPDATE';
export const USER_SOCKET_STATUS_UPDATE = 'USER_SOCKET_STATUS_UPDATE';
export const USER_CALL_STATUS_UPDATE = 'USER_CALL_STATUS_UPDATE';
export const CALL_CONNECTING_STATUS = 'CALL_CONNECTING_STATUS';
export const EMIT_CALL_CONNECTING_STATUS = 'EMIT_CALL_CONNECTING_STATUS';
export const USER_NAME_CHANGE = 'USER_NAME_CHANGE';
export const ADD_NEW_PEER = 'ADD_NEW_PEER';
export const ROOM_JOINED_SOUND_ON = 'ROOM_JOINED_SOUND_ON';
export const ROOM_JOINED_SOUND_OFF = 'ROOM_JOINED_SOUND_OFF';

export function loadUserList() {
	return {
		type: LOAD_USER_LIST
	};
}
