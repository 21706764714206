export const JOIN_CALL = 'JOIN_CALL';
export const NEW_CALL_STARTED = 'NEW_CALL_STARTED';
export const LEAVE_CALL = 'LEAVE_CALL';
export const SEND_CALL_INVITE = 'SEND_CALL_INVITE';
export const CALL_INVITE = 'CALL_INVITE';
export const INCOMING_CALL_INVITE = 'INCOMING_CALL_INVITE';
export const INCOMING_CALL = 'INCOMING_CALL';
export const END_CALL = 'END_CALL';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export const DISCONNECT_LAST_USER = 'DISCONNECT_LAST_USER';
export const SET_AUDIO_ONLY_IN_PROGRESS = 'SET_AUDIO_ONLY_IN_PROGRESS';
export const SET_AUDIO_ONLY_STATE = 'SET_AUDIO_ONLY_STATE';
export const SET_AUDIO_MUTED_STATE = 'SET_AUDIO_MUTED_STATE';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';
export const ADD_PEER = 'ADD_PEER';
export const SET_PRODUCER_PAUSED = 'SET_PRODUCER_PAUSED';
export const SET_PRODUCER_RESUMED = 'SET_PRODUCER_RESUMED';
export const SET_WEBCAM_IN_PROGRESS = 'SET_WEBCAM_IN_PROGRESS';
export const ADD_PRODUCER = 'ADD_PRODUCER';
export const REMOVE_PRODUCER = 'REMOVE_PRODUCER';
export const SET_SHARE_IN_PROGRESS = 'SET_SHARE_IN_PROGRESS';
export const SET_CONSUMER_RESUMED = 'SET_CONSUMER_RESUMED';
export const SET_CONSUMER_PAUSED = 'SET_CONSUMER_PAUSED';
export const SET_CONSUMER_PREFERRED_LAYERS = 'SET_CONSUMER_PREFERRED_LAYERS';
export const ADD_DATA_PRODUCER = 'ADD_DATA_PRODUCER';
export const SET_MEDIA_CAPABILITIES = 'SET_MEDIA_CAPABILITIES';

// Saga
export const SET_CALL_ROOM_STATE = 'SET_CALL_ROOM_STATE';
export const SET_DISABLE_AUDIO_ONLY_STATE = 'SET_DISABLE_AUDIO_ONLY_STATE';
export const SET_ENABLE_AUDIO_ONLY_STATE = 'SET_ENABLE_AUDIO_ONLY_STATE';
export const SET_MUTE_AUDIO_ONLY_STATE = 'SET_MUTE_AUDIO_ONLY_STATE';
export const SET_UNMUTE_AUDIO_ONLY_STATE = 'SET_UNMUTE_AUDIO_ONLY_STATE';
export const START_NEW_CALL = 'START_NEW_CALL';
export const SET_ENABLE_MIC = 'SET_ENABLE_MIC';
export const SET_DISABLE_MIC = 'SET_DISABLE_MIC';
export const SET_MUTE_MIC = 'SET_MUTE_MIC';
export const SET_UNMUTE_MIC = 'SET_UNMUTE_MIC';
export const SET_ENABLE_CALL_WEBCAM = 'SET_ENABLE_CALL_WEBCAM';
export const SET_DISABLE_CALL_WEBCAM = 'SET_DISABLE_CALL_WEBCAM';
export const SET_ENABLE_CALL_SCREEN_SHARE = 'SET_ENABLE_CALL_SCREEN_SHARE';
export const SET_DISABLE_CALL_SCREEN_SHARE = 'SET_DISABLE_CALL_SCREEN_SHARE';
export const SET_CONSUMER_LAYER = 'SET_CONSUMER_LAYER';
export const SEND_MOUSE_EVENTS = 'SEND_MOUSE_EVENTS';
export const SET_RESET_VIDEO_ROOM_STATES = 'SET_RESET_VIDEO_ROOM_STATES';
export const SET_MOUSE_POINTER_ENABLED = 'SET_MOUSE_POINTER_ENABLED';
export const SET_MOUSE_POINTER_DISABLED = 'SET_MOUSE_POINTER_DISABLED';

//new actions
export const INITIATE_CALL_REQUEST_ROOM = 'INITIATE_CALL_REQUEST_ROOM';
export const CALL_ROOM_INFO = 'CALL_ROOM_INFO';
export const PEER_CALL_ROOM_INFO = "PEER_CALL_ROOM_INFO"
export const ATTEMPTING_CALL = 'ATTEMPTING_CALL';
export const PEER_ATTEMPTING_CALL = 'PEER_ATTEMPTING_CALL';
export const SEND_ATTEMPTING_CALL = 'SEND_ATTEMPTING_CALL';
export const PEER_INVITE_REQUEST = 'PEER_INVITE_REQUEST';
export const SEND_PEER_INVITE_REQUEST = 'SEND_PEER_INVITE_REQUEST';
export const PEER_INVITE_REQUEST_ACCEPTED = 'PEER_INVITE_REQUEST_ACCEPTED';
export const INVITE_REQUEST = 'INVITE_REQUEST';
export const ACCEPT_INVITE_REQUEST = 'ACCEPT_INVITE_REQUEST';
export const PEER_CALL_JOINED = 'PEER_CALL_JOINED';
export const PEER_CALL_LEFT = 'PEER_CALL_LEFT';
export const SEND_CALL_END = 'SEND_CALL_END';
export const CALL_ENDED = 'CALL_ENDED';
