import React from 'react';

function InfoIcon(props) {
	return (
		<svg style={{ ...props }} viewBox="0 0 426.667 426.667" id={props.id ? props.id : 'fillSvg'}>
			<g>
				<g>
					<path
						d="M213.333,0C95.467,0,0,95.467,0,213.333s95.467,213.333,213.333,213.333S426.667,331.2,426.667,213.333S331.2,0,213.333,0
			z M234.667,320H192V192h42.667V320z M234.667,149.333H192v-42.667h42.667V149.333z"
					/>
				</g>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
}

export default InfoIcon;
