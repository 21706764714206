import React from 'react';
import CrossSvg from '../../images/cross.svg';
import { Dropdown } from 'antd';
import dummy from '../../DummyData.json';
import { useState } from 'react';
import { Button } from 'antd';

function CreateNewGroup({ setmodal }) {
	const [users, setusers] = useState([]);

	function selectedUser(user) {
		let allUsers = users;
		allUsers.push(user);
		setusers([...allUsers]);
	}

	function removeUser(i) {
		let allUsers = users;
		allUsers.splice(i, 1);
		setusers([...allUsers]);
	}

	return (
		<div>
			<div className="m-b:1">
				<div className="t-a:l f-w:100 sub-title2 f:.8">Group name</div>
				<div className="p-t:.2 circular">
					<span
						class="ant-input-affix-wrapper search-box-left-panel"
						style={{ borderRadius: 6, padding: '2px 12px ' }}
					>
						<input
							style={{ padding: 5 }}
							placeholder="Name"
							type="text"
							className="ant-input circular f-w:100 p-l:.1 search-left-panel"
						/>
					</span>
				</div>
			</div>

			{users && users.length ? (
				<div className="t-a:l  m-b:.2">
					{users.map((user, i) => {
						return (
							<div className="d:i-b m-r:.5 sub-title f:.7 selected-user-drop p:.1 p-l:.7 p-r:.5">
								<div className="d:i-b m-r:.5 v-a:m">{user} </div>
								<div
									onClick={() => removeUser(i)}
									className="cross-icon cross-icon-small  d:i-b"
									style={{ backgroundImage: `url(${CrossSvg})` }}
								/>
							</div>
						);
					})}
				</div>
			) : null}

			<div className="t-a:l f-w:100 sub-title2 f:.8">Members</div>
			<Dropdown
				overlay={
					<UserList
						selectedUser={(user) => selectedUser(user)}
						allUsers={Object.keys(dummy.users).filter((user) => (users.includes(user) ? false : true))}
					/>
				}
				trigger={['click']}
			>
				<div className="p-t:.2 circular">
					<span
						class="ant-input-affix-wrapper search-box-left-panel"
						style={{ borderRadius: 6, padding: '2px 12px ' }}
					>
						<input
							style={{ padding: 5 }}
							placeholder="Members Name"
							type="text"
							className="ant-input circular f-w:100 p-l:.1 search-left-panel"
							value=""
						/>
					</span>
				</div>
			</Dropdown>

			<div className="t-a:r m-t:2">
				<Button type="primary" className="circular create-btn f-w:100 sub-title" size="middle">
					Create
				</Button>
			</div>
		</div>
	);
}

const UserList = function ({ selectedUser, allUsers }) {
	return (
		<div className="userlist-dropdown  p:.5">
			{allUsers.length
				? allUsers.map((user, i) => {
					return (
						<div
							onClick={() => selectedUser(user)}
							className="p:.1 p-l:.1 f:.8 user-item-drop m-l:.1 m-r:.1"
							id={i > 0 && 'user-item-drop-border'}
						>
							{user}
						</div>
					);
				})
				: null}
		</div>
	);
};

export default CreateNewGroup;
