import randomString from 'random-string';
import * as stateActions from '../actions/state.actions';

// This returns a redux-thunk action (a function).
export const notify = ({ type = 'info', text, title, timeout }) => {
	if (!timeout) {
		switch (type) {
			case 'info':
				timeout = 3000;
				break;
			case 'error':
				timeout = 5000;
				break;
		}
	}

	const notification = {
		id: randomString({ length: 6 }).toLowerCase(),
		type,
		title,
		text,
		timeout
	};
	if (window?.store?.dispatch) {
		window.store.dispatch(stateActions.addNotification(notification));
		const timeOut = setTimeout(() => {
			window.store.dispatch(stateActions.removeNotification(notification.id));
			clearTimeout(timeOut)
		}, timeout);
	}
};
