const VIDEO_CONSTRAINS = {
	qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
	vga: { width: { ideal: 640 }, height: { ideal: 480 } },
	hd: { width: { ideal: 1280 }, height: { ideal: 720 } }
};

// Used for simulcast webcam video.
const WEBCAM_SIMULCAST_ENCODINGS = [
	{ scaleResolutionDownBy: 4, maxBitrate: 500000 },
	{ scaleResolutionDownBy: 2, maxBitrate: 1000000 },
	{ scaleResolutionDownBy: 1, maxBitrate: 5000000 }
];

// Used for VP9 webcam video.
const WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: 'S3T3_KEY' }];

async function enableWebcam() {
	this.logger.debug('enableWebcam()');
	if (this?._webcamProducer || this?._webcamProducer?.closed) return;
	// else if (this._shareProducer) await this.disableShare();

	if (!this._mediasoupDevice.canProduce('video')) {
		this.logger.error('enableWebcam() | cannot produce video');
		throw new Error('enableWebcam() | cannot produce video');
	}

	let track;
	let device;

	try {
		if (!this._externalVideo) {
			await this.updateWebcams();
			device = this._webcam.device;

			const { resolution } = this._webcam;

			if (!device) throw new Error('no webcam devices');

			this.logger.debug('enableWebcam() | calling getUserMedia()');

			const stream = await navigator.mediaDevices.getUserMedia({
				video: {
					deviceId: { ideal: device.deviceId },
					...VIDEO_CONSTRAINS[resolution]
				}
			});

			track = stream.getVideoTracks()[0];

		} else {
			device = { label: 'external video' };

			const stream = await this._getExternalVideoStream();

			track = await stream.getVideoTracks()[0].clone();
		}

		let encodings;
		let codec;
		const codecOptions = {
			videoGoogleStartBitrate: 1000
		};
		if (this._forceH264) {
			codec = await this._mediasoupDevice.rtpCapabilities.codecs.find(
				(c) => c.mimeType.toLowerCase() === 'video/h264'
			);

			if (!codec) {
				throw new Error('desired H264 codec+configuration is not supported');
			}
		} else if (this._forceVP9) {
			codec = await this._mediasoupDevice.rtpCapabilities.codecs.find(
				(c) => c.mimeType.toLowerCase() === 'video/vp9'
			);

			if (!codec) {
				throw new Error('desired VP9 codec+configuration is not supported');
			}
		}

		if (this._useSimulcast) {
			// If VP9 is the only available video codec then use SVC.
			const firstVideoCodec = await this._mediasoupDevice.rtpCapabilities.codecs.find((c) => c.kind === 'video');

			if ((this._forceVP9 && codec) || firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
				encodings = WEBCAM_KSVC_ENCODINGS;
			} else {
				encodings = WEBCAM_SIMULCAST_ENCODINGS;
			}
		}
		console.log(this, 'this enableWebcam before');
		this._webcamProducer = await this._sendTransport.produce({
			track,
			encodings,
			codecOptions,
			codec
		});

		this._webcamProducer.on('transportclose', () => {
			this._webcamProducer = null;
		});

		this._webcamProducer.on('trackended', async () => {
			this._webcamProducer = null;
			await this.disableWebcam().catch(() => { });
			throw new Error('Webcam disconnected!');
		});
	} catch (error) {
		this._webcamProducer = null;
		console.log(error, 'error inside');
		this.logger.error('enableWebcam() | failed:%o', error);
		if (track) track.stop();
		throw new Error(`Error enabling webcam: ${error}`);
	}
}

async function disableWebcam() {
	this.logger.debug('disableWebcam()');
	try {
		if (!this._webcamProducer) return;
		await this._protoo.request('closeProducer', { producerId: this._webcamProducer._id });
		await this._webcamProducer.close();
	} catch (error) {
		console.log(error, 'error inside');
		throw new Error(`Error closing server-side webcam Producer: ${error}`);
	}
}

async function changeWebcam() {
	this.logger.debug('changeWebcam()');
	try {
		await this.updateWebcams();

		const array = Array.from(this._webcams.keys());
		const len = array.length;
		const deviceId = this._webcam.device ? this._webcam.device.deviceId : undefined;
		let idx = array.indexOf(deviceId);

		if (idx < len - 1) idx++;
		else idx = 0;

		this._webcam.device = this._webcams.get(array[idx]);

		this.logger.debug('changeWebcam() | new selected webcam [device:%o]', this._webcam.device);

		// Reset video resolution to HD.
		this._webcam.resolution = 'hd';

		if (!this._webcam.device) throw new Error('no webcam devices');

		// Closing the current video track before asking for a new one (mobiles do not like
		// having both front/back cameras open at the same time).
		this._webcamProducer.track.stop();

		this.logger.debug('changeWebcam() | calling getUserMedia()');

		const stream = await navigator.mediaDevices.getUserMedia({
			video: {
				deviceId: { exact: this._webcam.device.deviceId },
				...VIDEO_CONSTRAINS[this._webcam.resolution]
			}
		});

		const track = stream.getVideoTracks()[0];

		await this._webcamProducer.replaceTrack({ track });

		// window.store.dispatch(stateActions.setProducerTrack(this._webcamProducer.id, track));
	} catch (error) {
		this.logger.error('changeWebcam() | failed: %o', error);

		throw new Error(`Could not change webcam: ${error}`);
	}
}

async function changeWebcamResolution() {
	this.logger.debug('changeWebcamResolution()');

	// window.store.dispatch(stateActions.setWebcamInProgress(true));

	try {
		switch (this._webcam.resolution) {
			case 'qvga':
				this._webcam.resolution = 'vga';
				break;
			case 'vga':
				this._webcam.resolution = 'hd';
				break;
			case 'hd':
				this._webcam.resolution = 'qvga';
				break;
			default:
				this._webcam.resolution = 'hd';
		}

		this.logger.debug('changeWebcamResolution() | calling getUserMedia()');

		const stream = await navigator.mediaDevices.getUserMedia({
			video: {
				deviceId: { exact: this._webcam.device.deviceId },
				...VIDEO_CONSTRAINS[this._webcam.resolution]
			}
		});

		const track = stream.getVideoTracks()[0];

		await this._webcamProducer.replaceTrack({ track });

		// window.store.dispatch(stateActions.setProducerTrack(this._webcamProducer.id, track));
	} catch (error) {
		this.logger.error('changeWebcamResolution() | failed: %o', error);

		throw new Error(`Could not change webcam resolution: ${error}`);
	}

	// window.store.dispatch(stateActions.setWebcamInProgress(false));
}

async function updateWebcams() {
	this.logger.debug('updateWebcams())');

	// Reset the list.
	this._webcams = new Map();

	this.logger.debug('updateWebcams()) | calling enumerateDevices()');

	try {
		const devices = await navigator.mediaDevices.enumerateDevices();


		for (const device of devices) {
			if (device.kind !== 'videoinput') continue;

			this._webcams.set(device.deviceId, device);
		}

		const array = Array.from(this._webcams.values());
		const len = array.length;
		const currentWebcamId = this._webcam.device ? this._webcam.device.deviceId : undefined;

		this.logger.debug('updateWebcams()) [webcams:%o]', array);

		if (len === 0) this._webcam.device = null;
		else if (!this._webcams.has(currentWebcamId)) this._webcam.device = array[0];
	} catch (error) {
		console.log(error)
	}
	// window.store.dispatch(stateActions.setCanChangeWebcam(this._webcams.size > 1));
}

export { enableWebcam, disableWebcam, changeWebcam, changeWebcamResolution, updateWebcams };
