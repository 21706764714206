import { all, call, put, takeEvery, takeLatest, fork, select } from 'redux-saga/effects';
import { INIT, INIT_FAILED } from '../actions/init.actions';
import { SYNC_START, startSync } from '../actions/sync.actions';
import {
	LOAD_ACTIVE_ROOMS_SUCCESS,
	LOAD_ACTIVE_ROOMS_FAILED,
	selectRoom,
	loadRoomMessage,
	REFERESH_UNREAD_NOTIFICATION_COUNT,
	FETCH_ROOMS_NETWORK
} from '../actions/room.actions';
import { RENDER_USERS } from '../actions/users.actions';

import { getUsers, getActiveRooms, logout } from '../helpers/api';
import { get } from 'lodash';
import { TRY_LOGOUT } from '../actions/user.actions';
import { START_CALL_TIMER, START_SNAP_TIMER } from '../actions/timer.actions';

export function* init() {
	try {

		const teamId = window.store.getState().me.teamId
		//load room data
		let rooms = yield call(getActiveRooms, teamId);
		let users = yield call(getUsers, teamId);
		if (users?.data?.length) {
			yield put({ type: RENDER_USERS, userList: users.data });
		}
		if (rooms?.data?.length) {
			yield put({
				type: LOAD_ACTIVE_ROOMS_SUCCESS,
				payload: { allRooms: rooms.data, from: 'INIT' }
			});
		}
		if (rooms?.data?.length) {
			const activeRoomId = localStorage.getItem('activeRoom') || get(rooms, `data[${0}]roomId`);
			const state = yield select();
			if (activeRoomId) {
				if (state.rooms.allRooms[activeRoomId].requiresHistory) {
					yield put(loadRoomMessage(activeRoomId));
				}
				yield put(selectRoom(activeRoomId));
			}
		}
		// yield put(startSync());

		// load users in store, non-blocking(*) load users only if localstorage doesnt have data @TO_DO
		yield put({ type: RENDER_USERS, userList: users.data });
		const initialUser = JSON.parse(localStorage.getItem('user'));
		let usersExists = false;
		if (initialUser) {
			users.data.map((user) => {
				if (user._id == initialUser._id) {
					usersExists = true;
					localStorage.setItem(
						'user',
						JSON.stringify({ ...initialUser, lastSnap: user.lastSnap, profilePic: user.profilePic })
					);
				}
			});
		}

		//log user out if user doesn't exit anymore
		if (!usersExists) {
			yield put({ type: TRY_LOGOUT });
		}
		//

		//tmp workaround for unread message count
		yield put({ type: REFERESH_UNREAD_NOTIFICATION_COUNT });

		//non-blocking(*) fetch messages only if localstorage doesnt have data @TO_DO
		// yield fork(fetchActiveRooms);

		//start snap timer and call timer
		yield put({ type: START_SNAP_TIMER });
		yield put({ type: START_CALL_TIMER });
	} catch (error) {
		console.log(error, 'error');
		yield put({ type: INIT_FAILED, payload: { message: error.message } });
	}
}

// export function* fetchActiveRooms() {
// 	// load active rooms in store
// 	let rooms = yield call(getActiveRooms);
// 	localStorage.setItem('allActiveRooms', JSON.stringify(rooms));
// 	yield put({
// 		type: LOAD_ACTIVE_ROOMS_SUCCESS,
// 		payload: { allRooms: rooms.data }
// 	});
// 	const activeRoomId = localStorage.getItem('activeRoom') || get(rooms, `data[${0}]roomId`);
// 	const state = yield select();
// 	if (activeRoomId) {
// 		if (state.rooms.allRooms[activeRoomId].requiresHistory) {
// 			yield put(loadRoomMessage(activeRoomId));
// 		}
// 		yield put(selectRoom(activeRoomId));
// 	}
// }
export function* fetchActiveRoomFromNetwork() {
	yield takeEvery(FETCH_ROOMS_NETWORK);
}

export function* listenInit() {
	yield takeLatest(INIT, init);
}

export default function* initSaga() {
	yield all([fork(listenInit)]);
}
