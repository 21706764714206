import Logger from '../../../../helpers/Logger';
import { notify } from '../../../../actions/request.actions'

const logger = new Logger('RoomClient_Protoo_Requests');


export default (roomClient, store) => {
	return async (request, accept, reject) => {
		const dispatch = store.dispatch;
		logger.debug('proto "request" event [method:%s, data:%o]', request.method, request.data);
		switch (request.method) {
			case 'newConsumer': {
				try {
					const { consumer, peerId } = await roomClient.handleNewConsumer(request, accept, reject);
					dispatch({
						type: 'ADD_CONSUMER',
						payload: { consumer, peerId }
					});
					dispatch({
						type: 'ADD_CONSUMER_ID',
						payload: { consumer, peerId }
					});
					accept();
				} catch (error) {
					debugger;
					console.log('Error in creating consumer: ', error);
					notify({
						type: 'error',
						text: `Error creating a Consumer: ${error}`
					})
				}
				break;
			}
			case 'newDataConsumer': {
				try {
					const { dataConsumer, peerId } = await roomClient.handleNewDataConsumer(request, accept, reject);
					dispatch({
						type: 'ADD_DATA_CONSUMER',
						payload: { dataConsumer, peerId }
					});
				} catch (error) {
					debugger;
					console.log('Error in creating consumer: ', error);
					notify({
						type: 'error',
						text: `Error creating a DataConsumer: ${error}`
					})
				}
				break;
			}
		}
	};
};
