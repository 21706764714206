import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from '../../helpers/videoRoom/appPropTypes';
import OtherPeer from './OtherPeer';
import MePeer from './MePeer';
import './Peer.scss';

import isElectron from 'is-electron';

let electron = null;

if (isElectron() && typeof window?.require === 'function') {
	electron = window.require('electron');
}

const Peers = ({ peers, activeSpeakerId, consumerShareScreensLength, meShareScreenLength }) => {
	return (
		<div data-component="Peers" className="peers-main-container">
			{peers?.length ? (
				<MePeer
					activeSpeakerId={activeSpeakerId}
					consumerShareScreensLength={consumerShareScreensLength}
					meShareScreenLength={meShareScreenLength}
				/>
			) : null}
			{peers.map((peer) => {
				return (
					<OtherPeer
						id={peer.id}
						activeSpeakerId={activeSpeakerId}
						consumerShareScreensLength={consumerShareScreensLength}
						meShareScreenLength={meShareScreenLength}
					/>
				);
			})}
		</div>
	);
};

Peers.propTypes = {
	peers: PropTypes.arrayOf(appPropTypes.Peer).isRequired,
	activeSpeakerId: PropTypes.string
};

const mapStateToProps = (state) => {
	const peersArray = Object.keys(state.call.peers).length > 0 ? Object.values(state.call.peers) : [];

	const consumersArray = Object.values(state.consumers);
	const producersArray = Object.values(state.producers);

	const consumerShareScreensLength = consumersArray
		? consumersArray.filter((consumer) => consumer.type === 'share').length
		: 0;
	// meis also considered as peer
	const meShareScreenLength = producersArray && producersArray.find((producer) => producer.type === 'share') ? 2 : 1;

	return {
		me: state.me,
		meShareScreenLength,
		consumerShareScreensLength,
		peers: peersArray,
		activeSpeakerId: state.room.activeSpeakerId
	};
};

const PeersContainer = connect(mapStateToProps, null)(Peers);

export default PeersContainer;
