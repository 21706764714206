import { all, call, put, takeEvery, takeLatest, fork } from 'redux-saga/effects';
import { useHistory } from "react-router-dom";
import {
	LOGGED_IN,
	LOGGED_OUT,
	TRY_LOGIN,
	TRY_LOGOUT,
	LOGIN_FAILED,
	LOGOUT_FAILED,
	USER_LOGIN_EVENT
} from '../actions/user.actions';
import { INIT, RESET_ALL_STATES } from '../actions/init.actions';
import { addToast } from '../actions/toast.actions';
import { login, logout, updateToken } from '../helpers/api';
import { createNotification } from '../helpers/utils';

export function* tryLogin(action) {
	try {
		const { userValue, password, cb } = action.payload;
		const response = yield call(login, { userValue, password });
		console.log(response)
		if (response && response?.success) {
			yield put({
				type: RESET_ALL_STATES
			});
			localStorage.setItem('user', JSON.stringify(response.data));
			updateToken(response.data.token);
			yield put({ type: LOGGED_IN, payload: { user: response.data } });
			console.log(action.payload.cb)
			cb && cb()
			//yield put({ type: INIT });
			// yield fork(userLoginEvent);
			// createNotification({ type: 'success', title: 'Logged in' });
		} else {
			throw new Error(response?.message || 'Error while login!')
		}
	} catch (error) {
		yield put({ type: LOGIN_FAILED, payload: { message: error.message } });
		console.log(error.message)
		createNotification({ type: 'danger', title: 'Login failed', message: error.message });
	}
}

function* userLoginEvent() {
	yield put({ type: USER_LOGIN_EVENT, payload: { success: true } });
}

export function* tryLogout() {
	try {
		yield call(logout);
		yield put({ type: LOGGED_OUT });
	} catch (error) {
		yield put({ type: LOGOUT_FAILED });
	}
}

export function* listenLogin() {
	yield takeLatest(TRY_LOGIN, tryLogin);
}
export function* listenLogout() {
	yield takeLatest(TRY_LOGOUT, tryLogout);
}

export default function* authSaga() {
	yield all([fork(listenLogin), fork(listenLogout)]);
	try {
		const initialUser = JSON.parse(localStorage.getItem('user'));
		if (initialUser) {
			updateToken(initialUser.token);
			yield put({ type: LOGGED_IN, payload: { user: initialUser } });
			// yield put({ type: INIT });
		} else {
		}
	} catch (error) { }
}
