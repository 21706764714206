import { SET_CALL_ROOM_STATE, END_CALL } from '../actions/call.actions';
import { RESET_ALL_STATES } from '../actions/init.actions'

const initialState = {};

const dataConsumers = (state = initialState, action) => {
	switch (action.type) {
		case END_CALL:
			return initialState;
		case SET_CALL_ROOM_STATE: {
			const roomState = action.payload.state;

			if (roomState === 'closed') return {};
			else return state;
		}

		case 'ADD_DATA_CONSUMER': {
			const { dataConsumer } = action.payload;

			return { ...state, [dataConsumer.id]: dataConsumer };
		}

		case 'REMOVE_DATA_CONSUMER': {
			const { dataConsumerId } = action.payload;
			const newState = { ...state };

			delete newState[dataConsumerId];

			return newState;
		}
		case RESET_ALL_STATES: {
			return initialState
		}
		default: {
			return state;
		}
	}
};

export default dataConsumers;
