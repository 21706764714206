import { combineReducers } from 'redux';
import users from './users.reducer';
import rooms from './rooms.reducer';
import call from './call.reducer';
import timer from './timer.reducer';

import room from './room';
import me from './me';
import producers from './producers';
import dataProducers from './dataProducers';
import consumers from './consumers';
import dataConsumers from './dataConsumers';
import notifications from './notifications';

export default combineReducers({
	users,
	rooms,
	call,
	timer,
	room,
	me,
	producers,
	dataProducers,
	consumers,
	dataConsumers,
	notifications
});
