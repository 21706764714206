import { ADD_DATA_PRODUCER } from '../../../../actions/call.actions';
import { notify } from '../../../../actions/request.actions';

async function sendMouseCoords({ roomClient, store, action }) {
	try {
		const obj = action.payload.event;
		await roomClient.sendMouseCoords(obj);
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
}

async function sendMousePointerEnabledDisabled({ roomClient, store, action }) {
	try {
		await roomClient.sendMousePointerEnabledDisabled(action.payload);
	} catch (error) {
		notify({
			type: 'error',
			text: error.message
		});
	}
}

export { sendMouseCoords, sendMousePointerEnabledDisabled };
