import React, { useEffect, useState } from 'react'
import {
    useLocation,
    Redirect
} from "react-router-dom";

import { isTeamTokenExists } from '../../helpers/api'

function Home(props) {

    const [redirectData, setRedirectData] = useState(null);
    const [isLoaded, setIsLaoded] = useState(false);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let invitedId = query.get('invitedId')

    const handleIfTokenExists = async () => {
        if (invitedId) {
            const res = await isTeamTokenExists(invitedId);
            //Redirect User To the Rigister Page with team token
            if (res?.success) {
                setRedirectData(res.team)
            }
            setIsLaoded(true)
        } else {
            setIsLaoded(true)

        }
    }


    useEffect(() => {
        //Check if invited id is valid, and then register user to the team else show error.
        handleIfTokenExists()

        return () => {
            setIsLaoded(false)
        }

    }, [])


    return (
        <div>
            {redirectData ?
                invitedId ?
                    <Redirect
                        to={{
                            pathname: "/signup",
                            search: `?invitedId=${invitedId}`,
                            state: { redirectData }
                        }}
                    /> : <Redirect
                        to={{
                            pathname: "/app",
                            state: { redirectData }
                        }}
                    />
                : isLoaded ? <Redirect
                    to={{
                        pathname: "/app",
                        state: { redirectData }
                    }}
                /> : null}
            {invitedId ? <div>verifing invited link, please wait, or try opening the link again</div> : 'Redirecting...'}
            {invitedId}
        </div>
    )
}

Home.propTypes = {}

export default Home
