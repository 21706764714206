import React from 'react';
import PanelHeader from './PanelHeader.jsx';
import ChatHeads from './ChatHeads.jsx';
import { connect } from 'react-redux';
import { KNOCK_USER, UPDATE_PIC, MOUSE_EVENT } from '../../actions/user.actions';
import { START_NEW_CALL, END_CALL, CALL_INVITE } from '../../actions/call.actions';
import { getAllOnetoOneRooms, getOnlineUsersInSelectedRoom } from '../../selectors/room.selector';
import RoomJoinedAudio from './RoomEnter.mp3';
import './RightPanel.scss';
import VideoCall from '../VideoCall/index.js';
import {
	ADD_CALL_TO_TIMER,
	REMOVE_CALL_FROM_TIMER,
	RESET_SNAP_TIMER,
	START_SNAP_TIMER
} from '../../actions/timer.actions.js';
function RightPanel({
	allUsers,
	allRooms,
	selectedRoom,
	call,
	me,
	updateUserPic,
	startNewCall,
	allOnetoOneRooms,
	endCall,
	roomJoined,
	inviteToCall,
	addCallToTimer,
	timer,
	resetSnapTimer,
	knockUser,
	onlineMembers,
	sendMouseEvent
}) {
	return (
		<div className="center-panel-card">
			{call.roomId ? (
				<div>
					<div id="mediasoup-demo-app-container">
						<VideoCall user={me} allUsers={allUsers} endCall={endCall} sendMouseEvent={sendMouseEvent} />
					</div>
					{roomJoined && <audio id="RoomJoinedAudio" src={RoomJoinedAudio} autoPlay></audio>}
					<div id="mediasoup-demo-app-media-query-detector"></div>
				</div>
			) : (
				[
					<PanelHeader onlineMembers={onlineMembers} allUsers={allUsers} />,
					<ChatHeads
						knockUser={knockUser}
						addCallToTimer={addCallToTimer}
						resetSnapTimer={resetSnapTimer}
						timer={timer}
						inviteToCall={inviteToCall}
						allUsers={allUsers}
						allRooms={allRooms}
						selectedRoom={selectedRoom}
						call={call}
						user={me}
						updateUserPic={updateUserPic}
						startNewCall={startNewCall}
						allOnetoOneRooms={allOnetoOneRooms}
						onlineMembers={onlineMembers}
					/>
				]
			)}
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		roomJoined: state.rooms.roomJoined,
		allUsers: (() => {
			let filteredUsers = {};
			if (state.rooms.selectedRoom) {
				state.rooms.selectedRoom.members.forEach((member) => {
					if (member) {
						filteredUsers[member.userId] = state.users.allUsers[member.userId];
					}
				});
			}
			return filteredUsers;
		})(),
		allRooms: state.rooms.allRooms,
		selectedRoom: state.rooms.selectedRoom,
		call: state.call,
		me: state.me,
		timer: state.timer,
		allOnetoOneRooms: getAllOnetoOneRooms(state),
		onlineMembers: getOnlineUsersInSelectedRoom(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		knockUser: (payload) => dispatch({ type: KNOCK_USER, payload }),
		addCallToTimer: (payload) => dispatch({ type: ADD_CALL_TO_TIMER, payload }),
		inviteToCall: (payload) => dispatch({ type: CALL_INVITE, payload }),
		startNewCall: (payload) => dispatch({ type: START_NEW_CALL, payload }),
		updateUserPic: (payload) => dispatch({ type: UPDATE_PIC, payload }),
		sendMouseEvent: (payload) => dispatch({ type: MOUSE_EVENT, payload }),
		resetSnapTimer: () => dispatch({ type: RESET_SNAP_TIMER }),
		endCall: () => {
			dispatch({ type: END_CALL });
			// dispatch({ type: REMOVE_CALL_FROM_TIMER });
		}
	};
};
RightPanel = connect(mapStateToProps, mapDispatchToProps)(RightPanel);
export default RightPanel;
