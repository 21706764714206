/**
 * @license
 * Copyright 2017 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

/**
 * Inputs of size above this threshold will be parallelized by calling multiple
 * shader programs.
 */
import {nearestDivisor} from '../util';

export const PARALLELIZE_THRESHOLD = 30;

export interface ReduceInfo {
  windowSize: number;
  batchSize: number;
  inSize: number;
  outSize: number;
}

export function computeOptimalWindowSize(inSize: number): number {
  if (inSize <= PARALLELIZE_THRESHOLD) {
    return inSize;
  }
  return nearestDivisor(inSize, Math.floor(Math.sqrt(inSize)));
}
