import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Modal,
} from 'antd';
import {
    useLocation,
    Link,
    Redirect
} from "react-router-dom";
import '../../css/login.scss';
import illustration from '../../images/illustrations.svg';
import logo from '../../images/logo.svg';
import Toasts from '../toast/Toasts.js';
import { createNotification } from '../../helpers/utils';
import { signup } from '../../helpers/api'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};


let Signup = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [values, setValues] = useState(null);
    const [isTeamAdding, setIsTeamAdding] = useState(false)
    const [teamName, setTeamName] = useState(null)
    const [redirect, setIsRedirect] = useState(false)

    const toggleSetTeamAdding = () => {
        setIsTeamAdding(!isTeamAdding)
    }

    const onFinish = async (values) => {
        setValues(values)
        toggleSetTeamAdding()
    };

    const handleSubmit = async () => {
        form
            .validateFields()
            .then(async (values) => {
                if (values) {
                    const vals = { ...values }
                    delete vals.confirm
                    vals.teamName = teamName || null;
                    console.log('Received values of form: ', vals);
                    // check if user invited, add teamId
                    if (state?.redirectData?.id && state?.redirectData?.name) {
                        vals.invitedToken = state?.redirectData?.token;
                        vals.teamName = state?.redirectData?.name
                    }
                    if (vals.teamName) {
                        await signup(vals)
                    }
                    createNotification({ type: 'info', title: 'Signup Complete', message: 'Please velidate your email!' });
                    setIsRedirect(true)
                } else {
                    createNotification({ type: 'danger', title: 'Signup failed', message: 'Please enter all the fields!' });
                }
            })
            .catch((error) => {
                console.log(error, 'error')
                createNotification({ type: 'danger', title: 'Signup failed', message: 'Email already in use!' });
            });
    }


    const handleChange = (e) => {
        setTeamName(e.target.value)
    }


    return (
        <>
            {
                redirect ? <Redirect
                    to={{
                        pathname: "/login",
                        search: `?verified=false`,
                    }}
                /> : null
            }
            {
                window?.store?.getState()?.me?.isLoggedIn ? <Redirect
                    to={{
                        pathname: "/app",
                    }}
                /> : null
            }
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                scrollToFirstError
            >
                <div className="circular login-box">
                    <span style={{ position: 'absolute', top: 20, right: 15, width: 'auto' }}>
                        <Toasts />
                    </span>
                    <div className="illustration-bg" style={{ backgroundImage: `url(${illustration})` }}>
                        <div className="login-right">
                            <div>
                                <div className="f:4 p:.7 p-l:.9">
                                    <img src={logo} width="85px" />
                                </div>
                                <div className=" p:.5 p-l:1 p-r:.8 p-b:1 m-t:.5">

                                    <div className="m-b:1">
                                        <div className="p-t:.2 circular">
                                            <Form.Item
                                                name="name"
                                                className='max-width'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Name',
                                                    },
                                                ]}
                                            >
                                                <Input className="ant-input login-input circular f-w:100 p-l:.1" placeholder="Name" style={{ backgroundColor: 'red' }} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="m-b:1">
                                        <div className="p-t:.2 circular">
                                            <Form.Item
                                                name="email"
                                                className='max-width'
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail!',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input your E-mail!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="E-mail" className="ant-input login-input circular f-w:100 p-l:.1" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="m-b:1">
                                        <div className="p-t:.2 circular">
                                            <Form.Item
                                                name="password"
                                                className='max-width'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password placeholder="Password" className="ant-input login-input circular f-w:100 p-l:.1" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Form.Item
                                        name="confirm"
                                        className='max-width'
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder="Confirm Password" className="ant-input login-input circular f-w:100 p-l:.1" />
                                    </Form.Item>

                                    <Modal
                                        title="Add Team"
                                        centered
                                        visible={isTeamAdding}
                                        onCancel={toggleSetTeamAdding}
                                        width={1000}
                                        footer={[
                                            <Button
                                                type="primary"
                                                className="circular login-btn f-w:400 sub-title"
                                                onClick={handleSubmit}
                                                style={{
                                                    background: '#fff',
                                                    color: '#2f3135'
                                                }}
                                            >
                                                Signup
                                            </Button>
                                        ]}
                                    >
                                        <Form.Item
                                            name="teamName"
                                            className='team_input'
                                            initialValue={state?.redirectData?.id && state?.redirectData?.name ? state?.redirectData?.name : ''}
                                        >
                                            <Input disabled={state?.redirectData?.id} placeholder="Team Name" onChange={handleChange} bordered className="ant-input login-input input_dark_color circular f-w:100 p-l:.1" style={{ maxWidth: '100%', width: '100%', borderRadius: '5px', color: '#2f3135' }} />
                                        </Form.Item>
                                    </Modal>

                                    <div className="m-t:5 t-a:l">
                                        {!state?.redirectData?.id && !state?.redirectData?.name ? <Button
                                            type="primary"
                                            className="circular login-btn f-w:400 sub-title"
                                            htmlType="submit"
                                            onClick={onFinish}
                                        >
                                            Next
                                        </Button> :
                                            <Button
                                                type="primary"
                                                className="circular login-btn f-w:400 sub-title"
                                                onClick={handleSubmit}
                                                style={{
                                                    background: '#fff',
                                                    color: '#2f3135'
                                                }}
                                            >
                                                Signup
                                            </Button>}
                                        <Link to={'/login'} ><div className="forget-password m-l:4 cursor">Login</div></Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form ></>
    );
};

export default Signup;
