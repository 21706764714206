import React from 'react'
import Container from './Container'
import Dot from './Dot'
import './Background.scss'

function Background({ children, hasDots = true }) {
    return (
        <div className='background'>{hasDots ?
            <>
                <Dot topRight={true} color="#C3C3C3" />
                <Dot topLeft={true} color="#C3C3C3" />
                <Dot bottomLeft={true} color="#4C54C7" />
                <Dot bottomRight={true} color="#4C54C7" />
            </>
            : null}{children}</div>
    )
}

Background.propTypes = {}

export default Background
