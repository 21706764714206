import { Spin } from 'antd';
import Avatar from 'react-avatar';
import React, { useState } from 'react';
import { deleteMessage } from '../../../helpers/api';
import { createNotification } from '../../../helpers/utils';
import './DeleteMessage.scss';
import { renderAttachments } from '../../CenterPanel/ChatMessage';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { ATTACHMENT_TYPE } from '../../../helpers/commonConsts';
import UserProfile from '../../HelperComponents/UserProfile';

export default function DeleteMessage({ message, updateModal, profilePic }) {
	const [loading, setLoading] = useState(false);

	async function deleteMsg(message) {
		try {
			let result = await deleteMessage(message._id);
			if (result.success) {
				createNotification({ message: 'Message deleted', type: 'success' });
				updateModal({ show: false, ModalBody: null });
			} else {
				throw new Error(result.error);
			}
		} catch (e) {
			createNotification({ title: 'Failed to delete the message', message: e.message, type: 'danger' });
		}
	}

	return (
		<div>
			<div className="t-a:l p-t:.4">
				<div className="m-t:1 m-l:3 circular f:4 truncate p-r:6 f-w:500">Delete message</div>
				<div className="m-t:.0 m-l:3 circular f-w:100 truncate p-r:6" style={{ marginTop: 2, fontSize: 14 }}>
					<span className="f-w:5x00">Are you sure you want to delete this message?</span>
				</div>
				<div className="m-t:5 m-l:3 f-w:100 m-r:3">
					<div
						className="m-t:0 p-t:.0 user-info chat-block p-t:.7 m-b:3"
						style={{ marginRight: 3, boxShadow: '-3px 1px 0px 3px #ffffff08', background: '#ffffff08' }}
					>
						<div className="m-r:.9 d:i-b v-a:t">
							{profilePic ? (
								<UserProfile styleProps={{ width: 35, height: 35 }} profilePic={profilePic} />
							) : (
								<Avatar
									size="35"
									textSizeRatio={2.2}
									round={true}
									name={message.fromName}
									className="circular"
								/>
							)}
						</div>
						<div class="v-a:t d:i-b  p-b:.9 false" style={{ width: 'calc(100% - 50px)' }}>
							<div class="f-w:400 circular">
								<span class="f:1">{message.fromName} </span>
								<span class="sub-title2 f-w:100 f:.8 m-l:.2">
									<span class="m-r:.2">·</span> Jul 26th, 11:08 am
								</span>
							</div>
							{message.attachments?.length
								? message.attachments
										.filter((file) => file.attachmentType != ATTACHMENT_TYPE.TEXT)
										.map((file) => {
											return renderAttachments(file);
										})
								: null}
							<div className="f-w:100 circular sub-title f:.9" style={{ marginBottom: '-1em' }}>
								<ReactMarkdown skipHtml={true} children={message.message.text} remarkPlugins={[gfm]} />
							</div>
						</div>
					</div>
				</div>

				<div
					className="t-a:l m-t:3 t-a:r p:2"
					style={{ borderRadius: '0px 0px 6px 6px', background: '#282d32' }}
				>
					<span
						type="text"
						class="circular link-btn f-w:400 sub-title cursor m-r:4 cancel-btn"
						onClick={() => !loading && updateModal({ show: false, ModalBody: null })}
					>
						Cancel
					</span>

					<button
						type="primary"
						class="ant-btn ant-btn-primary"
						id="upload-button"
						onClick={() => !loading && deleteMsg(message)}
					>
						{loading ? (
							<span>
								<Spin size="small" className="v-a:m m-r:.2" style={{ marginTop: 3, marginRight: 10 }} />
								<span className="v-a:m">Deleting</span>
							</span>
						) : (
							'Delete'
						)}
					</button>
				</div>
			</div>
		</div>
	);
}
