import React from 'react';

function CrossIcon(props) {
	return (
		<svg
			style={{ ...props }}
			viewBox="0 0 413.348 413.348"
			id={props.id}
			enable-background="new 0 0 413.348 413.348"
			height="512"
			width="512"
		>
			<path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
		</svg>
	);
}

export default CrossIcon;
