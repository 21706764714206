import React, { useEffect, useRef, useState } from 'react';
import AudioCall from '../../images/AudioCall.svg';
import VideoIcon from '../../images/video.svg';
import Webcam from 'webcam-easy';
import SnapAudio from './snap.wav';
import KnockIcon from '../../images/knock.svg';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { JOIN_CALL, INCOMING_CALL_INVITE } from '../../actions/call.actions';
import { createNotification, sleep } from '../../helpers/utils';
import { STATUS, SOCKET_STATUS } from '../../helpers/commonConsts';
import UserStatusIcon from '../LeftPanel/Rooms/UserStatusIcon';
import { pixelImage, blurImage } from '../../helpers/ImageProcessing/index';

function getCallColor(call, user) {
	if (user.activeCallId) {
		//this would be other active calls in the app
		return '#' + user.activeCallId.split('-')[0];
	}
	if (call.roomId) {
		//this would be the current he may have joined
		return '#' + call.roomId.split('-')[0];
	}
	return '#009687f5';
}

const ChatHeads = ({
	allUsers,
	user,
	updateUserPic,
	call,
	startNewCall,
	allOnetoOneRooms,
	inviteToCall,
	addCallToTimer,
	timer,
	resetSnapTimer,
	knockUser,
	onlineMembers
}) => {
	const webCam = useRef(null);
	const canvas = useRef(null);
	const [camera, setCamera] = useState(null);
	const snapSound = useRef(null);
	const [cameraReady, setCameraReady] = useState(false);
	const [cameraInAction, setCameraInAction] = useState(false);
	const [cameraError, setCameraError] = useState('');
	const chatHead = (member = {}, isCurrentUser = false) => {
		function connectCall() {
			if (call.roomId && call.roomId.length && call.roomId.includes('-')) {
				inviteToCall({
					videoRoomId: call.roomId,
					to: allOnetoOneRooms[member._id] && allOnetoOneRooms[member._id]._id,
					user: user,
					invitedUser: member,
					teamId: user.teamId
				});
			} else if (member.activeCallId && member.activeCallId.length && member.activeCallId.includes('-')) {
				// window.store.dispatch({
				// 	type: JOIN_CALL,
				// 	payload: { roomId: member.activeCallId }
				// });
				window.store.dispatch({
					type: INCOMING_CALL_INVITE,
					payload: { videoRoomId: member.activeCallId, from: member._id }
				});
			} else {
				startNewCall({
					oneToOneRoomId: allOnetoOneRooms[member._id] && allOnetoOneRooms[member._id]._id,
					invitedUser: member,
					user: user,
					audioOnly: true
				});
			}
		}
		return (
			<div
				className="chat-head-box grid-element"
				style={{
					backgroundImage: `url(${member && member.lastSnap})`
				}}
				onClick={() => {
					isCurrentUser && getSnap();
				}}
			>
				{member.socketStatus == SOCKET_STATUS.CONNECTING || member.status == STATUS.AWAY ? (
					<div className="hover-overlay-block"></div>
				) : null}

				{!isCurrentUser &&
					member.status == STATUS.BUSY &&
					member.socketStatus != SOCKET_STATUS.CONNECTING &&
					allUsers[user?._id].status != 6 && [
						((<div className="hover-overlay"></div>),
						(
							<div className="buttons-overlay">
								<div
									title="Knock"
									className="icon-cont d:i-b m-r:.5"
									style={{ backgroundImage: `url(${KnockIcon})`, backgroundSize: '70%' }}
									onClick={() => {
										knockUser({ from: user._id, to: member._id });
									}}
								/>
							</div>
						))
					]}
				{!isCurrentUser &&
					!user.callConnecting &&
					member.socketStatus !== SOCKET_STATUS.CONNECTING &&
					member.status !== STATUS.AWAY &&
					member.status !== STATUS.BUSY &&
					allUsers[user?._id].status != 6 && [
						<div className="hover-overlay"></div>,

						<div className="buttons-overlay">
							<div
								className="icon-cont d:i-b m-r:.5"
								style={{ backgroundImage: `url(${AudioCall})` }}
								onClick={() => {
									connectCall();
								}}
							/>
							<div
								onClick={() => {
									connectCall();
								}}
								className="icon-cont icon-cont-video d:i-b m-r:.5"
								style={{ backgroundImage: `url(${VideoIcon})` }}
							/>
						</div>
					]}

				<div className="chat-head-name circular">
					<div className="p-b:.4">
						<div className="d:i-b p-l:1">
							<UserStatusIcon
								righPanel
								status={allUsers?.[member._id]?.status}
								/* When user log in socket is not initiated by then, hence current users socketStatus is disconnected
								So for current user we need to use socketStatus from client side manatained on client. Issue mainly occurs
								when first call for all users go to server. So using client side socket for current  */
								socketStatus={isCurrentUser ? user.socketStatus : allUsers?.[member._id]?.socketStatus}
								onCall={member.activeCallId ? true : false}
								callColor={member.activeCallId && `#${allUsers?.[member._id]?.color}`}
							/>
						</div>
						<div
							className={`v-a:m d:i-b m-l:.6 m-b:.4 p-r:.7 f-w:400 f:.9 circular ${
								!member.name && 'sub-title2'
							}`}
						>
							{member.name || 'N/A'}
						</div>
					</div>
					{member.activeCallId && !isCurrentUser && (
						<div
							style={{ width: '100%', height: '8px', background: `#${allUsers?.[member._id]?.color}` }}
						/>
					)}
				</div>
			</div>
		);
	};

	const getSnap = async () => {
		if (!cameraReady || !camera) {
			if (!call.roomId) {
				createNotification({
					title: 'Unable to access camera',
					type: 'danger',
					message: 'Try closing other apps that might be using the camera.'
				});
			}
			return;
		}
		try {
			if (!cameraInAction) {
				await camera.start();
				setCameraInAction(true);
				//wait for few seconds in order for camera hardware to start
				await sleep(3000);
			}
			const imgData = camera.snap();
			//sometimes the data from camera is empty so need to handle that. In that case data is data:,
			if (imgData.length > 10) {
				let pStae = JSON.parse(window.localStorage.getItem('settings'))?.photoEffect || 1;
				pStae === 2 && updateUserPic({ imgData }); //normal
				pStae === 3 && updateUserPic({ imgData: await blurImage(imgData) }); //for blur background
				pStae === 1 && updateUserPic({ imgData: await pixelImage(imgData) }); //for pixel image
			}
			setCameraError('');
		} catch (error) {
			if (!call.roomId) {
				createNotification({
					title: 'Camera error',
					type: 'danger',
					message: error.message + '. Try closing other apps that might be using the camera.'
				});
			}
			setCameraError(error.message);
		} finally {
			camera.stop();
			setCameraInAction(false);
			resetSnapTimer();
		}
	};

	useEffect(() => {
		(async () => {
			let c = new Webcam(webCam.current, 'user', canvas.current, snapSound.current);
			try {
				setCamera(c);
				await c.start();
				c.stop();
				setCameraReady(true);
			} catch (e) {
				c.stop();
				createNotification({
					title: 'Camera error',
					type: 'danger',
					message: e.message + '. Try closing other apps that might be using the camera.'
				});
				setCameraError(e.message);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			let snapTimer = 3,
				settings = JSON.parse(window.localStorage.getItem('settings'));
			if (settings?.snap) {
				snapTimer = Number(settings.snap);
			}
			if (timer.snap.minutes == snapTimer && !call.roomId && user.status !== STATUS.AWAY) {
				getSnap();
			}
		})();
	}, [timer.snap.minutes]);

	return (
		<div className="grid-container grid-container--fill">
			<div
				style={{
					height: 190,
					verticalAlign: 'middle',
					overflow: 'hidden',
					display: 'flex',
					position: 'relative'
				}}
			>
				<video
					style={{
						display: cameraInAction ? 'block' : 'none',
						width: 'auto',
						height: '100%',
						margin: 'auto'
					}}
					ref={webCam}
					id="webcam"
					autoPlay
					className="chat-head-box grid-element v-a:m"
					playsInline
				></video>
				{cameraInAction && (
					<div className="timer-container">
						<CountdownCircleTimer
							size={60}
							isPlaying
							duration={3}
							strokeWidth={7}
							colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]}
							onComplete={() => [true, 1000]}
						>
							{({ remainingTime }) => (
								<div className="timer circular" style={{ color: 'white' }}>
									{remainingTime}
								</div>
							)}
						</CountdownCircleTimer>
					</div>
				)}
				{!cameraInAction && chatHead(user, true)}
				<canvas style={{ display: 'none' }} ref={canvas} id="canvas" height={'720px'} width="1280px"></canvas>
				{!call.roomId && <audio ref={snapSound} id="snapSound" src={SnapAudio} preload="auto"></audio>}
			</div>
			{onlineMembers &&
				onlineMembers
					.filter((member) => {
						return member.userId !== user._id;
					})
					.map((member) => {
						return chatHead(allUsers[member.userId]);
					})}
		</div>
	);
};

export default ChatHeads;
