import React from 'react';

function AttachmentIcon(props) {
	return (
		<svg
			style={{ ...props }}
			id={props.id ? props.id : 'search-icon-left-panel'}
			enable-background="new 0 0 512 512"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="m226 512c-49.626 0-90-40.374-90-90v-302c0-66.168 53.832-120 120-120s120 53.832 120 120v302h-30v-302c0-49.626-40.374-90-90-90s-90 40.374-90 90v302c0 33.084 26.916 60 60 60s60-26.916 60-60v-242c0-16.542-13.458-30-30-30s-30 13.458-30 30v242h-30v-242c0-33.084 26.916-60 60-60s60 26.916 60 60v242c0 49.626-40.374 90-90 90z" />
		</svg>
	);
}

export default AttachmentIcon;
