import { Button, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LOAD_ACTIVE_ROOMS } from '../../actions/room.actions';
import { addMembers } from '../../helpers/api';
import { createNotification } from '../../helpers/utils';
import CrossSvg from '../../images/cross.svg';
import SelectInput from '../Common/SelectInput/SelectInput';
import './AddMemberToGroup.scss';
function AddMemberToGroup({ allUsers, user, groupName, members, selectedRoom, loadActiveRooms, ...props }) {
	const [users, setusers] = useState([]);
	const [alreadyInGroup, setAlreadyInGroup] = useState([]);
	const [roomName, setRoomName] = useState(groupName);
	const [allList, setAllList] = useState(null)
	const [selectedUsers, setSelectedUsers] = useState([])
	async function updateRoom() {
		if (roomName && roomName.length && selectedUsers?.length) {
			try {
				let result = await addMembers({ members: selectedUsers, roomId: selectedRoom.roomId });
				if (!result.success) {
					throw new Error(result.message);
				}
				loadActiveRooms();
				createNotification({ type: 'success', message: 'Added to the group' });
				setAlreadyInGroup(selectedUsers)
			} catch (e) {
				createNotification({
					type: 'danger',
					message: e.message,
					title: `Could not add users to the group`
				});
			}
		} else {
			createNotification({ type: 'danger', title: 'All the fields are required to fill' });
		}
	}


	useEffect(() => {
		let existingUsers = [];
		members.map((existingUser) => {
			if (existingUser._id !== user._id) {
				existingUsers.push(existingUser._id);
			}
		});
		setAlreadyInGroup(existingUsers);
	}, []);




	useEffect(() => {
		const allUsersArr = Object.values(allUsers);
		if (allUsersArr && Array.isArray(allUsersArr)) {

			const newList = [];
			allUsersArr.forEach(u => {
				const image = {
					type: u?.profilePic ? 'blob' : 'base64',
					src: u?.profilePic || u?.lastSnap || null
				}
				if (user?._id !== u._id) {
					newList.push({
						key: u._id,
						value: u?.name || u?.username,
						image
					})
				}
			})
			console.log(newList)
			setAllList(newList)
		}

	}, [])


	const handleChange = (keys, users) => {
		setSelectedUsers(keys)
	}

	const handleRemoveAll = () => {
		setSelectedUsers([])
	}

	return (
		<div className="m-t:.5">

			<div className="t-a:l m-t:2 f-w:100 sub-title2 f:.8">Members</div>
			<SelectInput allList={allList} selectedStatic={alreadyInGroup} placeholder="Add Members" onChange={handleChange} onSelect={handleChange} onRemove={handleChange} onRemoveAll={handleRemoveAll} />

			<div className="t-a:r m-t:2">
				<Button
					type="primary"
					className="circular create-btn f-w:100 sub-title"
					size="middle"
					onClick={() => {
						updateRoom();
					}}
				>
					Update
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		allUsers: state.users.allUsers,
		user: state.me
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadActiveRooms: () => dispatch({ type: LOAD_ACTIVE_ROOMS })
		// createRoom: (payload) => dispatch({ type: CREATE_ROOM, payload })
	};
};

AddMemberToGroup = connect(mapStateToProps, mapDispatchToProps)(AddMemberToGroup);
export default AddMemberToGroup;
