import { LOGGED_OUT } from '../actions/user.actions';
import { SERVER_URL } from '../config.js';
const axios = require('axios');
const baseURL = SERVER_URL;

let server = axios.create({
	validateStatus: function validateStatus(status) {
		let default_ = status >= 200 && status < 300;
		let extra = status == 401;
		return default_ || extra
	},
	baseURL,
	timeout: 100000
});
let token = null;

server.interceptors.request.use(function (config) {
	if (token) {
		config.headers['x-auth-token'] = token;
	}
	return config;
});
server.interceptors.response.use(
	(response) => response,
	async (error) => {
		console.log(error)
		if (error.response && error.response.status === 401) {
			window.store.dispatch({ type: LOGGED_OUT });
		}
		return Promise.reject(error);
	}
);
export const updateToken = (newToken) => {
	token = newToken;
};
export const login = async ({ userValue, password }) => {
	const response = await server.post('/auth/login', {
		userValue,
		password
	});
	const data = response.data;
	return data;
};
export const signup = async (info) => {
	const response = await server.post('/auth/signup', {
		info
	});
	const data = response.data;
	return data;
};
export const logout = async () => {
	await server.post('/auth/logout', {});
};

export const getUsers = async (teamId) => {
	const response = await server.get('/user/search/' + teamId, {});
	return response.data;
};

export const getActiveRooms = async (teamId) => {
	const response = await server.get('/user/active-rooms/' + teamId, {});
	return response.data;
};

export const resetPassword = async (newPassword) => {
	const response = await server.post(`/user/resetPassword`, { newPassword });
	return response.data;
};

export const changeUserName = async (newUserName) => {
	const response = await server.post(`/user/changeName`, { newUserName });
	return response.data;
};

export const getRoomInfo = async (roomId) => {
	const response = await server.get(`/room/${roomId}`, {});
	return response.data;
};

export const getMessageHistory = async (roomId, teamId, limit, skip) => {
	// let s = skip <= 100 ? 0 : skip;
	let l = limit ? limit : 100;
	let s = skip ? skip : 0;

	const response = await server.get(`/message/${roomId}?limit=${l}&skip=${s}`, {});
	console.log(response, 'response');
	return response.data;
};

export const createRoom = async ({ name, members, roomType, teamId }) => {
	const response = await server.post(`/room`, {
		name,
		members,
		roomType,
		teamId
	});
	return response.data;
};

export const removeMembers = async ({ members, roomId }) => {
	const response = await server.post(`/room/removeMembers/${roomId}`, {
		members
	});
	return response.data;
};

export const addAdmins = async ({ userId, roomId }) => {
	const response = await server.post(`/room/addAdmins/${roomId}`, {
		userId
	});
	return response.data;
};

export const removeUserFromTeam = async ({ userId }) => {
	const response = await server.get(`/user/disable/${userId}`, {
		userId
	});
	return response.data;
};

export const addMembers = async ({ members, roomId }) => {
	const response = await server.post(`/room/addMembers/${roomId}`, {
		members
	});
	return response.data;
};

export const createUser = async ({ name, email, teamId, password }) => {
	const response = await server.post(`/user/createUser`, {
		name,
		email,
		password,
		teamId
	});
	return response.data;
};

export const createAdmin = async ({ name, username, password }) => {
	const response = await server.post(`/user/createAdmin`, {
		name,
		username,
		password
	});
	return response.data;
};

export const checkIfAdminExists = async () => {
	const response = await server.get(`/checkAdmin`);
	return response.data;
};

export const uploadFile = async (file) => {
	const response = await server.post(`/data/attachment`, file, {
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
	return response.data;
};

export const sendMessage = async (messageBody, roomId) => {
	const response = await server.post(`/message/${roomId}`, { ...messageBody });
	return response.data;
};

export const deleteMessage = async (messageId) => {
	const response = await server.delete(`/message/delete/${messageId}`);
	return response.data;
};

export const editMessage = async (messageId, message) => {
	const response = await server.post(`/message/edit/${messageId}`, { ...message });
	return response.data;
};
export const updateProfilePic = async (profilePic) => {
	const response = await server.post(`/user/profilePic`, { profilePic });
	return response.data;
};
export const syncApi = async (syncObject) => {
	const response = await server.post(`/sync`, {
		syncObject
	});
	return response.data;
};
export const isTeamTokenExists = async (token) => {
	const response = await server.get(`/team/isTeamTokenExists/${token}`);
	return response.data;
};
