import { Button, Spin } from 'antd';
import React, { useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { CREATE_ROOM, LOAD_MESSAGE, REFERESH_UNREAD_NOTIFICATION_COUNT, SELECT_ROOM } from '../../actions/room.actions';
import { updateProfilePic, uploadFile } from '../../helpers/api';
import { createNotification, getCallColor, getRoomId, imageCompressOptions, imageMimeTypes } from '../../helpers/utils';
import ThreeDotIcon from '../CenterPanel/SvgComponents/ThreeDotIcon';
import UserStatusIcon from '../LeftPanel/Rooms/UserStatusIcon';
import DefaultImg from '../../images/videoImages/Avatar_male.png';
import './MemberProfile.scss';
import imageCompression from 'browser-image-compression';
import { UPDATE_CURRENT_USER_PROFILE } from '../../actions/user.actions';
import UserProfile from '../HelperComponents/UserProfile';

function MemberProfile({
	allUsers,
	user,
	userProfile,
	isLoggedInUser,
	call,
	selectRoom,
	allRooms,
	refereshUnreadMessageCount,
	loadRoomMessage,
	makeRoom,
	updateCurrentUserProfile
}) {
	const [file, setFile] = useState('');
	const [fileToUpload, setFileToUpload] = useState('');

	const [loading, setLoading] = useState(false);

	function sendMessage() {
		if (user._id == userProfile._id) {
		} else if (selectRoom(getRoomId(userProfile, user, allRooms))) {
			selectRoom(getRoomId(userProfile, user, allRooms));
			loadRoomMessage(getRoomId(userProfile, user, allRooms));
			localStorage.setItem(getRoomId(userProfile, user, allRooms), 0);
			refereshUnreadMessageCount();
		} else {
			createNewRoom();
		}
	}
	async function createNewRoom() {
		let payload = {
			members: [userProfile._id, user._id],
			teamId: user.teamId,
			roomType: 1
		};
		await makeRoom(payload);
	}

	async function handleFiles(e) {
		try {
			e.stopPropagation();
			e.preventDefault();
			let file = e.target.files[0];
			let mimeType = file.type;
			if (imageMimeTypes.indexOf(mimeType) >= 0) {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async function (event) {
					let compressedFile = await imageCompression(file, imageCompressOptions);
					let fileAsDataUrl = await imageCompression.getDataUrlFromFile(compressedFile);
					setFile(fileAsDataUrl);
					setFileToUpload(compressedFile);
					inputFile.current.value = '';
				};
			} else {
				throw new Error('Not valid image');
			}
		} catch (e) {
			setFile('');
			setFileToUpload('');
			createNotification({ title: 'Failed to load the avatar', message: e.message, type: 'danger' });
		}
	}

	async function updateProfile() {
		try {
			const formData = new FormData();
			formData.append('attachment', fileToUpload);
			setLoading(true);
			let fileData = await uploadFile(formData);
			if (fileData.success) {
				let result = await updateProfilePic(fileData.data.filename);
				if (result.success) {
					createNotification({ message: 'Avatar updated', type: 'success' });
					updateCurrentUserProfile({ profilePic: fileData.data.filename });
					setFile('');
					setFileToUpload('');
					setLoading(false);

					console.log(fileToUpload, 'fileToUpload');

					let obj = {};
					const localUser = localStorage.getItem('user');
					if (localUser) {
						obj = JSON.parse(localUser);
						obj.profilePic = fileData.data.filename;
					}
					localStorage.setItem('user', JSON.stringify(obj));
				} else {
					throw new Error(result.error);
				}
			}
		} catch (e) {
			setLoading(false);
			setFile('');
			setFileToUpload('');
			createNotification({ title: 'Failed to update the avatar', message: e.message, type: 'danger' });
		}
	}

	const inputFile = useRef(null);

	return (
		<div className="member-profile" id="member-profile">
			<div className="snap" style={{ backgroundImage: `url(${userProfile.lastSnap || DefaultImg})` }}></div>
			<div>
				<div className="v-a:m">
					<div className="t-a:l">
						<div className="m-r:.6 d:i-b v-a:m avatar-selector avatar-selector-no-bs avatar-box">
							{file ? (
								<div
									style={{
										width: 100,
										height: 100,
										backgroundImage: `url(${file})`,
										borderRadius: 100,
										backgroundSize: 'cover',
										backgroundPosition: 'center'
									}}
								/>
							) : isLoggedInUser && user.profilePic ? (
								<UserProfile
									profilePic={user.profilePic}
									styleProps={{
										width: 100,
										height: 100,
										borderRadius: 100,
										backgroundSize: 'cover',
										backgroundPosition: 'center'
									}}
								/>
							) : userProfile.profilePic ? (
								<UserProfile
									profilePic={userProfile.profilePic}
									styleProps={{
										width: 100,
										height: 100,
										borderRadius: 100,
										backgroundSize: 'cover',
										backgroundPosition: 'center'
									}}
								/>
							) : (
								<Avatar
									size="100"
									textSizeRatio={1.95}
									round={true}
									name={userProfile.username || '#'}
									className="product"
								/>
							)}
						</div>
						<div className="d:i-b v-a:m">
							<div
								className="f-w:100 f:.7 sub-title m-t:.0 cursor user-status-box"
								data-tip
								data-for="status-change"
								data-event="click focus"
							>
								<UserStatusIcon
									status={allUsers?.[userProfile._id]?.status}
									socketStatus={userProfile.socketStatus}
									onCall={allUsers?.[userProfile._id]?.activeCallId}
									callColor={getCallColor(userProfile, call)}
								/>
							</div>
						</div>
						<div className="fl:r v-a:t m-t:2 m-r:4">
							{isLoggedInUser ? (
								!file ? (
									<button
										type="primary"
										class="ant-btn ant-btn-primary m-r:.9"
										id="upload-button"
										onClick={() => inputFile.current.click()}
									>
										<span className="f-w:300">Change Avatar</span>
									</button>
								) : (
									[
										<span
											type="text"
											class="circular link-btn f-w:400 sub-title cursor m-r:4 cancel-btn"
											onClick={() => !loading && setFile('')}
										>
											Cancel
										</span>,
										<button
											type="primary"
											class="ant-btn ant-btn-primary m-r:.9"
											id="upload-button"
											onClick={() => {
												updateProfile();
											}}
										>
											{loading ? (
												<span className="f-w:300">
													<Spin
														size="small"
														className="v-a:m m-r:.2"
														style={{ marginTop: 3, marginRight: 10 }}
													/>
													<span className="v-a:m">Updating</span>
												</span>
											) : (
												<span className="f-w:300">Update</span>
											)}
										</button>
									]
								)
							) : (
								<Button
									style={{ padding: '7px 26px', height: 'auto' }}
									type="primary"
									className="dm circular v-a:m m-r:.9 cursor"
									onClick={() => sendMessage()}
								>
									Send Message
								</Button>
							)}
							<ThreeDotIcon width="17px" height="17px" class="v-a:m cursor" />
						</div>
						<div>
							<div style={{ minWidth: 100, marginLeft: 27 }} className="t-a:l d:i-b m-t:.5 m-b:8">
								<div className="t-a:c circular f-w:400 f:2">{userProfile.name}</div>
							</div>
						</div>
						<input
							type="file"
							id="file2"
							ref={inputFile}
							style={{ display: 'none' }}
							onChange={(e) => handleFiles(e)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		allRooms: state.rooms.allRooms,
		allUsers: state.users.allUsers,
		user: state.me,
		call: state.call
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCurrentUserProfile: (payload) => {
			dispatch({ type: UPDATE_CURRENT_USER_PROFILE, payload });
		},
		makeRoom: (payload) => dispatch({ type: CREATE_ROOM, payload }),
		selectRoom: (payload) => dispatch({ type: SELECT_ROOM, payload }),
		refereshUnreadMessageCount: () => dispatch({ type: REFERESH_UNREAD_NOTIFICATION_COUNT }),
		loadRoomMessage: (payload) =>
			dispatch({
				type: LOAD_MESSAGE,
				payload: {
					roomId: payload
				}
			})
	};
};

MemberProfile = connect(mapStateToProps, mapDispatchToProps)(MemberProfile);
export default MemberProfile;
