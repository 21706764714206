import '@tensorflow/tfjs-backend-webgl';
import * as bodyPix from '@tensorflow-models/body-pix';
// import worker_script from '../../components/RightPanel/ChatHeadsWorker';

export const pixelImage = async (base64Img) => {
	/*
		const imgtemp = document.createElement('img')
		imgtemp.src = base64Img
		const dataToSend = {
			img: imgtemp,
			base64Img: base64Img,
			bodyPix
		}
		const worker = new Worker(worker_script, { type: 'module' })
		worker.postMessage(JSON.stringify(dataToSend))
    
		worker.onmessage = (e) => {
			console.log('-------------');
			console.log(e.data);
		}
    
	*/
	const img = document.createElement('img');
	img.src = base64Img;
	let pixelImgBase64;
	img.onload = () => {
		pixelImgBase64 = pixelateImage(img, 5);
	};
	await new Promise((res, rej) => {
		const timeOut = setTimeout(() => {
			res();
			clearTimeout(timeOut)
		}, 100);
	});
	return pixelImgBase64;
};

function pixelateImage(originalImage, pixelationFactor) {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	const originalWidth = originalImage.width;
	const originalHeight = originalImage.height;

	const canvasWidth = originalWidth;
	const canvasHeight = originalHeight;

	canvas.width = canvasWidth;
	canvas.height = canvasHeight;

	context.drawImage(originalImage, 0, 0, originalWidth, originalHeight);

	const originalImageData = context.getImageData(0, 0, originalWidth, originalHeight).data;

	if (pixelationFactor !== 0) {
		for (let y = 0; y < originalHeight; y += pixelationFactor) {
			for (let x = 0; x < originalWidth; x += pixelationFactor) {
				const pixelIndexPosition = (x + y * originalWidth) * 4;
				context.fillStyle = `rgba(
          ${originalImageData[pixelIndexPosition]},
          ${originalImageData[pixelIndexPosition + 1]},
          ${originalImageData[pixelIndexPosition + 2]},
          ${originalImageData[pixelIndexPosition + 3]}
        )`;
				context.fillRect(x, y, pixelationFactor, pixelationFactor);
			}
		}
	}
	return canvas.toDataURL('image/png', 0.5);
}

export async function blurImage(base64Img) {
	const img = document.createElement('img');
	img.src = base64Img;
	const net = await bodyPix.load();
	let segmentation;
	try {
		segmentation = await net.segmentPerson(img);
	} catch (err) {
		console.log(err);
	}

	const canvas = document.getElementById('canvas');
	bodyPix.drawBokehEffect(canvas, img, segmentation, 7, 10, false);
	img.remove();
	const canvasImgBase64 = canvas.toDataURL('image/png', 0.5);
	return canvasImgBase64;
}
