import React, { useState } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import '../../css/ContextMenu.scss';
function ChatMessageConextMenu() {
	const [isAuthor, setIsAuthor] = useState(false);
	const [loggedInUser, setLoggedInUser] = useState('');
	const [message, setMessage] = useState('');

	function handleClick(e, data, target, actionType) {
		switch (actionType) {
			case 'reply':
				data.reply(data.message);
				break;

			case 'delete':
				data.delete(data.message);
				break;

			case 'edit':
				data.edit(data.message);
				break;

			case 'copy':
				navigator.clipboard.writeText(data.message.message.text);
				break;

			default:
				return false;
		}
	}

	function prepareContextData(e) {
		setIsAuthor(e.detail.data.isMessageAuthor);
		setLoggedInUser(e.detail.data.loggedInUser);
		setMessage(e.detail.data.message);
		let clickedMessageDiv = document.getElementById(e.detail.data.message._id);
		if (clickedMessageDiv) {
			clickedMessageDiv.classList.add('active-message');
		}
	}

	function prepareContextDataOnLeave(e) {
		let clickedMessageDiv = document.getElementById(message._id);
		if (clickedMessageDiv) {
			clickedMessageDiv.classList.remove('active-message');
		}
	}

	return (
		<div>
			<ContextMenu
				onShow={(e) => prepareContextData(e)}
				onHide={(e) => prepareContextDataOnLeave(e)}
				id="chat_message_context"
				style={{ zIndex: 1111 }}
				className="context-menu-box circular f-w:300"
			>
				<MenuItem
					onClick={(e, data, target) => handleClick(e, data, target, 'reply')}
					className="cursor context-item"
				>
					Reply
				</MenuItem>
				<MenuItem
					onClick={(e, data, target) => handleClick(e, data, target, 'copy')}
					className="cursor context-item"
				>
					Copy Message
				</MenuItem>

				{isAuthor && [
					<MenuItem divider className="divider-context" />,
					<MenuItem
						data={{ foo: 'bar' }}
						onClick={(e, data, target) => handleClick(e, data, target, 'edit')}
						className="cursor context-item"
					>
						Edit
					</MenuItem>,

					<MenuItem
						data={{ foo: 'bar' }}
						onClick={(e, data, target) => handleClick(e, data, target, 'delete')}
						className="danger-items cursor context-item"
					>
						Delete message
					</MenuItem>
				]}
			</ContextMenu>
		</div>
	);
}

export default ChatMessageConextMenu;
