import { SET_CONSUMER_PREFERRED_LAYERS } from '../../../../actions/call.actions';
import { notify } from '../../../../actions/request.actions';

async function setConsumerPreferredLayers({ roomClient, store, action }) {
	const { consumerId, spatialLayer, temporalLayer } = action.payload;
	try {
		await this._protoo.request('setConsumerPreferredLayers', { consumerId, spatialLayer, temporalLayer });

		store.dispatch({
			typr: SET_CONSUMER_PREFERRED_LAYERS,
			payload: { consumerId, spatialLayer, temporalLayer }
		});
	} catch (error) {
		notify({
			type: 'error',
			text: `Error setting Consumer preferred layers: ${error}`
		});
	}
}

export { setConsumerPreferredLayers };
