export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export function addToast(options = {}) {
	return {
		payload: createToast(options),
		type: ADD_TOAST
	};
}

export function removeToast(id) {
	return {
		payload: id,
		type: REMOVE_TOAST
	};
}

let id = 0;

const defaultOptions = {
	color: '#212529'
};

function createToast(options) {
	return {
		...defaultOptions,
		...options,
		id: id++
	};
}
