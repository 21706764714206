import protooClient from 'protoo-client';
import * as mediasoupClient from 'mediasoup-client';
import Logger from '../Logger';
import { getProtooUrl } from '../urlFactory';
import * as cookiesManager from '../cookiesManager';
import * as requestActions from '../../actions/request.actions';
import * as stateActions from '../../actions/state.actions';
import { ROOM_JOINED_SOUND_OFF, ROOM_JOINED_SOUND_ON } from '../../actions/user.actions';
import { RECEIVE_NOTIFICATION, SEND_NOTIFICATION } from '../../actions/call.actions';
import { createPushNotification } from '../utils';

import {
	muteMic,
	enableMic,
	disableMic,
	unmuteMic,
	enableAudioOnly,
	disableAudioOnly
} from './roomClientHelpers/audio.proto.request';
import {
	enableWebcam,
	disableWebcam,
	changeWebcam,
	changeWebcamResolution,
	updateWebcams
} from './roomClientHelpers/cam.proto.request';
import { enableShare, disableShare } from './roomClientHelpers/share.proto.request';
import { pauseConsumer, resumeConsumer, setConsumerPreferredLayers } from './roomClientHelpers/consumer.proto.request';
import {
	enableMEventProducer,
	sendMouseCoords,
	sendMousePointerEnabledDisabled
} from './roomClientHelpers/events.proto.request';

import isElectron from 'is-electron';

let electron = null;
let ipcRen = null;

if (isElectron() && typeof window?.require === 'function') {
	electron = window.require('electron');
	ipcRen = window.ipcRenderer;
}

const PC_PROPRIETARY_CONSTRAINTS = {
	optional: [{ googDscp: true }]
};

const logger = new Logger('RoomClient');

let store;
class RoomClient {
	/**
	 * @param  {Object} data
	 * @param  {Object} data.store - The Redux window.store.
	 */
	static init(data) {
		store = store;
	}

	constructor({
		roomId,
		peerId,
		displayName,
		device,
		handlerName,
		forceTcp,
		produce,
		consume,
		datachannel,
		_protoo
	}) {
		logger.debug(
			'constructor() [roomId:"%s", peerId:"%s", displayName:"%s", device:%s]',
			roomId,
			peerId,
			displayName,
			device.flag
		);

		this.logger = logger;

		// Closed flag.
		// @type {Boolean}
		this._closed = false;

		// Display name.
		// @type {String}
		this._displayName = displayName;

		// Device info.
		// @type {Object}
		this._device = device;

		// Whether we want to force RTC over TCP.
		// @type {Boolean}
		this._forceTcp = forceTcp;

		// Whether we want to produce audio/video.
		// @type {Boolean}
		this._produce = produce;

		// Whether we should consume.
		// @type {Boolean}
		this._consume = consume;

		// Whether we want DataChannels.
		// @type {Boolean}
		this._useDataChannel = datachannel;

		// External video.
		// @type {HTMLVideoElement}
		this._externalVideo = null;

		// MediaStream of the external video.
		// @type {MediaStream}
		this._externalVideoStream = null;

		// Next expected dataChannel test number.
		// @type {Number}
		this._nextDataChannelTestNumber = 0;

		// Custom mediasoup-client handler name (to override default browser
		// detection if desired).
		// @type {String}
		this._handlerName = handlerName;

		// Protoo URL.
		// @type {String}
		this._protooUrl = getProtooUrl({ roomId, peerId });

		// protoo-client Peer instance.
		// @type {protooClient.Peer}
		this._protoo = _protoo;

		// mediasoup-client Device instance.
		// @type {mediasoupClient.Device}
		this._mediasoupDevice = null;

		// mediasoup Transport for sending.
		// @type {mediasoupClient.Transport}
		this._sendTransport = null;

		// mediasoup Transport for receiving.
		// @type {mediasoupClient.Transport}
		this._recvTransport = null;

		// Local mic mediasoup Producer.
		// @type {mediasoupClient.Producer}
		this._micProducer = null;

		// Local webcam mediasoup Producer.
		// @type {mediasoupClient.Producer}
		this._webcamProducer = null;

		// Local share mediasoup Producer.
		// @type {mediasoupClient.Producer}
		this._shareProducer = null;

		// Local chat DataProducer.
		// @type {mediasoupClient.DataProducer}
		this._chatDataProducer = null;

		// Local bot DataProducer.
		// @type {mediasoupClient.DataProducer}
		this._botDataProducer = null;

		// mediasoup Consumers.
		// @type {Map<String, mediasoupClient.Consumer>}
		this._consumers = new Map();

		// mediasoup DataConsumers.
		// @type {Map<String, mediasoupClient.DataConsumer>}
		this._dataConsumers = new Map();

		// Map of webcam MediaDeviceInfos indexed by deviceId.
		// @type {Map<String, MediaDeviceInfos>}
		this._webcams = new Map();

		this.mEventProducer = null;

		// Local Webcam.
		// @type {Object} with:
		// - {MediaDeviceInfo} [device]
		// - {String} [resolution] - 'qvga' / 'vga' / 'hd'.
		this._webcam = {
			device: null,
			resolution: 'hd'
		};
	}

	setterVal(name = [], value, nestedName = null) {
		if (nestedName) {
			this[name][nestedName] = value;
		} else {
			this[name] = value;
		}
	}

	close() {
		try {
			if (this._closed) return;

			this._closed = true;

			logger.debug('close()');

			// Close protoo Peer
			this._protoo.close();

			// Close mediasoup Transports.
			if (this._sendTransport) this._sendTransport.close();

			if (this._recvTransport) this._recvTransport.close();
		} catch (error) {
			console.log(error);
		}
	}

	async join() {
		console.log('Joined Call');
		return await this._joinRoom();
	}

	async handleNewConsumer(request, accept, reject) {
		if (!this._consume) {
			reject(403, 'I do not want to consume');

			return;
		}

		const { peerId, producerId, id, kind, rtpParameters, type, appData, producerPaused } = request.data;
		console.log(appData, 'appData');
		try {
			const consumer = await this._recvTransport.consume({
				id,
				producerId,
				kind,
				rtpParameters,
				appData: { ...appData, peerId } // Trick.
			});

			// Store in the map.
			this._consumers.set(consumer.id, consumer);

			consumer.on('transportclose', () => {
				this._consumers.delete(consumer.id);
			});

			const { spatialLayers, temporalLayers } = mediasoupClient.parseScalabilityMode(
				consumer.rtpParameters.encodings[0].scalabilityMode
			);

			// We are ready. Answer the protoo request so the server will
			// resume this Consumer (which was paused for now if video).
			//accept();

			// If audio-only mode is enabled, pause it.
			if (consumer.kind === 'video' && window.store.getState().me.audioOnly) this.pauseConsumer(consumer);

			const obj = {
				id: consumer.id,
				type: appData?.share ? 'share' : type,
				locallyPaused: false,
				remotelyPaused: producerPaused,
				rtpParameters: consumer.rtpParameters,
				spatialLayers: spatialLayers,
				temporalLayers: temporalLayers,
				preferredSpatialLayer: spatialLayers - 1,
				preferredTemporalLayer: temporalLayers - 1,
				priority: 1,
				codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
				track: consumer.track
			};

			console.log('Obj => ', obj);

			return {
				consumer: obj,
				peerId
			};
		} catch (error) {
			logger.error('"newConsumer" request failed:%o', error);
			console.log(error, 'audio error');

			throw new Error(error);
		}
	}

	async handleNewDataConsumer(request, accept, reject) {
		if (!this._consume) {
			reject(403, 'I do not want to data consume');

			return;
		}

		if (!this._useDataChannel) {
			reject(403, 'I do not want DataChannels');

			return;
		}

		const {
			peerId, // NOTE: Null if bot.
			dataProducerId,
			id,
			sctpStreamParameters,
			label,
			protocol,
			appData
		} = request.data;

		try {
			const dataConsumer = await this._recvTransport.consumeData({
				id,
				dataProducerId,
				sctpStreamParameters,
				label,
				protocol,
				appData: { ...appData, peerId } // Trick.
			});

			// Store in the map.
			this._dataConsumers.set(dataConsumer.id, dataConsumer);

			dataConsumer.on('transportclose', () => {
				this._dataConsumers.delete(dataConsumer.id);
			});

			dataConsumer.on('open', () => {
				logger.debug('DataConsumer "open" event');
			});

			dataConsumer.on('close', () => {
				logger.warn('DataConsumer "close" event');

				this._dataConsumers.delete(dataConsumer.id);

				throw new Error('DataConsumer closed');
			});

			dataConsumer.on('error', (error) => {
				logger.error('DataConsumer "error" event:%o', error);

				requestActions.notify({
					type: 'error',
					text: `DataConsumer error: ${error}`
				});
			});

			// We are ready. Answer the protoo request.
			accept();

			return {
				dataConsumer: {
					id: dataConsumer.id,
					sctpStreamParameters: dataConsumer.sctpStreamParameters,
					label: dataConsumer.label,
					protocol: dataConsumer.protocol
				},
				peerId
			};
		} catch (error) {
			logger.error('"newDataConsumer" request failed:%o', error);

			throw new Error(error);
		}
	}

	async muteAudio() {
		logger.debug('muteAudio()');
	}

	async unmuteAudio() {
		logger.debug('unmuteAudio()');
	}

	async restartIce() {
		logger.debug('restartIce()');

		window.store.dispatch(stateActions.setRestartIceInProgress(true));

		try {
			if (this._sendTransport) {
				const iceParameters = await this._protoo.request('restartIce', { transportId: this._sendTransport.id });

				await this._sendTransport.restartIce({ iceParameters });
			}

			if (this._recvTransport) {
				const iceParameters = await this._protoo.request('restartIce', { transportId: this._recvTransport.id });

				await this._recvTransport.restartIce({ iceParameters });
			}

			requestActions.notify({
				text: 'ICE restarted'
			});
		} catch (error) {
			logger.error('restartIce() | failed:%o', error);

			requestActions.notify({
				type: 'error',
				text: `ICE restart failed: ${error}`
			});
		}

		window.store.dispatch(stateActions.setRestartIceInProgress(false));
	}

	async setMaxSendingSpatialLayer(spatialLayer) {
		logger.debug('setMaxSendingSpatialLayer() [spatialLayer:%s]', spatialLayer);

		try {
			if (this._webcamProducer) await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
			else if (this._shareProducer) await this._shareProducer.setMaxSpatialLayer(spatialLayer);
		} catch (error) {
			logger.error('setMaxSendingSpatialLayer() | failed:%o', error);

			requestActions.notify({
				type: 'error',
				text: `Error setting max sending video spatial layer: ${error}`
			});
		}
	}

	async setConsumerPriority(consumerId, priority) {
		logger.debug('setConsumerPriority() [consumerId:%s, priority:%d]', consumerId, priority);

		try {
			await this._protoo.request('setConsumerPriority', { consumerId, priority });

			window.store.dispatch(stateActions.setConsumerPriority(consumerId, priority));
		} catch (error) {
			logger.error('setConsumerPriority() | failed:%o', error);

			requestActions.notify({
				type: 'error',
				text: `Error setting Consumer priority: ${error}`
			});
		}
	}

	async requestConsumerKeyFrame(consumerId) {
		logger.debug('requestConsumerKeyFrame() [consumerId:%s]', consumerId);

		try {
			await this._protoo.request('requestConsumerKeyFrame', { consumerId });

			requestActions.notify({
				text: 'Keyframe requested for video consumer'
			});
		} catch (error) {
			logger.error('requestConsumerKeyFrame() | failed:%o', error);

			requestActions.notify({
				type: 'error',
				text: `Error requesting key frame for Consumer: ${error}`
			});
		}
	}

	async getSendTransportRemoteStats() {
		logger.debug('getSendTransportRemoteStats()');

		if (!this._sendTransport) return;

		return this._protoo.request('getTransportStats', { transportId: this._sendTransport.id });
	}

	async getRecvTransportRemoteStats() {
		logger.debug('getRecvTransportRemoteStats()');

		if (!this._recvTransport) return;

		return this._protoo.request('getTransportStats', { transportId: this._recvTransport.id });
	}

	async getAudioRemoteStats() {
		logger.debug('getAudioRemoteStats()');

		if (!this._micProducer) return;

		return this._protoo.request('getProducerStats', { producerId: this._micProducer.id });
	}

	async getVideoRemoteStats() {
		logger.debug('getVideoRemoteStats()');

		const producer = this._webcamProducer || this._shareProducer;

		if (!producer) return;

		return this._protoo.request('getProducerStats', { producerId: producer.id });
	}

	async getConsumerRemoteStats(consumerId) {
		logger.debug('getConsumerRemoteStats()');

		const consumer = this._consumers.get(consumerId);

		if (!consumer) return;

		return this._protoo.request('getConsumerStats', { consumerId });
	}

	async getChatDataProducerRemoteStats() {
		logger.debug('getChatDataProducerRemoteStats()');

		const dataProducer = this._chatDataProducer;

		if (!dataProducer) return;

		return this._protoo.request('getDataProducerStats', { dataProducerId: dataProducer.id });
	}

	async getBotDataProducerRemoteStats() {
		logger.debug('getBotDataProducerRemoteStats()');

		const dataProducer = this._botDataProducer;

		if (!dataProducer) return;

		return this._protoo.request('getDataProducerStats', { dataProducerId: dataProducer.id });
	}

	async getDataConsumerRemoteStats(dataConsumerId) {
		logger.debug('getDataConsumerRemoteStats()');

		const dataConsumer = this._dataConsumers.get(dataConsumerId);

		if (!dataConsumer) return;

		return this._protoo.request('getDataConsumerStats', { dataConsumerId });
	}

	async getSendTransportLocalStats() {
		logger.debug('getSendTransportLocalStats()');

		if (!this._sendTransport) return;

		return this._sendTransport.getStats();
	}

	async getRecvTransportLocalStats() {
		logger.debug('getRecvTransportLocalStats()');

		if (!this._recvTransport) return;

		return this._recvTransport.getStats();
	}

	async getAudioLocalStats() {
		logger.debug('getAudioLocalStats()');

		if (!this._micProducer) return;

		return this._micProducer.getStats();
	}

	async getVideoLocalStats() {
		logger.debug('getVideoLocalStats()');

		const producer = this._webcamProducer || this._shareProducer;

		if (!producer) return;

		return producer.getStats();
	}

	async getConsumerLocalStats(consumerId) {
		const consumer = this._consumers.get(consumerId);

		if (!consumer) return;

		return consumer.getStats();
	}

	async applyNetworkThrottle({ uplink, downlink, rtt, secret }) {
		logger.debug('applyNetworkThrottle() [uplink:%s, downlink:%s, rtt:%s]', uplink, downlink, rtt);

		try {
			await this._protoo.request('applyNetworkThrottle', { uplink, downlink, rtt, secret });
		} catch (error) {
			logger.error('applyNetworkThrottle() | failed:%o', error);

			requestActions.notify({
				type: 'error',
				text: `Error applying network throttle: ${error}`
			});
		}
	}

	async resetNetworkThrottle({ silent = false, secret }) {
		logger.debug('resetNetworkThrottle()');

		try {
			await this._protoo.request('resetNetworkThrottle', { secret });
		} catch (error) {
			if (!silent) {
				logger.error('resetNetworkThrottle() | failed:%o', error);

				requestActions.notify({
					type: 'error',
					text: `Error resetting network throttle: ${error}`
				});
			}
		}
	}

	async _joinRoom() {
		logger.debug('_joinRoom()');
		// try {

		console.log('in join call ');

		this._mediasoupDevice = new mediasoupClient.Device({
			handlerName: this._handlerName
		});

		const routerRtpCapabilities = await this._protoo.request('getRouterRtpCapabilities');

		await this._mediasoupDevice.load({ routerRtpCapabilities });

		// NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
		//
		// Just get access to the mic and DO NOT close the mic track for a while.
		// Super hack!
		{
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				const audioTrack = stream.getAudioTracks()[0];

				audioTrack.enabled = false;

				// setTimeout(() => audioTrack.stop(), 120000);
			} catch (error) {
				console.log('Mic not found')
			}
		}
		// Create mediasoup Transport for sending (unless we don't want to produce).
		const transportInfo = await this._protoo.request('createWebRtcTransport', {
			forceTcp: this._forceTcp,
			producing: true,
			consuming: false,
			sctpCapabilities: this._useDataChannel ? this._mediasoupDevice.sctpCapabilities : undefined
		});
		if (this._produce) {

			const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

			this._sendTransport = this._mediasoupDevice.createSendTransport({
				id,
				iceParameters,
				iceCandidates,
				dtlsParameters,
				sctpParameters,
				iceServers: [],
				proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
				additionalSettings: { encodedInsertableStreams: false }
			});

			this._sendTransport.on(
				'connect',
				(
					{ dtlsParameters },
					callback,
					errback // eslint-disable-line no-shadow
				) => {
					this._protoo
						.request('connectWebRtcTransport', {
							transportId: this._sendTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				}
			);

			this._sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
				try {
					console.log(`KIND IS: ${kind}`);
					// eslint-disable-next-line no-shadow
					const { id } = await this._protoo.request('produce', {
						transportId: this._sendTransport.id,
						kind,
						rtpParameters,
						appData
					});

					callback({ id });
				} catch (error) {
					errback(error);
				}
			});

			this._sendTransport.on(
				'producedata',
				async ({ sctpStreamParameters, label, protocol, appData }, callback, errback) => {
					logger.debug(
						'"producedata" event: [sctpStreamParameters:%o, appData:%o]',
						sctpStreamParameters,
						appData
					);

					try {
						// eslint-disable-next-line no-shadow
						const { id } = await this._protoo.request('produceData', {
							transportId: this._sendTransport.id,
							sctpStreamParameters,
							label,
							protocol,
							appData
						});

						callback({ id });
					} catch (error) {
						errback(error);
					}
				}
			);
		}

		// Create mediasoup Transport for receiving (unless we don't want to consume).
		if (this._consume) {
			const transportInfo = await this._protoo.request('createWebRtcTransport', {
				forceTcp: this._forceTcp,
				producing: false,
				consuming: true,
				sctpCapabilities: this._useDataChannel ? this._mediasoupDevice.sctpCapabilities : undefined
			});

			const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

			this._recvTransport = this._mediasoupDevice.createRecvTransport({
				id,
				iceParameters,
				iceCandidates,
				dtlsParameters,
				sctpParameters,
				iceServers: [],
				additionalSettings: { encodedInsertableStreams: false }
			});

			this._recvTransport.on(
				'connect',
				(
					{ dtlsParameters },
					callback,
					errback // eslint-disable-line no-shadow
				) => {
					this._protoo
						.request('connectWebRtcTransport', {
							transportId: this._recvTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				}
			);
		}

		// Join now into the room.
		// NOTE: Don't send our RTP capabilities if we don't want to consume.

		const { peers } = await this._protoo.request('join', {
			displayName: this._displayName,
			device: this._device,
			rtpCapabilities: this._consume ? this._mediasoupDevice.rtpCapabilities : undefined,
			sctpCapabilities: this._useDataChannel && this._consume ? this._mediasoupDevice.sctpCapabilities : undefined
		});

		this._sendTransport.on('connectionstatechange', (connectionState) => {
			if (connectionState === 'connected') {
				this.enableMEventProducer();
			}
		});
		this._recvTransport.on('connectionstatechange', (connectionState) => {
			if (connectionState === 'connected') {

			} else {

			}
		});
		// ************************************************************* REMOVE FROM HERE**********************************

		// NOTE: For testing.
		if (window.SHOW_INFO) {
			const { me } = window.store.getState();

			window.store.dispatch(stateActions.setRoomStatsPeerId(me.id));
		}

		console.log('peers => ', peers);

		return {
			peers,
			canSendMic: this._mediasoupDevice.canProduce('audio'),
			canSendWebcam: this._mediasoupDevice.canProduce('video')
		};
	}

	_getWebcamType(device) {
		if (/(back|rear)/i.test(device.label)) {
			logger.debug('_getWebcamType() | it seems to be a back camera');

			return 'back';
		} else {
			logger.debug('_getWebcamType() | it seems to be a front camera');

			return 'front';
		}
	}

	async _getExternalVideoStream() {
		if (this._externalVideoStream) return this._externalVideoStream;

		if (this._externalVideo.readyState < 3) {
			await new Promise((resolve) => this._externalVideo.addEventListener('canplay', resolve));
		}

		if (this._externalVideo.captureStream) this._externalVideoStream = this._externalVideo.captureStream();
		else if (this._externalVideo.mozCaptureStream)
			this._externalVideoStream = this._externalVideo.mozCaptureStream();
		else throw new Error('video.captureStream() not supported');

		return this._externalVideoStream;
	}
}

RoomClient.prototype.pauseConsumer = pauseConsumer;
RoomClient.prototype.resumeConsumer = resumeConsumer;
RoomClient.prototype.setConsumerPreferredLayers = setConsumerPreferredLayers;

RoomClient.prototype.muteMic = muteMic;
RoomClient.prototype.enableMic = enableMic;
RoomClient.prototype.disableMic = disableMic;
RoomClient.prototype.unmuteMic = unmuteMic;
RoomClient.prototype.enableAudioOnly = enableAudioOnly;
RoomClient.prototype.disableAudioOnly = disableAudioOnly;

RoomClient.prototype.updateWebcams = updateWebcams;
RoomClient.prototype.enableWebcam = enableWebcam;
RoomClient.prototype.disableWebcam = disableWebcam;
RoomClient.prototype.changeWebcam = changeWebcam;
RoomClient.prototype.changeWebcamResolution = changeWebcamResolution;

RoomClient.prototype.enableShare = enableShare;
RoomClient.prototype.disableShare = disableShare;

RoomClient.prototype.enableMEventProducer = enableMEventProducer;
RoomClient.prototype.sendMouseCoords = sendMouseCoords;
RoomClient.prototype.sendMousePointerEnabledDisabled = sendMousePointerEnabledDisabled;

export default RoomClient;
