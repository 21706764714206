import { take, put, call, apply, delay, fork, takeLatest, takeEvery, all, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
	LOGGED_IN,
	LOGGED_OUT,
	NEW_MESSAGE_SOUND_OFF,
	NEW_MESSAGE_SOUND_ON,
	SOCKET_STATUS,
	UPDATE_PIC,
	USER_LOGIN_EVENT,
	STATUS_CHANGED,
	KNOCK_USER,
	MOUSE_EVENT,
	CHANGE_NAME,
	FORCE_SOCKET_DISCONNECT
} from '../actions/user.actions';

import { USER_CALL_STATUS_UPDATE, USER_NAME_CHANGE } from '../actions/users.actions';

import {
	SEND_MESSAGE,
	SEND_MESSAGE_SUCCESS,
	SEND_MESSAGE_FAILED,
	NEW_ROOM_CREATED,
	LOAD_ACTIVE_ROOMS_SUCCESS,
	MESSAGE
} from '../actions/room.actions';
import {
	CALL_INVITE,
	SEND_NOTIFICATION,
	INCOMING_CALL_INVITE,
	INITIATE_CALL_REQUEST_ROOM,
	CALL_ROOM_INFO,
	ATTEMPTING_CALL,
	SEND_ATTEMPTING_CALL,
	PEER_INVITE_REQUEST,
	SEND_PEER_INVITE_REQUEST,
	PEER_INVITE_REQUEST_ACCEPTED,
	INVITE_REQUEST,
	ACCEPT_REQUEST,
	ACCEPT_INVITE_REQUEST,
	SEND_CALL_END,
	CALL_ENDED,
	END_CALL,
	PEER_CALL_LEFT,
	PEER_CALL_JOINED,
	SET_CALL_ROOM_STATE,
	PEER_ATTEMPTING_CALL,
	PEER_CALL_ROOM_INFO
} from '../actions/call.actions';
import { EMIT_CALL_CONNECTING_STATUS, USER_STATUS_UPDATE } from '../actions/users.actions';
import { STOP_CALL_TIMER, STOP_SNAP_TIMER } from '../actions/timer.actions';
import { INIT, SERVER_ERROR } from '../actions/init.actions';
import { SOCKET_URL } from '../config.js';
import { createRoom, getActiveRooms } from '../helpers/api';
import { createNotification, sleep } from '../helpers/utils';
import CONSTS from '../helpers/commonConsts';

const { io } = require('socket.io-client');
let socket, socketChannel, token;

const createWebSocketConnection = () => {
	socket = io(SOCKET_URL, {
		reconnectionDelayMax: 10000,
		auth: {
			teamId: window.store.getState().me.teamId,
			token
		}
	});
	socket.on('disconnect', () => {
		socketStatusUpdate(CONSTS.SOCKET_STATUS.DISCONNECTED);
		//Clear all timers here
		window.store.dispatch({
			type: STOP_SNAP_TIMER
		});
		window.store.dispatch({
			type: STOP_CALL_TIMER
		});
	});
	socket.on('connect', () => {
		//refetch data and call init

		window.store.dispatch({
			type: INIT
		});
		socketStatusUpdate(CONSTS.SOCKET_STATUS.CONNECTED);
	});

	return socket;
};

function socketStatusUpdate(socketStatus) {
	try {
		window.store.dispatch({
			type: SOCKET_STATUS,
			payload: { socketStatus }
		});
	} catch (e) {
		console.log(e);
	}
}

// this function creates an event channel from a given socket
// Setup subscription to incoming `ping` events
function createSocketChannel() {
	// `eventChannel` takes a subscriber function
	// the subscriber function takes an `emit` argument to put messages onto the channel
	var onevent = socket.onevent;
	socket.onevent = function (packet) {
		var args = packet.data || [];
		onevent.call(this, packet); // original call
		packet.data = ['*'].concat(args);
		onevent.call(this, packet); // additional call to catch-all
	};
	return eventChannel((emit) => {
		const errorHandler = (errorEvent) => {
			// create an Error object and put it into the channel
			cleanWebSocketConnection(socket, socketChannel)
			emit(new Error(errorEvent.reason));
		};

		// setup the subscription
		socket.on('error', errorHandler);

		const eventHandler = (type, payload) => {
			emit({ type, payload });

			const allUsers = window.store?.getState().users.allUsers;
			const callData = window.store?.getState().call;
			const loggedInUserId = window.store?.getState().me._id;
			payload.call = callData;
			payload.allUsers = allUsers;
			payload.loggedInUserId = loggedInUserId;

			window.store.dispatch({
				type, payload
			})

			if (type == MESSAGE) {
				const selectedRoom = window.store?.getState().rooms.selectedRoom;
				if (!window.inActive && selectedRoom?._id == payload?.to) {
				} else if (!window.inActive) {
					window.store.dispatch({ type: NEW_MESSAGE_SOUND_ON })
					sleep(3000)
						.then((res) => {
							window.store.dispatch({ type: NEW_MESSAGE_SOUND_OFF });
						})
						.catch((e) => {
							console.log(e);
						});
				}
			}

			if (type === PEER_CALL_ROOM_INFO) {
				window.store.dispatch({ type: USER_CALL_STATUS_UPDATE, payload: { userId: payload.userId, color: payload.color, callRoomId: payload.callRoomId, blink: true } });
			}

			// invite user
			if (type === PEER_INVITE_REQUEST) {
				window.store.dispatch({ type: INVITE_REQUEST, payload });
			}

			if (type === PEER_CALL_JOINED) {
				window.store.dispatch({
					type: SET_CALL_ROOM_STATE,
					payload: { state: 'connected' }
				});
				window.store.dispatch({ type: PEER_CALL_JOINED, payload });
				window.store.dispatch({ type: USER_CALL_STATUS_UPDATE, payload: { userId: payload.peerId, callRoomId: payload.callRoomId, color: payload.color, blink: false } });
			}
			//end call event
			if (type === CALL_ENDED) {
				// yield put({ type: END_CALL, payload });
				window.store.dispatch({ type: PEER_CALL_LEFT, payload });

				// if there is no peer in call and init call state
				if (Object.keys(callData.peers).length === 0) {
					window.store.dispatch({ type: END_CALL, payload });
					window.store.dispatch({ type: USER_CALL_STATUS_UPDATE, payload: { userId: payload.peerId, callRoomId: null, color: null, blink: false } });
				}
			}

		};
		socket.on('*', eventHandler);


		// the subscriber must return an unsubscribe function
		// this will be invoked when the saga calls `channel.close` method
		const unsubscribe = () => {
			socket.off('*', eventHandler);
		};

		return unsubscribe;
	});
}

const cleanWebSocketConnection = () => {
	try {
		socketChannel && socketChannel.close();
		socket && socket.close();
		localStorage.clear();
	} catch (error) {
		alert(error);
	}
};

export function* sendMessage(action) {
	try {
		if (action.type != SEND_MESSAGE) {
			if (!action.payload) {
				let data = yield call(createRoom, {
					members: [action.payload.user._id, action.payload.invitedUser._id],
					roomType: 1,
					teamId: action.payload.teamId
				});
				let oneToOneRoomId = data.data._id;
				action.payload.to = oneToOneRoomId;
				yield put({ type: NEW_ROOM_CREATED, payload: data.data });
			}
		}
		let actionType = action.type;

		if (actionType == SEND_MESSAGE) {
			actionType = 'MESSAGE';
		}
		//  else if (actionType == CALL_INVITE) {
		// 	actionType = INCOMING_CALL_INVITE;
		// } 
		// else if (actionType == EMIT_CALL_CONNECTING_STATUS) {
		// 	actionType = 'CALL_CONNECTING_STATUS';
		// } 
		else {
			actionType = action.type;
		}
		socket.emit(actionType, action.payload);
		yield put({
			type: SEND_MESSAGE_SUCCESS
		});
	} catch (error) {
		yield put({
			type: SEND_MESSAGE_FAILED,
			payload: { message: error.message }
		});
	}
}

export function* sendMessageListener() {
	yield takeEvery([SEND_MESSAGE, SEND_NOTIFICATION, USER_LOGIN_EVENT, EMIT_CALL_CONNECTING_STATUS], sendMessage);
}

export function* socketEventListener(result) {

	token = result.payload.user.token;
	createWebSocketConnection();
	socketChannel = yield call(createSocketChannel);
	// while (true) {


	// 	const { type, payload } = yield take(socketChannel);

	// 	const allUsers = window.store?.getState().users.allUsers;
	// 	const callData = window.store?.getState().call;
	// 	payload.call = callData;
	// 	payload.allUsers = allUsers;
	// 	debugger
	// 	//Emit the event
	// 	yield put({ type, payload });
	// }




}

export function* socketListener() {
	try {
		yield takeEvery(LOGGED_IN, socketEventListener);
	} catch (error) {
		console.log(error);
	}
}

function* sendImageToSocket(action) {
	socket.emit(UPDATE_PIC, action.payload);
}

function* sendStatusToSocket(action) {
	socket.emit(STATUS_CHANGED, { status: action.status });
	let state = yield select();
	yield put({ type: USER_STATUS_UPDATE, payload: { status: action.status, userId: state?.me?._id } });
}

function* sendChangeNameToSocket(action) {
	socket.emit(CHANGE_NAME, { name: action.name });
	let state = yield select();
	yield put({ type: USER_NAME_CHANGE, payload: { name: action.name, userId: state?.me?._id } });
}

function* sendKnockToSocket(action) {
	socket.emit(KNOCK_USER, action.payload);
}

function* sendMEvent(action) {
	socket.emit(MOUSE_EVENT, action.payload);
}

function* initiateCall(action) {
	socket.emit(INITIATE_CALL_REQUEST_ROOM, (res) => {
		console.log(res);
		if (res.success) {
			window.store.dispatch({ type: CALL_ROOM_INFO, payload: res.data });
			window.store.dispatch({ type: SEND_ATTEMPTING_CALL, payload: action.payload });
		}
	});
}

function* attempCall(action) {
	console.log('ATTEMPTING_CALL => ', action);
	socket.emit(ATTEMPTING_CALL, action.payload, (res) => {
		if (res.success) {
			console.log("ATTEMPTING_CALL ===> ", res);

		}
	});
}
function* sendinvite(action) {
	socket.emit(PEER_INVITE_REQUEST, action.payload, (res) => {
		console.log('(PEER_INVITE_REQUEST === >', res);
	});
}

function* acceptCall(action) {
	socket.emit(PEER_INVITE_REQUEST_ACCEPTED, action.payload, (res) => {
		console.log('(PEER_INVITE_REQUEST_ACCEPTED === >', res);
	});
}

function* endCall(action) {
	socket.emit(CALL_ENDED, action.payload, (res) => {
		console.log('END CALL =>', res);
	});
}

export function* imageUpdateListener() {
	try {
		yield takeEvery(UPDATE_PIC, sendImageToSocket);
	} catch (error) { }
}

export function* resetSocket() {
	try {
		yield takeEvery(LOGGED_OUT, cleanWebSocketConnection);
	} catch (error) { }
}

export function* changeStatus() {
	try {
		yield takeEvery(STATUS_CHANGED, sendStatusToSocket);
	} catch (error) { }
}
export function* changeName() {
	try {
		yield takeEvery(CHANGE_NAME, sendChangeNameToSocket);
	} catch (error) { }
}

export function* knockUser() {
	try {
		yield takeEvery(KNOCK_USER, sendKnockToSocket);
	} catch (error) { }
}

export function* sendMouseEvent() {
	try {
		yield takeEvery(MOUSE_EVENT, sendMEvent);
	} catch (error) { }
}

// get room id from server
export function* getRoomId() {
	try {
		yield takeEvery(INITIATE_CALL_REQUEST_ROOM, initiateCall);
	} catch (error) { }
}

// send room id to server for broadcast
export function* attemptingCall() {
	try {
		yield takeLatest(SEND_ATTEMPTING_CALL, attempCall);
	} catch (error) { }
}


export function* attemptingCallForPeer() {
	try {
		yield takeLatest(PEER_ATTEMPTING_CALL, attempCall);
	} catch (error) { }
}

export function* sendCallInvite() {
	try {
		yield takeLatest(SEND_PEER_INVITE_REQUEST, sendinvite);
	} catch (error) { }
}

export function* acceptCallInvite() {
	try {
		yield takeLatest(ACCEPT_INVITE_REQUEST, acceptCall);
	} catch (error) { }
}

export function* sendEndCall() {
	try {
		yield takeLatest(SEND_CALL_END, endCall);
	} catch (error) { }
}
export function* forceSocketDsconnect() {
	try {
		yield takeLatest(FORCE_SOCKET_DISCONNECT, function () {
			socket.disconnect()
		})
	} catch (error) { }
}

export function* serverError() {
	try {
		yield takeLatest(SERVER_ERROR, function (action) {
			// createNotification({
			// 	title: 'Server Error!',
			// 	message: action?.payload?.message || "Error connecting to server!",
			// 	type: 'danger'
			// })
		})
	} catch (error) {

	}
}



export default function* initiateSocket() {
	yield all([
		resetSocket(),
		socketListener(),
		sendMessageListener(),
		imageUpdateListener(),
		changeStatus(),
		knockUser(),
		sendMouseEvent(),
		changeName(),
		getRoomId(),
		attemptingCall(),
		attemptingCallForPeer(),
		sendCallInvite(),
		acceptCallInvite(),
		sendEndCall(),
		forceSocketDsconnect(),
		serverError()
	]);
}
