export default function downloadImageFromUrl(href, fileName = 'image') {
	fetch(href, {
		method: 'GET',
		headers: {}
	})
		.then((response) => {
			response.arrayBuffer().then(function (buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${fileName}.png`);
				document.body.appendChild(link);
				link.click();
			});
		})
		.catch((err) => {
			console.log(err);
		});
}
