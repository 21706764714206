import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { LOGGED_OUT } from '../actions/user.actions';

function usePermissions({ name, eventName, descriptor = {} }) {
    const noop = function () { };
    const [permission, setPermission] = useState('prompt');
    const [isLoaded, setIsLaoded] = useState(false)


    function handleChange(permissionName, newState) {
        if (newState === 'denied') {
            error();
            window.store.dispatch({ type: LOGGED_OUT });
        }

        setPermission(newState)
    }


    async function checkPermission(permissionName, descriptor) {
        return navigator.permissions.query(Object.assign({ name: permissionName }, descriptor))
            .then(function (permission) {
                console.log(permission.state, 'permission.state');
                setPermission(permission.state);
                if (permission.state === 'denied') {
                    error()
                    window.store.dispatch({ type: LOGGED_OUT });
                }
                permission.addEventListener('change', function (e) {
                    handleChange(permissionName, permission.state);
                });
            });

    }
    function error() {
        Modal.error({
            title: `Permission not found!`,
            content: `Enable ${name} permission from the broswer's setting to use this site.`,
            okText: 'Grant Permission',
            onOk: handlePermission,
            okButtonProps: { style: { display: 'none' } },
            cancelButtonProps: { style: { display: 'none' } },
        });
    }

    function handlePermission() {
        navigator.permissions.query({ name }).then(function (result) {
            if (result.state == 'granted') {
                report(result.state);
            } else if (result.state == 'prompt') {
                report(result.state);
                navigator.getUserMedia({ [`${eventName}`]: true }, noop, noop)
            } else if (result.state == 'denied') {
                report(result.state);
            }
            result.onchange = function () {
                report(result.state);
            }
        });
    }

    function report(state) {
        console.log('Permission ' + state);
    }


    useEffect(() => {
        (async () => {
            try {
                if (!isLoaded) {
                    setIsLaoded(true)
                    navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
                    await checkPermission(name, descriptor);
                    navigator.getUserMedia({ [`${eventName}`]: true }, noop, noop)
                    setIsLaoded(false)
                }
            } catch (error) {

            }
        })()
        return () => {
        };
    }, []);

    return permission;
}

export default usePermissions;