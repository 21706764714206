import React from 'react';
import ReactTooltip from 'react-tooltip';
import ThreeDotIcon from '../CenterPanel/SvgComponents/ThreeDotIcon';
import UserStatusIcon from '../LeftPanel/Rooms/UserStatusIcon';
import { STATUS, SOCKET_STATUS } from '../../helpers/commonConsts';

function PanelHeader({ allUsers, onlineMembers }) {
	return (
		<div className="panel-header-right p:.8 p-l:2 p-r:2 p-t:.9 circular">
			<div className="m-b:.0 f-w:500 f:1 section-header p-t:.2">
				<span className="m-r:.5">Chat Heads</span>
			</div>
			{/* <div className="sub-title2 f-w:100 f:.8 d:i-b p-l:.0">
				{' '}
				&nbsp;Total {Object.keys(allUsers).length} members
			</div> */}
			<div className="sub-title2 f-w:100 f:.8 d:i-b p-l:.0">
				{/* {' '}
				&nbsp;&nbsp;&nbsp; <UserStatusIcon socketStatus={SOCKET_STATUS.CONNECTED} status={STATUS.ONLINE} />{' '} */}
				{Object.keys(onlineMembers).length} {Object.keys(onlineMembers) <= 1 ? 'member' : 'members'}
			</div>
		</div>
	);
}

export default PanelHeader;
