import React, { useEffect } from 'react';
import isElectron from 'is-electron';

function AudioNotification({ id, src, autoPlay = true, isMe }) {
	useEffect(() => {

		console.log(id, src)
		if (isElectron()) {
			(async () => {
				await window.ipcRenderer.invoke('play-audio', { src, id, isMe, title: null, message: null });

			})()
		}
	}, [])

	return (
		!isElectron() && <>
			<audio id={id} src={src} autoPlay={autoPlay}>
				<script>alert("Your browser doesn't support the Audio tag!")</script>
				<embed src={src} width="180" height="90" hidden="true" />
			</audio>
		</>
	);
}

export default AudioNotification;
