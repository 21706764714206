import React, { useState } from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import CopyIcon from './SvgComponents/CopyIcon';
import { formatBytes, sleep } from '../../helpers/utils';
import ImageViewer from '../ModalComponents/ImageViewer';
import { SERVER_URL } from '../../config';
import { ATTACHMENT_TYPE } from '../../helpers/commonConsts';
import ZipSvg from '../../images/Zip.svg';
import FileIcon from '../../images/File.svg';
import DownloadIcon from '../../images/Download.svg';
import ReplyIcon from './SvgComponents/ReplyIcon';
import EditIcon from './SvgComponents/EditIcon';
import UserProfile from '../HelperComponents/UserProfile';

export const ImageView = ({ image, updateModal, fileName }) => {
	return (
		<div className="f-w:100 circular sub-title f:.9 m-b:1 m-t:.6">
			<img
				src={image}
				className="b-r:.1 cursor"
				style={{ height: 180, pointerEvents: 'all' }}
				onClick={() => {
					updateModal &&
						updateModal({
							show: true,
							ModalBody: ImageViewer,
							title: fileName || 'Image',
							image: image,
							modalWidth: 'auto',
							modalHeight: 'auto'
						});
				}}
			/>
		</div>
	);
};

const ActionBlock = ({ message, top, right, setActiveReply, setActiveEdit, isMessageAuthor }) => {
	const [showCopyMessage, setShowCopyMessage] = useState(false);
	return (
		<div className="action-block-chat d:i-b v-a:t fl:r">
			<div className="copy-message-box" style={{ top, right }}>
				<div
					style={{ marginLeft: -20 }}
					className={`d:i-b ${
						!showCopyMessage ? 'copied-message' : 'copied-message-active'
					} m-r:1 v-a:m m-t:.3`}
				>
					Message copied
				</div>
				<div className="d:i-b action-buttons-chat">
					<CopyToClipboard
						text={message.message.text}
						onCopy={async () => {
							setShowCopyMessage(true);
							await sleep(700);
							setShowCopyMessage(false);
						}}
					>
						<div data-tip data-for="chatCopy" className="d:i-b v-a:m p-r:1 cursor" title="Copy message">
							<CopyIcon width="1em" height="1em" />
						</div>
					</CopyToClipboard>

					{isMessageAuthor && (
						<div
							data-tip
							data-for="chatCopy"
							className="d:i-b v-a:m p-r:.9 cursor"
							title="Edit"
							onClick={() => {
								setActiveReply('');
								setActiveEdit(message);
							}}
						>
							<EditIcon width="0.80em" height="0.80em" />
						</div>
					)}
					<div
						data-tip
						data-for="chatCopy"
						className="d:i-b v-a:m"
						title="Reply"
						onClick={() => {
							setActiveReply(message);
							setActiveEdit('');
							let inputBox = document.getElementsByClassName('search-left-panel')[1];
							if (inputBox) {
								inputBox.focus();
							}
						}}
					>
						<ReplyIcon width="1.1em" height="1.1em" />
					</div>
				</div>
			</div>
		</div>
	);
};

export function renderAttachments(file, updateModal) {
	switch (file.attachmentType) {
		case ATTACHMENT_TYPE.TEXT:
			return file.attachmentType;
		case ATTACHMENT_TYPE.IMAGE:
			return (
				<ImageView image={SERVER_URL + '/' + file.name} fileName={file.fileName} updateModal={updateModal} />
			);
		default:
			return (
				<div className="file-block m-b:.5 m-t:.5" style={{ maxWidth: 300 }}>
					<div className="d:i-b v-a:m">
						<img
							src={file.attachmentType == ATTACHMENT_TYPE.COMPRESSED ? ZipSvg : FileIcon}
							style={{ height: 35, width: 35 }}
						/>
					</div>
					<div className="d:i-b circular f-w:100 v-a:m  m-l:.7">
						<a
							target="_blank"
							className="file-link circular"
							href={SERVER_URL + '/' + file.name}
							download={file.fileName}
						>
							{file.fileName}
						</a>

						<div style={{ opacity: 0.5, fontSize: 11 }} className="f-w:100">
							{formatBytes(Number(file.size))}
						</div>
					</div>
					<a className="v-a:m d:i-b m-l:.9 cursor" target="_blank" href={SERVER_URL + '/' + file.name}>
						<img src={DownloadIcon} width="20px" height="20px" />
					</a>
				</div>
			);
	}
}

const ChatMessage = ({
	message,
	updateModal,
	previousMessage,
	nextMessage,
	allUsers,
	setActiveReply,
	isMessageAuthor,
	setActiveEdit,
	profilePic,
	userProfile
}) => {
	let currentMessageTime = moment(message.timestamp),
		currentMessageDate = moment(message.timestamp).format('MMM Do YY'),
		showUserInfo = true,
		showUserInfoForNextMessage = true;

	let previousMessageTime, previousMessageDate, nextMessageTime, nextMessageDate;
	if (previousMessage) {
		previousMessageTime = moment(previousMessage.timestamp);
		previousMessageDate = moment(previousMessage.timestamp).format('MMM Do YY');
	}
	if (nextMessage) {
		nextMessageTime = moment(nextMessage.timestamp);
		nextMessageDate = moment(nextMessage.timestamp).format('MMM Do YY');
	}
	if (previousMessageDate && previousMessage.from == message.from && previousMessageDate == currentMessageDate) {
		let messageTimeDiff = currentMessageTime.diff(previousMessageTime, 'minutes');
		if (messageTimeDiff <= 7) {
			showUserInfo = false;
		}
	}

	if (nextMessageDate && nextMessage.from == message.from && nextMessageDate == currentMessageDate) {
		let messageTimeDiff = nextMessageTime.diff(currentMessageTime, 'minutes');
		if (messageTimeDiff <= 7) {
			showUserInfoForNextMessage = false;
		}
	}
	if (
		message.attachments?.filter((file) => file.attachmentType == ATTACHMENT_TYPE.TEXT).length ||
		previousMessage?.attachments?.filter((file) => file.attachmentType == ATTACHMENT_TYPE.TEXT).length
	) {
		showUserInfo = true;
	}
	if (message?.attachments?.filter((file) => file.attachmentType == ATTACHMENT_TYPE.TEXT).length) {
		showUserInfoForNextMessage = true;
	}
	if (nextMessage?.attachments?.filter((file) => file.attachmentType == ATTACHMENT_TYPE.TEXT).length) {
		showUserInfoForNextMessage = true;
	}
	if (!nextMessage) {
		showUserInfoForNextMessage = true;
	}

	function rightPostion() {
		if (isMessageAuthor) {
			if (showUserInfo && showUserInfoForNextMessage) {
				return -100;
			}

			if (showUserInfo) {
				return -120;
			}

			return -110;
		} else {
			if (showUserInfo && showUserInfoForNextMessage) {
				return -2;
			}

			if (showUserInfo) {
				return -9;
			}

			return -4;
		}
	}
	function topPosition() {
		if (isMessageAuthor) {
			if (showUserInfo && !showUserInfoForNextMessage) {
				return -51;
			}
			if (showUserInfo && showUserInfoForNextMessage) {
				return -54;
			}
			return -50;
		} else {
			if (showUserInfo && !showUserInfoForNextMessage) {
				return -17;
			}
			if (showUserInfo && showUserInfoForNextMessage) {
				return -25;
			}
			if (showUserInfo) {
				return 37;
			}
			return -16;
		}
	}

	return [
		<div
			id={message._id}
			style={{ marginRight: 3 }}
			className={`
			${!showUserInfo ? 'no-user-info' : 'm-t:0 p-t:.0 user-info'}
			${showUserInfo && !showUserInfoForNextMessage ? 'user-info-top m-b:.2 m-t:3' : showUserInfo && 'chat-block p-t:.7'}
			${!showUserInfo && showUserInfoForNextMessage && 'm-b:3'}
			${showUserInfo && showUserInfoForNextMessage && 'm-b:3'}
			`}
		>
			{showUserInfo && (
				<div className="m-r:.9 d:i-b v-a:t circular" onClick={() => userProfile(message.from)}>
					{profilePic ? (
						<UserProfile styleProps={{ width: 35, height: 35 }} profilePic={profilePic} />
					) : (
						<Avatar
							size="35"
							textSizeRatio={2.3}
							round={true}
							name={message.fromName}
							className="circular cursor"
						/>
					)}
				</div>
			)}
			<div
				className={`v-a:t d:i-b ${showUserInfo && showUserInfoForNextMessage && ' p-b:.9'} ${
					!showUserInfo && 'chat-block m-l:7 p-t:.1 p-b:.1'
				}`}
				style={{ width: 'calc(100% - 50px)' }}
			>
				<div className="f-w:400 circular" style={{ height: !showUserInfo && 0 }}>
					<span className="f:1">{showUserInfo && message.fromName} </span>
					{showUserInfo && message.timestamp && (
						<span className="sub-title2 f-w:100 f:.8 m-l:.2">
							<span className="m-r:.2">&#183;</span> {moment(message.timestamp).format('MMM Do, h:mm a')}
						</span>
					)}
					<ActionBlock
						setActiveEdit={setActiveEdit}
						isMessageAuthor={isMessageAuthor}
						setActiveReply={setActiveReply}
						message={message}
						top={topPosition()}
						right={rightPostion()}
					/>
				</div>

				{message.image ? <ImageView image={message.image} updateModal={updateModal} /> : null}

				{message.attachments?.length
					? message.attachments
							.filter((file) => file.attachmentType != ATTACHMENT_TYPE.TEXT)
							.map((file) => {
								return renderAttachments(file, updateModal);
							})
					: null}

				<div className="f-w:100 circular sub-title f:.9" style={{ marginBottom: '-1em' }}>
					<ReactMarkdown skipHtml={true} children={message.message.text} remarkPlugins={[gfm]} />
				</div>

				{message.attachments
					?.filter((file) => file.attachmentType == ATTACHMENT_TYPE.TEXT)
					.map((file) => (
						<div className="m-t:2 f:.8 m-l:.3">
							<i className="dm-sans f-w:400 sub-title2">in reply to </i>
							<div className="quoted-block p:.5 m-t:.1 p-l:.8">
								<div className="f-w:400 circular sub-title f:.9">
									{allUsers[file.replyTo.from].name}
								</div>
								<div className="f-w:100 circular sub-title2 f:.8">{file.replyTo.message}</div>
							</div>
						</div>
					))}
			</div>
		</div>
	];
};

export default ChatMessage;
