import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Me from './Me';
import useDimentionObserver from '../../hooks/useDimentionObserver';

// import { getWidthForPeer } from '../../helpers/utils';
import { Appear } from './transitions';
import classnames from 'classnames';

export const MePeer = ({
	audioProducer,
	videoProducer,
	shareProducer,
	me,
	activeSpeakerId,
	peers,
	meShareScreenLength,
	consumerShareScreensLength
}) => {
	const cssStyle = {
		border: '3px solid rgba(255, 255, 255, 0.15)',
		boxShadow: '0px 5px 12px 2px rgb(17 17 17 / 50%)',
		transitionProperty: 'border-color',
		transitionDuration: '0.35s',
		borderRadius: '5px'
	};

	const ref = useRef(null);
	const dimentions = useDimentionObserver(ref);
	const actualDimentions =
		dimentions?.height && dimentions?.width
			? Object.freeze({
					height: dimentions?.height,
					width: dimentions?.width
			  })
			: null;
	return (
		<>
			<Appear key={me.id + videoProducer?.type} duration={1000}>
				<div
					ref={ref}
					style={{
						borderWidth: 3,
						// width: getWidthForPeer(peers?.length + consumerShareScreensLength + meShareScreenLength),
						...cssStyle
					}}
					className={classnames('peer-container peer-main-container', {
						'active-speaker': me.id === activeSpeakerId
					})}
				>
					<Me audioProducer={audioProducer} videoProducer={videoProducer} isSharing={shareProducer} />
				</div>
			</Appear>

			{shareProducer ? (
				<Appear key={me.id + shareProducer.id} duration={1000}>
					<div
						style={{
							borderWidth: 3,
							maxHeight: actualDimentions?.height || 'auto',
							// width: getWidthForPeer(peers?.length + consumerShareScreensLength + meShareScreenLength),
							...cssStyle
						}}
						className={classnames('peer-container peer-main-container', {
							'active-speaker': me.id === activeSpeakerId
						})}
					>
						<Me videoProducer={shareProducer} hideControls={true} disableAudioObserver={true} />
					</div>
				</Appear>
			) : null}
		</>
	);
};

MePeer.propTypes = {
	props: PropTypes
};

const mapStateToProps = (state) => {
	const peersArray = Object.keys(state.call.peers).length > 0 ? Object.values(state.call.peers) : [];
	const me = state.me;
	const producersArray = Object.values(state.producers);
	const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer = producersArray.find(
		(producer) => producer.track.kind === 'video' && producer?.type !== 'share'
	);
	const shareProducer = producersArray.find(
		(producer) => producer.track.kind === 'video' && producer?.type === 'share'
	);

	return {
		connected: state.room.state === 'connected',
		audioProducer: audioProducer,
		videoProducer: videoProducer,
		shareProducer: shareProducer,
		me,
		peers: peersArray
	};
};

export default connect(mapStateToProps, null)(MePeer);
