import React, { useState } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import '../../css/ContextMenu.scss';
import { SOCKET_STATUS, STATUS } from '../../helpers/commonConsts';
function MembersContextMenu() {
	const [isAdmin, setIsAdmin] = useState(false);
	const [isCurrentUser, setIsCurrentUser] = useState(false);
	const [status, setStatus] = useState('');
	const [loggedInUser, setLoggedInUser] = useState('');
	const [invitedUser, setInvitedUser] = useState('');
	function handleClick(e, data, target, actionType) {
		switch (actionType) {
			case 'profile':
				data.profile();
				break;

			case 'message':
				data.message();
				break;

			case 'call':
				data.call(data.user);
				break;

			case 'makeAdmin':
				data.makeAdmin(data.user);
				break;

			case 'removeFromGroup':
				data.removeFromGroup(data.user);
				break;

			case 'deleteUser':
				break;

			case 'removeFromTeam':
				data.disableUser(data.user);
				break;
			default:
				return false;
		}
	}

	function prepareContextData(e) {
		setIsAdmin(e.detail.data.isAdmin);
		setIsCurrentUser(e.detail.data.isCurrentUser);
		setStatus(e.detail.data.user.socketStatus);
		setInvitedUser(e.detail.data.user);
		setLoggedInUser(e.detail.data.loggedInUser);
	}

	function callReady() {
		if (
			invitedUser.callConnecting ||
			invitedUser.socketStatus === SOCKET_STATUS.DISCONNECTED ||
			invitedUser.socketStatus === SOCKET_STATUS.CONNECTING ||
			invitedUser.status === STATUS.BUSY ||
			invitedUser.status === STATUS.AWAY ||
			// (invitedUser.activeCallId && call.roomId && invitedUser.activeCallId == call.roomId) ||
			isCurrentUser ||
			status !== SOCKET_STATUS.CONNECTED
		) {
			return true;
		}
		return false;
	}

	return (
		<div>
			<ContextMenu
				onShow={(e) => prepareContextData(e)}
				id="same_unique_identifier"
				style={{ zIndex: 1111 }}
				className="context-menu-box circular f-w:300"
			>
				<MenuItem
					onClick={(e, data, target) => handleClick(e, data, target, 'profile')}
					className="cursor context-item"
				>
					Profile
				</MenuItem>
				<MenuItem
					disabled={isCurrentUser ? true : false}
					onClick={(e, data, target) => handleClick(e, data, target, 'message')}
					className="cursor context-item"
				>
					Message
				</MenuItem>

				<MenuItem
					disabled={callReady() ? true : false}
					data={{ foo: 'bar' }}
					onClick={(e, data, target) => handleClick(e, data, target, 'call')}
					className="cursor context-item"
				>
					Call
				</MenuItem>

				{isAdmin &&
					!isCurrentUser && [
						<MenuItem
							data={{ foo: 'bar' }}
							onClick={(e, data, target) => handleClick(e, data, target, 'makeAdmin')}
							className="cursor context-item"
						>
							Make admin
						</MenuItem>,
						<MenuItem divider className="divider-context" />,

						<MenuItem
							data={{ foo: 'bar' }}
							onClick={(e, data, target) => handleClick(e, data, target, 'removeFromGroup')}
							className="cursor context-item"
						>
							Remove from group
						</MenuItem>,
						// <MenuItem
						// 	disabled={true}
						// 	data={{ foo: 'bar' }}
						// 	onClick={(e, data, target) => handleClick(e, data, target, 'deleteUser')}
						// 	className="cursor context-item"
						// >
						// 	Delete user
						// </MenuItem>,
						<MenuItem
							data={{ foo: 'bar' }}
							onClick={(e, data, target) => handleClick(e, data, target, 'removeFromTeam')}
							className="danger-items cursor context-item"
						>
							Remove from team
						</MenuItem>
					]}
			</ContextMenu>
		</div>
	);
}

export default MembersContextMenu;
